/* eslint-disable no-unused-vars */
import { useMemo } from 'react'
import { ArrowForward, Save } from '@mui/icons-material'
import { Box, CircularProgress, Divider, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { useProposalContext } from '~/contexts'
import { Button } from '~/components/Form/Button'
import { ProposalFlowActionDropdown, PropsWithWizard } from '~/components'
import { BaseDataForm } from '../../utils'
import { ECustomerPersonType, EProposalStatus } from '~/graphql/types'
import { usePermission } from '~/hooks'
import { theme } from '~/utils'

export type ActionButtonsProps = {
  loading: boolean
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}
export const ActionButtons: React.FC<PropsWithWizard<ActionButtonsProps>> = ({ loading, onSubmit, onNext }) => {
  const navigate = useNavigate()
  const { proposal } = useProposalContext()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const isFormalized = proposal?.status === EProposalStatus.formalization
  const isCanceled = proposal?.status === EProposalStatus.canceled
  const disableFields = proposal?.status !== EProposalStatus.documentation

  const onPrevClick = () => {
    navigate('/app/proposals')
  }

  const { watch } = useFormContext<BaseDataForm>()
  const [name, email, document, phone, uf, nationality, maritalStatus, dealershipId, averageEnergyBillValue, personType] = watch(['name', 'email', 'document', 'phone', 'uf', 'nationality', 'maritalStatus', 'dealershipId', 'averageEnergyBillValue', 'personType'])

  const canEditProposal = usePermission(['proposals.updated'])

  const hasCompletedFormFields = useMemo(() => {
    const commonValidation = Boolean(
      name &&
      email &&
      document &&
      phone &&
      uf &&
      dealershipId &&
      averageEnergyBillValue
    )

    if (personType === ECustomerPersonType.pf) {
      return commonValidation && Boolean(maritalStatus && nationality)
    }

    return commonValidation
  }, [name, email, document, phone, uf, nationality, maritalStatus, dealershipId, averageEnergyBillValue, personType])

  const disableNextClick = useMemo(() => {
    return !hasCompletedFormFields
  }, [hasCompletedFormFields])

  const handleSubmit = async (submitType: 'continue' | 'save') => {
    await onSubmit()
    if (submitType === 'continue') {
      onNext()
    }
  }

  return (
    <>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }} >
        {!isLowerMd && (
          <Button disabled={loading} color='secondary' onClick={onPrevClick}>
            Cancelar
          </Button>
        )}

        {proposal ? (
          <Box sx={{ display: 'flex', flexDirection: isLowerSm ? 'column' : 'row', gap: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: isLowerSm ? 'space-between' : 'flex-end' }}>
              <ProposalFlowActionDropdown disabled={loading || disableFields} proposal={proposal} />

              <Button
                size='small'
                color='secondary'
                onClick={() => handleSubmit('save')}
                disabled={loading || !canEditProposal || disableFields}
                startIcon={loading ? <CircularProgress size={24} color='inherit' /> : <Save />}
              >
                Gravar dados
              </Button>

            </Box>
            <Button fullWidth={isLowerSm} onClick={() => handleSubmit('continue')} endIcon={<ArrowForward />} disabled={loading || !canEditProposal || disableFields}>
              Continuar
            </Button>
          </Box>
        ) : (
          <Button
            onClick={() => handleSubmit('save')}
            startIcon={<Save />}
            disabled={disableNextClick || isFormalized || isCanceled || !canEditProposal}
          >
            Cadastrar proposta
          </Button>
        )}
      </Box>
    </>
  )
}
