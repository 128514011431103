import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IconArrowRight, IconPlus, IconSearch } from '@tabler/icons-react'
import { Box, Button, Card, IconButton, InputAdornment, TextField, useMediaQuery } from '@mui/material'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { displayCpfCnpj, displayPhone, ifIsDocumentReturnFormated, theme, trimString } from '~/utils'
import { ContentTitle } from '~/components/ContentTitle'
import { CustomerStatus, FilterButton, TableApiV2, TableHeaderApi } from '~/components'
import { SearchQueysData, useDebounce, usePermission, useQueryString, useToggle } from '~/hooks'
import { ECustomerPersonType, useCustomerListQuery } from '~/graphql/types'
import { FilterCustomerDrawer } from './components'

export const CustomersList: React.FC = () => {
  const navigate = useNavigate()
  const { isTrue: drawerIsOpen, toggle: toggleDrawer, setIsTrue: setDrawerIsOpen } = useToggle(false)

  const queryKeys: SearchQueysData[] = [
    { key: 'person', defaultValue: [], parseValue: true },
    { key: 'name', defaultValue: '' },
    { key: 'begin', defaultValue: '' },
    { key: 'end', defaultValue: '' },
  ]
  const { searchQuerys, paginationQuerySize, searchParams, setSearchParams, page, size } = useQueryString()

  const queryValues = searchQuerys(queryKeys)

  const isLowerlg = useMediaQuery(theme.breakpoints.down('xl'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [filterName, setFilterName] = useState(queryValues.name)

  const debouncedFilterName = useDebounce(filterName, 1000)

  useEffect(() => {
    setSearchParams(state => {
      if(debouncedFilterName) {
        state.set('name', debouncedFilterName)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }, [debouncedFilterName])

  const { data, loading: customersListIsLoading } = useCustomerListQuery({
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        sort: {
          field: 'createdAt',
          order: -1
        },
        ...queryValues?.name?.length > 0 ? { nameOrDocumentOrEmail: ifIsDocumentReturnFormated(queryValues.name) } : {},
        ...queryValues?.person?.length > 0 ? { personType: queryValues.person[0] } : {},
        ...queryValues?.begin && queryValues?.end ? 
          { dateRange: 
          { startDate: new Date(queryValues.begin), 
            endDate: new Date(`${queryValues.end}Z23:59:59`) 
          }
          } 
          : {},
      }
    },
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.customerList.meta.documentCounts))
        return state
      })
    },
    fetchPolicy: 'no-cache'
  })

  const canEditClients = usePermission(['customers.create', 'customers.updated'])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'name',
        Cell: ({ value }) => <>{trimString(value, { maxTextLength: 20, textCutLength: 20 })}</>
      },
      {
        Header: 'Documento',
        accessor: 'document',
        Cell: ({ value }) => <>{displayCpfCnpj(value)}</>
      },
      {
        Header: 'Tipo',
        accessor: 'personType',
        Cell: ({ value }) => <>Pessoa {value === ECustomerPersonType.pf ? 'Física' : 'Jurídica'}</>
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
        Cell: ({ value }) => <>{displayPhone(value)}</>
      },
      {
        Header: 'E-mail',
        accessor: 'email',
        Cell: ({ value }) => <>{trimString(value, { maxTextLength: 40, textCutLength: 40 })}</>
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <CustomerStatus status={value} />
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        Cell: ({ value }) => (
          <>
            {canEditClients ? (
              <Link to={`/app/customers/${value}`}>
                <IconButton>
                  <IconArrowRight />
                </IconButton>
              </Link>
            ) : (
              <IconButton disabled={true}>
                <IconArrowRight />
              </IconButton>
            )}
          </>
        )
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.customerList.data || [], [data])

  const defaultColumnHiddens = isLowerlg ? ['document'].concat(isLowerMd ? ['phone'] : '').concat(isLowerSm ? ['email'] : '') : []

  const tableMethods = useTable(
    {
      columns,
      data: dataWithMemo,
      initialState: {
        hiddenColumns: defaultColumnHiddens
      },
      manualPagination: true,
      defaultColumn: {
        Filter: <></>
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  )

  return (
    <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
      <ContentTitle
        title='Lista de clientes'
        description='Consulte todos clientes cadastrados'
        breadcrumbLinks={{ currentLink: 'Listar clientes', links: [{ href: '/app/customers', label: 'Clientes' }] }}
        rightContent={<Button disabled={!canEditClients} onClick={() => navigate('/app/customers/create')} startIcon={<IconPlus />}>Novo cliente</Button>}
      />
      <TableHeaderApi
        startElement={(
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', width: '100%', flexFlow: isLowerSm ? 'column-reverse' : 'row' }}>
            <TextField
              fullWidth
              sx={{ maxWidth: '400px' }}
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              placeholder='Procurar clientes por nome, email ou CPF/CNPJ'
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position='end'>
                      <IconSearch />
                    </InputAdornment>
                  </>
                )
              }}
            />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <FilterButton 
                onCustomClear={() => setFilterName('')} 
                filtersToClear={queryKeys} 
                toggleDrawer={toggleDrawer} 
                filterCounter={searchParams.size - paginationQuerySize} 
              />
            </Box>

            <FilterCustomerDrawer queryKeys={queryKeys}  drawerIsOpen={drawerIsOpen} setDrawerIsOpen={setDrawerIsOpen} toggleDrawer={toggleDrawer} />

          </Box>
        )}
      />

      <TableApiV2
        isLoading={customersListIsLoading}
        columns={columns}
        tableMethods={tableMethods}
      />
    </Card>
  )
}
