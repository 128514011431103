import styled from '@emotion/styled'
import { CSSProperties } from 'react'

type ContainerProps = {
  $isTrue?: boolean
  
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: max-content;
  z-index: ${(props) => props.$isTrue ? '999' : '0'};
`

export type DropProps = {
  $options?: {
    top?: string,
    left?: string
  }
  $isTrue?: boolean
  $minWidht?: CSSProperties['minWidth']
}
export const Drop = styled.div<DropProps>`
  position: ${(props) => props.$isTrue ? 'absolute' : 'relative'};
  top: ${(props) => props.$options?.top ?? '40px'};
  left: ${(props) => props.$options?.left ?? '0'};
  min-width: ${(props) => props.$minWidht || '0'};
`
