import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { theme } from '~/utils'

type RoundedIconProps = {
  children?: ReactNode
}
export const RoundedIcon: React.FC<RoundedIconProps> = ({ children }) => {
  return (
    <Box sx={{ 
      background: theme.palette.grey[100], 
      width: '36px', 
      height: '36px', 
      padding: '.1rem', 
      borderRadius: '50%', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }}>
      {children}
    </Box>
  )
}
