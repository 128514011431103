import { Box, Typography, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { StyledLink } from './styles'

type StepTitleProps = {
  step: {
    title: string,
    value: string
  },
  proposalId?: string,
  clientName?: string
}

export const StepTitle: React.FC<StepTitleProps> = ({ clientName, proposalId, step }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexFlow: isLowerSm ? 'column' : 'row', gap: '1rem' }}>
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <Box sx={{ color: '#fff', background: theme.palette.info.dark, width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '.4rem', }}>
          <Typography>{step.value}</Typography>
        </Box>
        <Typography fontWeight='500' variant='body1'>{step.title}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'flex-end', flexFlow: 'column', gap: '.2rem' }}>
        <Typography color={theme.palette.grey[400]} variant='body3'>{`Proposta #${proposalId}`}</Typography>
        <Typography fontWeight='500' variant='body1'>{clientName}</Typography>
        <StyledLink to={`/app/proposals/${proposalId}`}>Acesso à proposta de origem</StyledLink>
      </Box>
    </Box>
  )
}
