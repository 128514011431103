import { Box, CircularProgress, Typography } from '@mui/material'
import { Dialog } from '~/components'
import { DialogProps } from '~/components/Dialog'

export const CreatingProposalDialog: React.FC<DialogProps> = (props) => {
  return (
    <Dialog {...props}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', textAlign: 'center' }}>
        <CircularProgress size={100} color='inherit' />
        <Typography sx={{ marginTop: 3 }} variant='h3'>Criando proposta...</Typography>
        <Typography color='grey.400'>Aguarde, estamos terminando de <br />gerar a proposta</Typography>
      </Box>
    </Dialog>
  )
}
