import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { IconInfoCircle, IconSearch } from '@tabler/icons-react'
import { Box, CircularProgress, Grid, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { useCep } from '~/hooks'
import { ConsumerUnitForm } from '../..'
import { brazilStates } from '~/constants'
import { Input } from '~/components/Form/Input'
import { Select } from '~/components/Form/Select'
import { Option, customerTensionOptions, getConsumerClassificationOptions, tariffClassificationOptions } from '~/utils/options'
import { useContract } from '~/contexts'
import { EContractStatus } from '~/graphql/types'

export type EnergyBillInfoProps = {
  dealerships: Option[]
  loadingDealerships: boolean
}
export const EnergyBillFields: React.FC<EnergyBillInfoProps> = ({ dealerships, loadingDealerships }) => {
  const { fetchCep, isLoading } = useCep({ showErrorMessage: true })
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { watch, setValue } = useFormContext<ConsumerUnitForm>()
  const { isDisabledContractUpdate, contract } = useContract()

  const currentTariffClassification = watch('tariffClassification')
  const consumerClassificationOptions = useMemo(() => getConsumerClassificationOptions(currentTariffClassification), [currentTariffClassification])

  const address = watch('address')

  const onSearchCep = useCallback(async () => {
    if (address?.zipcode) {
      const { data } = await fetchCep(address?.zipcode)

      if (data) {
        setValue('address.state', data.uf, { shouldDirty: true })
        setValue('address.city', data.localidade)
        setValue('address.street', data.logradouro)
        setValue('address.neighborhood', data.bairro)
        setValue('address.complement', data.complemento)
      }
    }
  }, [address?.zipcode])

  const disabledFields = isDisabledContractUpdate && (contract?.status === EContractStatus.active || contract?.status === EContractStatus.sent || contract?.status === EContractStatus.cancellationRequested || contract?.status === EContractStatus.inDeactivation)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography fontWeight={600}>Dados da fatura</Typography>

      <Grid spacing={3} container>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} mask='BRL' name='AmountCents' label='Valor da fatura' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Select disabled={disabledFields} options={tariffClassificationOptions} name='tariffClassification' label='Classificação tarifárica' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Select disabled={disabledFields} options={consumerClassificationOptions} name='consumerClassification' label='Classe de consumo' />
        </Grid>

        <Grid item md={4} xs={12}>
          <Select disabled={disabledFields} options={customerTensionOptions} name='tension' label='Tensão do consumidor' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} name='installationNumber' label='Instalação / UC' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} name='clientNumber' label='Número do cliente' />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} mask='kwh' name='avarageConsumption' label='Média de consumo' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} name='avarageBillAmountCents' label='Valor médio de consumo' mask='BRL' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Select disabled={loadingDealerships || disabledFields} options={dealerships} name='dealershipId' label='Concessionária' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} name='invoiceDueDay' label='Dia de vencimento' icons={{ end: { element: <Tooltip title='Data máxima para pagamento mensal de fatura. Ex.: Todo dia 05'><IconInfoCircle size={24} color='#3BA1FF' /></Tooltip> } }} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} mask='BRL' name='distributionTaxes' label='Taxa de contribuição' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} mask='BRL' name='energyTaxes' label='Taxa de energia' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} mask='BRL' name='tusdValue' label='Tusd' />
        </Grid>
        {!isMobile && <Grid item xs={8} />}
        <Grid item md={4} xs={12}>
          <Input
            icons={{ end: { element: isLoading ? <CircularProgress size={18} color='inherit' /> : <IconButton onClick={() => onSearchCep()}><IconSearch /></IconButton> } }}
            mask='cep'
            disabled={disabledFields}
            label='CEP'
            name='address.zipcode'
          />
        </Grid>
        {!isMobile && <Grid item xs={8} />}
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} name='address.street' label='Rua' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} name='address.number' label='Número' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} name='address.complement' label='Complemento' />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} name='address.neighborhood' label='Bairro' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disabledFields} name='address.city' label='Cidade' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Select disabled={disabledFields} options={brazilStates} name='address.state' label='UF' />
        </Grid>
      </Grid>
    </Box>
  )
}
