import * as yup from 'yup'
import { toast } from 'react-toastify'
import { Info } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { Dialog } from '~/components/Dialog'
import { Form } from '~/components/Form/Form'
import { Input } from '~/components/Form/Input'
import { useContract } from '~/contexts'
import { Button } from '~/components/Form/Button'
import { useCancelContractMutation } from '~/graphql/types'

type ContractCancelForm = {
  reason: string
}

const schema = yup.object().shape({
  reason: yup.string().required('Campo obrigatório')
})

export type ContractCancelDialogProps = {
  isVisible: boolean
  toggleModal: () => void
}
export const ContractCancelDialog: React.FC<ContractCancelDialogProps> = ({ isVisible, toggleModal }) => {
  const { contract, setContract } = useContract()
  const [cancelContract, { loading }] = useCancelContractMutation()

  const methods = useForm<ContractCancelForm>({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (formData: ContractCancelForm) => {
    if (!contract) {
      return toast.error('Houve um problema ao cancelar o contrato', {
        autoClose: 3000,
        position: 'top-right'
      })
    }

    try {
      const { data } = await cancelContract({
        variables: {
          params: {
            contractId: contract._id,
            reason: formData.reason
          }
        }
      })

      data?.cancelContract && setContract({ ...contract, status: data?.cancelContract?.status })
      toggleModal()
      toast.success('Contrato cancelado com sucesso!', {
        autoClose: 3000,
        position: 'top-right'
      })
    } catch (err) {
      toast.error('Houve um problema ao cancelar o contrato', {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  return (
    <Dialog
      isVisible={isVisible}
      title='Cancelar contrato'
      onClose={toggleModal}
      minWidth={700}
      titleIcon={<Info fontSize='large' color='error' />}
    >
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box sx={{ flexDirection: 'column', display: 'flex', gap: 3 }}>
            <Divider />
            
            <Typography>Informe o motivo de cancelamento do contrato:</Typography>
            <Input minRows={10} multiline name='reason' placeholder='Escreva aqui suas observações' />

            <Typography>Ao cancelar a proposta, não será possível continuar com ela.</Typography>

            <Divider />

            <Box sx={{ flexDirection: 'row', gap: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                color='secondary'
                disabled={loading}
                onClick={toggleModal}
              >
                Voltar para a proposta
              </Button>

              <Button
                type='submit'
                color='error'
                disabled={loading}
                endIcon={loading ? <CircularProgress size={24} color='inherit' /> : null}
              >
                Cancelar contrato
              </Button>
            </Box>
          </Box>
        </Form>
      </FormProvider>
    </Dialog>
  )
}
