import { EStage } from '~/graphql/types'

export const customStagesFormalization = {
  [EStage.negotiating]: [
    EStage.lost,
    EStage.awaitingSignature,
  ],
  [EStage.lost]: [
    EStage.negotiating,
    EStage.awaitingSignature,
  ]
}

// eslint-disable-next-line no-unused-vars
export const enableStagesByActualStage: { [x in EStage]: EStage[] } = {
  [EStage.lead]: [
    EStage.opportunity, 
    EStage.negotiating,
    EStage.negotiationCoolingDown,
    EStage.firstContactAttempt,
    EStage.secondContactAttempt,
    EStage.thirdContactAttempt,
    EStage.contactReestablished,
    EStage.legalAssembly,
    EStage.lost,
  ],
  [EStage.opportunity]: [
    EStage.negotiating,
    EStage.negotiationCoolingDown,
    EStage.firstContactAttempt,
    EStage.secondContactAttempt,
    EStage.thirdContactAttempt,
    EStage.contactReestablished,
    EStage.legalAssembly,
    EStage.lost,
  ],
  [EStage.negotiating]: [
    EStage.negotiationCoolingDown,
    EStage.firstContactAttempt,
    EStage.secondContactAttempt,
    EStage.thirdContactAttempt,
    EStage.contactReestablished,
    EStage.legalAssembly,
    EStage.lost,
  ],
  [EStage.negotiationCoolingDown]: [
    EStage.negotiating,
    EStage.firstContactAttempt,
    EStage.secondContactAttempt,
    EStage.thirdContactAttempt,
    EStage.contactReestablished,
    EStage.legalAssembly,
    EStage.lost,
  ],
  [EStage.firstContactAttempt]: [
    EStage.negotiating,
    EStage.negotiationCoolingDown,
    EStage.secondContactAttempt,
    EStage.thirdContactAttempt,
    EStage.contactReestablished,
    EStage.legalAssembly,
    EStage.lost,
  ],
  [EStage.secondContactAttempt]: [
    EStage.negotiating,
    EStage.negotiationCoolingDown,
    EStage.thirdContactAttempt,
    EStage.contactReestablished,
    EStage.legalAssembly,
    EStage.lost,
  ],
  [EStage.thirdContactAttempt]: [
    EStage.negotiating,
    EStage.negotiationCoolingDown,
    EStage.contactReestablished,
    EStage.legalAssembly,
    EStage.lost,
  ],
  [EStage.contactReestablished]: [
    EStage.negotiating,
    EStage.negotiationCoolingDown,
    EStage.firstContactAttempt,
    EStage.secondContactAttempt,
    EStage.thirdContactAttempt,
    EStage.legalAssembly,
    EStage.lost,
  ],
  [EStage.legalAssembly]: [
    EStage.negotiating,
    EStage.negotiationCoolingDown,
    EStage.firstContactAttempt,
    EStage.secondContactAttempt,
    EStage.thirdContactAttempt,
    EStage.contactReestablished,
    EStage.lost,
  ],
  [EStage.awaitingSignature]: [
    EStage.negotiating,
    EStage.lost
  ],
  [EStage.lost]: [
    EStage.lead,
    EStage.opportunity,
    EStage.negotiating,
    EStage.negotiationCoolingDown,
    EStage.firstContactAttempt,
    EStage.secondContactAttempt,
    EStage.thirdContactAttempt,
    EStage.contactReestablished,
    EStage.legalAssembly,
  ],
  // Não usado
  [EStage.proposalContracted]: []
}
