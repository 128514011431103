import { restApi } from '~/services/api'
import { getToken } from '~/utils/token'
import { CustomerModel, ECustomerFileType, EStorageService, FileModel, FileUploadModel } from '~/graphql/types'
import { isEmpty } from 'ramda'
import { toast } from 'react-toastify'
import { mapErrorToMessage } from '~/utils/errors'
import { ApolloError } from '@apollo/client'
import { clearString } from '~/utils'

export const fileExtension = (file: File) => {
  const splitFilename = file.name.split('.')
  const extensionFile = `.${splitFilename.pop()}`

  return extensionFile
}

const headers = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
  Authorization: `Bearer ${getToken()}` || ''
}

type UpdateFilesInCustomerInput = {
  customerId: string
  file: FileUploadModel
  type: ECustomerFileType
  legalRepresentativeDocument?: string
}

export type UploadMultiFilesParams = {
  files: File[][],
  customerId: string
  types: ECustomerFileType[],
  legalRepresentativeDocument?: string
}

const updateFilesInCustomer = async (params: UpdateFilesInCustomerInput): Promise<CustomerModel> => {
  const { customerId, ...rest } = params
  const { data: updatedCustomer }: { data: CustomerModel } = await restApi.put(`customers/${customerId}/update/files`, { ...rest }, { headers: { Authorization: `Bearer ${getToken()}` || '' } })

  return updatedCustomer
}

export const uploadMultiCustomerFiles = async (params: UploadMultiFilesParams): Promise<CustomerModel> => {
  const { files, types, customerId, legalRepresentativeDocument } = params
  const updatedCustomers: CustomerModel[] = []

  const fileFormData = new FormData()

  files.forEach(mappedFile => {
    fileFormData.append('files', mappedFile[0])
  })

  const { data: sentFiles }: { data: FileModel[] } = await restApi.post('files/upload/multi', fileFormData, { headers })

  for (let i = 0; i < sentFiles.length; i++) {
    const formattedData: UpdateFilesInCustomerInput = {
      type: types[i],
      customerId,
      file: {
        key: sentFiles[i].key,
        fileUrl: sentFiles[i].fileUrl,
        fileName: sentFiles[i].fileName,
        storageService: sentFiles[i].storageService || EStorageService.awsS3
      }
    }

    const formattedDataForLegalRepresentative: UpdateFilesInCustomerInput =
      legalRepresentativeDocument
        ? {
          ...formattedData,
          legalRepresentativeDocument: clearString(legalRepresentativeDocument)
        }
        : formattedData

    const updatedCustomer = await updateFilesInCustomer(formattedDataForLegalRepresentative)
    updatedCustomers.push(updatedCustomer)
  }

  return updatedCustomers[updatedCustomers.length - 1]
}

export const uploadCustomerFiles = async (customer: CustomerModel | null, files: any) => {
  const { customerPjSocialContract, customerPjDocumentCard, customerPfDocumentWithPhotoBack, customerPfDocumentWithPhotoFront } = files

  if (!customer) {
    return
  }

  const filesToUpload: File[][] | null = []
  const fileTypesToUpload: ECustomerFileType[] = []

  if (customerPfDocumentWithPhotoBack && !isEmpty(customerPfDocumentWithPhotoBack) && !customerPfDocumentWithPhotoBack?.size) {
    filesToUpload.push(customerPfDocumentWithPhotoBack)
    fileTypesToUpload.push(ECustomerFileType.customerPfDocumentWithPhotoBack)
  }

  if (customerPfDocumentWithPhotoFront && !isEmpty(customerPfDocumentWithPhotoFront) && !customerPfDocumentWithPhotoFront?.size) {
    filesToUpload.push(customerPfDocumentWithPhotoFront)
    fileTypesToUpload.push(ECustomerFileType.customerPfDocumentWithPhotoFront)
  }

  if (customerPjSocialContract && !isEmpty(customerPjSocialContract) && !customerPjSocialContract?.size) {
    filesToUpload.push(customerPjSocialContract)
    fileTypesToUpload.push(ECustomerFileType.customerPjSocialContract)
  }
  if (customerPjDocumentCard && !isEmpty(customerPjDocumentCard) && !customerPjDocumentCard?.size) {
    filesToUpload.push(customerPjDocumentCard)
    fileTypesToUpload.push(ECustomerFileType.customerPjDocumentCard)
  }

  if (isEmpty(filesToUpload)) {
    return
  }

  try {
    return uploadMultiCustomerFiles({ customerId: customer._id, files: filesToUpload, types: fileTypesToUpload })
  } catch (err) {
    const apolloError = err as ApolloError
    toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao enviar os arquivos'), {
      autoClose: 3000,
      position: 'top-right'
    })
  }
}
