import { EProposalStatus } from '~/graphql/types'
import { Container } from './styles'
import { EDigitalAccountStatus, translateDigitalAccountStatus } from '~/utils'

type SingleAccountStatusProps = {
  status: EDigitalAccountStatus
}
export const SingleAccountStatus: React.FC<SingleAccountStatusProps> = ({ status }) => {
  return (
    <Container status={status || EProposalStatus.finalized}>
      <span>{translateDigitalAccountStatus(status)}</span>
    </Container>
  )
}
