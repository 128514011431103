import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { IconAlarm, IconCheck, IconClock, IconInfoCircle } from '@tabler/icons-react'
import { CustomerPendencyFields, EProposalFileStatus } from '~/graphql/types'
import { theme } from '~/utils'

type InputPendenciesStatusProps = {
  pendencie?: CustomerPendencyFields,
  status?: EProposalFileStatus
}
export const InputPendenciesStatus: React.FC<InputPendenciesStatusProps> = ({ pendencie, status }) => {

  const isRefused = Boolean(pendencie?.rejectedAt)
  const isAccepted = Boolean(pendencie?.acceptedAt)
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  if(status === EProposalFileStatus.pending) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '.2rem' }}>
        {!isLowerSm && 
          <Typography color={theme.palette.yellow.main} variant='body2'>Pendente</Typography>
        }
        <Tooltip title='Pendente'>
          <IconAlarm color={theme.palette.yellow.main} size={16} />
        </Tooltip>
      </Box>
    )
  }

  if(isAccepted || status === EProposalFileStatus.accepted) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '.2rem' }}>
        {!isLowerSm && 
          <Typography color={theme.palette.success.main} variant='body2'>Aprovado</Typography>
        }
        <Tooltip title='Aprovado'>
          <IconCheck color={theme.palette.success.main} size={16} />
        </Tooltip>
      </Box>
    )
  }

  if(isRefused || status === EProposalFileStatus.rejected) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '.2rem' }}>
        {!isLowerSm && 
          <Typography color={theme.palette.error.main} variant='body2'>Recusado</Typography>
        }
        <Tooltip title='Recusado'>
          <IconInfoCircle color={theme.palette.error.main} size={16} />
        </Tooltip>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '.2rem' }}>
      {!isLowerSm && 
        <Typography color={theme.palette.info.dark} variant='body2'>Em análise</Typography>
      }
      <Tooltip title='Em análise'>
        <IconClock color={theme.palette.info.dark} size={16} />
      </Tooltip>
    </Box>
  )
}
