/* eslint-disable no-unused-vars */
import { useState, createContext, PropsWithChildren, useContext } from 'react'
import { ProposalModel } from '~/graphql/types'

type ProposalContextValue = {
  proposal: ProposalModel | null
  resetProposal: () => void
  setProposal: (proposal: ProposalModel | null) => void
}

export const ProposalContext = createContext<ProposalContextValue>({
  proposal: null,
  setProposal: () => null,
  resetProposal: () => null,
})

export const ProposalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [proposal, setProposal] = useState<ProposalModel | null>(null)

  const resetProposal = () => {
    setProposal(null)
  }

  return (
    <ProposalContext.Provider value={{ proposal, setProposal, resetProposal }}>
      {children}
    </ProposalContext.Provider>
  )
}

export const useProposalContext = () => useContext(ProposalContext)
