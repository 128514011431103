/* eslint-disable no-unused-vars */
import { useState, useMemo, useCallback } from 'react'

export type UseWizardResult = {
  totalPages: number
  currentPage: number
  onPrev: () => void
  additionalParams: any
  lastPageReached: boolean
  initialPageReached: boolean
  onNext: () => void
  goToPage: (page: number) => void
}

export const useWizard = (totalPages: number): UseWizardResult => {
  const [currentPage, setCurrentPage] = useState(0)
  const [additionalParams, setAdditionalParams] = useState(null)
  const initialPageReached = useMemo(() => currentPage === 0, [currentPage])
  const lastPageReached = useMemo(() => currentPage === totalPages - 1, [length, currentPage])

  const onNext = useCallback((params?: any) => {
    if (lastPageReached) {
      return
    }

    if (params) {
      setAdditionalParams(params)
    }

    setCurrentPage(prev => prev + 1)
  }, [setCurrentPage, setAdditionalParams, lastPageReached])

  const onPrev = useCallback(() => {
    if (initialPageReached) {
      return
    }

    setCurrentPage(prev => prev - 1)
  }, [initialPageReached, setCurrentPage])

  const goToPage = useCallback((page: number) => {
    setCurrentPage(page)
  }, [setCurrentPage])

  return {
    onNext,
    onPrev,
    goToPage,
    totalPages,
    currentPage,
    lastPageReached,
    additionalParams,
    initialPageReached,
  }
}
