
/* eslint-disable no-unused-vars */

import { Box, IconButton, Link, Typography } from '@mui/material'
import { useToggle } from '~/hooks'
import { Dropdown } from '~/components'
import { ThreeDots } from '~/assets/icons'
import { IconCheck, IconDownload, IconRefresh, IconTrash, IconX } from '@tabler/icons-react'
import { ContractTermOfManagementModel, ETermOfManagementType, ETermStatus, useContractRequestTermOfManagementMutation } from '~/graphql/types'
import { useTheme } from '@emotion/react'
import { ApproveOrRefuseModalType, ApproveOrRefuseTermModal } from '../ApproveOrRefuseTermModal'
import { useContract } from '~/contexts'
import { toast } from 'react-toastify'

export type ManualTermOptionsProps = {
  term?: ContractTermOfManagementModel | null
}
export const ManualTermOptions: React.FC<ManualTermOptionsProps> = ({ term }) => {
  const theme = useTheme()
  const { contract, setContract } = useContract()
  const { isTrue: approveModalIsVisible, toggle: approveModalToggle } = useToggle()
  const { isTrue: refuseModalIsVisible, toggle: refuseModalToggle } = useToggle()
  const hasDownloadLink = Boolean(term?.file?.fileUrl) && term?.file?.fileUrl !== '-'

  const canApproveAndRefuse = term?.status === ETermStatus.uploaded
  const canChangeTermToAutomatic = term?.status === ETermStatus.refused
  
  const [generateNewAutomaticTerm] = useContractRequestTermOfManagementMutation({
    onCompleted(data) {
      if(contract) {
        toast.success('Seu novo termo de adesão automático foi gerado!')
        setContract({ ...contract, termOfManagement: data.contractRequestTermOfManagement?.termOfManagement })
      }
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  return (
    <>
      <Dropdown
        $options={{ left: '-200px' }}
        $minWidht='240px'
        elementClick={(
          <IconButton>
            <ThreeDots />
          </IconButton>
        )}
      >
        <Box sx={{
          display: 'flex',
          borderRadius: 3,
          flexDirection: 'column',
          minWidth: '220px',
          background: 'white',
          boxShadow: '0px 0px 2px rgba(64, 64, 64, 0.1), 0px 6px 15px -4px rgba(64, 64, 64, 0.14)',
        }}>

          {canApproveAndRefuse && (
            <Box onClick={approveModalToggle} display='flex' flex={1} gap={2} alignItems='center' sx={{ cursor: 'pointer', padding: 2, '&:hover': { background: '#FAFAFA' } }}>
              <IconCheck />
              <Typography variant='body2'>Informar aprovação</Typography>
            </Box>
          )}

          {canApproveAndRefuse && (
            <Box onClick={refuseModalToggle} display='flex' flex={1} gap={2} alignItems='center' sx={{ cursor: 'pointer', padding: 2, '&:hover': { background: '#FAFAFA' } }}>
              <IconX />
              <Typography variant='body2'>Informar recusa</Typography>
            </Box>
          )}
          
          {hasDownloadLink && (
            <Link sx={{ textDecoration: 'none', color: theme.palette.grey[800] }} target='_blank' href={term?.file?.fileUrl || ''} rel='noreferrer'>
              <Box display='flex' flex={1} gap={2} alignItems='center' sx={{ cursor: 'pointer', padding: 2, '&:hover': { background: '#FAFAFA' } }}>
                <IconDownload />
                <Typography variant='body2'>Baixar termo de gestão</Typography>
              </Box>
            </Link>
          )}

          {canChangeTermToAutomatic && (
            <Box
              onClick={() => generateNewAutomaticTerm({ variables: { contractId: contract?._id || '' } })} 
              display='flex' 
              flex={1} 
              gap={2} 
              alignItems='center' 
              sx={{ cursor: 'pointer', padding: 2, '&:hover': { background: '#FAFAFA' } }}>
              <IconTrash color={theme.palette.error.main} />
              <Typography color={theme.palette.error.main} variant='body2'>Cancelar upload manual</Typography>
            </Box>
          )}
        </Box>
      </Dropdown>

      <ApproveOrRefuseTermModal term={term} onClose={approveModalToggle} isVisible={approveModalIsVisible} type={ApproveOrRefuseModalType.approve} />
      <ApproveOrRefuseTermModal term={term} onClose={refuseModalToggle} isVisible={refuseModalIsVisible} type={ApproveOrRefuseModalType.refuse} />
    </>
  )
}
