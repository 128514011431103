import { Autocomplete, Box, FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps, TextField, TextFieldProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { PowerPlantCashbackIcon } from '~/assets/icons'

type SelectProps = MuiSelectProps & TextFieldProps & {
  name: string,
  label?: string,
  options: {
    value: string | number,
    label?: string
    isCashback?: boolean
  }[]
  shouldUnregister?: boolean,
  searchable?: boolean,
  startAdornment?: React.ReactNode
}

export const Select: React.FC<SelectProps> = ({ name, label, shouldUnregister = false, options, searchable = false, startAdornment, ...rest }) => {

  const { control } = useFormContext()

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        shouldUnregister={shouldUnregister}
        defaultValue={options[0]?.value ?? ''}
        render={({ field, fieldState: { error } }) => (
          <>
            {!searchable ? (
              <>
                <InputLabel id={field.name}>{label}</InputLabel>
                <MuiSelect error={Boolean(error?.message)} label={label} {...rest} {...field}>
                  <MenuItem value=''>Selecione</MenuItem>
                  {options.map((option, index) => {
                    return (
                      <MenuItem
                        value={option.value}
                        key={`select-option-${option.value}-${index}`}
                      >
                        <Box
                          sx={{
                            gap: 1,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {option.isCashback && <PowerPlantCashbackIcon />}
                          {option.label}
                        </Box>
                      </MenuItem>
                    )
                  })}
                </MuiSelect>
                {error && <FormHelperText sx={{ color: 'error.main' }}>{error.message}</FormHelperText>}
              </>
            ) : (
              <>
                <Autocomplete
                  autoHighlight
                  options={options}
                  defaultValue={{ value: '', label: 'Selecione' }}
                  onChange={(ev, value) => field.onChange(value?.value)}
                  renderInput={(params) => <TextField {...params} variant='outlined' label={label} {...rest} InputProps={{ ...params.InputProps, startAdornment }} />}
                  renderOption={(props, option) => <Box component='li' {...props} key={option.value}>{option.label}</Box>}
                />
              </>
            )}
          </>
        )}
      />
    </FormControl>
  )
}
