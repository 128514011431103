import { InputHTMLAttributes, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Checkbox, CheckboxProps, FormControlLabel, RadioProps, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import { IconInfoCircle } from '@tabler/icons-react'

export type InnerCheckboxMultipleProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> & Omit<CheckboxProps, 'defaultValue'> & {
  name: string
  checkboxProps?: RadioProps,
  shouldUnregister?: boolean
  options: {
    label?: string,
    value: string,
    category?: string,
    checkboxProps?: RadioProps,
    tooltipLabel?: string
  }[]
}

export const CheckboxMultiple: React.FC<InnerCheckboxMultipleProps> = ({ name, shouldUnregister = false, options }) => {
  const theme = useTheme()
  const formMethods = useFormContext()

  const onClick = useCallback((newValue: string, values: string[] = []) => {
    if (values.includes(newValue)) {
      return values.filter(item => item !== newValue)
    } else {
      return [...values, newValue]
    }
  }, [formMethods])

  return (
    <>
      <Controller
        name={name}
        control={formMethods.control}
        shouldUnregister={shouldUnregister}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <Box sx={{ display: 'flex', flexFlow: 'column', padding: '.8rem 0 0 .6rem' }}>
            {options.map((option) => {
              if (option?.category) {
                return (
                  <Box key={option.value}>
                    <Typography sx={{ marginLeft: '-.6rem', paddingTop: '1rem', fontSize: '.85rem', color: theme.palette.grey[800], fontWeight: 500 }}>{option.category}</Typography>
                    <FormControlLabel
                      key={option.value}
                      label={option.label}
                      checked={value?.includes(option.value)}
                      onChange={() => onChange(onClick(option.value, value))}
                      control={
                        <Checkbox {...option.checkboxProps} />
                      }
                    />
                  </Box>
                )
              }

              return (
                <FormControlLabel
                  key={option.value}
                  label={<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>{option.label} {option.tooltipLabel && <Tooltip placement='top' title={option.tooltipLabel}><IconInfoCircle size={24} color='#3BA1FF' /></Tooltip>}</Box>}
                  checked={value?.includes(option.value)}
                  onChange={() => onChange(onClick(option.value, value))}
                  control={

                    <Checkbox {...option.checkboxProps} />

                  }
                />
              )
            })}
          </Box>
        )
        }
      />
    </>

  )
}
