import { Divider } from '@mui/material'
import { Container } from './styles'
import { CashbackInfo, ContractLink } from './components'

export const ContractConfig: React.FC = () => {
  return (
    <Container>
      <CashbackInfo />
      <Divider />
      <ContractLink />
      <Divider />
    </Container>
  )
}
