import { useTheme } from '@emotion/react'
import { Box, Button, CircularProgress, Divider } from '@mui/material'
import { IconAlertCircleFilled } from '@tabler/icons-react'
import { FormProvider, useForm } from 'react-hook-form'
import { Dialog } from '~/components/Dialog'
import { Select } from '~/components/Form/Select'
import { lossReasonOptions } from '~/utils/options'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ELossReason, EStage, useUpdateSalesFunnelStageMutation } from '~/graphql/types'
import { toast } from 'react-toastify'
import { useProposalContext } from '~/contexts'
import { useCallback } from 'react'

type LostFunnelStageModalProps = {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}

type FormData = {
  lossReason: ELossReason
}

const schema = yup.object().shape({
  lossReason: yup.string().required()
})

export const LostFunnelStageModal: React.FC<LostFunnelStageModalProps> = ({ isVisible, setIsVisible }) => {
  const theme = useTheme()
  const { proposal, setProposal } = useProposalContext()
  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: { 
      lossReason: '' as ELossReason
    }
  })

  const onCloseModal = useCallback(() => {
    setIsVisible(false)
    formMethods.reset()
  }, [])

  const [updateFunnelStage, { loading }] = useUpdateSalesFunnelStageMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if(proposal) {
        onCloseModal()
        setProposal({ ...proposal, 
          salesFunnel: data.updateSalesFunnelStage.salesFunnel,
          lossReason: data.updateSalesFunnelStage.lossReason,
          rejectedInfo: data.updateSalesFunnelStage.rejectedInfo as any 
        })
      }
    },
  })

  const onSubmit = useCallback((data: FormData) => {
    updateFunnelStage({
      variables: {
        params: {
          newStage: EStage.lost,
          lossReason: data.lossReason,
          proposalId: proposal?._id || ''
        }
      }
    })
  }, [proposal])

  return (
    <Dialog 
      onClose={() => onCloseModal()} 
      isVisible={isVisible}
      title='Perda de proposta'
      titleIcon={<IconAlertCircleFilled size={32} style={{ color: theme.palette.error.main }} />}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>  
          <Divider sx={{ margin: '1rem 0 2rem 0' }} />
          <Select name='lossReason' label='Motivo' options={lossReasonOptions} />
          <Divider sx={{ margin: '2rem 0' }} />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between' }}>
            <Button 
              disabled={loading} 
              onClick={() => onCloseModal()} 
              type='button' 
              color='secondary'
            >
              Voltar para proposta
            </Button>
            <Button 
              disabled={loading} 
              type='submit' 
              variant='contained' 
              color='error'
              startIcon={loading ? <CircularProgress color='inherit' size={24} /> : <></>}
            >
              Sinalizar perda
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  )
}
