import { useMemo } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { useAuth } from '~/contexts'
import { InfoCard } from './components'
import { InfoCardProps } from './components/InfoCard'
import { ProposalListDto, useProposalListQuery } from '~/graphql/types'

export const InfoCards: React.FC = () => {
  const { authUser } = useAuth()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const params: ProposalListDto = useMemo(() => {
    if (authUser?.user.type === 'seller') {
      return {
        createBy: {
          id: authUser?.user.id ?? '',
          name: authUser?.user.name ?? '',
          phone: authUser?.user.phone ?? '',
          email: authUser?.user.email ?? '',
          type: authUser?.user?.type ? authUser?.user?.type : ''
        }
      }
    }

    return {
      pageSize: 9999
    }
  }, [authUser])

  const { data, loading } = useProposalListQuery({ variables: { params } })

  const proposals = data?.proposalList?.data
  const finalizedProposals = useMemo(() => proposals?.filter(proposal => proposal.termOfAdhesion?.signedAt), [proposals])
  const proposalsInAnalysis = useMemo(() => proposals?.filter(proposal => proposal.hasAnalysis), [proposals])

  const infoCardsList: Omit<InfoCardProps, 'loading'>[] = useMemo(() => [
    { title: 'PROPOSTAS GERADAS', description: `Total de propostas ${authUser?.user.type === 'seller' ? 'que você gerou' : 'geradas'}`, value: proposals?.length || 0, icon: 'Stack' },
    { title: 'PROPOSTAS EM ANALISE', description: 'Propostas geradas em análise', value: proposalsInAnalysis?.length || 0, icon: 'Clock' },
    { title: 'PROPOSTAS CONTRATADAS', description: 'Termos de gestão assinados', value: finalizedProposals?.length || 0, icon: 'WritingPen' }
  ], [proposals, finalizedProposals, proposalsInAnalysis])

  const infoCards = useMemo(() => {
    return infoCardsList.map(infoCard => (
      <InfoCard
        loading={loading}
        key={`info-card-${infoCard.title}`}
        {...infoCard}
      />
    ))
  }, [infoCardsList, loading])

  return (
    <Box sx={{
      gap: '24px',
      display: 'grid',
      marginTop: '32px',
      gridTemplateColumns: `repeat(${isMobile ? 1 : 3}, 1fr)`
    }}>
      {infoCards}
    </Box>
  )
}
