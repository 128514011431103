import * as yup from 'yup'
import { isEmpty } from 'ramda'
import { clearString } from '~/utils'
import { isPhoneValid } from '~/utils/phoneValidator'
import { CustomerData, LegalRepresentativeType } from './types'
import { documentSchema, personTypeSchema, phoneSchema } from '~/utils/yupSchema'
import { CustomerModel, ECustomerFileType, ECustomerPersonType, EMaritalStatus, LegalRepresentativeModel } from '~/graphql/types'

export const LEGAL_REPRESENTATIVE_INITIAL_VALUES: LegalRepresentativeType = {
  name: '',
  email: '',
  phone: '',
  document: '',
  nationality: '',
  maritalStatus: '' as EMaritalStatus,
  identificationNumber: '',
  address: {
    city: '',
    state: '',
    number: '',
    street: '',
    zipcode: '',
    complement: '',
    neighborhood: ''
  },
  customerPjRepresentativeDocumentWithPhotoBack: undefined,
  customerPjRepresentativeDocumentWithPhotoFront: undefined
}

export const BASE_DATA_FORM_INITIAL_VALUES: CustomerData = {
  name: '',
  email: '',
  phone: '',
  document: '',
  nationality: undefined,
  personType: ECustomerPersonType.pf,
  maritalStatus: '' as EMaritalStatus,
  address: {
    city: '',
    state: '',
    street: '',
    number: '',
    zipcode: '',
    complement: '',
    neighborhood: ''
  },
  customerPjDocumentCard: undefined,
  customerPjSocialContract: undefined,
  legalRepresentatives: [LEGAL_REPRESENTATIVE_INITIAL_VALUES]
}

export const mapLegalRepresentativesToForm = (legalRepresentatives: LegalRepresentativeModel[]): LegalRepresentativeType[] => {
  return legalRepresentatives.map(legalRepresentative => {
    const customerPjRepresentativeDocumentWithPhotoBackFile = legalRepresentative.files?.find(file => file.type === ECustomerFileType.customerPjRepresentativeDocumentWithPhotoBack)
    const customerPjRepresentativeDocumentWithPhotoFrontFile = legalRepresentative.files?.find(file => file.type === ECustomerFileType.customerPjRepresentativeDocumentWithPhotoFront)

    return {
      name: legalRepresentative.name || '',
      phone: legalRepresentative.phone,
      email: legalRepresentative.email,
      address: {
        city: legalRepresentative.address?.city || '',
        state: legalRepresentative.address?.state || '',
        number: legalRepresentative.address?.number || '',
        street: legalRepresentative.address?.street || '',
        country: legalRepresentative.address?.country || '',
        zipcode: legalRepresentative.address?.zipcode || '',
        complement: legalRepresentative.address?.complement || '',
        neighborhood: legalRepresentative.address?.neighborhood || ''
      },
      document: legalRepresentative.document || '',
      nationality: legalRepresentative.nationality || '',
      maritalStatus: legalRepresentative.maritalStatus as EMaritalStatus,
      identificationNumber: legalRepresentative.identificationNumber || '',
      customerPjRepresentativeDocumentWithPhotoBack: customerPjRepresentativeDocumentWithPhotoBackFile ? [customerPjRepresentativeDocumentWithPhotoBackFile] : undefined,
      customerPjRepresentativeDocumentWithPhotoFront: customerPjRepresentativeDocumentWithPhotoFrontFile ? [customerPjRepresentativeDocumentWithPhotoFrontFile] : undefined
    }
  })
}

export const getInitialValues = (customer: CustomerModel | null): CustomerData => {
  if (customer) {
    const { document, email, phone, name, personType, nationality, maritalStatus, address, legalRepresentatives } = customer

    const formattedValues = {
      name,
      document,
      phone: phone ?? '',
      email: email ?? '',
      nationality: nationality ?? undefined,
      personType: personType as ECustomerPersonType,
      maritalStatus: maritalStatus as EMaritalStatus ?? '',
      address: {
        city: address?.city || '',
        state: address?.state || '',
        street: address?.street || '',
        number: address?.number || '',
        zipcode: address?.zipcode || '',
        complement: address?.complement || '',
        neighborhood: address?.neighborhood || ''
      },
      legalRepresentatives: legalRepresentatives && !isEmpty(legalRepresentatives) ? mapLegalRepresentativesToForm(legalRepresentatives) : [LEGAL_REPRESENTATIVE_INITIAL_VALUES]
    }

    return formattedValues
  }

  return BASE_DATA_FORM_INITIAL_VALUES
}

export const schema = yup.object().shape({
  phone: phoneSchema,
  document: documentSchema,
  personType: personTypeSchema,
  email: yup.string().required('Email é obrigatório').email('Email inválido'),
  name: yup.string().required('Nome completo é obrigatório').min(3, 'Nome deve possuir no mínimo 3 dígitos'),
  maritalStatus: yup.string().optional(),
  nationality: yup.string().optional(),
  address: yup.object().shape({
    complement: yup.string().nullable().optional(),
    state: yup.string().optional(),
    city: yup.string().optional(),
    number: yup.string().optional(),
    neighborhood: yup.string().optional(),
    street: yup.string().when({
      is: (exists: any) => !!exists && exists.length > 0,
      then: (validationSchema) => validationSchema.min(5, 'Rua deve ter pelo menos 5 caracteres'),
      otherwise: (validationSchema) => validationSchema.optional()
    }),
    zipcode: yup.string().when({
      is: (exists: any) => !!exists && exists.length > 0,
      then: (validationSchema) => validationSchema.test('Length', 'CEP válido necessita ter 8 digitos', (value: any) => clearString(value).length === 8 ? true : false),
      otherwise: (validationSchema) => validationSchema.optional()
    })
  }).nullable(),
  legalRepresentatives: yup.array().of(yup.object().shape({
    phone: yup.string().nullable().when({
      is: (exists: any) => !!exists && exists.length > 0,
      then: (validationSchema) => validationSchema
        .test('Phone length', 'Celular deve ter 11 dígitos', phone => phone ? clearString(phone).length === 11 || clearString(phone).length === 10 : false)
        .test(
          'isPhoneValid',
          'Telefone inválido',
          phone => {
            return phone ? isPhoneValid(clearString(phone)) : false
          }
        )
    }),
    address: yup.object().shape({
      complement: yup.string().nullable().optional(),
      state: yup.string().optional(),
      city: yup.string().optional(),
      number: yup.string().optional(),
      neighborhood: yup.string().optional(),
      street: yup.string().when({
        is: (exists: any) => !!exists && exists.length > 0,
        then: (validationSchema) => validationSchema.min(5, 'Rua deve ter pelo menos 5 caracteres'),
        otherwise: (validationSchema) => validationSchema.optional()
      }),
      zipcode: yup.string().when({
        is: (exists: any) => !!exists && exists.length > 0,
        then: (validationSchema) => validationSchema.test('Length', 'CEP válido necessita ter 8 digitos', (value: any) => clearString(value).length === 8 ? true : false),
        otherwise: (validationSchema) => validationSchema.optional()
      })
    }),
    maritalStatus: yup.string().optional(),
    name: yup.string().optional(),
    document: yup.string().when('name', {
      is: (exists: any) => !!exists && exists.length > 0,
      then: (validationSchema) => validationSchema.required('Campo obrigatório'),
      otherwise: (validationSchema) => validationSchema.optional()
    }),
    identificationNumber: yup.string().optional(),
    nationality: yup.string().optional(),
    email: yup.string().email('Email inválido').optional()
  }))
})

export const getLegalRepresentativesInfo = (formData: CustomerData, customer: CustomerModel) => {
  const { legalRepresentatives } = formData
  const { legalRepresentatives: legalRepresentativesFromCustomer } = customer

  const registeredRepresentativeDocuments = legalRepresentativesFromCustomer?.map(representative => clearString(representative.document || ''))
  const legalRepresentativeDocumentsFromForm = legalRepresentatives?.map(representative => clearString(representative.document || ''))

  const legalRepresentativesToAdd = legalRepresentatives?.filter(formRepresentative => !registeredRepresentativeDocuments?.includes(clearString(formRepresentative.document || '')))
  const legalRepresentativesToUpdate = legalRepresentatives?.filter(formRepresentative => registeredRepresentativeDocuments?.includes(clearString(formRepresentative.document || '')))

  const legalRepresentativesToRemoveDocuments = registeredRepresentativeDocuments?.filter(document => !legalRepresentativeDocumentsFromForm?.includes(clearString(document)))
  const legalRepresentativesToRemove = legalRepresentativesFromCustomer?.filter(formRepresentative => legalRepresentativesToRemoveDocuments?.includes(clearString(formRepresentative.document || '')))

  return {
    legalRepresentativesToAdd,
    legalRepresentativesToUpdate,
    legalRepresentativesToRemove: legalRepresentativesToRemove || []
  }
}
