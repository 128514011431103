import { Box, Typography, useMediaQuery } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Dropdown } from '../Dropdown'
import { Button } from '../Form/Button'
import { useToggle } from '~/hooks'
import { ContractActiveDialog, ContractCancelDialog } from './components'
import { theme } from '~/utils'
import { useContract } from '~/contexts'
import { EContractStatus, ETermStatus } from '~/graphql/types'
import { ContractUpdateStatusDialog } from '../ContractUpdateStatusDialog'
import { ContractDeactivationDialog } from '../ContractDeactivationDialog'
import { useMemo } from 'react'

export type ContractFlowActionDropdownProps = {
  disabled?: boolean
}
export const ContractFlowActionDropdown: React.FC<ContractFlowActionDropdownProps> = ({ disabled }) => {
  const { isTrue: cancelContractModalIsOpen, toggle: toggleCancelContract } = useToggle()
  const { isTrue: activeContractModalIsOpen, toggle: toggleActiveContract } = useToggle()
  const { isTrue: updateContractStatusModalIsOpen, toggle: toggleUpdateContractStatus } = useToggle()
  const { isTrue: contractDeactivationModalIsOpen, toggle: toggleContractDeactivation } = useToggle()
  const { contract } = useContract()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const termOfAdhesion = useMemo(() => contract?.termOfAdhesion ? contract?.termOfAdhesion.find(term => term.active === true) : null, [contract])
  const termOfManagement = useMemo(() => contract?.termOfManagement ? contract?.termOfManagement.find(term => term.active) : null, [contract])

  const isInCancellation = contract?.status === EContractStatus.cancellationRequested
  const isInDeactivation = contract?.status === EContractStatus.inDeactivation
  const canAprroveContract = termOfAdhesion?.status === ETermStatus.signed && termOfManagement?.status === ETermStatus.signed

  return (
    <>
      <Dropdown
        $options={{ left: isLowerSm ? '0px' : '-100px', top: isInCancellation || isInDeactivation ? '-150px' : '-60px' }}
        elementClick={(
          <Button size={isLowerSm ? 'small' : 'medium'} disabled={disabled} color='secondary' endIcon={<ExpandMore />}>
            Ações
          </Button>
        )}
      >
        <Box sx={{
          display: 'flex',
          borderRadius: 3,
          flex: 1,
          flexDirection: 'column',
          minWidth: '220px',
          background: 'white',
          boxShadow: '0px 0px 2px rgba(64, 64, 64, 0.1), 0px 6px 15px -4px rgba(64, 64, 64, 0.14)'
        }}>
          {isInCancellation || isInDeactivation ? (
            <>
              <Box onClick={isInCancellation ? toggleUpdateContractStatus : undefined} sx={{ cursor: 'pointer', padding: 2, display: 'flex', flexGrow: 1, gap: 2, color: isInCancellation ? 'black' : '#8e8e8e', '&:hover': { background: isInCancellation ? '#FAFAFA' : 'transparent' } }}>
                <Typography variant='body2'>{`Mudar status para "Em desativação"`}</Typography>
              </Box>
              <Box onClick={isInDeactivation ? toggleContractDeactivation : undefined} sx={{ cursor: 'pointer', padding: 2, display: 'flex', flexGrow: 1, gap: 2, alignItems: 'center', color: isInDeactivation ? 'black' : '#8e8e8e', '&:hover': { background: isInDeactivation ? '#FAFAFA' : 'transparent' } }}>
                <Typography variant='body2'>{`Mudar status para "Desativado"`}</Typography>
              </Box>
            </>
          ) : (
            <>
              <Box onClick={toggleCancelContract} sx={{ cursor: 'pointer', padding: 2, display: 'flex', flex: 1, gap: 2, alignItems: 'center', color: 'black', '&:hover': { background: '#FAFAFA' } }}>
                <Typography variant='body2' color='error'>Cancelar contrato</Typography>
              </Box>
              {(contract?.status === EContractStatus.sent || contract?.status === EContractStatus.readyToSend) && canAprroveContract && (
                <Box onClick={toggleActiveContract} sx={{ cursor: 'pointer', padding: 2, display: 'flex', flex: 1, gap: 2, alignItems: 'center', color: 'black', '&:hover': { background: '#FAFAFA' } }}>
                  <Typography variant='body2'>Mudar status para {`"Ativado"`}</Typography>
                </Box>
              )}</>
          )}
        </Box>
      </Dropdown>
      <ContractCancelDialog isVisible={cancelContractModalIsOpen} toggleModal={toggleCancelContract} />
      <ContractActiveDialog isVisible={activeContractModalIsOpen} toggleModal={toggleActiveContract} />
      <ContractUpdateStatusDialog isVisible={updateContractStatusModalIsOpen} toggleModal={toggleUpdateContractStatus} />
      <ContractDeactivationDialog isVisible={contractDeactivationModalIsOpen} toggleModal={toggleContractDeactivation} />
    </>
  )
}
