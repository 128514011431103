import { useEffect } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import { IconX } from '@tabler/icons-react'

import { CheckboxMultiple } from '~/components/Form/CheckboxMultiple'
import { Input } from '~/components/Form/Input'

import { SearchQueysData, useQueryString } from '~/hooks'
import { discountTableOptions } from '~/utils/options'

type FilterDiscountTableProps = {
  drawerIsOpen: boolean
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleDrawer: () => void
  queryKeys: SearchQueysData[]
}

export const FilterDiscountTable: React.FC<FilterDiscountTableProps> = ({ drawerIsOpen, queryKeys, setDrawerIsOpen, toggleDrawer }) => {
  const { searchQuerys, setSearchParams } = useQueryString()
  const queryValues = searchQuerys(queryKeys)
  const formMethods = useForm()

  const handleFilters = (filters: { name?: string, type?: string[], begin?: string, end?: string }) => {
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      filters?.type && filters?.type?.length > 0 || queryValues?.type?.length > 0 ? state.set('type', JSON.stringify(filters.type) || queryValues.type) : state.delete('type')
      filters.begin  || queryValues.begin  ? state.set('begin', filters.begin || queryValues.begin) : state.delete('begin')
      filters.end  || queryValues.end  ? state.set('end', filters.end || queryValues.end) : state.delete('end')
      return state
    })
  }

  const onRemoveFilters = () => {
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      state.delete('type')
      state.delete('begin')
      state.delete('end')
      return state
    })
  }

  useEffect(() => {
    if(drawerIsOpen) {
      formMethods.setValue('begin', queryValues.begin)
      formMethods.setValue('end', queryValues.end)
      formMethods.setValue('type', queryValues.type)
    }
  }, [drawerIsOpen])

  return (
    <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={drawerIsOpen} onClose={toggleDrawer}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
        <IconButton type='button' onClick={() => toggleDrawer()}>
          <IconX />
        </IconButton>
      </Box>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((formData) => handleFilters({ name: queryValues.name || '', begin: formData.begin, end: formData.end, type: formData.type }))}>
          <Typography sx={{ paddingTop: '.8rem' }} fontWeight={500}>Data de criação</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '.8em 0 2rem 0' }}>
            <Input name='begin' type='date' />
            <Typography>Até</Typography>
            <Input name='end' type='date' />
          </Box>
          <Typography fontWeight={500}>Flutua bandeira?</Typography>
          <CheckboxMultiple name='type' options={discountTableOptions} />
          <Box sx={{ display: 'flex', padding: '2rem 0', gap: '1rem', justifyContent: 'flex-end' }}>
            <Button onClick={() => onRemoveFilters()} type='button' fullWidth color='secondary'>Limpar</Button>
            <Button type='submit' fullWidth color='primary'>Aplicar</Button>
          </Box>
        </form>
      </FormProvider>
    </Drawer>

  )
}
