import { useTheme } from '@emotion/react'
import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { useProposalContext } from '~/contexts'
import { ELossReason, EProposalStatus, useUserGetLazyQuery } from '~/graphql/types'
import { formatDate, formatHour, translateLossReason } from '~/utils'

export const LoseProposalInfo: React.FC = () => {
  const theme = useTheme()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const { proposal } = useProposalContext()

  const isCanceledProposal = proposal?.status === EProposalStatus.canceled

  const [getUser, { loading: userIsLoading, data: userData }] = useUserGetLazyQuery()

  useEffect(() => {
    if(proposal && proposal?.salesFunnel?.user) {
      getUser({
        variables: {
          id: proposal?.salesFunnel?.user
        }
      })
    }
  },[proposal])

  return (
    <div>
      {Boolean(proposal?.rejectedInfo?.reason) && (
        <Typography sx={{ maxWidth: '800px', color: theme.palette.grey[800], fontSize: '14px' }}>
          <li>{proposal?.rejectedInfo?.reason}</li>
        </Typography>
      )}
      <Box sx={{ display: 'flex', alignItems: isLowerMd ? 'flex-start' : 'center', gap: '.6rem', flexFlow: isLowerMd ? 'column' : 'row' }}>

        <Typography sx={{ color: theme.palette.error.main, fontWeight: 500, fontSize: '14px' }}>
          {isCanceledProposal ? 'Proposta cancelada': 'Proposta perdida'}
        </Typography>
        {!isLowerMd && 
          <Box sx={{ width: '2px', height: '16px', background: theme.palette.grey[300] }} />
        }

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px', color: theme.palette.grey[400], display: 'flex', gap: '.3rem' }} component='span'>
            Motivo:
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }} component='strong'>
              {translateLossReason(proposal?.lossReason as ELossReason) || `-`}
            </Typography>
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: isLowerMd ? 'flex-start' : 'center', gap: '.6rem', flexFlow: isLowerMd ? 'column' : 'row' }}>

        <Typography sx={{ display: 'flex' , gap: '.2rem', color: theme.palette.grey[400], fontSize: '14px'  }}>
          {isCanceledProposal ? 'Cancelada em:': 'Perda sinalizada em:'}
          <Typography sx={{ display: 'flex', gap: '.2rem', color: theme.palette.grey[400], fontWeight: 500, fontSize: '14px' }}>
            {formatDate(proposal?.salesFunnel?.date) || '-'}
          </Typography> 
          <Typography sx={{ color: theme.palette.grey[400], fontSize: '14px' }}>às</Typography>
          
          <Typography sx={{ color: theme.palette.grey[400], fontWeight: 500, fontSize: '14px' }}>{formatHour(proposal?.salesFunnel?.date)}</Typography>
        </Typography>
        {userIsLoading ? 
          <Skeleton variant='text' width={100} />
          : (
            <>
              {userData?.userGet?.name && (
                <>
                  {!isLowerMd && 
                    <Box sx={{ width: '2px', height: '16px', background: theme.palette.grey[300] }} />
                  }
                  <Typography sx={{ display: 'flex' , gap: '.2rem', color: theme.palette.grey[400], fontSize: '14px'  }}>
                    por: 
                    <Typography sx={{ color: theme.palette.grey[400], fontWeight: 500, fontSize: '14px' }}>{userData?.userGet?.name}</Typography>
                  </Typography>
                </>
              )}
            </>
          )}
        
      </Box>

    </div>
  )
}
