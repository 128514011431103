import { ECustomerPersonType, EMaritalStatus, EProposalCustomerPendencies, ProposalModel } from '~/graphql/types'
import { clearString } from '~/utils'
import { isPendencyField } from '~/utils/proposal'

export type BaseDataForm = {
  uf: string
  name: string
  email: string
  phone: string
  document: string
  nationality: string
  dealershipId: string
  dealershipName: string
  maritalStatus: EMaritalStatus
  averageEnergyBillValue?: number
  personType: ECustomerPersonType
}

export const BASE_DATA_FORM_INITIAL_VALUES: BaseDataForm = {
  uf: '',
  name: '',
  email: '',
  phone: '',
  document: '',
  nationality: '',
  dealershipId: '',
  dealershipName: '',
  averageEnergyBillValue: 0,
  personType: ECustomerPersonType.pf,
  maritalStatus: '' as EMaritalStatus
}

export const getProposalSolvedPendencies = (formData: BaseDataForm, proposal?: ProposalModel | null): EProposalCustomerPendencies[] => {
  const solvedPendecies: EProposalCustomerPendencies[] = []

  if (proposal) {
    const { customerRef } = proposal
    const { pendencies } = customerRef

    if (isPendencyField(EProposalCustomerPendencies.phone, pendencies) && clearString(formData.phone) !== clearString(customerRef.phone || '')) {
      solvedPendecies.push(EProposalCustomerPendencies.phone)
    }

    if (isPendencyField(EProposalCustomerPendencies.name, pendencies) && formData.name !== customerRef.name) {
      solvedPendecies.push(EProposalCustomerPendencies.name)
    }

    if (isPendencyField(EProposalCustomerPendencies.uf, pendencies) && formData.uf !== customerRef.uf) {
      solvedPendecies.push(EProposalCustomerPendencies.uf)
    }

    if (isPendencyField(EProposalCustomerPendencies.email, pendencies) && formData.email !== customerRef.email) {
      solvedPendecies.push(EProposalCustomerPendencies.email)
    }

    if (isPendencyField(EProposalCustomerPendencies.document, pendencies) && clearString(formData.document) !== clearString(customerRef.document)) {
      solvedPendecies.push(EProposalCustomerPendencies.document)
    }

    if (isPendencyField(EProposalCustomerPendencies.nationality, pendencies) && formData.nationality !== customerRef.nationality) {
      solvedPendecies.push(EProposalCustomerPendencies.nationality)
    }

    if (isPendencyField(EProposalCustomerPendencies.dealershipId, pendencies) && formData.dealershipId !== customerRef.dealershipId) {
      solvedPendecies.push(EProposalCustomerPendencies.dealershipId)
    }

    if (isPendencyField(EProposalCustomerPendencies.dealershipName, pendencies) && formData.dealershipName !== customerRef.dealershipName) {
      solvedPendecies.push(EProposalCustomerPendencies.dealershipName)
    }

    if (isPendencyField(EProposalCustomerPendencies.averageEnergyBillValue, pendencies) && formData.averageEnergyBillValue !== customerRef.averageEnergyBillValue) {
      solvedPendecies.push(EProposalCustomerPendencies.averageEnergyBillValue)
    }

    if (isPendencyField(EProposalCustomerPendencies.personType, pendencies) && formData.personType !== customerRef.personType) {
      solvedPendecies.push(EProposalCustomerPendencies.personType)
    }

    if (isPendencyField(EProposalCustomerPendencies.maritalStatus, pendencies) && formData.maritalStatus !== customerRef.maritalStatus) {
      solvedPendecies.push(EProposalCustomerPendencies.maritalStatus)
    }
  }

  return solvedPendecies
}
