import { Box, Typography, useMediaQuery } from '@mui/material'
import { ProposalStatus as ProposalStatusBadge } from '~/components'
import { formatDate } from '~/utils'
import { CSSProperties } from 'react'
import { useProposalContext } from '~/contexts'
import { useTheme } from '@emotion/react'
import { ProposalFunnel } from './components'

export type ProposalHeaderInformationsProps = {
  align?: CSSProperties['alignItems']
}
export const ProposalHeaderInformations: React.FC<ProposalHeaderInformationsProps> = ({ align = 'end' }) => {
  const theme = useTheme()
  const { proposal } = useProposalContext()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const isIndicationPartner = Boolean(proposal?.indicationRef?.partner?.name)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: align }}>
      <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', gap: 1, flexFlow: isLowerSm ? 'column' : 'row' }}>
        <Typography color='grey.400'>Status da proposta:</Typography>
        {proposal?.status && <ProposalStatusBadge status={proposal?.status} />}
      </Box>
      <ProposalFunnel />
      <Box sx={{ display: 'flex', gap: '.6rem' }}>
        <Typography variant='body2' color='grey.400'>Gerada em: <b>{formatDate(proposal?.createdAt)}</b></Typography>
        <Box sx={{ width: '2px', height: '16px', background: theme.palette.grey[300] }} />
        {proposal?.createBy?.name && <Typography variant='body2' color='grey.400'>por: <b>{proposal?.createBy?.name}</b></Typography>}
      </Box>
      {isIndicationPartner && (
        <Box>
          <Typography variant='body2' color='grey.400'>por: <b>{proposal?.indicationRef?.partner?.name}</b></Typography>
          <Typography variant='body2' color='grey.400'>Origem: <b>Parceiro</b></Typography>
        </Box>
      )}
    </Box>
  )
}
