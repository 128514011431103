import { SvgIcon, SvgIconProps } from '@mui/material'

export const ContractsSidebar: React.FC<SvgIconProps> = ({ width, height, htmlColor = '#434343', fill, ...props }) => {
  return (
    <SvgIcon width={width || 24} height={height || 24} viewBox='0 0 24 24' fill={fill || 'none'} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M14 2C14.5523 2 15 2.44772 15 3V7H19C19.5523 7 20 7.44772 20 8C20 8.55228 19.5523 9 19 9H15C14.4696 9 13.9609 8.78929 13.5858 8.41421C13.2107 8.03914 13 7.53043 13 7V3C13 2.44772 13.4477 2 14 2Z' fill={htmlColor || '#F9DC45'} />
      <path fillRule='evenodd' clipRule='evenodd' d='M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V8C6 8.55228 5.55228 9 5 9C4.44772 9 4 8.55228 4 8V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289L19.7071 7.29289C19.8946 7.48043 20 7.73478 20 8V19C20 19.7957 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7957 22 17 22H12C11.4477 22 11 21.5523 11 21C11 20.4477 11.4477 20 12 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19V8.41421L13.5858 4H7Z' fill={htmlColor || '#F9DC45'} />
      <path fillRule='evenodd' clipRule='evenodd' d='M3.17157 11.1716C3.92172 10.4214 4.93913 10 6 10C7.06087 10 8.07828 10.4214 8.82843 11.1716C9.57857 11.9217 10 12.9391 10 14C10 15.0609 9.57857 16.0783 8.82843 16.8284C8.07828 17.5786 7.06087 18 6 18C4.93913 18 3.92172 17.5786 3.17157 16.8284C2.42143 16.0783 2 15.0609 2 14C2 12.9391 2.42143 11.9217 3.17157 11.1716ZM6 12C5.46957 12 4.96086 12.2107 4.58579 12.5858C4.21071 12.9609 4 13.4696 4 14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16C6.53043 16 7.03914 15.7893 7.41421 15.4142C7.78929 15.0391 8 14.5304 8 14C8 13.4696 7.78929 12.9609 7.41421 12.5858C7.03914 12.2107 6.53043 12 6 12Z' fill={htmlColor || '#F9DC45'} />
      <path fillRule='evenodd' clipRule='evenodd' d='M4.78733 16.0422C5.31632 16.2009 5.61651 16.7583 5.45781 17.2873L4.62285 20.0705L5.55277 19.6056C5.8343 19.4648 6.16567 19.4648 6.4472 19.6056L7.37711 20.0705L6.54216 17.2873C6.38346 16.7583 6.68364 16.2009 7.21263 16.0422C7.74163 15.8835 8.29911 16.1836 8.45781 16.7126L9.95781 21.7126C10.0724 22.0948 9.94898 22.5085 9.64367 22.7653C9.33835 23.0221 8.9096 23.0728 8.55277 22.8944L5.99998 21.618L3.4472 22.8944C3.09036 23.0728 2.66161 23.0221 2.3563 22.7653C2.05098 22.5085 1.92752 22.0948 2.04216 21.7126L3.54216 16.7126C3.70085 16.1836 4.25834 15.8835 4.78733 16.0422Z' fill={htmlColor || '#F9DC45'} />
    </SvgIcon>
  )
}
