import { SvgIcon, SvgIconProps } from '@mui/material'

export const Notes: React.FC<SvgIconProps> = ({ width, height, htmlColor, ...props }) => {
  return (
    <SvgIcon width={width || 24} height={height || 24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M5 5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5Z' stroke={htmlColor || '#434343'} fill='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 7H15' stroke={htmlColor || '#434343'} fill='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 11H15' stroke={htmlColor || '#434343'} fill='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 15H13' stroke={htmlColor || '#434343'} fill='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  )
}
