import { toast } from 'react-toastify'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { clearString } from '~/utils'
import { brazilStates } from '~/constants'
import { ProposalInfo, PropsWithWizard } from '~/components'
import { ActionButtons } from './components'
import { Form } from '~/components/Form/Form'
import { Input } from '~/components/Form/Input'
import { useProposalContext } from '~/contexts'
import { addressSchema } from '~/utils/yupSchema'
import { Select } from '~/components/Form/Select'
import { yupResolver } from '@hookform/resolvers/yup'
import { ADDRESS_FORM_INITIAL_VALUES, AddressForm, getProposalAddressSolvedPendencies } from './utils'
import { EProposalStatus, ProposalCustomerPendenciesFields, ProposalModel, ProposalUpdateGraphqlDto, useProposalUpdateMutation, useSolveProposalCustomerPendenciesMutation } from '~/graphql/types'
import { isEmpty } from 'ramda'
import { useCallback, useEffect } from 'react'
import { isPendencyField } from '~/utils/proposal'
import { ApolloError } from '@apollo/client'
import { mapErrorToMessage } from '~/utils/errors'
import { FullPageLoader } from '~/components/FullPageLoader'
import { useCep } from '~/hooks'
import { IconSearch } from '@tabler/icons-react'

const getInitialValues = (proposal: ProposalModel | null): AddressForm => {
  if (proposal) {
    const { customerRef } = proposal
    const { address } = customerRef

    if (address) {
      const { city, state, street, number, complement, zipcode, neighborhood } = address

      return {
        city,
        state,
        street,
        number,
        zipcode,
        neighborhood,
        complement: complement || ''
      }
    }

    return ADDRESS_FORM_INITIAL_VALUES
  }

  return ADDRESS_FORM_INITIAL_VALUES
}

export const Address: React.FC<PropsWithWizard> = (props) => {
  const { proposal, setProposal } = useProposalContext()
  const [updateProposal, { loading: updateProposalLoading }] = useProposalUpdateMutation()
  const [solveCustomerPendencies, { loading: solveCustomerPendenciesLoading }] = useSolveProposalCustomerPendenciesMutation()
  const { fetchCep, isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })

  const methods = useForm<AddressForm>({
    resolver: yupResolver(addressSchema),
    defaultValues: getInitialValues(proposal)
  })

  const address = useWatch({ control: methods.control })

  const disableFields = proposal?.status !== EProposalStatus.documentation

  useEffect(() => {
    if (proposal) {
      const { customerRef } = proposal
      const { pendencies } = customerRef

      pendencies && Object.keys(pendencies).forEach((item) => {
        isPendencyField(item as keyof ProposalCustomerPendenciesFields, pendencies) && methods.setError(item as keyof AddressForm, { message: 'Dado anterior recusado. Por favor, informe um dado válido.' })
      })
    }
  }, [])

  const onSearchCep = useCallback(async () => {
    if (address?.zipcode) {
      const { data, isSuccess } = await fetchCep(address?.zipcode)
      if (isSuccess) {
        methods.setValue('state', data?.uf || '')
        methods.setValue('city', data?.localidade || '')
        methods.setValue('neighborhood', data?.bairro || '')
        methods.setValue('street', data?.logradouro || '')
      }
    }
  }, [address])

  const onSubmit = async (formData: AddressForm) => {
    if (proposal) {
      const { _id } = proposal
      let updatedProposal: ProposalModel | null = null

      const params: ProposalUpdateGraphqlDto = {
        _id,
        address: {
          ...formData,
          country: 'Brasil',
          zipcode: clearString(formData.zipcode)
        }
      }

      try {
        const { data } = await updateProposal({ variables: { params } })
        if (data) {
          updatedProposal = data.proposalUpdate as ProposalModel
        }

        const solvedPendencies = getProposalAddressSolvedPendencies(formData, proposal)
        if (!isEmpty(solvedPendencies)) {
          const { data: solvedPendenciesProposal } = await solveCustomerPendencies({
            variables: {
              params: {
                proposalId: proposal?._id || '',
                pendencies: solvedPendencies
              }
            }
          })

          updatedProposal = solvedPendenciesProposal?.solveProposalCustomerPendencies as ProposalModel
        }

        toast.success('Dados atualizados com sucesso!', {
          autoClose: 3000,
          position: 'top-right'
        })

        setProposal(updatedProposal)
      } catch (err) {
        const apolloError = err as ApolloError
        toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao atualizar a proposta'), {
          autoClose: 3000,
          position: 'top-right'
        })
      }
    } else {
      toast.error('Houve um problema ao atualizar a proposta', {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <Form>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
          <ProposalInfo />

          <Typography variant='h3'>Endereço do cliente</Typography>
          <Grid spacing={3} container>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                disabled={disableFields}
                icons={{ end: { element: fetchCepIsLoading ? <FullPageLoader /> : <IconButton disabled={disableFields} onClick={() => onSearchCep()}><IconSearch /></IconButton> } }}
                label='CEP'
                name='zipcode'
                mask='cep'
              />
            </Grid>
            <Grid sx={{ paddingTop: '0px !important' }} item lg={8} sm={6} xs={0} />

            <Grid item lg={4} sm={6} md={6} xs={12}>
              <Input disabled={disableFields} label='Rua' name='street' />
            </Grid>
            <Grid item lg={4} sm={6} md={6} xs={12}>
              <Input disabled={disableFields} label='Número' name='number' />
            </Grid>
            <Grid item lg={4} sm={6} md={6} xs={12}>
              <Input disabled={disableFields} label='Complemento (Opcional)' name='complement' />
            </Grid>

            <Grid item lg={4} sm={6} md={6} xs={12}>
              <Input disabled={disableFields} label='Bairro' name='neighborhood' />
            </Grid>
            <Grid item lg={4} sm={6} md={6} xs={12}>
              <Input disabled={disableFields} label='Cidade' name='city' />
            </Grid>
            <Grid item lg={4} sm={6} md={6} xs={12}>
              <Select disabled={disableFields} name='state' label='UF' options={brazilStates} />
            </Grid>
          </Grid>
          <ActionButtons onSubmit={methods.handleSubmit(onSubmit)} loading={updateProposalLoading || solveCustomerPendenciesLoading} {...props} />
        </Box>
      </Form>
    </FormProvider>
  )
}
