import { SvgIcon, SvgIconProps } from '@mui/material'

export const ContractSign: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <SvgIcon width={width || 40} height={height || 40} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='20' cy='20.0001' r='20' fill='#FAFAFA' />
      <path fillRule='evenodd' clipRule='evenodd' d='M23 14.0001C23 12.3268 24.3267 11.0001 26 11.0001C27.6733 11.0001 29 12.3268 29 14.0001V26.0001C29 26.2653 28.8946 26.5197 28.7071 26.7072L26.7071 28.7072C26.3166 29.0978 25.6834 29.0978 25.2929 28.7072L23.2929 26.7072C23.1054 26.5197 23 26.2653 23 26.0001V14.0001ZM26 13.0001C25.4313 13.0001 25 13.4314 25 14.0001V25.5859L26 26.5859L27 25.5859V14.0001C27 13.4314 26.5687 13.0001 26 13.0001Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M23 16.0001C23 15.4478 23.4477 15.0001 24 15.0001H28C28.5523 15.0001 29 15.4478 29 16.0001C29 16.5524 28.5523 17.0001 28 17.0001H24C23.4477 17.0001 23 16.5524 23 16.0001Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M13 20.0001C13 19.4478 13.4477 19.0001 14 19.0001H17C17.7957 19.0001 18.5587 19.3162 19.1213 19.8788C19.6839 20.4414 20 21.2045 20 22.0001C20 22.7958 19.6839 23.5588 19.1213 24.1214C18.5587 24.6841 17.7957 25.0001 17 25.0001H13C12.7348 25.0001 12.4804 25.1055 12.2929 25.293C12.1054 25.4806 12 25.7349 12 26.0001C12 26.2653 12.1054 26.5197 12.2929 26.7072C12.4804 26.8948 12.7348 27.0001 13 27.0001H26C26.5523 27.0001 27 27.4478 27 28.0001C27 28.5524 26.5523 29.0001 26 29.0001H13C12.2043 29.0001 11.4413 28.6841 10.8787 28.1214C10.3161 27.5588 10 26.7958 10 26.0001C10 25.2045 10.3161 24.4414 10.8787 23.8788C11.4413 23.3162 12.2043 23.0001 13 23.0001H17C17.2652 23.0001 17.5196 22.8948 17.7071 22.7072C17.8946 22.5197 18 22.2653 18 22.0001C18 21.7349 17.8946 21.4806 17.7071 21.293C17.5196 21.1055 17.2652 21.0001 17 21.0001H14C13.4477 21.0001 13 20.5524 13 20.0001Z' fill='#999999' />
    </SvgIcon>
  )
}
