import { Status } from '~/components'
import { ETermStatus } from '~/graphql/types'
import { termStatusColors, translateTermStatus } from '~/utils'

export type ManagementTermStatusProps = {
  status: ETermStatus
}
export const AdhesionTermStatus: React.FC<ManagementTermStatusProps> = ({ status }) => {
  const colorProps = termStatusColors(status)

  return (
    <Status
      color={colorProps.color}
      background={colorProps.background}
      text={translateTermStatus(status)}
    />
  )
}
