/* eslint-disable no-unused-vars */
import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { ContractModel } from '~/graphql/types'

type CustomerContractContextValue = {
  contract: ContractModel | null
  resetContract: () => void
  setContract: (contract: ContractModel | null) => void
}

const CustomerContractContext = createContext<CustomerContractContextValue>({
  contract: null,
  setContract: () => null,
  resetContract: () => null,
})

export const CustomerContractProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [contract, setContract] = useState<ContractModel | null>(null)

  const resetContract = () => {
    setContract(null)
  }

  return (
    <CustomerContractContext.Provider value={{ contract, setContract, resetContract }}>
      {children}
    </CustomerContractContext.Provider>
  )
}

export const useCustomerContract = () => useContext(CustomerContractContext)
