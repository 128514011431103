import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Card, Divider, Typography, useMediaQuery, Button, Switch as MuiSwitch, FormControlLabel } from '@mui/material'
import { Navigate, useNavigate, Link } from 'react-router-dom'
import { ContentTitle } from '~/components/ContentTitle'
import { Input } from '~/components/Form/Input'
import { useAccessControl } from '~/contexts'
import { useAccessControlCreateMutation, useGetModulesListQuery } from '~/graphql/types'
import { useForm, FormProvider, Controller, useFieldArray, useWatch } from 'react-hook-form'
import { Fragment } from 'react'
import { FullPageLoader } from '~/components/FullPageLoader'
import { usePermission } from '~/hooks'
import * as yup from 'yup'
import { Checkbox } from '~/components/Form/Checkbox'
import { theme } from '~/utils'
import { IconCheck } from '@tabler/icons-react'
import { Switch } from '~/components/Form/Switch'
import { toast } from 'react-toastify'

const schema = yup
  .object({
    name: yup.string(),
    active: yup.boolean(),
    modules: yup.array().of(
      yup.object({
        internalName: yup.string().required(),
        publicName: yup.string().required(),
        actions: yup.array().of(
          yup.object({
            internalName: yup.string().required(),
            publicName: yup.string().required(),
          })
        )
      })
    )
  })
  .required()

interface Action {
  internalName: string
  publicName: string
  enabled?: boolean
}

interface Module {
  id?: string;
  internalName: string
  publicName: string
  actions: Action[]
  enabled?: boolean
}

interface TypeForm {
  modules: Module[]
  name: string
  active: boolean
}

export const AccessControlCreate: React.FC = () => {
  const navigate = useNavigate()
  const { accessControlListIsLoading, setAccessControls } = useAccessControl()
  const canCreateAndEditAccessControl = usePermission(['accessControl.updated', 'accessControl.create'])
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const formMethods = useForm<TypeForm>({
    resolver: yupResolver(schema)
  })
  const { fields } = useFieldArray({
    control: formMethods.control,
    name: 'modules'
  })

  const { modules: modulesWatch } = useWatch({
    control: formMethods.control
  }
  )

  const { loading: loadingModulesList } = useGetModulesListQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      formMethods.setValue('modules', data?.modules.map((item) => {
        return {
          ...item,
          actions: item.actions.map((action) => ({ ...action, enabled: false })),
          enabled: false
        }
      }) || [] as Module[])
    }
  })

  const [createAccessControl, { loading: createIsLoading }] = useAccessControlCreateMutation({
    onCompleted: (data) => {
      setAccessControls(prev => [data.accessControlCreate, ...prev])
      toast.success('Um novo perfil foi cadastrado!')
      navigate('/app/access-control')
    },
    onError: () => {
      toast.error('Ocorreu um erro ao cadastrar um novo perfil.')
    }
  })
  
  function onSubmit(data: any) {
    const modulesSelected = data.modules
      .map((item: Module) => {
        const actionsEnabled = item.actions
          .filter(action => action.enabled)
          .map(action => action.internalName)
       
        return {
          name: item.internalName,
          actions: actionsEnabled
        }
       
      })
      .filter((item: Module | null) => item !== null)

    const dataToSubmit = {
      name: data.name,
      active: data.active,
      modules: modulesSelected
    }
    
    createAccessControl({ variables: { params: dataToSubmit } })
    
  }

  if (!canCreateAndEditAccessControl) {
    return <Navigate replace to='/app' />
  }

  if (accessControlListIsLoading || loadingModulesList) return <FullPageLoader />
  
  return (
    <Box sx={{ paddingBottom: '2rem' }}>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Criar Perfil'
          description='Complete os dados para cadastrar um perfil'
          breadcrumbLinks={{ currentLink: 'Cadastrar perfil', links: [{ href: '/app/access-control', label: 'Perfis' }] }}
        />

        <Divider />

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
            <Box minHeight='100vh' padding='2rem 0 0 0' margin='0 auto' display='flex' flexDirection='column' gap='.4rem' >

              <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} variant='h5' fontWeight='500'>Cadastrar perfil</Typography>
              <Input name='name' label='Nome' />
              <Typography sx={{ paddingTop: '.6rem' }} fontSize='1rem' variant='h5' fontWeight='500'>Status do cadastro</Typography>
              <Checkbox name='active' label='Ativo' />
              <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} sx={{ paddingTop: '.6rem', paddingBottom: '1rem' }} variant='h5' fontWeight='500'>Permissões</Typography>
           
              {fields.map((field, fieldIndex) => {
                
                return (
                  <Fragment key={fieldIndex}>
                    <Divider />
                    <Box justifyContent='space-between' display='flex' gap='1rem'>
                      <Typography fontSize={isLowerMd ? '1.1rem' : '1.3rem'} variant='h6'>{field.publicName}</Typography>
                      <Controller
                        name={`modules.${fieldIndex}.enabled`}
                        control={formMethods.control}
                        defaultValue={false}
                        render={({ field: controllerField }) => {
                          const selectedModules = modulesWatch?.[fieldIndex]
                          return (
                            <FormControlLabel
                              {...controllerField}
                              sx={{ margin: 0 }}
                              control={<MuiSwitch />}
                              label={<></>}
                              checked={selectedModules && selectedModules?.actions?.every(action => action.enabled)}
                              onChange={() => {
                                const enabled = !selectedModules?.actions?.every(action => action.enabled)
                                formMethods.setValue(`modules.${fieldIndex}.actions`, selectedModules?.actions?.map((item) => ({ ...item, enabled })) as Action[])
                                formMethods.setValue(`modules.${fieldIndex}.enabled`, enabled)
                                
                              }}
                            />
                          )
                        }}
                      />
                    </Box>
                    {field.actions.map((action, actionIndex) => (
                      <Box key={actionIndex} justifyContent='space-between' display='flex' gap='.4rem'>
                        <Typography sx={{ paddingLeft: '2rem' }}>{action.publicName}</Typography>
                        <Switch
                          name={`modules.${fieldIndex}.actions.${actionIndex}.enabled`}
                          checked={action.enabled}
                        />
                      </Box>
                    ))}
                  </Fragment>
                )
              })}
              <Box sx={{ 
                width: '100%', 
                padding: '2rem 0', 
                display: 'flex', 
                justifyContent: 'space-between', 
                flexFlow: isLowerSm ? 'column-reverse' : 'row', 
                gap: isLowerSm ? '1rem' : '0' 
              }}>

                <Link style={{ width: '100%' }} to='/app/access-control'>
                  <Button sx={{ maxWidth: isLowerSm ? '100%' : '160px' }} fullWidth disabled={createIsLoading} color='secondary'>
                    Cancelar
                  </Button>
                </Link>

                <Button
                  sx={{ maxWidth: isLowerSm ? '100%' : '220px' }}
                  startIcon={<IconCheck />}
                  fullWidth
                  disabled={createIsLoading} type='submit'>
                  Cadastrar Perfil
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </Box>
  )
}
