import * as yup from 'yup'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Form } from '../Form/Form'
import { Input } from '../Form/Input'
import { Button } from '../Form/Button'
import { useContract } from '~/contexts'
import { RegisteredAnnotation } from './components'
import { ContractModel, EContractStatus, useContractAddObservationMutation } from '~/graphql/types'
import { usePermission } from '~/hooks'

export type AnnotationForm = {
  annotation: string
}

const schema = yup.object().shape({
  annotation: yup.string().required('Campo obrigatório')
})

export type ContractAnnotationsProps = {
  contract: ContractModel
  customUpdateProposal?: Function
}
export const ContractAnnotations: React.FC<ContractAnnotationsProps> = ({ contract, customUpdateProposal }) => {
  const { observations } = contract
  const { setContract, isDisabledContractUpdate } = useContract()
  const [addAnnotationToContract, { loading }] = useContractAddObservationMutation()

  const disabledFields = isDisabledContractUpdate && (contract?.status === EContractStatus.active || contract?.status === EContractStatus.sent || contract?.status === EContractStatus.cancellationRequested || contract?.status === EContractStatus.inDeactivation)

  const canAddAnnotations = usePermission(['contracts.updated'])

  const isDeactivated = contract?.status === EContractStatus.deactivated || contract?.status === EContractStatus.inDeactivation || contract?.status === EContractStatus.cancellationRequested

  const renderedAnnotations = useMemo(() => {
    return observations?.map((item, index) => (
      <RegisteredAnnotation
        key={`annotation-${index}`}
        annotation={item.text}
        createdAt={item.createdAt}
        analystName={item.analystName}
      />
    ))
  }, [observations])

  const methods = useForm<AnnotationForm>({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (formData: AnnotationForm) => {
    try {
      const { data: updatedContract } = await addAnnotationToContract({
        variables: {
          params: {
            _id: contract._id,
            text: formData.annotation
          }
        }
      })

      methods.reset()

      if (customUpdateProposal) {
        updatedContract && setContract(updatedContract.contractAddObservation as ContractModel)
        return
      }

      updatedContract && setContract(updatedContract.contractAddObservation as ContractModel)
    } catch (err) {
      toast.error('Houve um problema ao adicionar observação')
    }
  }

  return (
    <FormProvider {...methods}>
      <Form key='annotations'>
        <Box flexDirection='column' display='flex' gap={3}>
          <Typography variant='h3' fontWeight={500}>Observações</Typography>

          {renderedAnnotations}

          <Input
            multiline
            minRows={5}
            name='annotation'
            disabled={loading || !canAddAnnotations || isDeactivated || disabledFields}
            placeholder='Escreva aqui suas observações'
          />

          <Button
            color='secondary'
            style={{ alignSelf: 'start' }}
            disabled={loading || !canAddAnnotations || isDeactivated}
            onClick={methods.handleSubmit(onSubmit)}
            startIcon={loading ? <CircularProgress color='inherit' size={24} /> : <Add />}
          >
            Registrar observação
          </Button>
        </Box>
      </Form>
    </FormProvider>
  )
}
