import { SvgIcon, SvgIconProps } from '@mui/material'

export const Contract: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <SvgIcon width={width || 40} height={height || 40} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='20' cy='20.0001' r='20' fill='#FAFAFA' />
      <path fillRule='evenodd' clipRule='evenodd' d='M15 12.0001C14.7348 12.0001 14.4804 12.1055 14.2929 12.293C14.1054 12.4806 14 12.7349 14 13.0001V27.0001C14 27.2653 14.1054 27.5197 14.2929 27.7072C14.4804 27.8948 14.7348 28.0001 15 28.0001H25C25.2652 28.0001 25.5196 27.8948 25.7071 27.7072C25.8946 27.5197 26 27.2653 26 27.0001V13.0001C26 12.7349 25.8946 12.4806 25.7071 12.293C25.5196 12.1055 25.2652 12.0001 25 12.0001H15ZM12.8787 10.8788C13.4413 10.3162 14.2044 10.0001 15 10.0001H25C25.7956 10.0001 26.5587 10.3162 27.1213 10.8788C27.6839 11.4414 28 12.2045 28 13.0001V27.0001C28 27.7958 27.6839 28.5588 27.1213 29.1214C26.5587 29.6841 25.7957 30.0001 25 30.0001H15C14.2043 30.0001 13.4413 29.6841 12.8787 29.1214C12.3161 28.5588 12 27.7958 12 27.0001V13.0001C12 12.2045 12.3161 11.4414 12.8787 10.8788Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M16 15.0001C16 14.4478 16.4477 14.0001 17 14.0001H23C23.5523 14.0001 24 14.4478 24 15.0001C24 15.5524 23.5523 16.0001 23 16.0001H17C16.4477 16.0001 16 15.5524 16 15.0001Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M16 19.0001C16 18.4478 16.4477 18.0001 17 18.0001H23C23.5523 18.0001 24 18.4478 24 19.0001C24 19.5524 23.5523 20.0001 23 20.0001H17C16.4477 20.0001 16 19.5524 16 19.0001Z' fill='#999999' />
      <path fillRule='evenodd' clipRule='evenodd' d='M16 23.0001C16 22.4478 16.4477 22.0001 17 22.0001H21C21.5523 22.0001 22 22.4478 22 23.0001C22 23.5524 21.5523 24.0001 21 24.0001H17C16.4477 24.0001 16 23.5524 16 23.0001Z' fill='#999999' />
    </SvgIcon>
  )
}
