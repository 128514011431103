import { useTheme } from '@emotion/react'
import { Box, Button, Divider, Typography } from '@mui/material'
import { IconHelpCircleFilled } from '@tabler/icons-react'
import { toast } from 'react-toastify'
import { Dialog } from '~/components/Dialog'
import { useContract } from '~/contexts'
import { EContractStatus, useActiveContractMutation } from '~/graphql/types'

export type ContractActiveDialogProps = {
  isVisible: boolean
  toggleModal: () => void
}

export const ContractActiveDialog: React.FC<ContractActiveDialogProps> = ({ isVisible, toggleModal }) => {
  const theme = useTheme()
  const { contract, setContract } = useContract()

  const [activateContract] = useActiveContractMutation({
    onCompleted() {
      if (contract) {
        setContract({ ...contract, status: EContractStatus.active })
        toast.success('O seu contrato foi ativado!')
        toggleModal()
      }
    },
    onError(error) {
      toast.error(error.message ? error.message : 'Erro ao ativar o contrato.')
    },
  })

  return (
    <Dialog
      isVisible={isVisible}
      title='Ativação do contrato'
      onClose={toggleModal}
      minWidth={700}
      titleIcon={<IconHelpCircleFilled style={{ color: theme.palette.primary.main }} size={32} />}
    >
      <Divider />
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '1rem', padding: '2rem 0' }}>
        <Typography>Tem certeza de que deseja alterar o status do contrato para “Ativo”?</Typography>
        <Typography>Essa ação não poderá ser desfeita</Typography>
      </Box>
      <Divider />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', paddingTop: '2rem' }}>
        <Button onClick={toggleModal} color='secondary'>Cancelar</Button>
        <Button onClick={() => activateContract({
          variables: {
            id: contract?._id || ''
          }
        })}>
          Confirmar
        </Button>
      </Box>
    </Dialog>
  )
}
