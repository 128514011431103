import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material'
import { IconCheck, IconHelpCircleFilled } from '@tabler/icons-react'
import { useContract } from '~/contexts'
import { transformMoney } from '~/utils'
import { DealershipAndPowerplantForm } from '../..'
import { Dialog, DialogProps } from '~/components/Dialog'
import { ContractModel, useContractChangePowerPlantMutation, useGetWalletBalanceByCustomerIdLazyQuery } from '~/graphql/types'

export type ChangePowerplantModalProps = {
  formData: DealershipAndPowerplantForm
} & DialogProps

export const ChangePowerplantModal: React.FC<ChangePowerplantModalProps> = ({ isVisible = true, onClose, loading, formData }) => {
  const { contract, setContract, setIsDisabledContractUpdate, setIsUpdatingContract } = useContract()
  const [walletBalance, setWalletBalance] = useState<number | null | undefined>(null)
  const [updateContractPowerplant, { loading: updateContractLoading }] = useContractChangePowerPlantMutation({
    onError(error) {
      toast.error(error.message)
    },
  })
  const [getWalletBalance, { loading: getWalletBalanceLoading, called }] = useGetWalletBalanceByCustomerIdLazyQuery({ 
    fetchPolicy: 'no-cache',
    onError(error) {
      toast.error(error.message)
    },
  })

  const isLoading = loading || getWalletBalanceLoading || updateContractLoading

  const updateContract = async () => {
    try {
      const { data } = await updateContractPowerplant({
        variables: {
          params: {
            contractId: contract?._id || '',
            ...formData
          }
        }
      })

      data?.contractChangePowerPlan && setContract(data?.contractChangePowerPlan as ContractModel)
      toast.success('Dados atualizados com sucesso.')
      setIsDisabledContractUpdate(true)
      setIsUpdatingContract(false)
      onClose && onClose()
    } catch (err) {
      console.log(err)
    }
  }

  const getBalance = async () => {
    try {
      const { data } = await getWalletBalance({ variables: { customerId: contract?.customerId || '' } })

      if (!data?.getWalletBalanceByCustomerId?.balanceCents || data?.getWalletBalanceByCustomerId?.balanceCents === 0) {
        await updateContract()
        return
      }

      setWalletBalance(data?.getWalletBalanceByCustomerId?.balanceCents)
    } catch (err) {
      console.log(err)
    }
  }

  const onSubmit = useCallback(async () => {
    if (called && walletBalance) {
      await updateContract()
    } else {
      await getBalance()
    }
  }, [called, walletBalance, getBalance, updateContract])

  const title = walletBalance ? 'Saldo disponivel' : 'Usina não elegível para cashback'
  const description = useMemo(() => {
    if (walletBalance) {
      return walletBalance < 500 ? (
        <Typography sx={{ lineHeight: '25px' }}>
          O saldo de cashback não utilizado <b>[{transformMoney(walletBalance, 'toReal')}]</b> será perdido após a alteração.
        </Typography>
      ) : (
        <Typography sx={{ lineHeight: '25px' }}>
          Identificamos que existe saldo de cashback disponível <b>[{transformMoney(walletBalance, 'toReal')}]</b> para o contrato atual. Ao confirmar as alterações, o valor será transferido para a conta bancária do cliente.
        </Typography>
      )
    } else {
      return (
        <>
          <Typography sx={{ lineHeight: '25px' }}>
            A usina selecionada <b>não</b> está elegível para cashback.
          </Typography>

          <Typography sx={{ lineHeight: '25px' }}>
            Ao confirmar a alteração, o tipo de recompensa do cliente será alterada para o modo desconto.
          </Typography>
        </>
      )
    }
  }, [])

  return (
    <Dialog
      title={title}
      isVisible={isVisible}
      onClose={onClose}
      titleIcon={<IconHelpCircleFilled style={{ color: '#F3CC04' }} size={36} />}
    >
      <Box sx={{ flexDirection: 'column', display: 'flex', gap: 4 }}>
        <Divider />

        {description}

        <Typography sx={{ lineHeight: '25px' }}>
          Deseja confirmar a alteração de dados?
        </Typography>

        <Divider />

        <Box sx={{ flexDirection: 'row', gap: 3, display: 'flex', alignSelf: 'end' }}>
          <Button
            color='secondary'
            onClick={onClose}
            disabled={isLoading}
          >
            Cancelar
          </Button>

          <Button
            onClick={onSubmit}
            disabled={isLoading}
            startIcon={isLoading ? null :<IconCheck size={24} />}
            endIcon={isLoading ? <CircularProgress size={24} color='inherit' /> : null}
          >
            Confirmar
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
