import { Typography, useMediaQuery } from '@mui/material'
import { IconLink } from '@tabler/icons-react'
import { Link } from 'react-router-dom'
import { theme, trimString } from '~/utils'
import { useCustomerContract } from '~/contexts'
import { ContractConfigContainer, ContractLinkContainer, IconContainer, IconTitleContainer, TitleDescriptionContainer } from '../../styles'

export const ContractLink: React.FC = () => {
  const { contract } = useCustomerContract()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const path = `${window.location.origin}/app/contracts/${contract?._id}`

  return (
    <ContractConfigContainer>
      <IconTitleContainer>
        <IconContainer>
          <IconLink />
        </IconContainer>

        <TitleDescriptionContainer>
          <Typography>Link para contrato</Typography>
          <Typography color='grey.400'>Clique para seguir para contrato</Typography>
        </TitleDescriptionContainer>
      </IconTitleContainer>

      <Link to={`/app/contracts/${contract?._id}`} target='_blank'>
        <ContractLinkContainer>
          {isLowerMd ? trimString(path, { textCutLength: 20, maxTextLength: 35 }) : path}
        </ContractLinkContainer>
      </Link>
    </ContractConfigContainer>
  )
}
