import { useTheme } from '@emotion/react'
import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { FormData } from '../..'
import { IconPercentage, IconPlus, IconTrash } from '@tabler/icons-react'
import { Select } from '~/components/Form/Select'
import { brazilStates } from '~/constants'
import { useCallback } from 'react'
import { Input } from '~/components/Form/Input'
import { confirmDialog } from '~/components'
import { Option } from '~/utils/options'
import { DealershipListQuery } from '~/graphql/types'
import { useParams } from 'react-router-dom'

type DiscountExceptionTableProps = {
  dealerships: DealershipListQuery['dealershipList']['data']
}

export const DiscountExceptionTable: React.FC<DiscountExceptionTableProps> = ({ dealerships }) => {
  const { id } = useParams()
  const theme = useTheme()
  const formMethods = useFormContext<FormData>()
  const { name, discountsException } = useWatch({ control: formMethods.control })
  const { fields, append, remove } = useFieldArray({ control: formMethods.control, name: 'discountsException' })
  const onAddDealership = useCallback(() => {
    append({
      dealershipId: '',
      uf: '',
    })
  }, [])
  
  const onRemoveDealership = useCallback(async (index: number) => {
    if (discountsException) {
      const dealershipName = dealerships.find(item => item._id === discountsException?.[index].dealershipId)?.name
      const isConfirmed = await confirmDialog({
        title: 'Remover concessionária com exceção de benefício',
        type: 'warning',
        proccedText: 'Sim, desejo remover',
        content: <Box>
          {dealershipName ? (
            <>
              <p>Tem certeza de que deseja remover <b>{`${dealershipName}`}</b> da lista de concessionárias com exceção de benefício para o parceiro <b>{name}</b>?</p>
            </>
          ) 
            : (
              <>
                <p>Tem certeza de que deseja remover esses campos da lista de concessionárias com exceção de benefício para o parceiro <b>{name}</b>?</p>
              </>
            )}
        </Box>
      })

      if (isConfirmed) {
        remove(index)
      }
    }
  }, [discountsException, name, dealerships])

  return (
    <div>
      <Typography color={theme.palette.grey[800]} sx={{ fontWeight: 500, fontSize: '16px', padding: '.2rem 0 .4rem 0' }}>Concessionárias com exceção de benefício oferecido</Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow><TableCell colSpan={10} /></TableRow>
            <TableRow>
              {!id && 
                <TableCell sx={{ minWidth: '200px', color: theme.palette.grey[600] }}>UF</TableCell>
              }
              <TableCell sx={{ minWidth: '260px', color: theme.palette.grey[600] }}>Concessionária</TableCell>
              <TableCell sx={{ color: theme.palette.grey[600] }}>Benefício mínimo</TableCell>
              <TableCell sx={{ color: theme.palette.grey[600] }}>Benefício máximo</TableCell>
              <TableCell sx={{ color: theme.palette.grey[600] }}>Benefício sugerido</TableCell>
              {!id && 
                <TableCell></TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, i) => {
              const fieldWatch = discountsException ? discountsException[i] : undefined
         
              const dealershipEditOptions: Option[] = dealerships
                .map(item => ({ value: item._id, label: item.name || '' })) 
              || []

              const dealershipOptions: Option[] = dealerships
                .filter(item => item.locationUf === fieldWatch?.uf)
                .map(item => ({ value: item._id, label: item.name || '' })) 
              || []
            
              return (
                <TableRow
                  key={field.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {!id && (
                    <TableCell>
                      <Select label='UF' options={brazilStates} name={`discountsException.${i}.uf`} />
                    </TableCell>
                  )}
                  <TableCell>
                    <Select disabled={Boolean(id) || Boolean(!fieldWatch?.uf)} label='Concessionária' options={id ? dealershipEditOptions : dealershipOptions} name={`discountsException.${i}.dealershipId`} />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <Input 
                        disabled={Boolean(id) || Boolean(!fieldWatch?.dealershipId)} 
                        mask='percentage' 
                        icons={{ end: { element: <IconPercentage /> } }} 
                        label='PF' 
                        name={`discountsException.${i}.customerPFDiscountMin`} 
                      />
                      <Input 
                        disabled={Boolean(id) || Boolean(!fieldWatch?.dealershipId)} 
                        mask='percentage' 
                        icons={{ end: { element: <IconPercentage /> } }} 
                        label='PJ' 
                        name={`discountsException.${i}.customerPJDiscountMin`} 
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <Input 
                        disabled={Boolean(id) || Boolean(!fieldWatch?.dealershipId)} 
                        mask='percentage' 
                        icons={{ end: { element: <IconPercentage /> } }} 
                        label='PF' 
                        name={`discountsException.${i}.customerPFDiscountMax`} 
                      />
                      <Input 
                        disabled={Boolean(id) || Boolean(!fieldWatch?.dealershipId)} 
                        mask='percentage' 
                        icons={{ end: { element: <IconPercentage /> } }} 
                        label='PJ' 
                        name={`discountsException.${i}.customerPJDiscountMax`} 
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <Input 
                        disabled={Boolean(id) || Boolean(!fieldWatch?.dealershipId)} 
                        mask='percentage' 
                        icons={{ end: { element: <IconPercentage /> } }} 
                        label='PF' 
                        name={`discountsException.${i}.customerPFDiscountSuggested`} 
                      />
                      <Input 
                        disabled={Boolean(id) || Boolean(!fieldWatch?.dealershipId)} 
                        mask='percentage' 
                        icons={{ end: { element: <IconPercentage /> } }} 
                        label='PJ' 
                        name={`discountsException.${i}.customerPJDiscountSuggested`} 
                      />
                    </Box>
                  </TableCell>
                  {!id && (
                    <TableCell>
                      <IconButton onClick={() => onRemoveDealership(i)}>
                        <IconTrash color={theme.palette.error.main} />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
            {!id && (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell colSpan={10}>
                  <Button startIcon={<IconPlus />} onClick={() => onAddDealership()} color='secondary'>Adicionar distribuidora</Button>
                </TableCell>
              </TableRow>

            )}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}></TableRow>
           
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  )
}
