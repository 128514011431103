import { createContext, useContext, useEffect, useState } from 'react'
import { PowerPlantModel, usePowerPlantListLazyQuery } from '~/graphql/types'
import { PowerPlantContextData, PowerPlantProviderProps, PowerPlantListData } from './types'

const PowerPlantContext = createContext({} as PowerPlantContextData)

export const PowerPlantProvider: React.FC<PowerPlantProviderProps> = ({ children }) => {
  const [powerPlants, setPowerPlants] = useState<PowerPlantListData>([])
  const [selectedPowerPlant, setSelectedPowerPlant] = useState<PowerPlantModel | null>(null)
  
  const [getPowerPlants, { data, loading: powerPlantListIsLoading }] = usePowerPlantListLazyQuery()

  useEffect(() => {
    if(data) {
      setPowerPlants(data.powerPlantList.data as PowerPlantListData)
    }
  }, [data])

  return (
    <PowerPlantContext.Provider value={{ 
      powerPlantListIsLoading,
      powerPlants,
      setPowerPlants,
      selectedPowerPlant,
      setSelectedPowerPlant,
      getPowerPlants
    }}>{children}</PowerPlantContext.Provider>
  )
}

export const usePowerPlant = () => useContext(PowerPlantContext)
