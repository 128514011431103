import { createContext, useContext, useEffect, useState } from 'react'
import { AccessControlContextData, AccessControlData, AccessControlListData, AccessControlProviderProps } from './types'
import { useAccessControlListLazyQuery } from '~/graphql/types'

const AccessControlContext = createContext({} as AccessControlContextData)

export const AccessControlProvider: React.FC<AccessControlProviderProps> = ({ children }) => {
  const [accessControls, setAccessControls] = useState<AccessControlListData>([])
  // const [controlModules, setControlModules] = useState<string[]>([])
  const [selectedAccessControls, setSelectedAccessControls] = useState<AccessControlData | null>(null)

  const [getAccessControls, { data, loading: accessControlListIsLoading }] = useAccessControlListLazyQuery()

  // const { data: responseModules, loading: modulesIsListIsLoading } = useModulesListQuery()

  useEffect(() => {
    if(data) {
      setAccessControls(data.accessControlList.data as AccessControlListData)
    }
    // if(responseModules) {
    //   setControlModules(responseModules.modulesList)
    // }
  }, [data])

  const isLoading = accessControlListIsLoading

  return (
    <AccessControlContext.Provider value={{
      accessControlListIsLoading: isLoading,
      accessControls,
      selectedAccessControls,
      setAccessControls,
      setSelectedAccessControls,
      // controlModules,
      // setControlModules,
      getAccessControls
    }}>
      {children}
    </AccessControlContext.Provider>
  )
}

export const useAccessControl = () => useContext(AccessControlContext)
