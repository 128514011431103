import { Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Input } from '~/components/Form/Input'
import { Radio } from '~/components/Form/Radio'
import { Select } from '~/components/Form/Select'
import { ECustomerPersonType } from '~/graphql/types'
import { BaseDataForm } from '../../utils'
import { Option, bankAccountTypeOptions, defaultOption } from '~/utils/options'
import { useBank } from '~/hooks/useBank'

export type BankFieldsProps = {
  disableFields?: boolean
}

export const BankFields: React.FC<BankFieldsProps> = ({ disableFields }) => {
  const { watch } = useFormContext<BaseDataForm>()
  const isLegalRepresentativeBank = watch('bankInfoOwner')
  const personType = watch('personType')
  const isPj = personType === ECustomerPersonType.pj
  const legalRepresentatives = watch('legalRepresentatives')
  const hasLegalRepresentatives = legalRepresentatives?.some(legalRepresentative => Boolean(legalRepresentative.document))
  const activeBankCode = watch('code')
  const { accountLabel, agencyLabel, bankOptions, agencyMask, accountMask } = useBank(activeBankCode)

  const legalRepresentativeOptions = legalRepresentatives?.map((legalRepresentative) => {
    return {
      value: legalRepresentative.document,
      label: legalRepresentative.name
    }
  }).filter(option => option.value) as Option[]

  return (
    <>
      <Typography variant='h3' fontWeight={500}>Dados bancários</Typography>
      {isPj && <Typography fontWeight={500}>Titularidade da conta</Typography>}
      <Grid container spacing={3}>
        {isPj && (
          <>
            <Grid item xs={12}>
              <Radio disabled={!hasLegalRepresentatives || disableFields} row name='bankInfoOwner' options={[{ value: 'company', label: 'Empresa' }, { value: 'legalRepresentative', label: 'Representante legal' }]} />
            </Grid>

            {isLegalRepresentativeBank === 'legalRepresentative' && (
              <Grid item xs={12} md={4}>
                <Select disabled={disableFields} label='Sócio titular' name='legalRepresentativeIdForBank' options={[defaultOption, ...legalRepresentativeOptions]} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography fontWeight={500}>Dados da conta</Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={4}>
          <Select disabled={disableFields} label='Banco' name='code' options={bankOptions} />
        </Grid>
        <Grid item xs={0} md={4} />
        <Grid item xs={0} md={4} />

        <Grid item xs={12} md={4}>
          <Input disabled={disableFields} name='agency' label={agencyLabel} customMask={agencyMask} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input disabled={disableFields} name='account' label={accountLabel} customMask={accountMask} />
        </Grid>

        <Grid item xs={12}>
          <Radio disabled={disableFields} row name='accountType' options={bankAccountTypeOptions} />
        </Grid>
      </Grid>
    </>
  )
}
