import { Typography, useMediaQuery } from '@mui/material'
import { Breadcrumb } from '../Breadcrumb'
import { BreadcrumbProps } from '../Breadcrumb/types'
import { Container, ContainerProps } from './style'
import { ReactElement } from 'react'
import { theme } from '~/utils'

type ContentTitleProps = {
  title: string,
  description: string
  breadcrumbLinks: BreadcrumbProps
  style?: ContainerProps
  rightContent?: ReactElement
}
export const ContentTitle: React.FC <ContentTitleProps>= ({ description, title, breadcrumbLinks, style, rightContent }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container {...style}>
      <div>
        <Breadcrumb {...breadcrumbLinks} />
        <Typography fontSize={isLowerSm ? '1.4rem' : '2rem'} variant='h2' color='grey.800'>{title}</Typography>
        <Typography variant='body2' color='grey.400'>{description}</Typography>
      </div>
      {Boolean(rightContent) && rightContent}
    </Container>
  )
}
