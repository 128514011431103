import { createContext, useContext, useEffect, useState } from 'react'
import { usePowerGeneratorListLazyQuery } from '~/graphql/types'
import { PowerGeneratorContextData, PowerGeneratorProviderProps, PowerGeneratorListData, PowerGeneratorData } from './types'

const PowerGeneratorContext = createContext({} as PowerGeneratorContextData)

export const PowerGeneratorProvider: React.FC<PowerGeneratorProviderProps> = ({ children }) => {
  const [powerGenerators, setPowerGenerators] = useState<PowerGeneratorListData>([])
  const [selectedPowerGenerator, setSelectedPowerGenerator] = useState<PowerGeneratorData | null>(null)

  const [getPowerGenerators, { data, loading: powerGeneratorListIsLoading }] = usePowerGeneratorListLazyQuery()

  useEffect(() => {
    if(data) {
      setPowerGenerators(data.powerGeneratorList.data as PowerGeneratorListData)
    }
  }, [data])

  return (
    <PowerGeneratorContext.Provider value={{ 
      powerGeneratorListIsLoading,
      powerGenerators,
      setPowerGenerators,
      selectedPowerGenerator,
      setSelectedPowerGenerator,
      getPowerGenerators
    }}>{children}</PowerGeneratorContext.Provider>
  )
}

export const usePowerGenerator = () => useContext(PowerGeneratorContext)
