/* eslint-disable no-unused-vars */
import React from 'react'
import { List } from '@mui/material'
import { theme } from '~/utils'
import { Collapsibles } from '../..'
import { TSidebarItem } from '~/components/Layout'
import { SidebarListItem } from '../SidebarListItem'
import { CollapsibleList } from '../CollapsibleList'
import { DrawerHeader, StyleDrawer } from '../../style'
import DespertaLogo from '~/assets/images/logo/desperta-logo.svg'
import DespertaLogoReduced from '~/assets/images/logo/desperta-logo-reduced.svg'
import { IconLogout } from '@tabler/icons-react'

export type SidebarContentProps = {
  isDrawerOpen: boolean
  collapsibles: Collapsibles
  sidebarItems: TSidebarItem[]
  signOut: () => void
  toggleCollapsible: (collapsibleName: keyof Collapsibles) => void
}
export const SidebarContent: React.FC<SidebarContentProps> = (props) => {
  const { isDrawerOpen, sidebarItems, collapsibles, toggleCollapsible, signOut } = props

  return (
    <StyleDrawer variant='permanent' open={isDrawerOpen}>
      <DrawerHeader sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
        <img src={isDrawerOpen ? DespertaLogo : DespertaLogoReduced} height={40} />
      </DrawerHeader >

      <List sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {sidebarItems.map((sidebarItem) => {
          const { collapsibleName, text, nested, ...rest } = sidebarItem

          return (
            <React.Fragment key={`sidebar-list-item-${text}`}>
              <SidebarListItem
                text={text}
                isDrawerOpen={isDrawerOpen}
                isCollapsibleOpen={collapsibleName ? collapsibles[collapsibleName] : false}
                toggleCollapsible={collapsibleName ? () => toggleCollapsible(collapsibleName) : undefined}
                {...rest}
              />
              {nested && collapsibleName && (
                <CollapsibleList
                  isDrawerOpen={isDrawerOpen}
                  sidebarItem={sidebarItem}
                  isOpen={collapsibles[collapsibleName]}
                />
              )}
            </React.Fragment>
          )
        })}
        <SidebarListItem
          text='Sair'
          icon={<IconLogout />}
          hideChevronIcon
          onClick={signOut}
          isDrawerOpen={isDrawerOpen}
          sx={{ marginTop: 'auto', background: theme.palette.error.main }}
        />
      </List>
    </StyleDrawer>
  )
}
