
/* eslint-disable no-unused-vars */

import { Box, IconButton, Link, Typography } from '@mui/material'
import { useToggle } from '~/hooks'
import { Dropdown } from '~/components'
import { ThreeDots } from '~/assets/icons'
import { GenerateTermOfManagementDialog } from './components'
import { IconDownload, IconRefresh } from '@tabler/icons-react'
import { ContractTermOfManagementModel, EContractStatus, ETermStatus } from '~/graphql/types'
import { useTheme } from '@emotion/react'
import { useContract } from '~/contexts'

export type TermOfManagementOptionsProps = {
  term?: ContractTermOfManagementModel | null
}
export const TermOfManagementOptions: React.FC<TermOfManagementOptionsProps> = ({ term }) => {
  const theme = useTheme()
  const { contract } = useContract()
  const { isTrue: isVisible, toggle: toggleModal } = useToggle()
  const hasDownloadLink = Boolean(term?.file?.fileUrl) && term?.file?.fileUrl !== '-'

  const hasRegenerateTerm = contract?.status === EContractStatus.readyToSend || contract?.status === EContractStatus.sent || contract?.status === EContractStatus.active
  console.log(contract?.status)

  return (
    <>
      <Dropdown
        $options={{ left: '-160px' }}
        elementClick={(
          <IconButton>
            <ThreeDots />
          </IconButton>
        )}
      >
        <Box sx={{
          display: 'flex',
          borderRadius: 3,
          flexDirection: 'column',
          minWidth: '220px',
          background: 'white',
          boxShadow: '0px 0px 2px rgba(64, 64, 64, 0.1), 0px 6px 15px -4px rgba(64, 64, 64, 0.14)'
        }}>
          {/* {!hasRegenerateTerm && (
            <Box onClick={toggleModal} display='flex' flex={1} gap={2} alignItems='center' sx={{ cursor: 'pointer', padding: 2, '&:hover': { background: '#FAFAFA' } }}>
              <IconRefresh />
              <Typography variant='body2'>Gerar novo termo de gestão</Typography>
            </Box>
          )} */}

          {hasDownloadLink && (
            <Link sx={{ textDecoration: 'none', color: theme.palette.grey[800] }} target='_blank' href={term?.file?.fileUrl || ''} rel='noreferrer'>
              <Box display='flex' flex={1} gap={2} alignItems='center' sx={{ cursor: 'pointer', padding: 2, '&:hover': { background: '#FAFAFA' } }}>
                <IconDownload />
                <Typography variant='body2'>Baixar termo de gestão</Typography>
              </Box>
            </Link>
          )}

        </Box>
      </Dropdown>

      {/* <GenerateTermOfManagementDialog isVisible={isVisible} toggleModal={toggleModal} /> */}
    </>
  )
}
