import { Box, Button, Card, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { IconArrowRight, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { Status, TableApiV2, TableHeaderApi } from '~/components'
import { ContentTitle } from '~/components/ContentTitle'
import { usePowerGeneratorListQuery } from '~/graphql/types'
import { useDebounce, usePaginationValues, usePermission } from '~/hooks'
import { theme } from '~/utils'

export const PowerGeneratorList: React.FC = () => {
  const navigate = useNavigate()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [searchParams, setSearchParams] = useSearchParams()
  const { page, size } = usePaginationValues()
  const name = searchParams.get('name')
  const [filterName, setFilterName] = useState(name || '')
  const debouncedFilterName = useDebounce(filterName, 1000)

  const { data: powerGenerators, loading: powerGeneratorListIsLoading } = usePowerGeneratorListQuery({
    variables: {
      params: {
        sort: {
          field: 'createdAt',
          order: -1
        },
        pageSize: size,
        pageNumber: page,
        ...name && name?.length > 0 ? { name: name.trim() } : {},
      }
    },
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.powerGeneratorList.meta.documentCounts))
        return state
      })
    },
  })

  const handleFilters = (filters: { name: string }) => {
    setSearchParams(state => {
      if (filters.name) {
        state.set('name', filters.name)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }

  useEffect(() => {
    handleFilters({ name: debouncedFilterName })
  }, [debouncedFilterName])

  const canEditPowerGenerators = usePermission(['powerGenerator.updated', 'powerGenerator.create'])

  const defaultColumnHiddens = isLowerMd ? [''].concat(isLowerSm ? ['isDesperta', 'active'] : '') : []

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Ativo',
        accessor: 'active',
        Cell: ({ value }) => (
          <>
            {value ?
              <Status text='Ativo' background={theme.palette.success.light} color={theme.palette.success.main} />
              : <Status text='Inativo' color={theme.palette.error.main} />
            }
          </>
        )
      },
      {
        Header: 'Desperta',
        accessor: 'isDesperta',
        Cell: ({ value }) => (
          <>
            {value ?
              <Status text='Sim' background={theme.palette.success.light} color={theme.palette.success.main} />
              : <Status text='Não' color={theme.palette.error.main} />
            }
          </>
        )
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        disableSortBy: true,
        // width: 200,
        Cell: ({ value }) => (
          <>
            {canEditPowerGenerators ? (
              <Link to={`/app/generators/update/${value}`}>
                <IconButton>
                  <IconArrowRight />
                </IconButton>
              </Link>
            ) : (
              <IconButton disabled={true}>
                <IconArrowRight />
              </IconButton>
            )}
          </>
        )
        ,
      }
    ]
  }, [])

  const dataWithMemo = useMemo(() => powerGenerators?.powerGeneratorList.data || [], [powerGenerators])

  const tableMethods = useTable(
    {
      columns,
      data: dataWithMemo,
      initialState: {
        hiddenColumns: defaultColumnHiddens,
      },
      manualPagination: true,
      defaultColumn: {
        Filter: <></>
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    //disable checkboxColumn
    // (hooks) => renderTableCheckbox(hooks)
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Lista de geradores'
          description='Consulte todos geradores cadastrados'
          breadcrumbLinks={{ currentLink: 'Listar geradores', links: [{ href: '/app/generators', label: 'Geradores' }] }}
          rightContent={<Button disabled={!canEditPowerGenerators} onClick={() => navigate('/app/generators/create')} startIcon={<IconPlus />}>Cadastrar gerador</Button>}
        />
        <TableHeaderApi
          startElement={(
            <>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder='Procurar usina por nome'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </>
          )}
          endElement={
            (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {tableMethods.selectedFlatRows.length !== 0 &&
                  (
                    <>
                      <Typography color='grey.600'>{tableMethods.selectedFlatRows.length} Selecionado(s)</Typography>
                      <Button sx={{ fontWeight: 600 }} size='small' startIcon={<IconTrash></IconTrash>} color='error'>Excluir</Button>
                    </>
                  )
                }
              </Box>
            )
          }
        />
        <TableApiV2
          isLoading={powerGeneratorListIsLoading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Card>
    </div>
  )
}
