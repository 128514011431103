/* eslint-disable no-unused-vars */
import { ArrowBack, ArrowForward, Save } from '@mui/icons-material'
import { ProposalFlowActionDropdown, PropsWithWizard } from '~/components'
import { Box, CircularProgress, Divider, useMediaQuery } from '@mui/material'
import { Button } from '~/components/Form/Button'
import { useProposalContext } from '~/contexts'
import { EProposalStatus } from '~/graphql/types'
import { theme } from '~/utils'

type ActionButtonsProps = {
  loading: boolean
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}
export const ActionButtons: React.FC<PropsWithWizard<ActionButtonsProps>> = (props) => {
  const { onNext, onPrev, loading, onSubmit } = props
  const { proposal } = useProposalContext()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const isFormalized = proposal?.status === EProposalStatus.formalization
  const isCanceled = proposal?.status === EProposalStatus.canceled

  const handleSubmit = async (submitType: 'continue' | 'save') => {
    await onSubmit()

    if (submitType === 'continue') {
      onNext()
    }
  }

  return (
    <>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }} >
        {!isLowerMd && (
          <Button disabled={loading} startIcon={<ArrowBack />} color='secondary' onClick={onPrev}>
          Voltar
          </Button>
        )}

        <Box sx={{ display: 'flex', flexDirection: isLowerSm ? 'column' : 'row', gap: 3, width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: isLowerSm ? 'space-between' : 'flex-end' }}>

            <ProposalFlowActionDropdown disabled={loading || isCanceled} proposal={proposal} />

            <Button
              onClick={() => handleSubmit('save')}
              disabled={loading || isFormalized || isCanceled}
              startIcon={loading ? <CircularProgress size={24} color='inherit' /> : <Save />}
              color='secondary'
              size='small'
            >
            Gravar dados
            </Button>
          </Box>

          <Button
            fullWidth={isLowerSm}
            onClick={() => handleSubmit('continue')}
            disabled={loading || isFormalized || isCanceled}
            endIcon={<ArrowForward />}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </>
  )
}
