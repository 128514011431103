import { EInvoiceStatus } from '~/graphql/types'
import { Container } from './styles'
import { CONTRAC_INVOICE, translateContractInvoice } from '~/utils'

type FinancialStatusProps = {
  status: EInvoiceStatus
}
export const FinancialStatus: React.FC<FinancialStatusProps> = ({ status }) => {
  return (
    <Container status={status}>
      <span>{translateContractInvoice(status, CONTRAC_INVOICE)}</span>
      {/* <span>{translateContractInvoice(status, CONTRAC_INVOICE)}</span> */}
    </Container>
  )
}
