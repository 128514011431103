import { Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Input } from '~/components/Form/Input'
import { Select } from '~/components/Form/Select'
import { ECustomerPersonType } from '~/graphql/types'
import { maritalStatusOptions } from '~/utils/options'

export type BaseDataFieldsProps = {
  disableFields: boolean
}
export const BaseDataFields: React.FC<BaseDataFieldsProps> = ({ disableFields }) => {
  const { watch } = useFormContext()

  const isPf = watch('personType') === ECustomerPersonType.pf

  return (
    <>
      <Typography variant='h3' fontWeight={500}>Dados {isPf ? 'pessoais' : 'da empresa'}</Typography>
      <Grid spacing={3} container>
        <Grid item md={8} xs={12}>
          <Input disabled={disableFields} name='name' label='Nome completo' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled name='document' label={isPf ? 'CPF' : 'CNPJ'} mask={isPf ? 'cpf' : 'cnpj'} />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} name='email' label='E-mail' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} name='phone' label='Telefone' mask='phone' />
        </Grid>
      </Grid>

      {isPf && (
        <>
          <Typography variant='h3' fontWeight={500}>Dados complementares</Typography>
          <Grid spacing={2} container>
            <Grid item md={4} xs={12}>
              <Input disabled={disableFields} name='nationality' label='Nacionalidade' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Select disabled={disableFields} name='maritalStatus' label='Estado civil' options={maritalStatusOptions} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
