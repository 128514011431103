import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Box, Button, Card, IconButton, InputAdornment, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { IconPlus, IconSearch, IconArrowRight } from '@tabler/icons-react'

import { ProposalStatus, TableApiV2, TableHeaderApi, ContentTitle, FilterButton } from '~/components'
import { FilterProposalDrawer } from './components'

import { SearchQueysData, useDebounce, usePermission, useQueryString, useToggle } from '~/hooks'
import { PROPOSAL_ORIGIN, PROPOSAL_ORIGIN_TO_TABLE, displayCpfCnpj, formatDate, ifIsDocumentReturnFormated, theme, translateProposalContractOrigin } from '~/utils'
import { useProposalListQuery } from '~/graphql/types'

export const ProposalsList: React.FC = () => {
  const navigate = useNavigate()
  const { isTrue: drawerIsOpen, toggle: toggleDrawer, setIsTrue: setDrawerIsOpen } = useToggle(false)

  const queryKeys: SearchQueysData[] = [
    { key: 'status', defaultValue: [], parseValue: true },
    { key: 'pendencies', defaultValue: [], parseValue: true },
    { key: 'origin', defaultValue: [], parseValue: true },
    { key: 'funnel', defaultValue: [], parseValue: true },
    { key: 'name', defaultValue: '' },
    { key: 'begin', defaultValue: '' },
    { key: 'end', defaultValue: '' },
  ]
  const { searchQuerys, paginationQuerySize, searchParams, setSearchParams, page, size } = useQueryString()

  const queryValues = searchQuerys(queryKeys)

  const canCreateProposals = usePermission(['proposals.create'])
  const canViewProposals = usePermission(['proposals.read'])
  const isLowerlg = useMediaQuery(theme.breakpoints.down('xl'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [filterName, setFilterName] = useState(queryValues.name)

  const debouncedFilterName = useDebounce(filterName, 1000)

  useEffect(() => {
    setSearchParams(state => {
      if (debouncedFilterName) {
        state.set('name', debouncedFilterName)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }, [debouncedFilterName])

  const { data, loading } = useProposalListQuery({
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        sort: {
          field: 'createdAt',
          order: -1
        },
        ...queryValues?.name?.length > 0 ? { nameOrDocument: ifIsDocumentReturnFormated(queryValues.name) } : {},
        ...queryValues?.status?.length > 0 ? { inStatus: queryValues.status } : {},
        ...queryValues?.pendencies?.length > 0 ? { inPendencies: queryValues.pendencies } : {},
        ...queryValues?.origin?.length > 0 ? { inOrigin: queryValues.origin } : {},
        ...queryValues?.funnel?.length > 0 ? { salesFunnelInStage: queryValues.funnel } : {},
        ...queryValues?.begin && queryValues?.end ?
          {
            dateRange:
            {
              startDate: new Date(queryValues.begin),
              endDate: new Date(`${queryValues.end}Z23:59:59`)
            }
          }
          : {},
      }
    },
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.proposalList.meta.documentCounts))
        return state
      })
    },
    fetchPolicy: 'no-cache'
  })

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Cliente',
        accessor: 'customerRef.name',
      },
      {
        Header: 'Tipo de pessoa',
        accessor: 'customerRef.personType',
      },
      {
        Header: 'CPF/CNPJ',
        accessor: 'customerRef.document',
        Cell: (props) => <>{displayCpfCnpj(props.value)}</>,
        disableSortBy: true,
        filter: 'includes'
      },
      {
        Header: 'Origem',
        accessor: 'origin',
        Cell: (props) => (
          <Tooltip placement='bottom-start' title={translateProposalContractOrigin(props.value, PROPOSAL_ORIGIN)}>
            <Typography fontSize='14px' fontWeight={500} color={theme.palette.despertaPurple.light} >{props.value ? translateProposalContractOrigin(props.value, PROPOSAL_ORIGIN_TO_TABLE) : props.value}</Typography>
          </Tooltip>
        )
        ,
      },
      {
        Header: 'Parceiro',
        accessor: 'indicationRef.partner.name',
        Cell: (props) => <>{props.value || '-'}</>,
      },
      {
        Header: 'Criação',
        accessor: 'createdAt',
        Cell: (props) => <>{formatDate(props.value, { dateHour: true })}</>
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: (props) => <ProposalStatus status={props.value} />
      },
      {
        Header: 'Ações',
        accessor: '_id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            {canViewProposals ? (
              <Link to={`/app/proposals/${value}`}>
                <IconButton>
                  <IconArrowRight />
                </IconButton>
              </Link>
            ) : (
              <IconButton disabled={true}>
                <IconArrowRight />
              </IconButton>
            )}
          </>
        ),
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.proposalList?.data || [], [data])

  const defaultColumnHiddens = isLowerlg ? ['createdAt', 'indicationRef.partner.name'].concat(isLowerMd ? ['origin'] : []).concat(isLowerSm ? ['customerRef.document'] : '') : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    defaultColumn: {
      Filter: <></>
    },
    manualPagination: true,
    initialState: {
      hiddenColumns: [...defaultColumnHiddens, 'customerRef.personType']
    },
  },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title={'Propostas'}
          description={'Visualize e gerencie as propostas de seus cliente'}
          breadcrumbLinks={{ currentLink: 'Propostas', links: [{ href: '/app/proposals', label: 'Vendas' }] }}
          rightContent={<Button disabled={!canCreateProposals} onClick={() => navigate('/app/proposals/create')} startIcon={<IconPlus />}>Cadastrar proposta</Button>}
        />
        <TableHeaderApi
          startElement={
            (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', width: '100%', flexFlow: isLowerSm ? 'column-reverse' : 'row' }}>
                <TextField
                  fullWidth
                  sx={{ maxWidth: '400px' }}
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder='Procurar proposta por nome ou CPF/CNPJ'
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position='end'>
                          <IconSearch />
                        </InputAdornment>
                      </>
                    )
                  }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <FilterButton onCustomClear={() => setFilterName('')} filtersToClear={queryKeys} toggleDrawer={toggleDrawer} filterCounter={searchParams.size - paginationQuerySize} />
                </Box>

                <FilterProposalDrawer queryKeys={queryKeys} drawerIsOpen={drawerIsOpen} setDrawerIsOpen={setDrawerIsOpen} toggleDrawer={toggleDrawer} />
              </Box>
            )
          }
        />
        <TableApiV2
          isLoading={loading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Card>
    </div>
  )
}
