import { EProposalFileStatus } from '~/graphql/types'
import { Container } from './styles'
import { IconDownload, IconEye } from '@tabler/icons-react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { InputPendenciesStatus } from '../InputPendenciesStatus'
import { theme } from '~/utils'
import { Link } from 'react-router-dom'

type FilePendencyStatusProps = {
  status?: EProposalFileStatus | null
  label?: string,
  fileName?: string
  fileUrl?: string
}

export const FilePendencyStatus: React.FC<FilePendencyStatusProps> = ({ status, label, fileName, fileUrl }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  if(!status) return null

  return (
    <Container status={status}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
          <IconDownload size={isLowerSm ? '22px' : '32px'} />
          <Typography fontSize={isLowerSm ? '.85rem' : '1rem'} fontWeight={500} variant='body1'>{label}</Typography>
          {!isLowerSm && 
            <Typography color={theme.palette.grey[400]}>{fileName}</Typography>
          }
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: isLowerSm ? '.6rem' : '1rem' }}>
          <InputPendenciesStatus status={status} />
          {isLowerSm && fileUrl && 
            <Link target='_blank' to={fileUrl}><IconEye size={isLowerSm ? 20 : 26} /></Link>
          }
          {!isLowerSm && fileUrl && <Link className='onHover' target='_blank' to={fileUrl}><IconEye size={isLowerSm ? 20 : 26} /></Link> }
        </Box>
      </Box>
    </Container>
  )
}
