import { Box, Button, Card, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { IconArrowRight, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { Status, TableApiV2, TableHeaderApi } from '~/components'
import { ContentTitle } from '~/components/ContentTitle'
import { useUsers } from '~/contexts'
import { useDebounce, usePaginationValues, usePermission } from '~/hooks'
import { displayPhone, theme, trimString } from '~/utils'

export const UsersList: React.FC = () => {
  const navigate = useNavigate()
  const { users, usersListIsLoading, getUsers } = useUsers()
  const canCreateAndEditUsers = usePermission(['users.updated', 'users.create'])
  const [searchParams, setSearchParams] = useSearchParams()
  const { page, size } = usePaginationValues()
  const name = searchParams.get('name')
  const [filterName, setFilterName] = useState(name || '')
  const debouncedFilterName = useDebounce(filterName, 1000)

  const isLowerlg = useMediaQuery(theme.breakpoints.down('xl'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    handleFilters({ name: debouncedFilterName })
  }, [debouncedFilterName])

  useEffect(() => {
    getUsers({
      variables: {
        params: {
          pageSize: Number(size),
          pageNumber: Number(page),
          ...name && name.length > 0 && {
            filter: {
              nameOrUsernameOrEmail: name.trim(),
            }
          },
          // filter: {
          //   ...name && name?.length > 0 ? { nameOrUsernameOrEmail: name.trim() } : {},
          // },
          sort: {
            field: 'createdAt',
            order: -1
          }
        }
      },
      onCompleted(result) {
        setSearchParams(state => {
          state.set('items', String(result.userList.meta.documentCounts))
          return state
        })
      },
    })
  }, [searchParams])

  const handleFilters = (filters: { name: string }) => {
    setSearchParams(state => {
      if (filters.name) {
        state.set('name', filters.name)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'name',
        Cell: ({ value }) => <>{trimString(value, { textCutLength: 40, maxTextLength: 40 })}</>
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <>{value === 'active' ? <Status color={theme.palette.success.main} text='Ativo' /> : <Status color={theme.palette.error.main} text='Inativo' />}</>
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
        Cell: ({ value }) => <>{displayPhone(value)}</>
      },
      {
        Header: 'Permissão',
        accessor: 'accessControlRef.name',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        Cell: ({ value }) => (
          <>
            {canCreateAndEditUsers ? (
              <Link to={`/app/users/update/${value}`}>
                <IconButton>
                  <IconArrowRight />
                </IconButton>
              </Link>
            ) : (
              <IconButton disabled={true}>
                <IconArrowRight />
              </IconButton>
            )}
          </>
        )
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => users || [], [users])

  const defaultColumnHiddens = isLowerlg ? ['accessControlRef.name', 'email'].concat(isLowerMd ? ['phone'] : '') : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    },
    defaultColumn: {
      Filter: <></>
    },
  },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Lista de Usuários'
          description='Consulte todos usuários cadastrados'
          breadcrumbLinks={{ currentLink: 'Listar usuários', links: [{ href: '/app/users', label: 'Usuários' }] }}
          rightContent={<Button onClick={() => navigate('/app/users/create')} startIcon={<IconPlus />}>Cadastrar usuário</Button>}
        />
        <TableHeaderApi
          startElement={(
            <>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder='Procurar um usuário por nome ou E-mail'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </>
          )}
          endElement={
            (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {tableMethods.selectedFlatRows.length !== 0 &&
                  (
                    <>
                      <Typography color='grey.600'>{tableMethods.selectedFlatRows.length} Selecionado(s)</Typography>
                      <Button sx={{ fontWeight: 600 }} size='small' startIcon={<IconTrash></IconTrash>} color='error'>Excluir</Button>
                    </>
                  )
                }
              </Box>
            )
          }
        />
        <TableApiV2
          isLoading={usersListIsLoading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Card>
    </div>
  )
}
