import React, { useCallback } from 'react'
import { UseWizardResult } from '~/hooks'

export type WizardProps = {
  screens: React.ComponentType<UseWizardResult>[],
  onFinish?: () => void,
  onExit?: () => void
  wizardHelpers: UseWizardResult
}

export type PropsWithWizard<P = {}> = P & UseWizardResult & Pick<WizardProps, 'onExit' | 'onFinish'>
export type WizardHelpers = UseWizardResult & Pick<WizardProps, 'onExit' | 'onFinish'>

export const Wizard: React.FC<WizardProps> = (props) => {
  const { screens, wizardHelpers } = props

  const getScreenToRender = useCallback((currentScreen: number) => {
    if (screens.length < currentScreen) {
      throw new Error('currentScreen is greater or equal than screen.length')
    }

    const CurrentScreen = screens[currentScreen]

    return (
      <>
        <CurrentScreen {...wizardHelpers} {...props} />
      </>
    )
  }, [screens, wizardHelpers])

  return getScreenToRender(wizardHelpers.currentPage)
}
