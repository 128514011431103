import { useEffect, useState } from 'react'
import { Box, Button, Card, Divider, Tab, useMediaQuery } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { ContentTitle } from '~/components/ContentTitle'
import { TabPanel, TabContext } from '@mui/lab'
import { TabListWithPadding } from './styles'
import { BasicData } from './BasicData'
import { useProposalWithDealershipsQuery, ECustomerPersonType, ProposalModel } from '~/graphql/types'
import { Address } from './Address'
import { Documents } from './Documents'
import { ModalPendencies } from './ModalPendencies'
import { useToggle } from '~/hooks'
import { FieldsType, StepPendency } from './types'
import { Representatives } from './Representatives'
import { ProposalHeaderInformations } from '~/components'
import { theme } from '~/utils'
import { FullPageLoader } from '~/components/FullPageLoader'
import { toast } from 'react-toastify'
import { IconChevronLeft } from '@tabler/icons-react'

export const ProposalResolvePendencies: React.FC = () => {
  const { isTrue, toggle, setIsTrue } = useToggle(false)
  const [stepPendencyValue, setStepPendencyValue] = useState<StepPendency>('basicData')
  const [proposal, setProposal] = useState<ProposalModel | null>(null)
  const [representativeIndex, setRepresentativeIndex] = useState(0)
  const [modalType, setModalType] = useState<'approve' | 'refuse'>('approve')
  const [fields, setFields] = useState<FieldsType>({  })
  const isLowerXs = useMediaQuery('(max-width:500px)')
  const isLowerToTabs = useMediaQuery('(max-width:693px)')
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { proposalId } = useParams()
  const { refetch, loading: proposalIsLoading } = useProposalWithDealershipsQuery({ variables: {
    id: proposalId || '',
    params: {
      pageSize: 99999
    }
  },
  onCompleted: (currentData) => setProposal(currentData.proposalGet as ProposalModel),
  onError: () => toast.error('Ocorreu um erro ao carregar esta proposta.')
  })

  useEffect(() => {
    if(!proposal?._id) {
      refetch()
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: StepPendency) => {
    setStepPendencyValue(newValue)
  }

  const onRefuse = () => {
    setModalType('refuse')
    toggle()
  }
  const onApprove = () => {
    setModalType('approve')
    toggle()
  }

  if(proposalIsLoading) return <FullPageLoader />

  return (
    <div>
      <Card sx={{ background: 'white', padding: isLowerSm ? '1rem' : '0 1rem', borderRadius: '1rem', }}>
        {isLowerSm ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexFlow: isLowerXs ? 'column' : 'row', gap: isLowerXs ? '1rem' : '0' }}>
              <Link to='/app/proposals'>
                <Button size='small' sx={{ margin: '0' }} color='secondary'>
                  <IconChevronLeft />
                </Button>
              </Link>
              {proposal && <ProposalHeaderInformations />}
            </Box>
            <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
            <ContentTitle
              style={{ padding: '0px 0px 24px 0px' }}
              title='Detalhes da proposta'
              description='Acompanhe e dê continuidade a proposta'
              breadcrumbLinks={{ 
                currentLink: proposal?._id ?? '', 
                links: [{ href: '/app/', label: 'Vendas' }, { href: '/app/proposals', label: 'Propostas' }] 
              }}
            />
          </>
        )
          : (
            <>
              <ContentTitle
                title='Detalhes da proposta'
                description='Acompanhe e dê continuidade a proposta'
                breadcrumbLinks={{ 
                  currentLink: proposal?._id ?? '', 
                  links: [{ href: '/app/', label: 'Vendas' }, { href: '/app/proposals', label: 'Propostas' }] 
                }}
                rightContent={
                  <>
                    {proposal ? <ProposalHeaderInformations /> : undefined}
                  </>
                }
              />
            </>
          )}

        <Box sx={{ typography: 'body1', padding: '1rem 0' }}>
          <TabContext value={stepPendencyValue}>
            <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider', maxWidth: 'calc(100vw - 97px)' }}>
              <TabListWithPadding
                variant='scrollable'
                scrollButtons='auto'
                // allowScrollButtonsMobile
                onChange={handleChange}
                aria-label='Etapas da proposta'
              >
                <Tab sx={{ margin: isLowerToTabs ? '0' : '.6rem 0' }} label='Dados básicos' value='basicData' />
                <Tab sx={{ margin: isLowerToTabs ? '0' : '.6rem 0' }} label='Endereço' value='address' />
                <Tab sx={{ margin: isLowerToTabs ? '0' : '.6rem 0' }} label='Documentos' value='documents' />
                {proposal?.customerRef.personType === ECustomerPersonType.pj && 
                  <Tab sx={{ margin: isLowerToTabs ? '0' : '.6rem 0' }} label='Representantes' value='representativies' />
                }
              </TabListWithPadding>
            </Box>
            <TabPanel sx={{ padding: '2rem 0' }} value='basicData'>
              <BasicData setFields={setFields} modalType={modalType} onRefuse={onRefuse} onApprove={onApprove} proposal={proposal} />
            </TabPanel>
            <TabPanel sx={{ padding: '2rem 0' }} value='address'>  
              <Address setFields={setFields} modalType={modalType} onRefuse={onRefuse} onApprove={onApprove} proposal={proposal} />
            </TabPanel>
            <TabPanel sx={{ padding: '2rem 0' }} value='documents'>
              <Documents setFields={setFields} modalType={modalType} onRefuse={onRefuse} onApprove={onApprove} proposal={proposal} setProposal={setProposal} />
            </TabPanel>
            {proposal?.customerRef.personType === ECustomerPersonType.pj && 
              (
                <TabPanel sx={{ padding: '2rem 0' }} value='representativies'>
                  <Representatives 
                    representativeIndex={representativeIndex} 
                    setRepresentativeIndex={setRepresentativeIndex} 
                    setFields={setFields} 
                    modalType={modalType} 
                    onRefuse={onRefuse} 
                    onApprove={onApprove} 
                    proposal={proposal} 
                  />
                </TabPanel>
              )
            }
            
          </TabContext>
        </Box>
      </Card>
      <ModalPendencies 
        representativeIndex={representativeIndex}
        stepPendencyValue={stepPendencyValue} 
        setFields={setFields} 
        proposal={proposal} 
        setProposal={setProposal} 
        fields={fields} 
        type={modalType} 
        onClose={() => toggle()} 
        isVisible={isTrue} 
        setIsVisible={setIsTrue}
      />
    </div>
  )
}
