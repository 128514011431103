import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchQueysData, useQueryString } from '~/hooks'
import { Select } from '~/components/Form/Select'
import { brazilStates } from '~/constants'
import { Radio } from '~/components/Form/Radio'

type FilterDealershipDrawerProps = {
  drawerIsOpen: boolean
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleDrawer: () => void
  queryKeys: SearchQueysData[]
}

type FilterData = {
  locationUf?: string,
  available?: string
}

export const FilterDealershipDrawer: React.FC<FilterDealershipDrawerProps> = ({ drawerIsOpen, queryKeys, setDrawerIsOpen, toggleDrawer }) => {
  const formMethods = useForm()

  const { searchQuerys, setSearchParams } = useQueryString()
  const queryValues = searchQuerys(queryKeys)

  useEffect(() => {
    if (drawerIsOpen) {
      formMethods.setValue('locationUf', queryValues.locationUf || '')
      formMethods.setValue('available', queryValues.available || '')
    }
  }, [drawerIsOpen])

  const handleFilters = (filters: FilterData) => {
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      if (filters.locationUf && filters?.locationUf?.length > 0) {
        state.set('locationUf', filters.locationUf)
      } else {
        state.delete('locationUf')
      }

      if (String(filters.available).length > 0) {
        state.set('available', String(filters.available))
      } else {
        state.delete('available')
      }

      return state
    })
  }

  const onRemoveFilters = () => {
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      state.delete('locationUf')
      state.delete('available')
      return state
    })
  }

  return (
    <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={drawerIsOpen} onClose={toggleDrawer}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
        <IconButton type='button' onClick={() => toggleDrawer()}>
          <IconX />
        </IconButton>
      </Box>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((formData) => handleFilters(formData))}>

          <Typography fontWeight={500}>Estado</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '1rem 0 2rem 0' }}>
            <Select label='Estado' name='locationUf' options={brazilStates} />
          </Box>
          <Typography fontWeight={500}>Disponibilidade</Typography>
          <Radio name='available' defaultValue={''} options={[{ value: true, label: 'Disponível' }, { value: false, label: 'Indisponível' }]} />
          <Box sx={{ display: 'flex', padding: '2rem 0', gap: '1rem', justifyContent: 'flex-end' }}>
            <Button onClick={() => onRemoveFilters()} type='button' fullWidth color='secondary'>Limpar</Button>
            <Button type='submit' fullWidth color='primary'>Aplicar</Button>
          </Box>
        </form>
      </FormProvider>
    </Drawer>
  )
}
