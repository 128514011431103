import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { ContractModel } from '~/graphql/types'

export type ContractInfoProps = {
  contract: ContractModel | null
  shouldShowCustomerName?: boolean
}
export const ContractInfo: React.FC<ContractInfoProps> = ({ contract, shouldShowCustomerName = true }) => {
  return (
    <Box sx={{ marginBottom: 2 }}>
      <Link to={`/app/proposals/${contract?.proposalId}`} style={{ color: '#3BA1FF', textDecoration: 'none' }}>Acesso à proposta de origem</Link>
      {shouldShowCustomerName && <Typography variant='body1'>{contract?.customerRef?.name || ''}</Typography>}
    </Box>
  )
}
