import * as cpf from '@fnando/cpf'
import * as cnpj from '@fnando/cnpj'

export function clearString(value: string): string {
  return value.replace(/[() -./]/g, '')
}

export function onlyNumbers(value: string): string {
  return value.replace(/\D/g, '')
}

export function displayCpfCnpj(value: string): string {
  if(value.length === 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  else {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
}

export function displayPhone(value: string): string {
  const clearValue = clearString(value)
  let formatedValue = ''

  switch (clearValue.length) {
  case 10:
    formatedValue = clearValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
    break
  case 11:
    formatedValue = clearValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    break
  case 12:
    formatedValue = clearValue.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '($2) $3-$4')
    break
  case 13:
    formatedValue = clearValue.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '($2) $3-$4')
    break
  
  default:
    break
  }

  return formatedValue
}

type TrimStringOptions = {
  textCutLength?: number
  maxTextLength?: number
}

export const trimString = (text?: string, options?: TrimStringOptions) => {
  if (!text) {
    return null
  }

  if (text.length > (options?.textCutLength || 100)) {
    return `${text.substring(0, options?.maxTextLength || 60)}...`
  }

  return text
}

export const ifIsDocumentReturnFormated = (value: string) => {
  const valueOnlyNumber = onlyNumbers(value)
  
  if(cpf?.isValid(valueOnlyNumber) || cnpj?.isValid(valueOnlyNumber)) {
    return valueOnlyNumber
  }
  
  return value.trim()
}

export const displayPercentage = (percentage: number) => {
  return `${percentage}%`
}
