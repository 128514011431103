import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { useWizard } from '~/hooks'
import { useContract } from '~/contexts'
import { Card, ContractFlowLayout, Wizard } from '~/components'
import { ContractModel, useContractGetLazyQuery } from '~/graphql/types'
import { ClientData, ComercialConditions, ConsumerUnit, DealershipAndPowerplant, Documents, Invoices } from './components'

export const ContractsCreate: React.FC = () => {
  const { setContract, resetContract, contract } = useContract()
  const [getContract, { loading }] = useContractGetLazyQuery({
    fetchPolicy: 'no-cache'
  })

  const { contractId } = useParams()

  const fecthContract = async () => {
    if (contractId) {
      try {
        const { data } = await getContract({ variables: { id: contractId } })
        setContract(data?.contractGet as ContractModel)
      } catch (err) {
        toast.error('Não foi possível carregar as informações do contrato', {
          autoClose: 3000,
          position: 'top-right'
        })
      }

      return
    }

    resetContract()
  }

  useEffect(() => {
    fecthContract()
  }, [contractId])

  useEffect(() => {
    return () => {
      resetContract()
    }
  }, [])

  const screens = useMemo(() => [
    ClientData,
    ConsumerUnit,
    DealershipAndPowerplant,
    ComercialConditions,
    Documents,
    Invoices
  ], [contract])

  const wizardHelpers = useWizard(screens.length)

  if (loading) {
    return (
      <Card sx={{ minHeight: '800px', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Card>
    )
  }

  return (
    <ContractFlowLayout {...wizardHelpers}>
      <Wizard screens={screens} wizardHelpers={wizardHelpers} />
    </ContractFlowLayout>
  )
}
