import { createContext, useContext, useEffect, useState } from 'react'
import { UsersProviderProps, UsersContextData, UserData, UserListData } from './types'
import { useAccessControlListQuery, useUserListLazyQuery } from '~/graphql/types'
import { toast } from 'react-toastify'

const UsersContext = createContext({} as UsersContextData)

export const UsersProvider: React.FC<UsersProviderProps> = ({ children }) => {
  const [users, setUsers] = useState<UserListData>([])
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null)
  
  const [getUsers, { data, loading: usersListIsLoading }] = useUserListLazyQuery()
  const { data: accessControlData, loading: accessControlIsLoading } = useAccessControlListQuery({
    onError(error) {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if(data) {
      setUsers(data.userList.data as UserListData)
    }
  }, [data])

  return (
    <UsersContext.Provider value={{
      selectedUser,
      setSelectedUser,
      setUsers,
      users,
      usersListIsLoading: usersListIsLoading || accessControlIsLoading,
      accessControls: accessControlData?.accessControlList.data || [],
      getUsers
    }}>{children}</UsersContext.Provider>
  )
}

export const useUsers = () => useContext(UsersContext)
