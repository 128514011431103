import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import { IconChevronDown, IconX } from '@tabler/icons-react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CheckboxMultiple } from '~/components/Form/CheckboxMultiple'
import { Input } from '~/components/Form/Input'
import { SearchQueysData, useQueryString } from '~/hooks'
import { funnelStageOptions, ProposalContractOriginOptions, ProposalPendenciesOptions, proposalStatusOptions } from '~/utils/options'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EProposalOrigin } from '~/graphql/types'

type FilterData = {
  name?: string, 
  status?: string[], 
  pendencies?: string[], 
  origin?: string[], 
  funnel?: string[],
  begin?: string, 
  end?: string
}

type FilterProposalDrawerProps = {
  drawerIsOpen: boolean
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleDrawer: () => void
  queryKeys: SearchQueysData[]
}

const schema = yup
  .object({
    begin: yup.string().test('isValid', (value, validation) => {

      if(value && Boolean(!validation.parent.end)) {
        return validation.createError({
          message: 'Este campo é obrigatório.',
          path: 'end'
        })
      }
      return true
    }),
    end: yup.string()
      .test('isValid', (value, validation) => {
        if(value && Boolean(!validation.parent.begin)) {
          return validation.createError({
            message: 'Este campo é obrigatório.',
            path: 'begin'
          })
        }
        return true
      }),
  })
  .required()

export const FilterProposalDrawer: React.FC<FilterProposalDrawerProps> = ({ drawerIsOpen, toggleDrawer, setDrawerIsOpen, queryKeys }) => {
  const formMethods = useForm({
    resolver: yupResolver(schema)
  })

  const { searchQuerys, setSearchParams } = useQueryString()
  const queryValues = searchQuerys(queryKeys)

  useEffect(() => {
    if(drawerIsOpen) {
      formMethods.setValue('begin', queryValues.begin)
      formMethods.setValue('end', queryValues.end)
      formMethods.setValue('status', queryValues.status)
      formMethods.setValue('pendencies', queryValues.pendencies)
      formMethods.setValue('origin', queryValues.origin)
      formMethods.setValue('funnel', queryValues.funnel)
    }
  }, [drawerIsOpen])

  const OriginOptions = ProposalContractOriginOptions.map((item) => {

    let category: null | string = null
    if(item.value === EProposalOrigin.partnerLink) {
      category = 'Parceiro'
    }
    if(item.value === EProposalOrigin.website) {
      category = 'Cliente'
    }
    if(item.value === EProposalOrigin.internal) {
      category = 'Backoffice'
    }
  
    return {
      ...item,
      ...category ? { category } : {}
    }
  })

  const handleFilters = (filters: FilterData) => {
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      if(filters?.status && filters?.status?.length > 0 || queryValues?.status?.length > 0) {
        state.set('status', JSON.stringify(filters.status) || queryValues.status)
      } else {
        state.delete('status')
      }
      if(filters?.pendencies && filters?.pendencies?.length > 0 || queryValues?.pendencies?.length > 0) {
        state.set('pendencies', JSON.stringify(filters.pendencies) || queryValues.pendencies)
      } else {
        state.delete('pendencies')
      }
      if(filters?.origin && filters?.origin?.length > 0 || queryValues?.origin?.length > 0) {
        state.set('origin', JSON.stringify(filters.origin) || queryValues.origin)
      } else {
        state.delete('origin')
      }
      if(filters?.funnel && filters?.funnel?.length > 0 || queryValues?.funnel?.length > 0) {
        state.set('funnel', JSON.stringify(filters.funnel) || queryValues.funnel)
      } else {
        state.delete('funnel')
      }
      filters.begin  || queryValues.begin  ? state.set('begin', filters.begin || queryValues.begin) : state.delete('begin')
      filters.end  || queryValues.end  ? state.set('end', filters.end || queryValues.end) : state.delete('end')
      return state
    })
  }

  const onRemoveFilters = () => {
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      state.delete('status')
      state.delete('pendencies')
      state.delete('origin')
      state.delete('funnel')
      state.delete('begin')
      state.delete('end')
      return state
    })
  }

  return (
    <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={drawerIsOpen} onClose={toggleDrawer}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
        <IconButton type='button' onClick={() => toggleDrawer()}>
          <IconX  />
        </IconButton>
      </Box>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((formData) => handleFilters({ ...formData, name: queryValues.name || '' }))}>
          <Accordion disableGutters elevation={0}>
            <AccordionSummary expandIcon={<IconChevronDown />} sx={{ padding: '0' }}>
              <Typography sx={{ paddingTop: '1rem' }} fontWeight={500}>Data de solicitação</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0 0 1rem 0' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '.5rem 0 1rem 0' }}>
                <Input name='begin' type='date' />
                <Typography>Até</Typography>
                <Input name='end' type='date' />
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion disableGutters elevation={0}>
            <AccordionSummary expandIcon={<IconChevronDown />} sx={{ padding: '0' }}>
              <Typography sx={{ padding: '0' }} fontWeight={500}>Status</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0 0 1rem 0' }}>
              <CheckboxMultiple name='status' options={proposalStatusOptions} />
            </AccordionDetails>
          </Accordion>

          <Accordion disableGutters elevation={0}>
            <AccordionSummary expandIcon={<IconChevronDown />} sx={{ padding: '0' }}>
              <Typography fontWeight={500}>Pendências</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0 0 1rem 0' }}>
              <CheckboxMultiple name='pendencies' options={ProposalPendenciesOptions} />
            </AccordionDetails>
          </Accordion>

          <Accordion disableGutters elevation={0}>
            <AccordionSummary expandIcon={<IconChevronDown />} sx={{ padding: '0' }}>
              <Typography fontWeight={500}>Origem</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0 0 1rem 0' }}>
              <CheckboxMultiple name='origin' options={OriginOptions} />
            </AccordionDetails>
          </Accordion>

          <Accordion disableGutters elevation={0}>
            <AccordionSummary expandIcon={<IconChevronDown />} sx={{ padding: '0' }}>
              <Typography fontWeight={500}>Etapa do funil</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0 0 1rem 0' }}>
              <CheckboxMultiple name='funnel' options={funnelStageOptions} />
            </AccordionDetails>
          </Accordion>
    
          <Box sx={{ display: 'flex', padding: '2rem 0', gap: '1rem', justifyContent: 'flex-end' }}>
            <Button onClick={() => onRemoveFilters()} type='button' fullWidth color='secondary'>Limpar</Button>
            <Button type='submit' fullWidth color='primary'>Aplicar</Button>
          </Box>
        </form>
      </FormProvider>
    </Drawer>
  )
}
