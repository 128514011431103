import { CSSProperties } from 'react'
import { Container } from './styles'

type ErrorTextProps = {
  error?: string | undefined
  $margin?: CSSProperties['margin']
}

export const ErrorText: React.FC<ErrorTextProps> = ({ error, $margin }) => {
  return (
    <>
      {error && <Container $margin={$margin}>{error}</Container>}
    </>
  )
}
