import { useTheme } from '@emotion/react'
import { Box, IconButton, Link, Typography } from '@mui/material'
import { IconDownload } from '@tabler/icons-react'
import { ThreeDots } from '~/assets/icons'
import { Dropdown } from '~/components'
import { ContractTermOfAdhesionModel } from '~/graphql/types'

export type TermoOfAdhesionOptionsProps = {
  term?: ContractTermOfAdhesionModel | null
}
export const TermoOfAdhesionOptions: React.FC<TermoOfAdhesionOptionsProps> = ({ term }) => {
  const theme = useTheme()
  const hasDownloadLink = Boolean(term?.file?.fileUrl) && term?.file?.fileUrl !== '-'

  return (
    <>
      {hasDownloadLink && (
        <Dropdown
          $options={{ left: '-160px' }}
          elementClick={(
            <IconButton>
              <ThreeDots />
            </IconButton>
          )}
        >
          <Box sx={{
            display: 'flex',
            borderRadius: 3,
            flexDirection: 'column',
            minWidth: '220px',
            background: 'white',
            boxShadow: '0px 0px 2px rgba(64, 64, 64, 0.1), 0px 6px 15px -4px rgba(64, 64, 64, 0.14)'
          }}>
            <Link sx={{ textDecoration: 'none', color: theme.palette.grey[800] }} target='_blank' href={term?.file?.fileUrl || ''} rel='noreferrer'>
              <Box display='flex' flex={1} gap={2} alignItems='center' sx={{ cursor: 'pointer', padding: 2, '&:hover': { background: '#FAFAFA' } }}>
                <IconDownload />
                <Typography variant='body2'>Baixar termo de adesão</Typography>
              </Box>
            </Link>
          </Box>
        </Dropdown>
      )}
      
    </>
  )
}
