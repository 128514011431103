/* eslint-disable indent */
import { Box, Button, Card, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { IconArrowRight, IconPlus, IconSearch } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { PowerPlantCashbackIcon } from '~/assets/icons'
import { Status, TableApiV2, TableHeaderApi } from '~/components'
import { ContentTitle } from '~/components/ContentTitle'
import { usePowerPlantListQuery } from '~/graphql/types'
import { useDebounce, usePaginationValues, usePermission } from '~/hooks'
import { theme } from '~/utils'

export const PowerPlantList: React.FC = () => {
  const navigate = useNavigate()
  const { page, size } = usePaginationValues()
  const [searchParams, setSearchParams] = useSearchParams()
  const name = searchParams.get('name')
  const [filterName, setFilterName] = useState(name || '')
  const debouncedFilterName = useDebounce(filterName, 1000)
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    handleFilters({ name: debouncedFilterName })
  }, [debouncedFilterName])

  const { data: powerPlants, loading: powerPlantListIsLoading } = usePowerPlantListQuery({
    variables: {
      params: {
        sort: {
          field: 'createdAt',
          order: -1
        },
        pageSize: size,
        pageNumber: page,
        ...name && name?.length > 0 ? { name: name.trim() } : {},
      },
    },
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.powerPlantList.meta.documentCounts))
        return state
      })
    },
  })

  const handleFilters = (filters: { name: string }) => {
    setSearchParams(state => {
      if (filters.name) {
        state.set('name', filters.name)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }

  const canEditPowerPlants = usePermission(['powerPlants.updated', 'powerPlants.create'])

  const defaultColumnHiddens = isLowerMd ? ['isDesperta'].concat(isLowerSm ? ['acceptsPF', 'acceptsPJ'] : '') : []

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'name',
        Cell: ({ row: { original } }: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {original.cashbackInfo?.active && <PowerPlantCashbackIcon />}
            <Typography variant='body2' sx={{ marginLeft: original.cashbackInfo?.active ? 0 : 5 }}>{original.name}</Typography>
          </Box>
        )
      },
      {
        Header: 'Aceita PF',
        accessor: 'acceptsPF',
        Cell: ({ value }) => (
          <>
            {value ?
              <Status text='Sim' background={theme.palette.success.light} color={theme.palette.success.main} />
              : <Status text='Não' color={theme.palette.error.main} />
            }
          </>
        )
      },
      {
        Header: 'Aceita PJ',
        accessor: 'acceptsPJ',
        Cell: ({ value }) => (
          <>
            {value ?
              <Status text='Sim' background={theme.palette.success.light} color={theme.palette.success.main} />
              : <Status text='Não' color={theme.palette.error.main} />
            }
          </>
        )
      },
      {
        Header: 'É desperta',
        accessor: 'isDesperta',
        Cell: ({ value }) => (
          <>
            {value ?
              <Status text='Sim' background={theme.palette.success.light} color={theme.palette.success.main} />
              : <Status text='Não' color={theme.palette.error.main} />
            }
          </>
        )
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        disableSortBy: true,
        Cell: ({ value }) =>
        (
          <>
            {canEditPowerPlants ? (
              <Link to={`/app/power-plants/update/${value}`}>
                <IconButton>
                  <IconArrowRight />
                </IconButton>
              </Link>
            ) : (
              <IconButton disabled={true}>
                <IconArrowRight />
              </IconButton>
            )}
          </>
        )
        ,
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => powerPlants?.powerPlantList.data || [], [powerPlants])

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    initialState: {
      hiddenColumns: defaultColumnHiddens,
    },
    manualPagination: true,
    defaultColumn: {
      Filter: <></>
    },
  },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    //disable checkboxColumn
    // (hooks) => renderTableCheckbox(hooks)
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Lista de Usinas'
          description='Consulte todas usinas cadastradas'
          breadcrumbLinks={{ currentLink: 'Listar usinas', links: [{ href: '/app/power-plants', label: 'Usinas' }] }}
          rightContent={<Button disabled={!canEditPowerPlants} onClick={() => navigate('/app/power-plants/create')} startIcon={<IconPlus />}>Cadastrar usina</Button>}
        />
        <TableHeaderApi
          startElement={(
            <>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder='Procurar usina por nome'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </>
          )}
        />
        <TableApiV2
          isLoading={powerPlantListIsLoading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Card>
    </div>
  )
}
