import { SvgIcon, SvgIconProps } from '@mui/material'

export const Money: React.FC<SvgIconProps> = ({ width, height, htmlColor, fill, ...props }) => {
  return (
    <SvgIcon width={width || 24} height={height || 24} viewBox='0 0 24 24' fill={fill || 'none'} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M7.17157 6.17157C7.92172 5.42143 8.93913 5 10 5H14C14.0144 5 14.0288 5.00031 14.0431 5.00093C14.8408 5.03537 15.6099 5.30774 16.2515 5.78298C16.8931 6.25822 17.3777 6.91458 17.6431 7.6676C17.8267 8.18848 17.5533 8.75956 17.0324 8.94314C16.5115 9.12672 15.9404 8.85328 15.7569 8.3324C15.6242 7.95589 15.3818 7.62771 15.061 7.39009C14.7455 7.15632 14.3681 7.02073 13.9762 7H10C9.46957 7 8.96086 7.21071 8.58579 7.58579C8.21071 7.96086 8 8.46957 8 9C8 9.53043 8.21071 10.0391 8.58579 10.4142C8.96086 10.7893 9.46957 11 10 11H14C15.0609 11 16.0783 11.4214 16.8284 12.1716C17.5786 12.9217 18 13.9391 18 15C18 16.0609 17.5786 17.0783 16.8284 17.8284C16.0783 18.5786 15.0609 19 14 19H10C9.98562 19 9.97123 18.9997 9.95686 18.9991C9.15919 18.9646 8.39007 18.6923 7.7485 18.217C7.10693 17.7418 6.62226 17.0854 6.35686 16.3324C6.17328 15.8115 6.44672 15.2404 6.9676 15.0569C7.48848 14.8733 8.05956 15.1467 8.24314 15.6676C8.37584 16.0441 8.61817 16.3723 8.93896 16.6099C9.25455 16.8437 9.63186 16.9793 10.0238 17H14C14.5304 17 15.0391 16.7893 15.4142 16.4142C15.7893 16.0391 16 15.5304 16 15C16 14.4696 15.7893 13.9609 15.4142 13.5858C15.0391 13.2107 14.5304 13 14 13H10C8.93913 13 7.92172 12.5786 7.17157 11.8284C6.42143 11.0783 6 10.0609 6 9C6 7.93913 6.42143 6.92172 7.17157 6.17157Z' fill={htmlColor || '#F3CC04'} />
      <path fillRule='evenodd' clipRule='evenodd' d='M12 2C12.5523 2 13 2.44772 13 3V6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6V3C11 2.44772 11.4477 2 12 2ZM12 17C12.5523 17 13 17.4477 13 18V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V18C11 17.4477 11.4477 17 12 17Z' fill={htmlColor || '#F3CC04'} />
    </SvgIcon>
  )
}
