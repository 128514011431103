import styled from '@emotion/styled'
import { darken } from 'polished'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.info.dark};
  font-weight: 600;
  font-size: .95rem;
  transition: color .2s ease-in-out;

  &:hover {
    color: ${(props) => darken('0.15', props.theme.palette.info.dark)};
  }
`
