import styled from '@emotion/styled'
import { colorContractStatus } from '~/utils'
import { EContractStatus } from '~/graphql/types'

type ContainerProps = {
  status: EContractStatus
}
export const Container = styled.div<ContainerProps>`

  span {
    ${(props) => colorContractStatus(props.status)}
    padding: .2rem .6rem;
    border-radius: .4rem;
    font-weight: 600;
    font-size: .8rem;
  }
`
