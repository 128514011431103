import { Typography } from '@mui/material'
import { Card } from '~/components'
import { RecoverPasswordBackground } from './styles'
import { RecoverPasswordForm } from './RecoverPasswordForm'
import { IconLock, IconLockCheck } from '@tabler/icons-react'
import { theme } from '~/utils'
import { useSearchParams } from 'react-router-dom'

export const RecoverPassword: React.FC = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  return (
    <>
      <RecoverPasswordBackground>
        <Card sx={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: '440px' }}>
          {token ? <IconLockCheck size={60} color={theme.palette.primary.main} /> : <IconLock size={60} color={theme.palette.primary.main} />}

          <Typography
            variant='h6'
            fontWeight={600}
            margin='24px 0px 8px 0px'
          >
            {token ? 'Altere sua senha' : 'Esqueceu a senha?'}
          </Typography>

          <Typography
            variant='body1'
            color='grey.400'
            margin='9px 0px 24px 0px'
            textAlign='center'
          >
            {token ? 'Informe sua nova senha e tenha acesso a plataforma novamente!' : 'Informe seu e-mail cadastrado para receber um link de redefinição de senha'}
            
          </Typography>

          <RecoverPasswordForm token={token} />
        </Card>
      </RecoverPasswordBackground>
    </>
  )
}
