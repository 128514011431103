/* eslint-disable no-unused-vars */
import React from 'react'
import { Box, Drawer, List } from '@mui/material'
import { TSidebarItem } from '~/components/Layout'

import { theme } from '~/utils'
import { Collapsibles } from '../..'
import { DrawerHeader } from '../../style'
import { CollapsibleList, SidebarListItem } from '../'
import DespertaLogo from '~/assets/images/logo/desperta-logo.svg'
import DespertaLogoReduced from '~/assets/images/logo/desperta-logo-reduced.svg'
import { IconLogout } from '@tabler/icons-react'

export type SidebarProps = {
  isDrawerOpen: boolean
  sidebarItems: TSidebarItem[]
  signOut: () => void
  toggleSidebar: () => void
  collapsibles: Collapsibles
  toggleCollapsible: (collapsibleName: keyof Collapsibles) => void
}

export const ResponsiveSidebar: React.FC<SidebarProps> = ({ isDrawerOpen, sidebarItems, toggleSidebar, toggleCollapsible, signOut, collapsibles }) => {

  return (
    <Drawer variant='temporary' onClose={toggleSidebar} anchor='left' open={isDrawerOpen}>
      <DrawerHeader sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={isDrawerOpen ? DespertaLogo : DespertaLogoReduced} height={40} />
      </DrawerHeader>

      <Box>
        <List sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {sidebarItems.map((sidebarItem) => {
            const { collapsibleName, text, nested, ...rest } = sidebarItem

            return (
              <React.Fragment key={`sidebar-list-item-${text}`}>
                <SidebarListItem
                  text={text}
                  isDrawerOpen={isDrawerOpen}
                  isCollapsibleOpen={collapsibleName ? collapsibles[collapsibleName] : false}
                  toggleCollapsible={collapsibleName ? () => toggleCollapsible(collapsibleName) : undefined}
                  {...rest}
                />
                {nested && collapsibleName && (
                  <CollapsibleList
                    isDrawerOpen={isDrawerOpen}
                    sidebarItem={sidebarItem}
                    isOpen={collapsibles[collapsibleName]}
                  />
                )}
              </React.Fragment>
            )
          })}
          <SidebarListItem
            text='Sair'
            icon={<IconLogout />}
            hideChevronIcon
            onClick={signOut}
            isDrawerOpen={isDrawerOpen}
            sx={{ marginTop: 'auto', background: theme.palette.error.main }}
          />
        </List>
      </Box>
    </Drawer>
  )
}
