import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { EProposalFileStatus } from '~/graphql/types'
import { theme } from '~/utils'

type ContainerProps = {
  status: EProposalFileStatus
}

export const Container = styled.div<ContainerProps>`
  padding: 1rem 2rem;
  border-radius: .4rem;
  width: 100%;

  @media(max-width: ${theme.breakpoints.values.sm}px) {
    padding: .4rem .8rem;
  }
  
  & > div {
    height: 40px
  }
  
  color: #999999;
  background: #fafafa;
  border: 2px dashed #999999;

  svg {
    color: #999999;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background .2s ease-in-out;
    height: 34px;
    width: 34px;
    border-radius: .2rem;

    &:hover {
      background: ${transparentize('0.85', '#999999')};
    }
  }

  .onHover {
    display: none;
  }

  &:hover .onHover {
    display: flex;
  }

  ${(props) => props.status === EProposalFileStatus.accepted && `
    color: ${props.theme.palette.success.main};
    background: #fafafa;
    border: 2px dashed ${props.theme.palette.success.main};
    svg {
      color: ${props.theme.palette.success.main};
    }
    a:hover {
      background: ${transparentize('0.9', props.theme.palette.success.main)};
    }
  `}

  ${(props) => props.status === EProposalFileStatus.rejected && `
    color: ${props.theme.palette.error.main};
    background: #fafafa;
    border: 2px dashed ${props.theme.palette.error.main};
    svg {
      color: ${props.theme.palette.error.main};
    }
    a:hover {
      background: ${transparentize('0.9', props.theme.palette.error.main)};
    }
  `}
`
