import { EContractStatus } from '~/graphql/types'
import { Container } from './styles'
import { translateContractStatus } from '~/utils'

type ContractStatusProps = {
  status?: EContractStatus | null
}
export const ContractStatus: React.FC<ContractStatusProps> = ({ status }) => {
  return (
    <Container status={status || EContractStatus.reserved}>
      <span>{translateContractStatus(status || EContractStatus.reserved)}</span>
    </Container>
  )
}
