import { useMemo, useRef, useState } from 'react'
import { Check, ErrorOutline } from '@mui/icons-material'
import { Step, StepButton, StepIconProps, StepLabel, Stepper, Typography, styled, useMediaQuery } from '@mui/material'
import { CustomIconContainer } from './style'
import { PropsWithWizard } from '~/components'
import { ContractModel } from '~/graphql/types'
import { isStepCompleted, shouldDisableStep } from '../../utils'
import { theme } from '~/utils'

// const StyledStepper = styled(Stepper)(
//   () => ({
//     marginBottom: '40px',
//     '.MuiStepLabel-label': {
//       fontSize: '16px',
//       fontWeight: 500,
//       color: '#CDCDCD'
//     },
//     '.MuiStepLabel-label.Mui-active': {
//       fontSize: '16px',
//       fontWeight: 600,
//       color: '#434343'
//     },
//     '.MuiStepLabel-label.Mui-completed:not(.Mui-active)': {
//       fontSize: '16px',
//       color: '#CDCDCD'
//     }
//   })
// )

const StyledStepper = styled(Stepper)`
  display: flex;
  flex-wrap: nowrap; 
  overflow: hidden;
  padding: 1rem 0 2rem 0;
  margin-bottom: 20px;
  cursor: grab;

  &.grabbing {
    cursor: grabbing;
  }

  & .MuiStep-root {
    flex-shrink: 0;
    
  }

  & .MuiStepLabel-label {
    font-size: 16px;
    font-weight: 500;
    color: #CDCDCD;
    
  }

  & .MuiStepLabel-label.Mui-active {
    font-size: 16px;
    font-weight: 600;
    color: #434343;
  }

  & .MuiStepLabel-label.Mui-completed:not(.Mui-active) {
    font-size: 16px;
    color: #CDCDCD;
  }

  @media(min-width: 1200px) and (max-width: 1450px) {
    & .MuiStepButton-root {
      margin-right: 12px;
    }
    & .MuiStepLabel-label {
      p {
        max-width: 87px;
        text-align: start;
      }
    } 
  }

  @media(max-width: 1200px) {
    overflow-x: auto;
    /* max-width: calc(100vw - 400px); */
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    & .MuiStepButton-root {
      margin-right: 16px;
    }

    & .MuiStepLabel-label {
      
    p {
      text-align: start;
      max-width: 73px;
    }
  }
  }  

  &::-webkit-scrollbar {
    display: none; 
  }
  

  @media(max-width: ${theme.breakpoints.values.md}px) {
    gap: 5px;
    & .MuiStepButton-root {
      margin-right: 16px;
    }
  }
`

const CustomStepIcon = (props: StepIconProps) => {
  return (
    <CustomIconContainer active={!!props.active} completed={!!props.completed} error={props.error}>
      {props.error && <ErrorOutline color='error' />}
      {!props.error && props.completed && <Check fontSize='small' sx={{ color: '#3BA1FF' }} />}
      {!props.error && !props.completed && <Typography color={props.active ? 'white.main' : '#CDCDCD'}>{props.icon}</Typography>}
    </CustomIconContainer>
  )
}

export type ContractStepperProps = {
  contract: ContractModel | null
}
export const ContractStepper: React.FC<PropsWithWizard<ContractStepperProps>> = (props) => {
  const { currentPage, goToPage, contract } = props
  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const stepsContainerRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [dragStartX, setDragStartX] = useState(0)
  const [scrollStartX, setScrollStartX] = useState(0)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (stepsContainerRef.current) {
      setIsDragging(true)
      setDragStartX(e.clientX)
      setScrollStartX(stepsContainerRef.current.scrollLeft)
      stepsContainerRef.current.classList.add('grabbing')
    }
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging && stepsContainerRef.current) {
      const dragDistance = e.clientX - dragStartX
      stepsContainerRef.current.scrollLeft = scrollStartX - dragDistance
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    if (stepsContainerRef.current) {
      stepsContainerRef.current.classList.remove('grabbing')
    }
  }

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false)
      if (stepsContainerRef.current) {
        stepsContainerRef.current.classList.remove('grabbing')
      }
    }
  }

  const steps = useMemo(() => [
    'Dados do cliente',
    'Unid. consumidora',
    'Consórcio e usina',
    'Condições comerciais',
    'Envio',
    'Faturas'
  ].filter(step => !!step) as string[], [contract])

  return (
    <StyledStepper
      nonLinear
      activeStep={currentPage}
      ref={stepsContainerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      {steps.map((label, index) => {
        return (
          <Step completed={isStepCompleted(label, contract)} key={label}>
            <StepButton sx={{
              padding: isLowerSm && index > 0 ? '0px !important' : '24px 16px',

            }} disabled={shouldDisableStep(label, contract)} onClick={() => goToPage(index)}>
              <StepLabel
                StepIconComponent={CustomStepIcon}
              >

                <Typography fontSize={isLowerLg ? '.85rem' : '1rem'} fontWeight={isLowerLg ? 400 : 500}>
                  {label}
                </Typography>

              </StepLabel>
            </StepButton>
          </Step>
        )
      })}
    </StyledStepper>
  )
}
