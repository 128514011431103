import { useTheme } from '@emotion/react'
import { Box, Button, CircularProgress, Divider, Typography, useMediaQuery } from '@mui/material'
import { IconAlertCircleFilled, IconHelpCircleFilled } from '@tabler/icons-react'
import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Dialog } from '~/components'
import { Input } from '~/components/Form/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ContractTermOfManagementModel, useContractUpdateManuallyTermManagementApproveOrRepproveMutation } from '~/graphql/types'
import { useContract } from '~/contexts'
import { toast } from 'react-toastify'

export enum ApproveOrRefuseModalType {
 'refuse',
 'approve'
}

type ApproveOrRefuseTermModalProps = {
  isVisible: boolean
  onClose: () => void
  type: ApproveOrRefuseModalType
  term?: ContractTermOfManagementModel | null
}

type FormData = {
  refusedReason: string
}

export const ApproveOrRefuseTermModal: React.FC<ApproveOrRefuseTermModalProps> = ({ isVisible, onClose, type, term }) => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { contract, setContract } = useContract()

  const schema = yup.object().shape({
    refusedReason: yup.string().when({
      is: () => {
        return type === ApproveOrRefuseModalType.approve
      },
      then: (validationSchema) => validationSchema.nullable(),
      otherwise: (validationSchema) => validationSchema.required()
    })
  })

  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const [approveOrRefuseTerm, { loading: approveOrRefuseIsLoading }] = useContractUpdateManuallyTermManagementApproveOrRepproveMutation({
    onCompleted(data) {
      if(type === ApproveOrRefuseModalType.approve) {
        toast.success('O termo de gestão foi aprovado!')
      } else {
        toast.success('O termo de gestão foi reprovado!')
      }

      if(contract) {
        setContract({ ...contract, termOfManagement: data.contractUpdateManuallyTermManagementApproveOrRepprove?.termOfManagement })
      }

      onClose()
      formMethods.reset()
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const handleSubmit = useCallback(({ refusedReason }: FormData) => {
    if(type === ApproveOrRefuseModalType.approve) {
      approveOrRefuseTerm({
        variables: {
          params: {
            approve: true,
            contractId: contract?._id || '',
            uuid: term?.uuid || ''
          }
        }
      })
    } else {
      approveOrRefuseTerm({
        variables: {
          params: {
            approve: false,
            contractId: contract?._id || '',
            uuid: term?.uuid || '',
            refusedReason
          }
        }
      })
    }
  }, [type, contract, term])

  return (
    <Dialog
      titleIcon={
      type === ApproveOrRefuseModalType.approve ? 
      <IconHelpCircleFilled size={30} style={{ color: theme.palette.primary.main}} /> : 
      <IconAlertCircleFilled size={30} style={{ color: theme.palette.error.main}} />
    }
      title={type === ApproveOrRefuseModalType.approve ? 'Aprovar termo de gestão' : 'Informar recusa'}
      onClose={onClose}
      isVisible={isVisible}
    >

      <Divider sx={{margin: '1rem 0 2rem 0'}} />

      {type === ApproveOrRefuseModalType.approve ? (
        <Typography>Tem certeza que deseja aprovar o termo de gestão?</Typography>
      ) : (
        <Typography>Informe o motivo de recusa da termo de gestão:</Typography>
      )}

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => handleSubmit(data))}>

          {type === ApproveOrRefuseModalType.refuse && (
            <Input sx={{marginTop: '1.4rem'}} name='refusedReason' />
          )}

          <Divider sx={{margin: '2rem 0'}} />

          <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '1rem', flexFlow: isLowerSm ? 'column-reverse' : 'row'}}>
            <Button type='button' onClick={onClose} color='secondary'>Cancelar</Button>
          
            {type === ApproveOrRefuseModalType.approve ? (
              <Button
                disabled={approveOrRefuseIsLoading}
                endIcon={approveOrRefuseIsLoading ? <CircularProgress size={24} color='inherit' /> : null}
                type='submit'
                color='primary'
              >
                Sim, desejo aprovar
              </Button>
            ) : (
              <Button
                disabled={approveOrRefuseIsLoading}
                endIcon={approveOrRefuseIsLoading ? <CircularProgress size={24} color='inherit' /> : null}
                type='submit'
                variant='contained'
                color='error'
              >
                Confirmar
              </Button>
            )}
            </Box>
        </form>
      </FormProvider>
    </Dialog>
  )
}
