/* eslint-disable no-unused-vars */
import { Delete } from '@mui/icons-material'
import { Box, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material'
import { clearString } from '~/utils'
import { brazilStates } from '~/constants'
import { Input } from '~/components/Form/Input'
import { Button } from '~/components/Form/Button'
import { Select } from '~/components/Form/Select'
import { maritalStatusOptions } from '~/utils/options'
import { DragAndDrop } from '~/components/Form/DragAndDrop'
import { CustomerModel, ECustomerFileStatus, ECustomerFileType } from '~/graphql/types'
import { LegalRepresentativeType } from '../../utils'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useCep } from '~/hooks'
import { IconSearch } from '@tabler/icons-react'

export type LegalRepresentativeFieldsProps = {
  index: number
  loading: boolean
  fieldsLength: number
  disableFields?: boolean
  customer?: CustomerModel | null
  legalRepresentative: LegalRepresentativeType
  removeLegalRepresentative: (index: number) => void
}

const fieldName = 'legalRepresentatives'

export const LegalRepresentativeFields: React.FC<LegalRepresentativeFieldsProps> = ({ disableFields, index, removeLegalRepresentative, fieldsLength, loading, legalRepresentative, customer }) => {
  const { setValue } = useFormContext()
  const { fetchCep, isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })
  const currentLegalRepresentative = customer?.legalRepresentatives?.find(representative => clearString(representative.document || '') === clearString(legalRepresentative.document))

  const legalRepresentativeBackDocument = currentLegalRepresentative?.files?.find(file => file.type === ECustomerFileType.customerPjRepresentativeDocumentWithPhotoBack)
  const legalRepresentativeFrontDocument = currentLegalRepresentative?.files?.find(file => file.type === ECustomerFileType.customerPjRepresentativeDocumentWithPhotoFront)

  const onSearchCep = useCallback(async () => {
    if (legalRepresentative && legalRepresentative?.address?.zipcode) {
      const { data, isSuccess } = await fetchCep(legalRepresentative.address.zipcode)
      if (isSuccess) {
        setValue(`${fieldName}.${index}.address.state`, data?.uf || '')
        setValue(`${fieldName}.${index}.address.city`, data?.localidade || '')
        setValue(`${fieldName}.${index}.address.neighborhood`, data?.bairro || '')
        setValue(`${fieldName}.${index}.address.street`, data?.logradouro || '')
      }
    }
  }, [legalRepresentative, index])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant='h3' fontWeight={500}>Representante legal</Typography>
      <Grid spacing={3} container>
        <Grid item md={8} xs={12}>
          <Input disabled={disableFields} name={`${fieldName}.${index}.name`} label='Nome completo' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} mask='cpf' name={`${fieldName}.${index}.document`} label='CPF' />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} name={`${fieldName}.${index}.identificationNumber`} label='RG' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} name={`${fieldName}.${index}.email`} label='E-mail' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} mask='phone' name={`${fieldName}.${index}.phone`} label='Telefone' />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} name={`${fieldName}.${index}.nationality`} label='Nacionalidade' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Select disabled={disableFields} name={`${fieldName}.${index}.maritalStatus`} label='Estado civil' options={maritalStatusOptions} />
        </Grid>
      </Grid>

      <Typography fontWeight={500}>Endereço</Typography>
      <Grid spacing={3} container>
        <Grid item md={4} xs={12}>
          <Input
            disabled={disableFields}
            icons={{ end: { element: fetchCepIsLoading ? <CircularProgress size={18} color='inherit' /> : <IconButton disabled={disableFields} onClick={() => onSearchCep()}><IconSearch /></IconButton> } }}
            label='CEP'
            name={`${fieldName}.${index}.address.zipcode`}
            mask='cep'
          />
        </Grid>
        <Grid item md={8} xs={12} />

        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Rua' name={`${fieldName}.${index}.address.street`} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Número' name={`${fieldName}.${index}.address.number`} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Complemento (Opcional)' name={`${fieldName}.${index}.address.complement`} />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Bairro' name={`${fieldName}.${index}.address.neighborhood`} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Cidade' name={`${fieldName}.${index}.address.city`} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Select disabled={disableFields} name={`${fieldName}.${index}.address.state`} label='UF' options={brazilStates} />
        </Grid>
      </Grid>

      <Typography fontWeight={500}>Documento</Typography>
      <Grid spacing={3} container>
        <Grid item md={6} xs={12}>
          <DragAndDrop
            disabled={disableFields}
            label='Anexar RG ou CNH (Frente)'
            fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
            $variant='secondary'
            sentAt={legalRepresentativeFrontDocument?.sendAt}
            $completed={Boolean(legalRepresentativeFrontDocument?.sendAt)}
            fileName={legalRepresentativeFrontDocument?.file?.fileName}
            fileUrl={legalRepresentativeFrontDocument?.file?.fileUrl}
            name={`${fieldName}.${index}.customerPjRepresentativeDocumentWithPhotoFront`}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DragAndDrop
            disabled={disableFields}
            $variant='secondary'
            label='Anexar RG ou CNH (Verso)'
            fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
            sentAt={legalRepresentativeBackDocument?.sendAt}
            $completed={Boolean(legalRepresentativeBackDocument?.sendAt)}
            fileName={legalRepresentativeBackDocument?.file?.fileName}
            fileUrl={legalRepresentativeBackDocument?.file?.fileUrl}
            name={`${fieldName}.${index}.customerPjRepresentativeDocumentWithPhotoBack`}
          />
        </Grid>
      </Grid>

      {fieldsLength > 1 && (
        <Button
          color='error'
          disabled={loading}
          startIcon={<Delete />}
          style={{ alignSelf: 'end' }}
          variant='outlined'
          onClick={() => removeLegalRepresentative(index)}
        >
          Remover representante
        </Button>
      )}

      {!(fieldsLength === index + 1) && <Divider />}
    </Box>
  )
}
