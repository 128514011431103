import { useTheme } from '@emotion/react'
import { Box, Button, Card, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ContentTitle } from '~/components'
import { Input } from '~/components/Form/Input'
import { Radio } from '~/components/Form/Radio'
import { FullPageLoader } from '~/components/FullPageLoader'
import { useDiscountTable } from '~/contexts'
import { CreateDiscountDto, EDiscountTableType, useDiscountTableByIdLazyQuery, useDiscountTableCreateMutation, useDiscountTableUpdateMutation } from '~/graphql/types'
import { discountTableOptions } from '~/utils/options'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object({
  code: yup.string().required(),
  type: yup.mixed<EDiscountTableType>().oneOf(Object.values(EDiscountTableType)).required(),
  discountPercentage: yup.number().max(100).required(),
})

export const DiscountTableCreateEdit: React.FC = () => {
  const { id } = useParams()
  const { selectedDiscountTable, setSelectedDiscountTable, setDiscountTables, discountTables } = useDiscountTable()
  const formMethods = useForm<CreateDiscountDto>({
    resolver: yupResolver(schema)
  })
  const navigate = useNavigate()

  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const isEdit = Boolean(id && selectedDiscountTable)

  const [getDiscountTableById, { loading: getDiscountTableByIdIsLoading }] = useDiscountTableByIdLazyQuery({
    onCompleted(data) {
      if(data.discountTableById) {
        setSelectedDiscountTable(data.discountTableById)
      }
    },
    onError(error) {
      toast.error(error.message)
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if(id) {
      getDiscountTableById({
        variables: {
          id: id || ''
        },
      })
    }
    setSelectedDiscountTable(null)
  }, [id])

  useEffect(() => {
    if(id && selectedDiscountTable) {
      formMethods.setValue('code', selectedDiscountTable?.code)
      formMethods.setValue('discountPercentage', selectedDiscountTable?.discountPercentage)
      formMethods.setValue('type', selectedDiscountTable?.type)
    } else {
      formMethods.reset()
    }
  },[id, selectedDiscountTable])

  const [createDiscountTable, { loading: createDiscountTableIsLoading }] = useDiscountTableCreateMutation({
    onCompleted(data) {
      if(data.discountTableCreate) {
        setDiscountTables([data.discountTableCreate, ...discountTables])
        toast.success(`A tabela foi cadastrada!`)
        navigate('/app/discount-table')
      }
    },
    onError(error) {
      toast.error(error.message)
    },
  })
  const [updateDiscountTable, { loading: updateDiscountTableIsLoading }] = useDiscountTableUpdateMutation({
    onCompleted(data) {
      if(data.discountTableUpdate) {
        setDiscountTables(prev => prev.map(item => item._id === data.discountTableUpdate._id ? { ...item, ...data.discountTableUpdate } : item))
        setSelectedDiscountTable(null)
        toast.success(`A tabela ${data.discountTableUpdate.code} foi alterada!`)
        navigate('/app/discount-table')
      }
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const onSubmit = useCallback((data: CreateDiscountDto) => {
    if(isEdit) {
      updateDiscountTable({
        variables: {
          id: id || '',
          params: {
            type: data.type,
            discountPercentage: data.discountPercentage
          }
        }
      })
    } else {
      createDiscountTable({
        variables: {
          params: {
            ...data,
          }
        }
      })
    }
  }, [isEdit])

  if(getDiscountTableByIdIsLoading) return <FullPageLoader />

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title={`${isEdit ? `Alterar a tabela de desconto ${selectedDiscountTable?.code}` : 'Tabelas de desconto'}`}
          description={`${isEdit ? '' : 'Configure os parâmetros da tabela de desconto'}`}
          breadcrumbLinks={{ currentLink: 'Tabelas de desconto', links: [{ href: '/app/power-plants', label: 'Usinas' }] }}
        />
        <Divider sx={{ marginBottom: '1rem' }} />
        <Typography sx={{ fontWeight: 500, color: theme.palette.grey[800], paddingBottom: '1rem', fontSize: '1.3rem' }}>Parâmetros de tabela</Typography>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Input disabled={isEdit} label='Nome da tabela' name='code' />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input mask='percentage' label='Percentual de desconto' name='discountPercentage' />
              </Grid >
            </Grid>
            
            <Typography sx={{ paddingTop: '1rem', fontWeight: 500, color: theme.palette.grey[800] }}>Flutua bandeira?</Typography>
            <Radio row name='type' options={discountTableOptions} />

            <Divider sx={{ marginTop: '1rem' }} />

            <Box sx={{ 
              width: '100%',
              padding: '2rem 0',
              display: 'flex',
              justifyContent: 'space-between',
              flexFlow: isLowerSm ? 'column-reverse' : 'row',
              gap: isLowerSm ? '1rem' : '0'
            }}>
              <Link style={{ maxWidth: isLowerSm ? '100%' : '160px' }} to='/app/discount-table'>
                <Button sx={{ maxWidth: isLowerSm ? '100%' : '160px' }} color='secondary'>Cancelar</Button>
              </Link>
              <Button
                sx={{ maxWidth: isLowerSm ? '100%' : '300px' }}
                disabled={createDiscountTableIsLoading || updateDiscountTableIsLoading} 
                type='submit'>
                {isEdit ? 'Salvar alterações' : 'Confimar cadastro'}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </div>
  )
}
