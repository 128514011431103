import { Box, Typography, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { useAuth } from '~/contexts'
import UserBannerImage from '~/assets/images/user-banner-background.webp'
import DespertaLogo from '~/assets/images/logo/desperta-logo-reduced.svg'
import ManWithNotebook from '~/assets/illustrations/user-banner-illustration.svg'

export const UserBanner: React.FC = () => {
  const { authUser } = useAuth()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box sx={{
      padding: 4,
      gap: '32px',
      display: 'flex',
      borderRadius: '10px',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `linear-gradient(90.59deg, #0F0F0F -60.14%, rgba(67, 67, 67, 0) 100.41%), url(${UserBannerImage})`
    }}>
      <Box
        sx={{
          display: 'flex',
          height: '80px',
          width: '80px',
          borderRadius: '80px',
          background: theme.palette.yellow.main,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '72px',
            width: '72px',
            background: '#561885',
            borderRadius: '72px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={DespertaLogo} />
        </Box>
      </Box>

      <Box sx={{
        gap: '16px',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography color='#FFF' variant='h3'>
          {authUser?.user?.name}
        </Typography>

        <Box sx={{
          display: 'flex',
          padding: '5px 14px',
          alignItems: 'center',
          borderRadius: '16px',
          justifyContent: 'center',
          background: theme.palette.yellow.main,
        }}>
          {authUser?.accessControlRef?.name === 'Admin' ? 'Administrador' : authUser?.accessControlRef?.name}
        </Box>
      </Box>

      {!isMobile && (
        <img
          src={ManWithNotebook}
          style={{
            bottom: 0,
            right: '32px',
            position: 'absolute'
          }}
        />
      )}
    </Box>
  )
}
