import styled from '@emotion/styled'
import { CSSProperties } from 'react'

type ContainerProps = {
  $margin?: CSSProperties['margin']
}

export const Container = styled.span<ContainerProps>`
  color: ${(props) => props.theme.palette.error.main};
  font-size: 14px;
  display: block;
  margin: ${(props) => props.$margin || ''};
`
