import { EDigitalAccountStatus, translateDigitalAccountStatus } from '~/utils'
import { Container } from './styles'

type DigitalAccountStatusProps = {
  status: any
}
export const DigitalAccountStatus: React.FC<DigitalAccountStatusProps> = ({ status }) => {
  return (
    <Container status={status || EDigitalAccountStatus.inactive}>
      <span>{translateDigitalAccountStatus(status || EDigitalAccountStatus.inactive)}</span>
    </Container>
  )
}
