import { useMemo } from 'react'
import {
  Box,
  Grid,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  useMediaQuery,
  SelectChangeEvent
} from '@mui/material'
import { theme } from '~/utils'
import { DigitalAccountStatement, SelectedContract } from './components'
import { ContractModel } from '~/graphql/types'
import { useCustomerContract } from '~/contexts'

export type CustomerContractsProps = {
  contracts: ContractModel[]
}

export const CustomerContracts: React.FC<CustomerContractsProps> = ({ contracts }) => {
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const { contract, setContract } = useCustomerContract()

  const contractOptions = useMemo(() => contracts.map(customerContract => {
    return <MenuItem value={customerContract._id} key={customerContract._id}>Contrato - {customerContract._id}</MenuItem>
  }), [contracts])

  const handleContractSelect = (event: SelectChangeEvent<ContractModel>) => {
    if (contracts) {
      const customerContract = contracts.find(contractItem => contractItem._id === event.target.value)

      customerContract && setContract(customerContract)
    }
  }

  return (
    <Box sx={{
      gap: 3,
      flex: 1,
      padding: 3,
      display: 'flex',
      borderRadius: '12px',
      background: '#FAFAFA',
      flexDirection: 'column',
      border: '1px solid #E6E6E6'
    }}>
      <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} variant='h3' fontWeight={500}>Contrato</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel>Contrato</InputLabel>
            <Select label='Contrato' onChange={handleContractSelect}>
              {contractOptions}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {contract && <SelectedContract />}

      <DigitalAccountStatement />
    </Box>
  )
}
