import { isEmpty } from 'ramda'
import { isPendencyField } from '~/utils/proposal'
import { ECustomerPersonType, EProposalCustomerPendencies, EProposalFileStatus, EProposalFileType, EProposalStatus, ProposalModel } from '~/graphql/types'

export const isStepCompleted = (label: string, proposal: ProposalModel | null) => {
  if (!proposal) {
    return false
  }

  if (proposal.status === EProposalStatus.finalized) {
    return true
  }

  const { customerRef, files, energyBillInfo } = proposal
  const {
    uf,
    name,
    email,
    phone,
    document,
    personType,
    nationality,
    dealershipId,
    maritalStatus,
    legalRepresentative,
    averageEnergyBillValue,
    address: customerAddress
  } = customerRef

  if (label === 'Dados básicos') {
    const baseValidation = Boolean(name && document && email && phone && uf && dealershipId && averageEnergyBillValue)

    return personType === ECustomerPersonType.pf ? Boolean(baseValidation && nationality && maritalStatus) : baseValidation
  }
  if (label === 'Endereço') {
    return Boolean(customerAddress?.zipcode && customerAddress?.street && customerAddress?.neighborhood && customerAddress?.city && customerAddress?.state && customerAddress?.number)
  }
  if (label === 'Representante') {
    return !isEmpty(legalRepresentative)
  }
  if (label === 'Documentos') {
    const hasEnergyBillDocument = Boolean(files?.find(item => item.type === EProposalFileType.energyBill)?.sendAt)
    const hasEnegyBillInfo = Boolean(
      energyBillInfo?.tension &&
      energyBillInfo?.AmountCents &&
      // energyBillInfo?.tusdValue &&
      // energyBillInfo?.energyTaxes &&
      energyBillInfo?.distributionTaxes &&
      energyBillInfo?.clientNumber &&
      energyBillInfo?.installationNumber &&
      energyBillInfo?.tariffClassification &&
      energyBillInfo?.address
    )

    if (personType === ECustomerPersonType.pf) {
      const hasFrontCustomerDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoFront)?.sendAt)
      const hasBackCustomerDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoBack)?.sendAt)

      return Boolean(hasEnegyBillInfo && hasFrontCustomerDocument && hasBackCustomerDocument && hasEnergyBillDocument)
    }

    const hasCustomerPjCardDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPjDocumentCard)?.sendAt)
    const hasSocialContractDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPjSocialContract)?.sendAt)

    return Boolean(hasCustomerPjCardDocument && hasSocialContractDocument && hasEnegyBillInfo && hasEnergyBillDocument)
  }
  if (label === 'Formalização') {
    return false
  }
}

export const shouldDisableStep = (label: string, proposal: ProposalModel | null) => {
  if (!proposal) {
    return label !== 'Dados básicos'
  }

  // Desativa o step 4 de "Formalização" se o status da proposta não for formalização e for igual a cancelado com termo de gestão = null
  
  if (label === 'Formalização' && (
    proposal.status !== EProposalStatus.formalization && proposal.status !== EProposalStatus.finalized && (proposal.status === EProposalStatus.canceled && !proposal.termOfAdhesion)
  )) {
    return true
  }

  return false
}

export const hasStepPendency = (label: string, proposal: ProposalModel | null) => {
  if (!proposal) {
    return false
  }

  if (proposal.status === EProposalStatus.finalized) {
    return false
  }

  const { customerRef, files } = proposal
  const { pendencies, legalRepresentative } = customerRef

  if (label === 'Dados básicos') {
    return Boolean(
      isPendencyField(EProposalCustomerPendencies.uf, pendencies) ||
      isPendencyField(EProposalCustomerPendencies.name, pendencies) ||
      isPendencyField(EProposalCustomerPendencies.email, pendencies) ||
      isPendencyField(EProposalCustomerPendencies.phone, pendencies) ||
      isPendencyField(EProposalCustomerPendencies.document, pendencies) ||
      isPendencyField(EProposalCustomerPendencies.nationality, pendencies) ||
      isPendencyField(EProposalCustomerPendencies.dealershipId, pendencies) ||
      isPendencyField(EProposalCustomerPendencies.maritalStatus, pendencies) ||
      isPendencyField(EProposalCustomerPendencies.averageEnergyBillValue, pendencies)
    )
  }
  if (label === 'Endereço') {
    return Boolean(
      isPendencyField(EProposalCustomerPendencies?.city, pendencies) ||
      isPendencyField(EProposalCustomerPendencies?.state, pendencies) ||
      isPendencyField(EProposalCustomerPendencies?.number, pendencies) ||
      isPendencyField(EProposalCustomerPendencies?.street, pendencies) ||
      isPendencyField(EProposalCustomerPendencies?.zipcode, pendencies) ||
      isPendencyField(EProposalCustomerPendencies?.complement, pendencies) ||
      isPendencyField(EProposalCustomerPendencies?.neighborhood, pendencies)
    )
  }
  if (label === 'Representante') {
    const hasDataPendency = legalRepresentative && legalRepresentative.some(representative => representative && representative.pendencies && Object.values(representative.pendencies).some(item => Boolean(item.rejectedAt)))
    const hasFilesPendency = legalRepresentative && legalRepresentative.some(representative => representative.files?.some(file => file.status === EProposalFileStatus.rejected))

    return Boolean(hasDataPendency || hasFilesPendency)
  }
  if (label === 'Documentos') {
    const hasEnergyBillDocumentPendency = Boolean(files?.find(item => item.type === EProposalFileType.energyBill)?.status === EProposalFileStatus.rejected)
    const hasCustomerPjCardDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPjDocumentCard)?.status === EProposalFileStatus.rejected)
    const hasSocialContractDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPjSocialContract)?.status === EProposalFileStatus.rejected)
    const hasBackCustomerDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoBack)?.status === EProposalFileStatus.rejected)
    const hasFrontCustomerDocument = Boolean(files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoFront)?.status === EProposalFileStatus.rejected)

    return hasEnergyBillDocumentPendency || hasCustomerPjCardDocument || hasSocialContractDocument || hasBackCustomerDocument || hasFrontCustomerDocument
  }

  return false
}
