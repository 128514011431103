import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { isEmpty } from 'ramda'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { useContract } from '~/contexts'
import { Form } from '~/components/Form/Form'
import { Input } from '~/components/Form/Input'
import { uploadToS3 } from '~/services/uploadFile'
import { Option, defaultOption } from '~/utils/options'
import { clearString, onlyNumbers, theme, transformMoney } from '~/utils'
import { DragAndDrop } from '~/components/Form/DragAndDrop'
import { addressSchema, fileSchema, requiredFileSchema } from '~/utils/yupSchema'
import { ActionButtons, EnergyBillFields } from './components'
import { ContractAnnotations, ContractInfo, PropsWithWizard } from '~/components'
import {
  FileModel,
  ContractModel,
  EStorageService,
  useDealershipListQuery,
  EContractCustomerTension,
  EContractTariffClassification,
  EContractConsumerClassification,
  ContractAddEnergyBillInfoGraphqlDto,
  useContractAddEnergyBillInfoMutation,
  useContractUpdateMutation,
  EContractStatus,
  ContractUpdateEnergyBillInfo,
  useContractUpdateEnergyBillInfoMutation
} from '~/graphql/types'

type AddressForm = {
  city: string
  state: string
  street: string
  number: string
  zipcode: string
  complement: string
  neighborhood: string
}

export type ConsumerUnitForm = {
  //base info
  name: string
  averageEnergyBillValue: number

  //energy bill info
  tusdValue?: number
  dealershipId: string
  energyTaxes?: number
  AmountCents?: number
  clientNumber: string
  avarageConsumption: string
  distributionTaxes?: number
  installationNumber: string
  invoiceDueDay?: number
  energyBill?: File[] | null
  address: AddressForm | null
  avarageBillAmountCents?: number
  tension: EContractCustomerTension
  tariffClassification: EContractTariffClassification
  consumerClassification: EContractConsumerClassification

  //dealeship login info
  login: string
  password: string
  extraPassword: string
}

export const ConsumerUnit: React.FC<PropsWithWizard> = (props) => {

  const { contract, setContract, isDisabledContractUpdate, setIsDisabledContractUpdate, setIsUpdatingContract } = useContract()
  const [uploadingFile, setUploadingFile] = useState(false)
  const [updateContractEnergyBillInfo, { loading: updateContractEnergyBillInfoLoading }] = useContractUpdateEnergyBillInfoMutation()
  const [updateContract, { loading: updateContractLoading }] = useContractUpdateMutation()
  const [updateEnergyBillInfo, { loading: updateEnergyBillLoading }] = useContractAddEnergyBillInfoMutation()
  const [filteredDealerships, setFilteredDealerships] = useState<Option[]>([defaultOption])
  const { data: dealerships, loading: loadingDealerships } = useDealershipListQuery({ variables: { params: { pageSize: 99999 } } })
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isComplete = contract?.status === EContractStatus.sent || contract?.status === EContractStatus.active
  const energyBillFile = Boolean(contract?.energyBillInfo?.energyBillFile)

  const schema = yup.object().shape({
    login: yup.string().required(),
    password: yup.string().required(),
    address: addressSchema,
    energyBill: energyBillFile ? fileSchema : requiredFileSchema,
    tusdValue: yup.number().required(),
    energyTaxes: yup.number().required(),
    AmountCents: yup.number().required(),
    clientNumber: yup.string().required(),
    dealershipId: yup.string().required(),
    extraPassword: yup.string(),
    distributionTaxes: yup.number().required(),
    installationNumber: yup.string().required(),
    invoiceDueDay: yup.number().max(31).min(1).required(),
    avarageConsumption: yup.string().required(),
    tension: yup.string().required(),
    averageEnergyBillValue: yup.number(),
    consumerClassification: yup.string(),
    tariffClassification: yup.string().required(),
    avarageBillAmountCents: yup.number().required(),
    name: yup.string()
  })

  const methods = useForm<ConsumerUnitForm>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (dealerships && dealerships.dealershipList.data.length !== 0) {
      const mappedDealerships = dealerships.dealershipList.data.map(item => {
        return {
          label: item.name || '',
          value: item._id
        }
      })

      if (mappedDealerships.length !== 0) {
        const registeredDealershipId = contract?.energyBillInfo?.dealershipId

        setFilteredDealerships(mappedDealerships)
        methods.setValue('dealershipId', registeredDealershipId ?? mappedDealerships[0].value)
      } else {
        methods.setValue('dealershipId', '')
        setFilteredDealerships([defaultOption])
      }
    }

    if (contract) {
      const { energyBillInfo } = contract

      if (energyBillInfo?.address) {
        methods.setValue('address.city', energyBillInfo.address.city)
        methods.setValue('address.complement', energyBillInfo.address.complement || '')
        methods.setValue('address.neighborhood', energyBillInfo.address.neighborhood)
        methods.setValue('address.number', energyBillInfo.address.number)
        methods.setValue('address.state', energyBillInfo.address.state)
        methods.setValue('address.street', energyBillInfo.address.street)
        methods.setValue('address.zipcode', energyBillInfo.address.zipcode)
      }

      methods.setValue('consumerClassification', energyBillInfo?.consumerClassification || '' as EContractConsumerClassification)
      methods.setValue('tension', energyBillInfo?.tension || '' as EContractCustomerTension)
      methods.setValue('dealershipId', energyBillInfo?.dealershipId || '')
      methods.setValue('clientNumber', energyBillInfo?.clientNumber || '')
      methods.setValue('installationNumber', energyBillInfo?.installationNumber || '')
      methods.setValue('invoiceDueDay', energyBillInfo?.invoiceDueDay || undefined)
      methods.setValue('tariffClassification', energyBillInfo?.tariffClassification || '' as EContractTariffClassification)
      methods.setValue('avarageConsumption', energyBillInfo?.avarageConsumption ? String(energyBillInfo?.avarageConsumption) : '')
      methods.setValue('tusdValue', contract?.energyBillInfo?.tusdValue ? transformMoney(contract?.energyBillInfo?.tusdValue, 'toReal') : undefined)
      methods.setValue('AmountCents', contract?.energyBillInfo?.AmountCents ? transformMoney(contract?.energyBillInfo?.AmountCents, 'toReal') : undefined)
      methods.setValue('energyTaxes', contract?.energyBillInfo?.energyTaxes ? transformMoney(contract?.energyBillInfo?.energyTaxes, 'toReal') : undefined)
      methods.setValue('distributionTaxes', contract?.energyBillInfo?.distributionTaxes ? transformMoney(contract?.energyBillInfo?.distributionTaxes, 'toReal') : undefined)
      methods.setValue('avarageBillAmountCents', contract?.energyBillInfo?.avarageBillAmountCents ? transformMoney(contract?.energyBillInfo?.avarageBillAmountCents, 'toReal') : undefined)
      methods.setValue('extraPassword', contract?.dealershipLogin?.extraPassword || '')
      methods.setValue('login', contract?.dealershipLogin?.login || '')
      methods.setValue('password', contract?.dealershipLogin?.password || '')
      methods.setValue('name', contract?.energyBillInfo?.name || '')
    }
  }, [dealerships, methods])

  useEffect(() => {
    methods.setValue('dealershipId', contract?.energyBillInfo?.dealershipId || '')
  }, [filteredDealerships])

  const uploadFileToS3 = async (file: File[]) => {
    setUploadingFile(true)

    try {
      const uploadedFile = await uploadToS3(file)
      setUploadingFile(false)

      return uploadedFile
    } catch (err) {
      setUploadingFile(false)
      toast.error('Houve um problema com um dos arquivos enviados', {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const handleSubmitInSentStatus = async (formData: ConsumerUnitForm) => {
    if (contract) {
      const {
        address,
        tension,
        tusdValue,
        AmountCents,
        energyBill,
        energyTaxes,
        clientNumber,
        dealershipId,
        distributionTaxes,
        installationNumber,
        invoiceDueDay,
        avarageConsumption,
        tariffClassification,
        consumerClassification,
        avarageBillAmountCents,
        name
      } = formData

      let file: FileModel | null | undefined = null
      const dealershipName = dealerships?.dealershipList.data.find(dealership => dealership._id === dealershipId)?.name

      if (energyBill && energyBill[0] && energyBill[0].size) {
        file = await uploadFileToS3(energyBill)
      }

      const formattedAddress = address ? {
        ...address,
        zipcode: clearString(address?.zipcode),
        country: 'Brasil',
      } : null

      const params: ContractUpdateEnergyBillInfo = {
        tension,
        name,
        clientNumber,
        dealershipId,
        dealershipName,
        installationNumber,
        invoiceDueDay,
        tariffClassification,
        consumerClassification,
        address: formattedAddress,
        tusdValue: tusdValue ? transformMoney(tusdValue) : 0,
        AmountCents: AmountCents ? transformMoney(AmountCents) : 0,
        energyTaxes: energyTaxes ? transformMoney(energyTaxes) : 0,
        avarageConsumption: Number(onlyNumbers(avarageConsumption)),
        distributionTaxes: distributionTaxes ? transformMoney(distributionTaxes) : 0,
        avarageBillAmountCents: avarageBillAmountCents ? transformMoney(avarageBillAmountCents) : 0,
        contractId: contract._id,
        dealershipLogin: {
          login: formData.login,
          password: formData.password,
          extraPassword: formData.extraPassword
        }
      }

      const formattedDataWithFile: ContractUpdateEnergyBillInfo = file && !isEmpty(file) ? {
        ...params,
        energyBillFile: {
          key: file.key,
          fileUrl: file.fileUrl,
          fileName: file.fileName,
          storageService: file.storageService as EStorageService
        }
      } : params

      try {
        const { data: updatedContract } = await updateContractEnergyBillInfo({ variables: { params: formattedDataWithFile } })

        updatedContract && updatedContract.contractUpdateEnergyBillInfo && setContract(updatedContract.contractUpdateEnergyBillInfo as ContractModel)

        setIsDisabledContractUpdate(true)
        setIsUpdatingContract(false)
        toast.success('Dados atualizados com sucesso!')
      } catch (err) {
        toast.error('Houve um problema ao atualizar os dados')
      }
    }
  }

  const handleSubmit = async (formData: ConsumerUnitForm) => {
    if (contract) {
      const {
        address,
        tension,
        tusdValue,
        AmountCents,
        energyBill,
        energyTaxes,
        clientNumber,
        dealershipId,
        distributionTaxes,
        installationNumber,
        invoiceDueDay,
        avarageConsumption,
        tariffClassification,
        consumerClassification,
        avarageBillAmountCents,
        name
      } = formData

      let file: FileModel | null | undefined = null
      const dealershipName = dealerships?.dealershipList.data.find(dealership => dealership._id === dealershipId)?.name

      if (energyBill && energyBill[0] && energyBill[0].size) {
        file = await uploadFileToS3(energyBill)
      }

      const formattedAddress = address ? {
        ...address,
        zipcode: clearString(address?.zipcode),
        country: 'Brasil',
      } : null

      const params: ContractAddEnergyBillInfoGraphqlDto = {
        _id: contract._id,
        tension,
        name,
        clientNumber,
        dealershipId,
        dealershipName,
        installationNumber,
        invoiceDueDay,
        tariffClassification,
        consumerClassification,
        address: formattedAddress,
        tusdValue: tusdValue ? transformMoney(tusdValue) : 0,
        AmountCents: AmountCents ? transformMoney(AmountCents) : 0,
        energyTaxes: energyTaxes ? transformMoney(energyTaxes) : 0,
        avarageConsumption: Number(onlyNumbers(avarageConsumption)),
        distributionTaxes: distributionTaxes ? transformMoney(distributionTaxes) : 0,
        avarageBillAmountCents: avarageBillAmountCents ? transformMoney(avarageBillAmountCents) : 0
      }

      const formattedDataWithFile: ContractAddEnergyBillInfoGraphqlDto = file && !isEmpty(file) ? {
        ...params,
        energyBillFile: {
          key: file.key,
          fileUrl: file.fileUrl,
          fileName: file.fileName,
          storageService: file.storageService as EStorageService
        }
      } : params

      try {
        await updateContract({
          variables: {
            params: {
              _id: contract._id,
              specialDiscount: contract.specialDiscount ? [...contract.specialDiscount.map(item => {
                return {
                  month: item.month,
                  discountPercent: item.discountPercent
                }
              })] : [],
              dealershipLogin: {
                login: formData.login,
                password: formData.password,
                extraPassword: formData.extraPassword
              }
            }
          }
        })

        const { data: updatedContract } = await updateEnergyBillInfo({ variables: { params: formattedDataWithFile } })

        updatedContract && updatedContract.contractAddEnergyBillInfo && setContract(updatedContract.contractAddEnergyBillInfo as ContractModel)
        toast.success('Dados atualizados com sucesso!')
      } catch (err) {
        toast.error('Houve um problema ao atualizar os dados')
      }

      return
    }

    toast.error('Houve um problema ao enviar os dados')
  }

  const onSubmit = async (formData: ConsumerUnitForm) => {
    if (isComplete) {
      await handleSubmitInSentStatus(formData)
    } else {
      await handleSubmit(formData)
    }
  }

  const disabledFields = isDisabledContractUpdate && (contract?.status === EContractStatus.active || contract?.status === EContractStatus.sent || contract?.status === EContractStatus.cancellationRequested || contract?.status === EContractStatus.inDeactivation)

  return (
    <FormProvider {...methods}>
      <Form>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
          {contract?.proposalId && <ContractInfo contract={contract} />}

          <Typography variant='h3' fontWeight={500}>Informações básicas</Typography>
          <Grid spacing={3} container>
            <Grid item md={4} xs={12}>
              <Input disabled={disabledFields} name='name' label='Nome (Opcional)' />
            </Grid>
          </Grid>

          <Box display='flex' alignItems='baseline' gap={isMobile ? 1 : 2} flexDirection={isMobile ? 'column' : 'row'}>
            <Typography variant='h3' fontWeight={500}>Fatura de energia</Typography>
            <Typography color='grey.400'>Informe os dados e anexe o arquivo da fatura de energia</Typography>
          </Box>

          <DragAndDrop
            canResend
            name='energyBill'
            $variant='secondary'
            disabled={disabledFields}
            label='Anexar última fatura de energia'
            fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
            $completed={energyBillFile}
            fileName={contract?.energyBillInfo?.energyBillFile?.fileName || undefined}
            fileUrl={contract?.energyBillInfo?.energyBillFile?.fileUrl || ''}
          />

          <EnergyBillFields loadingDealerships={loadingDealerships} dealerships={filteredDealerships} />

          <Typography variant='h3' fontWeight={500}>Chaves de acesso da concessionária</Typography>
          <Grid spacing={3} container>
            <Grid item xs={12} md={4}>
              <Input disabled={disabledFields} name='login' label='Login' />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input disabled={disabledFields} name='password' label='Senha' type='password' />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input disabled={disabledFields} name='extraPassword' label='Chave extra' type='password' />
            </Grid>
          </Grid>

          {contract && <ContractAnnotations contract={contract} />}

          <ActionButtons onSubmit={methods.handleSubmit(onSubmit)} loading={updateEnergyBillLoading || uploadingFile || updateContractLoading || updateContractEnergyBillInfoLoading} {...props} />
        </Box>
      </Form>
    </FormProvider>
  )
}
