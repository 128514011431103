import { Collapse, List } from '@mui/material'
import { TSidebarItem } from '~/components/Layout'
import { CollapsibleListItem } from './components'

export type CollapsibleListItemProps = {
  isOpen: boolean
  isDrawerOpen: boolean
  sidebarItem: TSidebarItem
}

export const CollapsibleList: React.FC<CollapsibleListItemProps> = ({ isDrawerOpen, isOpen, sidebarItem }) => {
  return (
    <Collapse in={isOpen}>
      <List component='div'>
        {sidebarItem.nested && sidebarItem.nested.map(nestedItem => (
          <CollapsibleListItem
            key={`sidebar-list-nested-item-${nestedItem.text}`}
            isDrawerOpen={isDrawerOpen}
            {...nestedItem}
          />
        ))}
      </List>
    </Collapse>
  )
}
