import { Box, CircularProgress, Typography } from '@mui/material'
import { useMemo } from 'react'
import * as icons from '~/assets/icons'

type Icons = keyof typeof icons

export type InfoCardProps = {
  title: string
  value: number
  icon: Icons
  description: string
  loading: boolean
}
export const InfoCard: React.FC<InfoCardProps> = (props) => {
  const { description, title, value, icon, loading } = props

  const Icon = useMemo(() => {
    return icons[icon]
  }, [icon])

  return (
    <Box sx={{
      padding: 3,
      gap: '24px',
      display: 'flex',
      background: 'white',
      borderRadius: '12px',
      flexDirection: 'column'
    }}>
      <Box sx={{
        display: 'flex',
        padding: '10px',
        borderRadius: '8px',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'start',
        background: 'rgba(86, 24, 133, 0.08)'
      }}>
        <Icon />
      </Box>

      <Box sx={{
        gap: '16px',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography>{title}</Typography>
        <Typography variant='body2' color='#999999'>{description}</Typography>
      </Box>

      {loading ? <CircularProgress size='24px' color='inherit' /> : <Typography variant='h2'>{value}</Typography>}
    </Box>
  )
}
