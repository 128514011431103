import { useTheme } from '@emotion/react'
import { Box, Divider, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { Cashback, Discount } from '~/assets/icons'
import { useProposalContext } from '~/contexts'
import { EStage } from '~/graphql/types'
import { LoseProposalInfo } from './components'

export const ProposalInfo: React.FC = () => {
  const theme = useTheme()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const { proposal } = useProposalContext()
  const isLoseProposal = proposal?.salesFunnel?.stage === EStage.lost
  const isCashback = Boolean(proposal?.cashbackInfo?.active)

  return (
    <Box
      sx={{ border: `1px solid ${theme.palette.grey[300]}`, padding: '1rem', borderRadius: '.8rem', }}>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexFlow: isLowerMd ? 'column' : 'row' }}>
        <Box sx={{ paddingBottom: isLowerMd ? '.6rem' : '0' }}>
          <Typography color='grey.400' variant='body3'>Proposta #{proposal?._id}</Typography>
          <Typography sx={{ fontWeight: 500, color: theme.palette.grey[800] }} variant='body1'>{proposal?.customerRef.name || ''}</Typography>

          {isLoseProposal && <LoseProposalInfo />}
        </Box>
        {isCashback ? (
          <Tooltip title='Tipo de benefício: Cashback'>
            <Box sx={{ height: '39px', width: '32px' }}>
              <Cashback width={32} height={39} />
            </Box>
          </Tooltip>
        )
          : (
            <Tooltip title='Tipo de benefício: Desconto'>
              <Box sx={{ height: '39px', width: '32px' }}>
                <Discount width={32} height={39} />
              </Box>
            </Tooltip>
          )}
      </Box>
      <Divider sx={{ margin: '1rem 0' }} />
      <Typography sx={{ fontSize: '14px', color: theme.palette.grey[400], fontWeight: 500 }} >{isCashback ? 'Cashback' : 'Desconto'}</Typography>
    </Box>
  )
}
