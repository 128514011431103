import { toast } from 'react-toastify'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { ContentCopy, Share } from '@mui/icons-material'
import { theme, trimString } from '~/utils'
import { copyToClipboard } from '~/utils/clipboard'
import { ContractTermOfAdhesionModel, ETermStatus } from '~/graphql/types'
import { IconWriting } from '@tabler/icons-react'
import { RoundedIcon } from '../RoundedIcon'
import { AdhesionTermStatus, TermoOfAdhesionOptions } from './components'

const getCardBorder = (hasSigned: boolean) => {
  if (hasSigned) return '#00A86B'

  return '#F9DC45'
}

export type ContractAdhesionTermCardProps = {
  term?: ContractTermOfAdhesionModel | null
}
export const ContractAdhesionTermCard: React.FC<ContractAdhesionTermCardProps> = ({ term }) => {
  const hasSigned = term?.status === ETermStatus.signed
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const onCopyToClipboard = (link?: string | null) => {
    copyToClipboard(link)
    toast.success('Link copiado!', {
      position: 'top-right',
      autoClose: 3000
    })
  }

  return (

    <Box sx={{
      gap: 3,
      padding: 3,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #E6E6E6',
      borderLeft: `6px solid ${getCardBorder(hasSigned)}`
    }}>
      {!isLowerSm && (
        <RoundedIcon>
          <IconWriting color={theme.palette.grey[400]} />
        </RoundedIcon>
      )}

      <Box sx={{
        gap: 3,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Box sx={{
            gap: isLowerSm ? 1 : 3,
            display: 'flex',
            alignItems: isLowerSm ? 'start' : 'center',
            flexDirection: isLowerSm ? 'column' : 'row'
          }}>
            <Typography>Assinatura do cliente</Typography>
            <AdhesionTermStatus status={term?.status || ETermStatus.created} />
            {!isLowerMd && <Typography color='#999999'>{hasSigned ? 'Assinado pelo cliente' : 'Aguardando cliente assinar'}</Typography>}

          </Box>

          {<TermoOfAdhesionOptions term={term} />}
        </Box>
        <Box display='flex' flexDirection={isLowerSm ? 'column' : 'row'} gap={isLowerSm ? 0 : 1}>
          <Typography display='inline-block'>
            • Assinante:
          </Typography>
          <Typography color='#999999'>
            {term?.signerEmail}
          </Typography>
        </Box>
        {term?.signatureLink && (
          <Box gap={2} display='flex' flexDirection='column'>
            <Box flexDirection='row' display='flex' alignItems='center' gap={1}>
              <Typography fontWeight={600}>Compartilhar</Typography>
              <Share color='action' />
            </Box>

            <Typography variant='body2' color='#999999'>Link para termo de adesão (Copie e cole onde desejar):</Typography>
            <Box
              onClick={() => onCopyToClipboard(term?.signatureLink)}
              sx={{
                display: 'flex',
                padding: 2,
                borderRadius: '8px',
                border: '1px solid #E6E6E6',
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center'
              }}>
              <Typography variant='body2'>
                {trimString(term.signatureLink)}
              </Typography>

              <ContentCopy color='action' fontSize='small' />
            </Box>
          </Box>
        )}
      </Box>
    </Box>

  )
}
