/* eslint-disable no-unused-vars */
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { brazilStates } from '~/constants'
import { useProposalContext } from '~/contexts'
import { Input } from '~/components/Form/Input'
import { Button } from '~/components/Form/Button'
import { Select } from '~/components/Form/Select'
import { maritalStatusOptions } from '~/utils/options'
import { DragAndDrop } from '~/components/Form/DragAndDrop'
import { EProposalFileStatus, EProposalFileType } from '~/graphql/types'
import { clearString } from '~/utils'
import { LegalRepresentativeType } from '../../utils'
import { useCallback, useEffect } from 'react'
import { isLegalRepresentativePendencyField } from '~/utils/proposal'
import { useFormContext, useWatch } from 'react-hook-form'
import { useCep } from '~/hooks'
import { FullPageLoader } from '~/components/FullPageLoader'
import { IconSearch } from '@tabler/icons-react'

export type LegalRepresentativeFieldsProps = {
  index: number
  loading: boolean
  fieldsLength: number
  legalRepresentative: LegalRepresentativeType
  removeLegalRepresentative: (index: number) => void
}

const fieldName = 'legalRepresentative'

export const LegalRepresentativeFields: React.FC<LegalRepresentativeFieldsProps> = ({ index, removeLegalRepresentative, fieldsLength, loading, legalRepresentative }) => {
  const { proposal } = useProposalContext()
  const { setError, control, setValue } = useFormContext()
  const { legalRepresentative: legalRepresentativeWatch } = useWatch({ control })
  const { fetchCep, isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })

  const onSearchCep = useCallback(async () => {
    const representativy = legalRepresentativeWatch[index]

    if(representativy && representativy?.address?.zipcode) {
      const { data, isSuccess } = await fetchCep(representativy.address.zipcode)
      if(isSuccess) {
        setValue(`legalRepresentative.${index}.address.state`, data?.uf || '')
        setValue(`legalRepresentative.${index}.address.city`, data?.localidade || '')
        setValue(`legalRepresentative.${index}.address.neighborhood`, data?.bairro || '')
        setValue(`legalRepresentative.${index}.address.street`, data?.logradouro || '')
 
      }
    }
  }, [legalRepresentativeWatch, index])

  const currentLegalRepresentative = proposal?.customerRef?.legalRepresentative?.find(representative => clearString(representative.document) === clearString(legalRepresentative.document))

  const legalRepresentativeBackDocument = currentLegalRepresentative?.files?.find(file => file.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack)
  const legalRepresentativeFrontDocument = currentLegalRepresentative?.files?.find(file => file.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoFront)

  useEffect(() => {
    if (currentLegalRepresentative) {
      const { pendencies } = currentLegalRepresentative

      pendencies && Object.keys(pendencies).forEach((item) => {
        isLegalRepresentativePendencyField(item as any, pendencies) && setError(`${fieldName}.${index}.${item as keyof LegalRepresentativeType}`, { message: 'Dado anterior recusado. Por favor, informe um dado válido.' })
      })
    }
  }, [])

  useEffect(() => {
    if (legalRepresentativeFrontDocument?.status === EProposalFileStatus.rejected) {
      setError(`${fieldName}.${index}.${EProposalFileType.customerPjRepresentativeDocumentWithPhotoFront}`, { message: 'Arquivo não atendeu os requisitos. Por favor, envie um novo arquivo.' })
    }
    if (legalRepresentativeBackDocument?.status === EProposalFileStatus.rejected) {
      setError(`${fieldName}.${index}.${EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack}`, { message: 'Arquivo não atendeu os requisitos. Por favor, envie um novo arquivo.' })
    }
  }, [legalRepresentativeBackDocument, legalRepresentativeFrontDocument])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant='h3'>Representante legal</Typography>
      <Grid spacing={3} container>
        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Input name={`${fieldName}.${index}.name`} label='Nome completo' />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input mask='cpf' name={`${fieldName}.${index}.document`} label='CPF' />
        </Grid>

        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input name={`${fieldName}.${index}.identificationNumber`} label='RG' />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input name={`${fieldName}.${index}.email`} label='E-mail' />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input mask='phone' name={`${fieldName}.${index}.phone`} label='Telefone' />
        </Grid>

        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input name={`${fieldName}.${index}.nationality`} label='Nacionalidade' />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Select name={`${fieldName}.${index}.maritalStatus`} label='Estado civil' options={maritalStatusOptions} />
        </Grid>
      </Grid>

      <Typography fontWeight={500}>Endereço</Typography>
      <Grid spacing={3} container>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Input
            icons={{ end: { element: fetchCepIsLoading ? <FullPageLoader /> : <IconButton onClick={() => onSearchCep()}><IconSearch /></IconButton> } }} 
            label='CEP' 
            name={`${fieldName}.${index}.address.zipcode`} 
            mask='cep' 
          />
        </Grid>
        
        <Grid item sx={{ paddingTop: '0px !important', width: '100%' }} lg={6} md={0} sm={0} xs={0} />

        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input label='Rua' name={`${fieldName}.${index}.address.street`} />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input label='Número' name={`${fieldName}.${index}.address.number`} />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input label='Complemento (Opcional)' name={`${fieldName}.${index}.address.complement`} />
        </Grid>

        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input label='Bairro' name={`${fieldName}.${index}.address.neighborhood`} />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Input label='Cidade' name={`${fieldName}.${index}.address.city`} />
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Select name={`${fieldName}.${index}.address.state`} label='UF' options={brazilStates} />
        </Grid>
      </Grid>

      <Typography fontWeight={500}>Documento</Typography>
      <Grid spacing={3} container>
        <Grid item xs={12} md={6} sm={12}>
          <DragAndDrop
            label='Anexar RG ou CNH (Frente)'
            fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
            $variant='secondary'
            sentAt={legalRepresentativeFrontDocument?.sendAt}
            $completed={legalRepresentativeFrontDocument?.sendAt}
            fileUrl={legalRepresentativeFrontDocument?.file?.fileUrl}
            fileName={legalRepresentativeFrontDocument?.file?.fileName}
            status={legalRepresentativeFrontDocument?.status as EProposalFileStatus}
            name={`${fieldName}.${index}.customerPjRepresentativeDocumentWithPhotoFront`}
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <DragAndDrop
            $variant='secondary'
            label='Anexar RG ou CNH (Verso)'
            fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
            sentAt={legalRepresentativeBackDocument?.sendAt}
            fileUrl={legalRepresentativeBackDocument?.file?.fileUrl}
            $completed={legalRepresentativeBackDocument?.sendAt}
            fileName={legalRepresentativeBackDocument?.file?.fileName}
            status={legalRepresentativeBackDocument?.status as EProposalFileStatus}
            name={`${fieldName}.${index}.customerPjRepresentativeDocumentWithPhotoBack`}
          />
        </Grid>
      </Grid>

      {fieldsLength !== 1 && (
        <Button
          color='error'
          disabled={loading}
          startIcon={<Delete />}
          style={{ alignSelf: 'end' }}
          variant='outlined'
          onClick={() => removeLegalRepresentative(index)}
        >
          Remover representante
        </Button>
      )}

      {!(fieldsLength === index + 1) && <Divider />}
    </Box>
  )
}
