import styled from '@emotion/styled'

export const Container = styled.div`
  gap: 1rem;
  display: flex;
`

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 48px;
  background: #FFFADF;
  border: 1px solid #F3CC04;
  border-radius: .4rem;

  svg {
    color: #F3CC04;
  }
`
