import * as yup from 'yup'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Form } from '../Form/Form'
import { Input } from '../Form/Input'
import { Button } from '../Form/Button'
import { useProposalContext } from '~/contexts'
import { RegisteredAnnotation } from './components'
import { ProposalModel, useAddNoteToProposalMutation } from '~/graphql/types'
import { usePermission } from '~/hooks'

export type AnnotationForm = {
  annotation: string
}

const schema = yup.object().shape({
  annotation: yup.string().required('Campo obrigatório')
})

export type AnnotationsProps = {
  proposal: ProposalModel
  customUpdateProposal?: Function
}
export const Annotations: React.FC<AnnotationsProps> = ({ proposal, customUpdateProposal }) => {
  const { notes } = proposal
  const { setProposal } = useProposalContext()
  const [addAnnotationToProposal, { loading }] = useAddNoteToProposalMutation()

  const canAddAnnotations = usePermission(['proposals.proposalAddNote'])

  const renderedAnnotations = useMemo(() => {
    return notes?.map((item, index) => (
      <RegisteredAnnotation
        key={`annotation-${index}`}
        annotation={item.text}
        createdAt={item.createdAt}
        analystName={item.analystName}
      />
    ))
  }, [notes])

  const methods = useForm<AnnotationForm>({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (formData: AnnotationForm) => {
    try {
      const { data: updatedProposal } = await addAnnotationToProposal({
        variables: {
          params: {
            proposalId: proposal._id,
            text: formData.annotation
          }
        }
      })

      methods.reset()

      if (customUpdateProposal) {
        updatedProposal && customUpdateProposal(updatedProposal.addNoteToProposal as ProposalModel)
        return
      }

      updatedProposal && setProposal(updatedProposal.addNoteToProposal as ProposalModel)
    } catch (err) {
      toast.error('Houve um problema ao adicionar observação')
    }
  }

  return (
    <FormProvider {...methods}>
      <Form key='annotations'>
        <Box flexDirection='column' display='flex' gap={3}>
          <Typography variant='h3'>Observações</Typography>

          {renderedAnnotations}

          <Input
            multiline
            minRows={5}
            name='annotation'
            disabled={loading || !canAddAnnotations}
            placeholder='Escreva aqui suas observações'
          />

          <Button
            color='secondary'
            style={{ alignSelf: 'start' }}
            disabled={loading || !canAddAnnotations}
            onClick={methods.handleSubmit(onSubmit)}
            startIcon={loading ? <CircularProgress color='inherit' size={24} /> : <Add />}
          >
            Registrar observação
          </Button>
        </Box>
      </Form>
    </FormProvider>
  )
}
