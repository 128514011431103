import { Box, Divider, Typography } from '@mui/material'
import { IconUser } from '@tabler/icons-react'
import { formatDate, theme } from '~/utils'
import { Container, ContainerIcon } from './styles'

export type RegisteredAnnotationProps = {
  annotation: string
  analystName: string
  createdAt: string | null
}
export const RegisteredAnnotation: React.FC<RegisteredAnnotationProps> = ({ annotation, analystName, createdAt }) => {
  return (
    <>
      <Container>
        <ContainerIcon>
          <IconUser stroke='2px' />
        </ContainerIcon>

        <Box sx={{ display: 'flex', gap: '.2rem', flexFlow: 'column', flex: 1 }}>
          <Typography fontSize='1.1rem' variant='h6'>{analystName}</Typography>

          <Typography color={theme.palette.grey[400]} fontWeight={500} variant='body2'>
            Enviado {formatDate(createdAt || '', { dateHour: true })}
          </Typography>

          <Divider sx={{ margin: '20px 0px 0px 0px' }} />

          <Box sx={{ whiteSpace: 'pre-wrap', padding: '1rem 0' }}>
            {annotation}
          </Box>
        </Box>
      </Container>
    </>
  )
}
