import { Box } from '@mui/material'
import { ProposalInfo, PropsWithWizard } from '~/components'
import { useProposalContext } from '~/contexts'
import { EProposalStatus } from '~/graphql/types'
import { ActionButtons, FormalizationInfo, ProposalManagementTermCard } from './components'
import { Form } from '~/components/Form/Form'
import { FormProvider, useForm } from 'react-hook-form'

export const Formalization: React.FC<PropsWithWizard> = (props) => {
  const { proposal } = useProposalContext()

  const methods = useForm()

  const isCompleted = proposal?.status === EProposalStatus.finalized

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
      <FormProvider {...methods}>

        <Form>
          {isCompleted &&
            <FormalizationInfo proposal={proposal} />
          }

          <ProposalInfo />

          {/* WARNING - Dentro da proposta o termOfAdhesion na verdade está com o nome errado, na verdade é o termOfManagement */}
          <ProposalManagementTermCard adhesionTerm={proposal?.termOfAdhesion} />

          <ActionButtons onSubmit={methods.handleSubmit(() => { })} loading={false} {...props} />
        </Form>
      </FormProvider>
    </Box>
  )
}
