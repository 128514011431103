/* eslint-disable no-nested-ternary */
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { ContentCopy, Share } from '@mui/icons-material'
import { theme, trimString } from '~/utils'
import { ContractSign } from '~/assets/icons'
import { copyToClipboard } from '~/utils/clipboard'
import { TermOfAdhesionStatus } from '~/components'
import { EDocumentSignStatus, ETermOfManagementType, TermOfAdhesionModel } from '~/graphql/types'
import { TermOfManagementOptions } from './components/TermOfManagementOptions'

const getCardBorder = (hasSigned: boolean, status?: EDocumentSignStatus) => {

  if (status === EDocumentSignStatus.refused) return theme.palette.error.main

  if (hasSigned) return '#00A86B'

  if (status === EDocumentSignStatus.expired) return '#CDCDCD'

  if (status === EDocumentSignStatus.uploaded) return theme.palette.info.dark

  if (status === EDocumentSignStatus.signatureRequested) return theme.palette.primary.main

  if (status) return '#8989890D'

  return theme.palette.primary.main
}

{/* WARNING - Dentro da proposta o `termOfAdhesion` na verdade está com o nome errado, na verdade é o `termOfManagement` */ }

type ProposalManagementTermCardProps = {
  adhesionTerm?: TermOfAdhesionModel | null
}

export const ProposalManagementTermCard: React.FC<ProposalManagementTermCardProps> = ({ adhesionTerm }) => {
  const hasSigned = Boolean(adhesionTerm?.signedAt)
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isManualManagement = adhesionTerm?.manualOrAutomatic === ETermOfManagementType.manual
  const signers = useMemo(() => {
    return adhesionTerm?.signers.map(signer => (
      <Box gap={1} display='flex' flexDirection={isLowerMd ? 'column' : 'row'} key={`${signer.email}.${signer.name}`}>
        {/* {!isLowerMd && ( */}
        <Typography display='inline-block'>
          • Assinante:
        </Typography>
        {/* )} */}
        <Typography color='#999999'>
          {signer.name}
        </Typography>
      </Box>
    ))
  }, [adhesionTerm])

  const onCopyToClipboard = (link?: string | null) => {
    copyToClipboard(link)
    toast.success('Link copiado!', {
      position: 'top-right',
      autoClose: 3000
    })
  }

  return (
    <Box sx={{ paddingTop: '2rem' }}>
      <Typography fontWeight={500} variant='h3'>Termo de gestão</Typography>
      <Box sx={{
        gap: 3,
        padding: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #E6E6E6',
        borderLeft: `6px solid ${getCardBorder(hasSigned, adhesionTerm?.status)}`,
        marginTop: '1.5rem'
      }}>

        {!isLowerSm &&
          <ContractSign />
        }

        <Box sx={{
          gap: 3,
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column'
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Box sx={{
              gap: 3,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}>
              <Typography>Assinatura do cliente</Typography>
              <TermOfAdhesionStatus status={adhesionTerm?.status || EDocumentSignStatus.created} />
              {!isLowerMd && (
                <Typography color='#999999'>
                  {adhesionTerm?.status === EDocumentSignStatus.expired
                    ? 'Gere um novo termo de gestão'
                    : hasSigned
                      ? 'Assinado pelo cliente'
                      : 'Aguardando cliente assinar'}
                </Typography>
              )}
            </Box>
            {/* COLOCAR AQUI A OPÇÃO DE GERAR UM NOVO TERMO */}
            {!isManualManagement && (adhesionTerm?.status === EDocumentSignStatus.signed || adhesionTerm?.status === EDocumentSignStatus.expired) && <TermOfManagementOptions term={adhesionTerm} />}

          </Box>
          {signers}

          {adhesionTerm?.signerUrl && (
            <Box gap={2} display='flex' flexDirection='column'>
              <Box flexDirection='row' display='flex' alignItems='center' gap={1}>
                <Typography fontWeight={600}>Compartilhar</Typography>
                <Share color='action' />
              </Box>

              <Typography variant='body2' color='#999999'>Link para termo de gestão (Copie e cole onde desejar):</Typography>
              <Box
                onClick={() => adhesionTerm?.status !== EDocumentSignStatus.expired && onCopyToClipboard(adhesionTerm?.signerUrl)}
                sx={{
                  display: 'flex',
                  padding: 2,
                  borderRadius: '8px',
                  border: '1px solid #E6E6E6',
                  justifyContent: 'space-between',
                  cursor: adhesionTerm?.status === EDocumentSignStatus.expired ? 'not-allowed' : 'pointer',
                  userSelect: adhesionTerm?.status === EDocumentSignStatus.expired ? 'none' : 'auto',
                  alignItems: 'center'
                }}>
                <Typography sx={{ paddingRight: '1rem' }} variant='body2' color={adhesionTerm?.status === EDocumentSignStatus.expired ? '#CDCDCD' : '#000000DE'}>
                  {trimString(adhesionTerm.signerUrl, { maxTextLength: isLowerSm ? 20 : 100, textCutLength: isLowerSm ? 20 : 100 })}
                </Typography>

                <ContentCopy color={adhesionTerm?.status === EDocumentSignStatus.expired ? 'disabled' : 'action'} fontSize='small' />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
