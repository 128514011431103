import { Status } from '~/components'
import { EDocumentSignStatus } from '~/graphql/types'
import { documentSignStatusColors, translateDocumentSignStatus } from '~/utils'

export type TermOfAdhesionStatusProps = {
  status: EDocumentSignStatus
}
export const TermOfAdhesionStatus: React.FC<TermOfAdhesionStatusProps> = ({ status }) => {
  const colorProps = documentSignStatusColors(status)

  return (
    <Status
      color={colorProps.color}
      background={colorProps.background}
      text={translateDocumentSignStatus(status)}
    />
  )
}
