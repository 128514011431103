import { useTheme } from '@emotion/react'
import { Box, Button, Divider, Typography, Checkbox, CircularProgress } from '@mui/material'
import { IconHelpCircleFilled } from '@tabler/icons-react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Dialog } from '~/components/Dialog'
import { useContract } from '~/contexts'
import { useDeactivateContractMutation } from '~/graphql/types'

export type ContractDeactivationDialogProps = {
  isVisible: boolean
  toggleModal: () => void
}

export const ContractDeactivationDialog: React.FC<ContractDeactivationDialogProps> = ({ isVisible, toggleModal }) => {
  const theme = useTheme()
  const { contract, setContract } = useContract()
  const [acceptUpdateContractStatus, setAcceptUpdateContractStatus] = useState(false)

  const [deactivateContract, { loading }] = useDeactivateContractMutation({
    onCompleted(result) {
      if (contract) {
        setContract({ ...contract, status: result.deactivateContract.status })
        toast.success('Contrato desativado com sucesso')
        toggleModal()
      }
    },
    onError() {
      toast.error('Erro ao desativar contrato.')
    },
  })

  return (
    <Dialog
      isVisible={isVisible}
      title='Desativar contrato'
      onClose={toggleModal}
      minWidth={700}
      titleIcon={<IconHelpCircleFilled style={{ color: theme.palette.primary.main }} size={32} />}
    >
      <Divider />
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '1rem', padding: '2rem 0' }}>
        <Typography>Para alterar o status, certifique-se de que o status do contrato na <b>Gera</b> também esteja como <b>desativado.</b></Typography>

        <Box display='flex' flexDirection='row' alignItems='center' gap='8px'>
          <Checkbox onChange={() => setAcceptUpdateContractStatus(prev => !prev)} checked={acceptUpdateContractStatus} name='acceptUpdateContractStatus' />
          <Typography variant='body2' color='grey.400'>Estou ciente de que ao confirmar esta ação, o cliente deixará de ser cobrado, ainda que o mesmo tenha pendências financeiras</Typography>
        </Box>

        <Typography>Deseja alterar o status do contrato para <b>Desativado</b>?</Typography>
      </Box>
      <Divider />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', paddingTop: '2rem' }}>
        <Button disabled={loading} onClick={toggleModal} color='secondary'>Cancelar</Button>
        <Button
          endIcon={loading ? <CircularProgress size={24} color='inherit' /> : null}
          disabled={!acceptUpdateContractStatus || loading}
          onClick={() => deactivateContract({
            variables: {
              id: contract?._id || ''
            }
          })}>
          Sim, alterar
        </Button>
      </Box>
    </Dialog>
  )
}
