import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { theme } from '~/utils'

export const Container = styled(Box)`
  gap: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ContractConfigContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-tems: center;
  justify-content: space-between;

  @media(max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
    gap: 24px;
  }
`

export const IconTitleContainer = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const IconContainer = styled(Box)`
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: #F7F8EF;
  align-items: center;
  padding: 8px;
`

export const TitleDescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ContractLinkContainer = styled(Box)`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  background: #FAFAFA;
  color: #3BA1FF;
` 
