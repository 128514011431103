/* eslint-disable no-unused-vars */
import { Info } from '@mui/icons-material'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { Dialog } from '~/components'
import { Button } from '~/components/Form/Button'

export type DataReviewModalProps = {
  isVisible: boolean
  toggleModal: () => void
  loading: boolean
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

export const DataReviewModal: React.FC<DataReviewModalProps> = (props) => {
  const { isVisible, toggleModal, onSubmit, loading } = props

  return (
    <Dialog
      isVisible={isVisible}
      title='Revisão dos dados'
      onClose={loading ? undefined : toggleModal}
      titleIcon={<Info fontSize='large' color='primary' />}
    >
      <Box sx={{ flexDirection: 'column', display: 'flex', gap: 3 }}>
        <Divider />
        <Typography>Se certifique de que todas as informações inseridas no contrato estão corretas.</Typography>
        <Typography>Deseja seguir para a geração de documentos?</Typography>

        <Divider />

        <Box sx={{ flexDirection: 'row', display: 'flex', gap: 3, justifyContent: 'end' }}>
          <Button
            color='secondary'
            disabled={loading}
            onClick={toggleModal}
          >
            Voltar e revisar
          </Button>

          <Button
            color='primary'
            onClick={onSubmit}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={24} color='inherit' /> : null}
          >
            Seguir para geração de docs.
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
