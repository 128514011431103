import { EProposalStatus } from '~/graphql/types'
import { Container } from './styles'
import { translateProposalStatus } from '~/utils'

type ProposalStatusProps = {
  status: EProposalStatus
}
export const ProposalStatus: React.FC<ProposalStatusProps> = ({ status }) => {
  return (
    <Container status={status || EProposalStatus.finalized}>
      <span>{translateProposalStatus(status || EProposalStatus.finalized)}</span>
    </Container>
  )
}
