import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { ApolloError } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, CircularProgress, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { clearString, theme } from '~/utils'
import { CustomerData, LegalRepresentativeType } from './types'
import { brazilStates } from '~/constants'
import { uploadCustomerFiles, uploadMultiCustomerFiles } from './api'
import { useCustomerFlow } from '~/contexts'
import { Form } from '~/components/Form/Form'
import { Input } from '~/components/Form/Input'
import { Radio } from '~/components/Form/Radio'
import { Select } from '~/components/Form/Select'
import { mapErrorToMessage } from '~/utils/errors'
import { LEGAL_REPRESENTATIVE_INITIAL_VALUES, getInitialValues, getLegalRepresentativesInfo, schema } from './utils'
import { DragAndDrop } from '~/components/Form/DragAndDrop'
import { maritalStatusOptions, personTypeOptions } from '~/utils/options'
import { ActionButtons, CreatingCustomerDialog, LegalRepresentativeFields } from './components'
import {
  CustomerModel,
  CustomerCreateGraphqlDto,
  ECustomerFileType,
  CustomerUpdateGraphqlDto,
  ECustomerPersonType,
  useCustomerCreateMutation,
  useCustomerUpdateByIdMutation,
  LegalRepresentativeAddGraphqlDto,
  EMaritalStatus,
  useCustomerAddLegalRepresentantMutation,
  LegalRepresentativeModel,
  useCustomerRemoveLegalRepresentantMutation,
  LegalRepresentativeUpdateGraphqlDto,
  useCustomerUpdateLegalRepresentantMutation,
  ContractModel
} from '~/graphql/types'
import { Button } from '~/components/Form/Button'
import { Add } from '@mui/icons-material'
import { isEmpty } from 'ramda'
import { useCep } from '~/hooks'
import { IconSearch } from '@tabler/icons-react'
import { CustomerContracts } from '../CustomerContracts'

export type CustomerFormProps = {
  onRefresh: () => void
  customerContracts: ContractModel[]
}

export const CustomerForm: React.FC<CustomerFormProps> = ({ onRefresh, customerContracts }) => {
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const { customer, setCustomer } = useCustomerFlow()
  const [uploadingFile, setUploadingFile] = useState(false)
  const navigate = useNavigate()

  const { fetchCep, isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })
  const [customerCreate, { loading: customerCreateLoading }] = useCustomerCreateMutation()
  const [customerUpdate, { loading: updateCustomerLoading }] = useCustomerUpdateByIdMutation()
  const [addCustomerLegalRepresentative, { loading: addCustomerRepresentativeLoading }] = useCustomerAddLegalRepresentantMutation()
  const [removeCustomerLegalRepresentative, { loading: removeCustomerRepresentativeLoading }] = useCustomerRemoveLegalRepresentantMutation()
  const [updateCustomerLegalRepresentative, { loading: updateCusomterRepresentativeLoading }] = useCustomerUpdateLegalRepresentantMutation()

  const loading = useMemo(() =>
    updateCustomerLoading ||
    uploadingFile ||
    addCustomerRepresentativeLoading ||
    removeCustomerRepresentativeLoading ||
    updateCusomterRepresentativeLoading,
  [uploadingFile, updateCustomerLoading, addCustomerRepresentativeLoading, removeCustomerRepresentativeLoading, updateCusomterRepresentativeLoading])

  const methods = useForm<CustomerData>({
    resolver: yupResolver(schema),
    values: getInitialValues(customer)
  })

  const { fields, append, remove } = useFieldArray<CustomerData>({
    name: 'legalRepresentatives',
    control: methods.control
  })

  const personTypeWatch = methods.watch('personType')
  const isPf = personTypeWatch === ECustomerPersonType.pf

  const customerFrontDocument = customer?.files?.find(item => item.type === ECustomerFileType.customerPfDocumentWithPhotoFront)
  const customerBackDocument = customer?.files?.find(item => item.type === ECustomerFileType.customerPfDocumentWithPhotoBack)
  const customerPjDocumentCardDocument = customer?.files?.find(item => item.type === ECustomerFileType.customerPjDocumentCard)
  const customerPjSocialContractDocument = customer?.files?.find(item => item.type === ECustomerFileType.customerPjSocialContract)

  const createPFCustomer = async (formData: CustomerData) => {
    const { name, document, email, phone, nationality, maritalStatus, address, personType } = formData

    const params: CustomerCreateGraphqlDto = {
      name,
      email,
      personType,
      nationality,
      maritalStatus: maritalStatus || null,
      phone: clearString(phone),
      document: clearString(document),
      address: {
        city: address?.city || '',
        neighborhood: address?.neighborhood || '',
        number: address?.number || '',
        state: address?.state || '',
        street: address?.street || '',
        complement: address?.complement,
        country: 'Brazil',
        zipcode: address?.zipcode ? clearString(address.zipcode) : ''
      }
    }

    try {
      setUploadingFile(true)
      const { data } = await customerCreate({ variables: { params } })

      await uploadCustomerFiles(data?.customerCreate as CustomerModel, formData)

      setUploadingFile(false)

      toast.success('Cliente criado com sucesso!')

      if (data?.customerCreate) {
        setCustomer(data.customerCreate as CustomerModel)
        navigate(`/app/customers/${data.customerCreate._id}`, { state: { newCustomerCreated: true } })
      }
    } catch (err) {
      setUploadingFile(false)
      const apolloError = err as ApolloError
      toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao cadastrar o cliente'), {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const createPJCustomer = async (formData: CustomerData) => {
    const { name, document, email, phone, personType, address } = formData

    const params: CustomerCreateGraphqlDto = {
      name,
      email,
      phone: clearString(phone),
      document: clearString(document),
      personType,
      address: {
        city: address?.city || '',
        neighborhood: address?.neighborhood || '',
        number: address?.number || '',
        state: address?.state || '',
        street: address?.street || '',
        complement: address?.complement,
        country: 'Brazil',
        zipcode: address?.zipcode ? clearString(address.zipcode) : ''
      }
    }

    try {
      setUploadingFile(true)
      const { data } = await customerCreate({ variables: { params } })

      await uploadCustomerFiles(data?.customerCreate as CustomerModel, formData)
      const updatedCustomer = await legalRepresentativeActions(formData, data?.customerCreate as CustomerModel)

      setUploadingFile(false)

      toast.success('Cliente criado com sucesso!')

      if (updatedCustomer && !isEmpty(updatedCustomer)) {
        setCustomer(updatedCustomer)
      } else if (data?.customerCreate) {
        setCustomer(data.customerCreate as CustomerModel)
        navigate(`/app/customers/${data.customerCreate._id}`, { state: { newCustomerCreated: true } })
      }

    } catch (err) {
      setUploadingFile(false)
      const apolloError = err as ApolloError
      toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao cadastrar o cliente'), {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const updatePJCustomer = async (formData: CustomerData) => {
    if (customer) {
      const { name, email, phone, address } = formData

      const params: CustomerUpdateGraphqlDto = {
        name,
        email,
        phone: clearString(phone),
        address: {
          city: address?.city || '',
          neighborhood: address?.neighborhood || '',
          number: address?.number || '',
          state: address?.state || '',
          street: address?.street || '',
          complement: address?.complement,
          country: 'Brazil',
          zipcode: address?.zipcode ? clearString(address.zipcode) : ''
        }
      }

      try {
        setUploadingFile(true)
        const { data } = await customerUpdate({ variables: { id: customer._id, params } })

        await uploadCustomerFiles(customer, formData)
        const updatedCustomer = await legalRepresentativeActions(formData, customer)

        setUploadingFile(false)

        if (updatedCustomer && !isEmpty(updatedCustomer)) {
          setCustomer(updatedCustomer)
        } else if (data?.customerUpdateById) {
          setCustomer(data.customerUpdateById as CustomerModel)
        }

        toast.success('Cliente atualizado com sucesso!')

        onRefresh()
      } catch (err) {
        setUploadingFile(false)
        const apolloError = err as ApolloError
        toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao atualizar o cliente'), {
          autoClose: 3000,
          position: 'top-right'
        })
      }
    }
  }

  const updatePFCustomer = async (formData: CustomerData) => {
    const { email, name, phone, nationality, maritalStatus, address } = formData

    const params: CustomerUpdateGraphqlDto = {
      name,
      email,
      nationality,
      phone: clearString(phone),
      maritalStatus: maritalStatus || null,
      address: {
        city: address?.city || '',
        neighborhood: address?.neighborhood || '',
        number: address?.number || '',
        state: address?.state || '',
        street: address?.street || '',
        complement: address?.complement,
        country: 'Brazil',
        zipcode: address?.zipcode ? clearString(address.zipcode) : ''
      }
    }

    try {
      setUploadingFile(true)
      const { data } = await customerUpdate({ variables: { id: customer?._id || '', params } })

      await uploadCustomerFiles(customer, formData)

      setUploadingFile(false)

      toast.success('Cliente atualizado com sucesso!')

      if (data?.customerUpdateById) {
        setCustomer(data.customerUpdateById as CustomerModel)
        onRefresh()
      }
    } catch (err) {
      setUploadingFile(false)
      const apolloError = err as ApolloError
      toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao atualizar o cliente'), {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const updateCustomer = useCallback(async (formData: CustomerData) => {
    if (formData.personType === ECustomerPersonType.pf) {
      return updatePFCustomer(formData)
    } else {
      return updatePJCustomer(formData)
    }
  }, [updatePFCustomer, updatePJCustomer])

  const createCustomer = useCallback(async (formData: CustomerData) => {
    if (formData.personType === ECustomerPersonType.pf) {
      return createPFCustomer(formData)
    } else {
      return createPJCustomer(formData)
    }
  }, [createPFCustomer, createPJCustomer])

  const addNewLegalRepresentative = () => {
    append(LEGAL_REPRESENTATIVE_INITIAL_VALUES)
  }

  const addLegalRepresentative = async (legalRepresentative: LegalRepresentativeType, customerArg?: CustomerModel | null) => {
    const { customerPjRepresentativeDocumentWithPhotoBack, customerPjRepresentativeDocumentWithPhotoFront } = legalRepresentative

    if (customerArg) {
      let updatedCustomer: CustomerModel | null = null
      const filesToUpload: File[][] | null = []
      const fileTypesToUpload: ECustomerFileType[] = []

      const params: LegalRepresentativeAddGraphqlDto = {
        customerId: customerArg._id,
        name: legalRepresentative.name,
        email: legalRepresentative.email || '',
        nationality: legalRepresentative.nationality,
        phone: clearString(legalRepresentative?.phone || ''),
        document: clearString(legalRepresentative.document || ''),
        maritalStatus: legalRepresentative.maritalStatus || '' as EMaritalStatus,
        identificationNumber: legalRepresentative?.identificationNumber || '',
        address: {
          ...legalRepresentative.address,
          zipcode: clearString(legalRepresentative?.address?.zipcode || ''),
          street: legalRepresentative?.address?.street || '',
          state: legalRepresentative?.address?.state || '',
          number: legalRepresentative?.address?.number || '',
          neighborhood: legalRepresentative?.address?.neighborhood || '',
          city: legalRepresentative?.address?.city || '',
          country: 'Brasil'
        }
      }

      if (customerPjRepresentativeDocumentWithPhotoBack && !isEmpty(customerPjRepresentativeDocumentWithPhotoBack) && !customerPjRepresentativeDocumentWithPhotoBack.size) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoBack)
        fileTypesToUpload.push(ECustomerFileType.customerPjRepresentativeDocumentWithPhotoBack)
      }

      if (customerPjRepresentativeDocumentWithPhotoFront && !isEmpty(customerPjRepresentativeDocumentWithPhotoFront && !customerPjRepresentativeDocumentWithPhotoFront.size)) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoFront)
        fileTypesToUpload.push(ECustomerFileType.customerPjRepresentativeDocumentWithPhotoFront)
      }

      try {
        const { data } = await addCustomerLegalRepresentative({ variables: { params } })
        if (data && data.customerAddLegalRepresentant) {
          updatedCustomer = data.customerAddLegalRepresentant as CustomerModel
        }

        if (!isEmpty(filesToUpload)) {
          updatedCustomer = await uploadMultiCustomerFiles({
            files: filesToUpload,
            types: fileTypesToUpload,
            customerId: customerArg._id,
            legalRepresentativeDocument: clearString(legalRepresentative.document || '')
          })
        }

        return updatedCustomer
      } catch (err) {
        toast.error('Houve um erro ao adicionar o(s) representante(s) legal(is)', {
          autoClose: 3000,
          position: 'top-right'
        })
      }
    }
  }

  const removeLegalRepresentative = async (legalRepresentative: LegalRepresentativeModel, customerArg?: CustomerModel | null) => {
    try {
      const { data } = await removeCustomerLegalRepresentative({
        variables: {
          params: {
            customerId: customerArg?._id || '',
            document: clearString(legalRepresentative.document || '')
          }
        }
      })

      return data && data.customerRemoveLegalRepresentant as CustomerModel
    } catch (err) {
      toast.error('Houve um erro ao remover o(s) representante(s) legal(is)', {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const updateLegalRepresentative = async (legalRepresentative: LegalRepresentativeType, customerArg?: CustomerModel | null) => {
    const { customerPjRepresentativeDocumentWithPhotoBack, customerPjRepresentativeDocumentWithPhotoFront } = legalRepresentative

    if (customerArg) {
      let updatedCustomer: CustomerModel | null = null
      const filesToUpload: File[][] | null = []
      const fileTypesToUpload: ECustomerFileType[] = []

      const params: LegalRepresentativeUpdateGraphqlDto = {
        customerId: customerArg._id,
        name: legalRepresentative.name,
        email: legalRepresentative.email,
        nationality: legalRepresentative.nationality,
        phone: clearString(legalRepresentative.phone || ''),
        document: clearString(legalRepresentative.document || ''),
        maritalStatus: legalRepresentative.maritalStatus as EMaritalStatus,
        //identificationNumber: clearString(legalRepresentative.identificationNumber || ''),
        address: {
          ...legalRepresentative.address,
          zipcode: clearString(legalRepresentative?.address?.zipcode || ''),
          street: legalRepresentative?.address?.street || '',
          state: legalRepresentative?.address?.state || '',
          number: legalRepresentative?.address?.number || '',
          neighborhood: legalRepresentative?.address?.neighborhood || '',
          city: legalRepresentative?.address?.city || '',
          country: 'Brasil'
        }
      }

      if (customerPjRepresentativeDocumentWithPhotoBack && !isEmpty(customerPjRepresentativeDocumentWithPhotoBack) && customerPjRepresentativeDocumentWithPhotoBack[0].size) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoBack)
        fileTypesToUpload.push(ECustomerFileType.customerPjRepresentativeDocumentWithPhotoBack)
      }

      if (customerPjRepresentativeDocumentWithPhotoFront && !isEmpty(customerPjRepresentativeDocumentWithPhotoFront) && customerPjRepresentativeDocumentWithPhotoFront[0].size) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoFront)
        fileTypesToUpload.push(ECustomerFileType.customerPjRepresentativeDocumentWithPhotoFront)
      }

      try {
        const { data } = await updateCustomerLegalRepresentative({ variables: { params } })
        if (data) {
          updatedCustomer = data.customerUpdateLegalRepresentant as CustomerModel
        }

        if (!isEmpty(filesToUpload)) {
          updatedCustomer = await uploadMultiCustomerFiles({
            files: filesToUpload,
            types: fileTypesToUpload,
            customerId: customerArg._id,
            legalRepresentativeDocument: clearString(legalRepresentative.document || '')
          })
        }

        return updatedCustomer
      } catch (err) {
        toast.error('Houve um erro ao atualizar o(s) representante(s) legal(is)', {
          autoClose: 3000,
          position: 'top-right'
        })
      }
    }
  }

  const addMultipleLegalRepresentatives = async (legalRepresentatives: LegalRepresentativeType[], customerArg?: CustomerModel | null) => {
    let updatedCustomer: CustomerModel | null | undefined = null
    for (let i = 0; i < legalRepresentatives.length; i++) {
      updatedCustomer = await addLegalRepresentative(legalRepresentatives[i], customerArg)
    }

    return updatedCustomer
  }

  const removeMultipleLegalRepresentative = async (legalRepresentatives: LegalRepresentativeModel[], customerArg?: CustomerModel | null) => {
    let updatedCustomer: CustomerModel | null = null

    for (let i = 0; i < legalRepresentatives.length; i++) {
      const data = await removeLegalRepresentative(legalRepresentatives[i], customerArg)

      if (data) {
        updatedCustomer = data
      }
    }

    return updatedCustomer
  }

  const updateMultipleLegalRepresentatives = async (legalRepresentatives: LegalRepresentativeType[], customerArg?: CustomerModel | null) => {
    let updatedCustomer: CustomerModel | null | undefined = null

    for (let i = 0; i < legalRepresentatives.length; i++) {
      updatedCustomer = await updateLegalRepresentative(legalRepresentatives[i], customerArg)
    }

    return updatedCustomer
  }

  const legalRepresentativeActions = async (formData: CustomerData, customerArg?: CustomerModel | null) => {
    let updatedCustomer: CustomerModel | null | undefined = null

    if (customerArg) {
      const { legalRepresentativesToAdd, legalRepresentativesToRemove, legalRepresentativesToUpdate } = getLegalRepresentativesInfo(formData, customerArg)

      if (legalRepresentativesToAdd && !isEmpty(legalRepresentativesToAdd)) {
        updatedCustomer = await addMultipleLegalRepresentatives(legalRepresentativesToAdd, customerArg)
      }
      if (legalRepresentativesToUpdate && !isEmpty(legalRepresentativesToUpdate)) {
        updatedCustomer = await updateMultipleLegalRepresentatives(legalRepresentativesToUpdate, customerArg)
      }
      if (legalRepresentativesToRemove && !isEmpty(legalRepresentativesToRemove)) {
        updatedCustomer = await removeMultipleLegalRepresentative(legalRepresentativesToRemove, customerArg)
      }

      return updatedCustomer
    }

    return updatedCustomer
  }

  const onSubmit = useCallback(async (formData: CustomerData) => {
    if (!customer) {
      await createCustomer(formData)
      return
    }

    await updateCustomer(formData)
  }, [customer, createCustomer, updateCustomer])

  const address = methods.watch('address')
  const legalRepresentativeList = methods.watch('legalRepresentatives')

  const onSearchCep = useCallback(async (index?: number) => {
    if (index || index === 0) {
      if (legalRepresentativeList && legalRepresentativeList[index]?.address?.zipcode) {
        const { data, isSuccess } = await fetchCep(legalRepresentativeList[index]?.address?.zipcode || '')
        if (isSuccess) {
          methods.setValue(`legalRepresentatives.${index}.address.state`, data?.uf || '')
          methods.setValue(`legalRepresentatives.${index}.address.city`, data?.localidade || '')
          methods.setValue(`legalRepresentatives.${index}.address.neighborhood`, data?.bairro || '')
          methods.setValue(`legalRepresentatives.${index}.address.street`, data?.logradouro || '')
        }
      }
    } else if (address?.zipcode) {
      const { data, isSuccess } = await fetchCep(address?.zipcode)
      if (isSuccess) {
        methods.setValue('address.state', data?.uf || '')
        methods.setValue('address.city', data?.localidade || '')
        methods.setValue('address.neighborhood', data?.bairro || '')
        methods.setValue('address.street', data?.logradouro || '')
      }
    }
  }, [address, legalRepresentativeList])

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
      <FormProvider {...methods}>
        <Form>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
            <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} variant='h3' fontWeight={500}>Tipo de pessoa:</Typography>
            <Radio disabled={Boolean(customer)} row name='personType' options={personTypeOptions} />

            <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} variant='h3' fontWeight={500}>Dados {isPf ? 'pessoais' : 'da empresa'}</Typography>
            <Grid spacing={3} container>
              <Grid item xs={12} md={8}>
                <Input name='name' label={isPf ? 'Nome completo' : 'Nome da empresa'} />
              </Grid>
              
              <Grid item md={4} xs={12}>
                <Input disabled={Boolean(customer)} name='document' label={isPf ? 'CPF' : 'CNPJ'} mask={isPf ? 'cpf' : 'cnpj'} />
              </Grid>

              <Grid item md={4} xs={12}>
                <Input name='email' label='E-mail' />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input name='phone' label='Telefone' mask='phone' />
              </Grid>
            </Grid>

            {isPf && (
              <>
                <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} variant='h3' fontWeight={500}>Dados complementares</Typography>
                <Grid spacing={2} container>
                  <Grid item md={4} xs={12}>
                    <Input name='nationality' label='Nacionalidade' />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Select name='maritalStatus' label='Estado civil' options={maritalStatusOptions} />
                  </Grid>
                </Grid>
              </>
            )}

            {!isPf ? (
              <Grid spacing={3} container>
                <Grid item md={6} xs={12}>
                  <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} marginBottom='24px' fontWeight={500}>Contrato social</Typography>
                  <DragAndDrop
                    label='Anexar contrato social'
                    name='customerPjSocialContract'
                    fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
                    $variant='secondary'
                    sentAt={customerPjSocialContractDocument?.sendAt}
                    $completed={Boolean(customerPjSocialContractDocument?.sendAt)}
                    fileUrl={customerPjSocialContractDocument?.file?.fileUrl}
                    fileName={customerPjSocialContractDocument?.file?.fileName}
                    canResend
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography fontWeight={500} marginBottom='24px'>Cartão CNPJ</Typography>
                  <DragAndDrop
                    canResend
                    $variant='secondary'
                    label='Anexar cartão CNPJ'
                    name='customerPjDocumentCard'
                    fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
                    sentAt={customerPjDocumentCardDocument?.sendAt}
                    $completed={Boolean(customerPjDocumentCardDocument?.sendAt)}
                    fileUrl={customerPjDocumentCardDocument?.file?.fileUrl}
                    fileName={customerPjDocumentCardDocument?.file?.fileName}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} variant='h3' fontWeight={500}>Documento de identificação</Typography>
                <Grid spacing={3} container>
                  <Grid item md={6} xs={12}>
                    <DragAndDrop
                      label='Anexar RG ou CNH (Frente)'
                      name='customerPfDocumentWithPhotoFront'
                      fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
                      $variant='secondary'
                      sentAt={customerFrontDocument?.sendAt}
                      $completed={Boolean(customerFrontDocument?.sendAt)}
                      fileName={customerFrontDocument?.file?.fileName}
                      fileUrl={customerFrontDocument?.file?.fileUrl}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DragAndDrop
                      $variant='secondary'
                      label='Anexar RG ou CNH (Verso)'
                      name='customerPfDocumentWithPhotoBack'
                      fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
                      sentAt={customerBackDocument?.sendAt}
                      $completed={Boolean(customerBackDocument?.sendAt)}
                      fileName={customerBackDocument?.file?.fileName}
                      fileUrl={customerBackDocument?.file?.fileUrl}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} fontWeight={500} variant='h3'>Endereço {isPf ? 'do cliente' : 'da empresa'}</Typography>
            <Grid spacing={3} container>
              <Grid item md={4} xs={12}>
                <Input
                  icons={{ end: { element: fetchCepIsLoading ? <CircularProgress size={18} color='inherit' /> : <IconButton onClick={() => onSearchCep()}><IconSearch /></IconButton> } }}
                  label='CEP'
                  name='address.zipcode'
                  mask='cep'
                />
              </Grid>
              <Grid sx={{ paddingTop: '0 !important' }} item xs={12} />
              <Grid sx={{ paddingTop: '0 !important' }} item xs={12} />

              <Grid item md={4} xs={12}>
                <Input label='Rua' name='address.street' />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input label='Número' name='address.number' />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input label='Complemento (Opcional)' name='address.complement' />
              </Grid>

              <Grid item md={4} xs={12}>
                <Input label='Bairro' name='address.neighborhood' />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input label='Cidade' name='address.city' />
              </Grid>
              <Grid item md={4} xs={12}>
                <Select name='address.state' label='UF' options={brazilStates} />
              </Grid>
            </Grid>

            {!isPf && (
              <>
                {fields.map((legalRepresentative, index) => (
                  <LegalRepresentativeFields
                    index={index}
                    loading={loading}
                    customer={customer}
                    onSearchCep={onSearchCep}
                    fieldsLength={fields.length}
                    removeLegalRepresentative={remove}
                    key={`legal-representative-${legalRepresentative.id}`}
                    legalRepresentative={legalRepresentative as LegalRepresentativeType}
                  />
                ))}
              </>
            )}

            {!isPf && (
              <Button
                color='secondary'
                disabled={false}
                startIcon={<Add />}
                style={{ alignSelf: 'start' }}
                onClick={addNewLegalRepresentative}
              >
                Adicionar representante
              </Button>
            )}

            {customer && <CustomerContracts contracts={customerContracts} />}
          </Box>
        </Form>
      </FormProvider>

      <ActionButtons onSubmit={methods.handleSubmit(onSubmit)} loading={loading} />
      <CreatingCustomerDialog isVisible={customerCreateLoading || uploadingFile && !customer} />
    </Box>
  )
}
