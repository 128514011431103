/* eslint-disable no-unused-vars */
import { CustomerModel } from '~/graphql/types'
import { useState, createContext, PropsWithChildren, useContext } from 'react'

type CustomerContextValue = {
  customer: CustomerModel | null
  resetCustomer: () => void
  setCustomer: (proposal: CustomerModel | null) => void
}

export const CustomerFlowContext = createContext<CustomerContextValue>({
  customer: null,
  setCustomer: () => null,
  resetCustomer: () => null,
})

export const CustomerFlowProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [customer, setCustomer] = useState<CustomerModel | null>(null)

  const resetCustomer = () => {
    setCustomer(null)
  }

  return (
    <CustomerFlowContext.Provider value={{ customer, setCustomer, resetCustomer }}>
      {children}
    </CustomerFlowContext.Provider>
  )
}

export const useCustomerFlow = () => useContext(CustomerFlowContext)
