import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { CircularProgress } from '@mui/material'
import { Navigate, useParams } from 'react-router-dom'
import { usePermission } from '~/hooks'
import { CustomerForm } from './components'
import { useCustomerFlow } from '~/contexts'
import { Card, CustomerFlowLayout } from '~/components'
import { ContractModel, CustomerModel, useCustomerContractListLazyQuery, useCustomerGetLazyQuery } from '~/graphql/types'

export type CustomerSteps = 'customerData' | 'contracts'

export const CustomersCreate: React.FC = () => {
  const { setCustomer, resetCustomer } = useCustomerFlow()
  const [getCustomer, { loading }] = useCustomerGetLazyQuery()
  const [getCustomerContracts, { loading: getContractsLoading }] = useCustomerContractListLazyQuery()
  const [customerContracts, setCustomerContracts] = useState<ContractModel[]>([])

  const { customerId } = useParams()

  const canEditClients = usePermission(['customers.create', 'customers.updated'])

  const fetchCustomer = async () => {
    if (customerId) {
      try {
        const { data } = await getCustomer({ variables: { id: customerId }, fetchPolicy: 'no-cache' })
        setCustomer(data?.customerGet as CustomerModel || null)
      } catch (err) {
        toast.error('Não foi possível carregar a proposta', {
          autoClose: 3000,
          position: 'top-right'
        })
      }

      return
    }

    resetCustomer()
  }

  const fetchContracts = useCallback(async () => {
    if (customerId) {
      const { data } = await getCustomerContracts({ variables: { customerId } })
      setCustomerContracts(data?.contractList?.data as ContractModel[] || null)
    }
  }, [customerId])

  useEffect(() => {
    fetchCustomer()
    fetchContracts()
  }, [customerId])

  useEffect(() => {
    return () => {
      resetCustomer()
    }
  }, [])

  if (!canEditClients) {
    return <Navigate replace to='/app' />
  }

  if (loading || getContractsLoading) {
    return (
      <Card sx={{ minHeight: '800px', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Card>
    )
  }

  return (
    <CustomerFlowLayout>
      <CustomerForm
        onRefresh={fetchCustomer}
        customerContracts={customerContracts}
      />
    </CustomerFlowLayout>
  )
}
