import styled from '@emotion/styled'

const getBackground = (props: CustomIconContainerProps) => {
  if (props.error) {
    return '#efe8ef'
  }

  if (props.completed) {
    return 'rgba(122, 191, 255, 0.25)'
  }

  if (props.active) {
    return '#3BA1FF'
  }

  return '#E6E6E6'
}

export type CustomIconContainerProps = {
  active: boolean
  completed: boolean
  error?: boolean
}
export const CustomIconContainer = styled.div<CustomIconContainerProps>`
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  align-items: center; 
  justify-content: center;
  background: ${props => getBackground(props)};
`
