import { SvgIcon, SvgIconProps } from '@mui/material'

export const Info: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <SvgIcon width={width || 30} height={height || 30} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='15' cy='15' r='15' fill='#3BA1FF' />
      <path fillRule='evenodd' clipRule='evenodd' d='M11 11C11 10.4477 11.4477 10 12 10H15C15.5523 10 16 10.4477 16 11V22H18C18.5523 22 19 22.4477 19 23C19 23.5523 18.5523 24 18 24H15C14.4477 24 14 23.5523 14 23V12H12C11.4477 12 11 11.5523 11 11Z' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd' d='M15 6C15.5523 6 16 6.44549 16 6.99502V7.00498C16 7.55451 15.5523 8 15 8C14.4477 8 14 7.55451 14 7.00498V6.99502C14 6.44549 14.4477 6 15 6Z' fill='white' />
    </SvgIcon>
  )
}
