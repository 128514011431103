import styled from '@emotion/styled'
import { EInvoiceStatus } from '~/graphql/types'
import { colorContractInvoice } from '~/utils'

type ContainerProps = {
  status: EInvoiceStatus;
}
export const Container = styled.div<ContainerProps>`

  span {
    ${(props) => colorContractInvoice(props.status)}
    padding: .2rem .6rem;
    border-radius: .4rem;
    font-weight: 600;
    font-size: .8rem;
  }

`
