import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { Option } from '~/utils/options'
import { Select } from '~/components/Form/Select'

export type CustomerSelectionProps = {
  customersOptions: Option[]
  loading?: boolean
}
export const CustomerSelection: React.FC<CustomerSelectionProps> = ({ customersOptions, loading }) => {
  return (
    <>
      <Box display='flex' gap='8px' flexDirection='column'>
        <Typography variant='h3' fontWeight={500}>Seleção de cliente</Typography>
        <Typography variant='body3' color='#999999'>
          Indique o cliente cadastrado no nosso sistema para o qual você criará o contrato. <br />
          Os campos abaixo serão liberados após seleção.
        </Typography>
      </Box>
      <Grid spacing={3} container>
        <Grid item md={4} xs={12}>
          <Select disabled={loading} startAdornment={loading ? <CircularProgress size={18} color='inherit' /> : null} searchable name='customerId' label='Cliente' options={customersOptions} />
        </Grid>
      </Grid>
    </>
  )
}
