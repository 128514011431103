import { useCallback, useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'ramda'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { ApolloError } from '@apollo/client'
import { Box, CircularProgress, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { clearString } from '~/utils'
import { useContract } from '~/contexts'
import { Form } from '~/components/Form/Form'
import { Radio } from '~/components/Form/Radio'
import { Button } from '~/components/Form/Button'
import { mapErrorToMessage } from '~/utils/errors'
import { Option, personTypeOptions } from '~/utils/options'
import { uploadCustomerFiles, uploadMultiCustomerFiles } from '~/utils/file'
import { ContractAnnotations, ContractInfo, PropsWithWizard, confirmDialog } from '~/components'
import { BaseDataForm, LEGAL_REPRESENTATIVE_INITIAL_VALUES, LegalRepresentativeType, getLegalRepresentativesInfo, hasAllBankData, schema } from './utils'
import { ActionButtons, AddressFields, BaseDataFields, CreatingProposalDialog, CustomerSelection, DocumentationFields, LegalRepresentativeFields, BankFields, DeactivationWarning } from './components'
import {
  EContractType,
  CustomerModel,
  EMaritalStatus,
  ECustomerFileType,
  ECustomerPersonType,
  CustomerUpdateGraphqlDto,
  LegalRepresentativeModel,
  useContractCreateMutation,
  useCustomerUpdateByIdMutation,
  LegalRepresentativeAddGraphqlDto,
  LegalRepresentativeUpdateGraphqlDto,
  useCustomerAddLegalRepresentantMutation,
  useCustomerRemoveLegalRepresentantMutation,
  useCustomerUpdateLegalRepresentantMutation,
  useCustomerListAllLazyQuery,
  useCustomerGetLazyQuery,
  EBankAccountType,
  EContractStatus,
  useCustomerCreateDigitalAccountMutation,
  EPersonType,
  BankGraphqlDto,
  CustomerCreateDigitalAccountGraphqlDto
} from '~/graphql/types'
import { useNavigate, useParams } from 'react-router-dom'
import { Input } from '~/components/Form/Input'

export const ClientData: React.FC<PropsWithWizard> = (props) => {
  const { contract } = useContract()

  const navigate = useNavigate()
  const { contractId } = useParams()

  const [uploadingFile, setUploadingFile] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState<CustomerModel | null>(null)
  const [contractCreate, { loading: contractCreateLoading }] = useContractCreateMutation()
  const [customerUpdate, { loading: customerUpdateLoading }] = useCustomerUpdateByIdMutation()
  const [customerCreateDigitalAccount, { loading: customerCreateDigitalAccountLoading }] = useCustomerCreateDigitalAccountMutation()
  const [addCustomerLegalRepresentative, { loading: addCustomerRepresentativeLoading }] = useCustomerAddLegalRepresentantMutation()
  const [removeCustomerLegalRepresentative, { loading: removeCustomerRepresentativeLoading }] = useCustomerRemoveLegalRepresentantMutation()
  const [updateCustomerLegalRepresentative, { loading: updateCusomterRepresentativeLoading }] = useCustomerUpdateLegalRepresentantMutation()
  const [customerGet, { loading: customerGetLoading }] = useCustomerGetLazyQuery()

  const [getCustomerList, { data: customers, loading: customersLoading }] = useCustomerListAllLazyQuery({
    variables: {
      params: {
        pageSize: 999
      }
    },
    fetchPolicy: 'no-cache'
  })

  const isLoading = customerCreateDigitalAccountLoading || customerGetLoading || addCustomerRepresentativeLoading || removeCustomerRepresentativeLoading || updateCusomterRepresentativeLoading || customerUpdateLoading || customersLoading || uploadingFile || contractCreateLoading

  const isComplete = contract?.status === EContractStatus.sent || contract?.status === EContractStatus.active
  const isCancelledOrDeactivated = contract?.status === EContractStatus.cancellationRequested || contract?.status === EContractStatus.inDeactivation || contract?.status === EContractStatus.deactivated
  const disableFields = Boolean(!currentCustomer || isLoading || isCancelledOrDeactivated || isComplete)
  const disableBankFields = Boolean(currentCustomer?.bank)

  const customersOptions: Option[] = useMemo(() => customers ? customers?.customerList?.data?.map(customer => {
    return {
      label: customer.name,
      value: customer._id
    }
  }) : [], [customers])

  const methods = useForm<BaseDataForm>({
    resolver: yupResolver(schema)
  })

  const { append, remove, fields } = useFieldArray<BaseDataForm>({
    name: 'legalRepresentatives',
    control: methods.control
  })

  const customerId = methods.watch('customerId')
  const personTypeWatch = methods.watch('personType')
  const legalRepresentativiesFromWatch = methods.watch('legalRepresentatives')
  const isPf = methods.watch('personType') === ECustomerPersonType.pf

  const setLegalRepresentativeFields = (legalRepresentatives?: LegalRepresentativeModel[] | null) => {
    if (isEmpty(legalRepresentatives)) {
      return methods.setValue('legalRepresentatives', [LEGAL_REPRESENTATIVE_INITIAL_VALUES])
    }

    if (legalRepresentatives) {
      const formatLegalRepresentativiesToHookForm: LegalRepresentativeType[] = legalRepresentatives.map(item => {
        return {
          address: {
            city: item?.address?.city || '',
            complement: item?.address?.complement || '',
            neighborhood: item?.address?.neighborhood || '',
            number: item?.address?.number || '',
            state: item?.address?.state || '',
            street: item?.address?.street || '',
            zipcode: item?.address?.zipcode || '',
          },
          document: item?.document || '',
          email: item.email,
          identificationNumber: item.identificationNumber || '',
          maritalStatus: item.maritalStatus || '',
          name: item.name || '',
          nationality: item.nationality || '',
          phone: item.phone,
        }
      })

      return methods.setValue(`legalRepresentatives`, formatLegalRepresentativiesToHookForm)

    }
  }

  const setCustomerValues = (customer?: CustomerModel | null) => {
    if (!customer) {
      resetCustomerValues()
      return
    }

    methods.setValue('name', customer.name)
    methods.setValue('email', customer.email)
    methods.setValue('phone', customer.phone)
    methods.setValue('document', customer.document)
    methods.setValue('nationality', customer.nationality || '')
    methods.setValue('personType', customer.personType as ECustomerPersonType)
    methods.setValue('maritalStatus', customer.maritalStatus || '' as EMaritalStatus)

    methods.setValue('address.city', customer.address?.city || '')
    methods.setValue('address.state', customer.address?.state || '')
    methods.setValue('address.number', customer.address?.number || '')
    methods.setValue('address.street', customer.address?.street || '')
    methods.setValue('address.zipcode', customer.address?.zipcode || '')
    methods.setValue('address.complement', customer.address?.complement || '')
    methods.setValue('address.neighborhood', customer.address?.neighborhood || '')
    methods.setValue('code', customer.bank?.code || '')
    methods.setValue('agency', customer.bank?.agency || '')
    methods.setValue('type', customer.bank?.type || EBankAccountType.cc)
    methods.setValue('account', customer.bank?.account || '')

    setLegalRepresentativeFields(customer.legalRepresentatives)
  }

  const resetCustomerValues = () => {
    methods.setValue('name', '')
    methods.setValue('email', '')
    methods.setValue('phone', '')
    methods.setValue('document', '')
    methods.setValue('nationality', '')
    methods.setValue('personType', ECustomerPersonType.pf)
    methods.setValue('maritalStatus', '' as EMaritalStatus)
    methods.setValue('customerId', '')

    methods.setValue('address.city', '')
    methods.setValue('address.state', '')
    methods.setValue('address.number', '')
    methods.setValue('address.street', '')
    methods.setValue('address.zipcode', '')
    methods.setValue('address.complement', '')
    methods.setValue('address.neighborhood', '')
    methods.setValue('legalRepresentatives', [LEGAL_REPRESENTATIVE_INITIAL_VALUES])
    methods.setValue('code', '')
    methods.setValue('agency', '')
    methods.setValue('type', EBankAccountType.cc)
    methods.setValue('account', '')
    methods.setValue('bankInfoOwner', 'company')
  }

  useEffect(() => {
    if (contract?.customerId) {
      const selectedCustomer = customers?.customerList?.data.find(customer => contract.customerId === customer._id) as CustomerModel
      if (selectedCustomer) {
        setCurrentCustomer(selectedCustomer)
        setCustomerValues(selectedCustomer)
        return
      }

      resetCustomerValues()
      setCurrentCustomer(null)
      return
    }

    if (customerId) {
      const selectedCustomer = customers?.customerList.data.find(customer => customer._id === customerId) as CustomerModel
      if (selectedCustomer) {
        setCurrentCustomer(selectedCustomer)
        setCustomerValues(selectedCustomer)
        return
      }

      resetCustomerValues()
      setCurrentCustomer(null)
      return
    }

    resetCustomerValues()
    setCurrentCustomer(null)
  }, [customerId, customers, contract, contractId])

  const addNewLegalRepresentative = () => {
    append(LEGAL_REPRESENTATIVE_INITIAL_VALUES)
  }

  const updatePFCustomer = useCallback(async (formData: BaseDataForm) => {
    const { document, email, name, phone, nationality, maritalStatus, address, code, account, agency, type } = formData

    const params: CustomerUpdateGraphqlDto = {
      name,
      email,
      nationality,
      phone: clearString(phone),
      document: clearString(document),
      maritalStatus: maritalStatus || null,
      address: {
        country: 'Brazil',
        city: address?.city || '',
        state: address?.state || '',
        number: address?.number || '',
        street: address?.street || '',
        complement: address?.complement,
        neighborhood: address?.neighborhood || '',
        zipcode: address?.zipcode ? clearString(address.zipcode) : ''
      }
    }

    const bankParams: BankGraphqlDto | null = hasAllBankData({ account, agency, code, type }) ? {
      code: code || '',
      agency: agency || '',
      account: account || '',
      type: type as EBankAccountType
    } : null

    try {
      setUploadingFile(true)
      const { data } = await customerUpdate({ variables: { id: formData.customerId || currentCustomer?._id || '', params } })

      const updatedCustomer = await uploadCustomerFiles(currentCustomer, formData)

      setUploadingFile(false)

      if (updatedCustomer) {
        setCurrentCustomer(updatedCustomer)
        return
      }

      if (data?.customerUpdateById) {
        setCurrentCustomer(data.customerUpdateById as CustomerModel)
      }

      if (!currentCustomer?.bank && bankParams && currentCustomer?._id) {
        const updatedCustomerWithBank = await customerCreateDigitalAccount({
          variables: {
            params: {
              accountOwner: EPersonType.pf,
              customerId: currentCustomer._id,
              bank: bankParams,
            }
          },
        })

        setCurrentCustomer(updatedCustomerWithBank.data?.customerCreateDigitalAccount as CustomerModel)
      }

      toast.success('Dados salvos com sucesso!')
    } catch (err) {
      setUploadingFile(false)
      const apolloError = err as ApolloError
      toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao atualizar o cliente'), {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }, [customerUpdate, setUploadingFile, currentCustomer])

  const addMultipleLegalRepresentatives = async (legalRepresentatives: LegalRepresentativeType[], formData: BaseDataForm, customerArg?: CustomerModel | null) => {
    let updatedCustomer: CustomerModel | null | undefined = null
    for (let i = 0; i < legalRepresentatives.length; i++) {
      updatedCustomer = await addLegalRepresentative(legalRepresentatives[i], formData, customerArg)
    }

    return updatedCustomer
  }

  const removeMultipleLegalRepresentative = async (legalRepresentatives: LegalRepresentativeModel[], customerArg?: CustomerModel | null) => {
    let updatedCustomer: CustomerModel | null = null

    for (let i = 0; i < legalRepresentatives.length; i++) {
      const data = await removeLegalRepresentative(legalRepresentatives[i], customerArg)

      if (data) {
        updatedCustomer = data
      }
    }

    return updatedCustomer
  }

  const updateMultipleLegalRepresentatives = async (legalRepresentatives: LegalRepresentativeType[], formData: BaseDataForm, customerArg?: CustomerModel | null) => {
    let updatedCustomer: CustomerModel | null | undefined = null

    for (let i = 0; i < legalRepresentatives.length; i++) {
      updatedCustomer = await updateLegalRepresentative(legalRepresentatives[i], formData, customerArg)
    }

    return updatedCustomer
  }

  const addLegalRepresentative = async (legalRepresentative: LegalRepresentativeType, formData: BaseDataForm, customerArg?: CustomerModel | null) => {
    const { bankInfoOwner, code, agency, account, type, legalRepresentativeIdForBank } = formData
    const { customerPjRepresentativeDocumentWithPhotoBack, customerPjRepresentativeDocumentWithPhotoFront } = legalRepresentative

    if (customerArg) {
      let updatedCustomer: CustomerModel | null = null
      const filesToUpload: File[][] | null = []
      const fileTypesToUpload: ECustomerFileType[] = []

      const params: LegalRepresentativeAddGraphqlDto = {
        customerId: customerArg._id,
        name: legalRepresentative.name,
        email: legalRepresentative.email || '',
        nationality: legalRepresentative.nationality,
        phone: clearString(legalRepresentative?.phone || ''),
        document: clearString(legalRepresentative.document || ''),
        maritalStatus: (legalRepresentative.maritalStatus || '') as EMaritalStatus,
        identificationNumber: legalRepresentative?.identificationNumber || '',
        address: {
          ...legalRepresentative.address,
          zipcode: clearString(legalRepresentative?.address?.zipcode || ''),
          street: legalRepresentative?.address?.street || '',
          state: legalRepresentative?.address?.state || '',
          number: legalRepresentative?.address?.number || '',
          neighborhood: legalRepresentative?.address?.neighborhood || '',
          city: legalRepresentative?.address?.city || '',
          country: 'Brasil'
        }
      }

      const paramsWithBank = bankInfoOwner === 'legalRepresentative' && legalRepresentativeIdForBank && clearString(legalRepresentativeIdForBank) === clearString(legalRepresentative.document) ? {
        ...params,
        bank: hasAllBankData({ account, agency, code, type }) ? {
          code: code || '',
          agency: agency || '',
          account: account || '',
          type: type as EBankAccountType
        } : null
      } : params

      if (customerPjRepresentativeDocumentWithPhotoBack && !isEmpty(customerPjRepresentativeDocumentWithPhotoBack) && !customerPjRepresentativeDocumentWithPhotoBack.size) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoBack)
        fileTypesToUpload.push(ECustomerFileType.customerPjRepresentativeDocumentWithPhotoBack)
      }

      if (customerPjRepresentativeDocumentWithPhotoFront && !isEmpty(customerPjRepresentativeDocumentWithPhotoFront && !customerPjRepresentativeDocumentWithPhotoFront.size)) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoFront)
        fileTypesToUpload.push(ECustomerFileType.customerPjRepresentativeDocumentWithPhotoFront)
      }

      try {
        const { data } = await addCustomerLegalRepresentative({ variables: { params: paramsWithBank } })
        if (data && data.customerAddLegalRepresentant) {
          updatedCustomer = data.customerAddLegalRepresentant as CustomerModel
        }

        if (!isEmpty(filesToUpload)) {
          updatedCustomer = await uploadMultiCustomerFiles({
            files: filesToUpload,
            types: fileTypesToUpload,
            customerId: customerArg._id,
            legalRepresentativeDocument: clearString(legalRepresentative.document || '')
          })
        }

        return updatedCustomer
      } catch (err) {
        toast.error('Houve um erro ao adicionar o(s) representante(s) legal(is)', {
          autoClose: 3000,
          position: 'top-right'
        })
      }
    }
  }

  const removeLegalRepresentative = async (legalRepresentative: LegalRepresentativeModel, customerArg?: CustomerModel | null) => {
    try {
      const { data } = await removeCustomerLegalRepresentative({
        variables: {
          params: {
            customerId: customerArg?._id || '',
            document: clearString(legalRepresentative.document || '')
          }
        }
      })

      return data && data.customerRemoveLegalRepresentant as CustomerModel
    } catch (err) {
      toast.error('Houve um erro ao remover o(s) representante(s) legal(is)', {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const updateLegalRepresentative = async (legalRepresentative: LegalRepresentativeType, formData: BaseDataForm, customerArg?: CustomerModel | null) => {
    const { code, account, type, agency, bankInfoOwner, legalRepresentativeIdForBank } = formData
    const { customerPjRepresentativeDocumentWithPhotoBack, customerPjRepresentativeDocumentWithPhotoFront } = legalRepresentative

    if (customerArg) {
      let updatedCustomer: CustomerModel | null = null
      const filesToUpload: File[][] | null = []
      const fileTypesToUpload: ECustomerFileType[] = []

      const params: LegalRepresentativeUpdateGraphqlDto = {
        customerId: customerArg._id,
        name: legalRepresentative.name,
        email: legalRepresentative.email,
        nationality: legalRepresentative.nationality,
        phone: clearString(legalRepresentative.phone || ''),
        document: clearString(legalRepresentative.document || ''),
        maritalStatus: legalRepresentative.maritalStatus as EMaritalStatus,
        //identificationNumber: clearString(legalRepresentative.identificationNumber || ''),
        address: {
          ...legalRepresentative.address,
          zipcode: clearString(legalRepresentative?.address?.zipcode || ''),
          street: legalRepresentative?.address?.street || '',
          state: legalRepresentative?.address?.state || '',
          number: legalRepresentative?.address?.number || '',
          neighborhood: legalRepresentative?.address?.neighborhood || '',
          city: legalRepresentative?.address?.city || '',
          country: 'Brasil'
        }
      }

      const paramsWithBank = bankInfoOwner === 'legalRepresentative' && legalRepresentativeIdForBank && clearString(legalRepresentativeIdForBank) === clearString(legalRepresentative.document) ? {
        ...params,
        bank: hasAllBankData({ account, agency, code, type }) ? {
          code: code || '',
          agency: agency || '',
          account: account || '',
          type: type as EBankAccountType
        } : null
      } : params

      if (customerPjRepresentativeDocumentWithPhotoBack && !isEmpty(customerPjRepresentativeDocumentWithPhotoBack) && customerPjRepresentativeDocumentWithPhotoBack[0].size) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoBack)
        fileTypesToUpload.push(ECustomerFileType.customerPjRepresentativeDocumentWithPhotoBack)
      }

      if (customerPjRepresentativeDocumentWithPhotoFront && !isEmpty(customerPjRepresentativeDocumentWithPhotoFront) && customerPjRepresentativeDocumentWithPhotoFront[0].size) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoFront)
        fileTypesToUpload.push(ECustomerFileType.customerPjRepresentativeDocumentWithPhotoFront)
      }

      try {
        const { data } = await updateCustomerLegalRepresentative({ variables: { params: paramsWithBank } })
        if (data) {
          updatedCustomer = data.customerUpdateLegalRepresentant as CustomerModel
        }

        if (!isEmpty(filesToUpload)) {
          updatedCustomer = await uploadMultiCustomerFiles({
            files: filesToUpload,
            types: fileTypesToUpload,
            customerId: customerArg._id,
            legalRepresentativeDocument: clearString(legalRepresentative.document || '')
          })
        }

        return updatedCustomer
      } catch (err) {
        toast.error('Houve um erro ao atualizar o(s) representante(s) legal(is)', {
          autoClose: 3000,
          position: 'top-right'
        })
      }
    }
  }

  const legalRepresentativeActions = async (formData: BaseDataForm, customerArg?: CustomerModel | null) => {
    let updatedCustomer: CustomerModel | null | undefined = null

    if (customerArg) {
      const { legalRepresentativesToAdd, legalRepresentativesToRemove, legalRepresentativesToUpdate } = getLegalRepresentativesInfo(formData, customerArg)

      if (legalRepresentativesToAdd && !isEmpty(legalRepresentativesToAdd)) {
        updatedCustomer = await addMultipleLegalRepresentatives(legalRepresentativesToAdd, formData, customerArg)
      }
      if (legalRepresentativesToUpdate && !isEmpty(legalRepresentativesToUpdate)) {
        updatedCustomer = await updateMultipleLegalRepresentatives(legalRepresentativesToUpdate, formData, customerArg)
      }
      if (legalRepresentativesToRemove && !isEmpty(legalRepresentativesToRemove)) {
        updatedCustomer = await removeMultipleLegalRepresentative(legalRepresentativesToRemove, customerArg)
      }

      return updatedCustomer
    }

    return updatedCustomer
  }

  const updatePJCustomer = async (formData: BaseDataForm) => {
    if (currentCustomer) {
      const { name, document, email, phone, address, bankInfoOwner, code, agency, account, type, legalRepresentativeIdForBank } = formData

      const params: CustomerUpdateGraphqlDto = {
        name,
        email,
        phone: clearString(phone),
        document: clearString(document),
        address: {
          city: address?.city || '',
          neighborhood: address?.neighborhood || '',
          number: address?.number || '',
          state: address?.state || '',
          street: address?.street || '',
          complement: address?.complement,
          country: 'Brazil',
          zipcode: address?.zipcode ? clearString(address.zipcode) : ''
        }
      }

      const bankParams: BankGraphqlDto | null = hasAllBankData({ account, agency, code, type }) ? {
        code: code || '',
        agency: agency || '',
        account: account || '',
        type: type as EBankAccountType
      } : null

      try {
        setUploadingFile(true)
        const { data } = await customerUpdate({ variables: { id: formData.customerId || currentCustomer._id || '', params } })

        await uploadCustomerFiles(currentCustomer, formData)
        const updatedCustomer = await legalRepresentativeActions(formData, currentCustomer)

        setUploadingFile(false)

        if (updatedCustomer && !isEmpty(updatedCustomer)) {
          setCurrentCustomer(updatedCustomer)
        } else if (data?.customerUpdateById) {
          setCurrentCustomer(data.customerUpdateById as CustomerModel)
        }

        if (!currentCustomer?.bank && bankParams && currentCustomer?._id) {

          const createDigitalAccountParams: CustomerCreateDigitalAccountGraphqlDto = {
            accountOwner: EPersonType.pj,
            customerId: currentCustomer._id,
            bank: bankParams
          }

          const paramsWithLegalRepresentative: CustomerCreateDigitalAccountGraphqlDto = bankInfoOwner === 'legalRepresentative' && legalRepresentativeIdForBank ? {
            ...createDigitalAccountParams,
            legalRepresentativeDocument: clearString(legalRepresentativeIdForBank)
          } : createDigitalAccountParams

          const updatedCustomerWithBank = await customerCreateDigitalAccount({
            variables: {
              params: paramsWithLegalRepresentative
            },
          })

          setCurrentCustomer(updatedCustomerWithBank.data?.customerCreateDigitalAccount as CustomerModel)
        }

        toast.success('Cliente atualizado com sucesso!')
      } catch (err) {
        setUploadingFile(false)
        const apolloError = err as ApolloError
        toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, apolloError.message || 'Houve um problema ao atualizar o cliente'), {
          autoClose: 3000,
          position: 'top-right'
        })
      }
    }
  }

  const updateCustomer = useCallback(async (formData: BaseDataForm) => {
    if (formData.personType === ECustomerPersonType.pf) {
      return updatePFCustomer(formData)
    } else {
      return updatePJCustomer(formData)
    }
  }, [updatePFCustomer, updatePJCustomer])

  const createContract = async (formData: BaseDataForm) => {
    if (formData.customerId) {
      try {
        const { data } = await contractCreate({
          variables: {
            params: {
              type: EContractType.desperta,
              customerId: formData.customerId
            }
          }
        })

        data && data.contractCreate && navigate(`/app/contracts/${data.contractCreate._id}`)
      } catch (err) {
        toast.error('Houve um problema ao criar o contrato')
      }

      return
    }

    toast.error('Houve um problema ao criar o contrato')
  }

  const onSubmit = useCallback(async (formData: BaseDataForm) => {
    await updateCustomer(formData)

    if (!contract) {
      await createContract(formData)
    }
  }, [updateCustomer, contract])

  const getCustomer = async (id: string) => {
    const { data } = await customerGet({
      variables: { id }
    })

    data && data.customerGet && setCurrentCustomer(data.customerGet as CustomerModel)
  }

  useEffect(() => {
    if (contract?.customerId) {
      getCustomer(contract.customerId)
    }

    getCustomerList()
  }, [contract])

  const onRemove = useCallback(async (index: number) => {
    if (legalRepresentativiesFromWatch) {
      const isConfirmed = await confirmDialog({
        title: 'Remoção de representante legal',
        type: 'warning',
        content: <Box>
          <p>Esta ação irá remover o representante legal <b>{`${legalRepresentativiesFromWatch[index].name}`}</b> do contrato.</p>
          <p>Para salvar a informação de exclusão de um representante legal, você deverá clicar em {'"Gravar dados"'} ou {'"Continuar"'} no rodapé da página após esta ação.</p>
        </Box>
      })

      if (isConfirmed) {
        remove(index)
      }
    }
  }, [legalRepresentativiesFromWatch])

  if (customerGetLoading || customersLoading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' flex='1'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <FormProvider {...methods}>
      <Form>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
          {isCancelledOrDeactivated && <DeactivationWarning />}

          {contract?.proposalId && <ContractInfo shouldShowCustomerName={false} contract={contract} />}

          <Typography variant='h3' fontWeight={500}>Tipo de pessoa:</Typography>
          <Radio disabled={Boolean(contract || currentCustomer)} row name='personType' options={personTypeOptions} />

          {!contract?.customerId && <CustomerSelection loading={customersLoading} customersOptions={customersOptions} />}

          <BaseDataFields disableFields={disableFields} />

          <AddressFields disableFields={disableFields} />

          {!isPf && (
            <>
              {fields?.map((legalRepresentative, index) => (
                <LegalRepresentativeFields
                  key={legalRepresentative.id}
                  index={index}
                  loading={false}
                  customer={currentCustomer}
                  fieldsLength={fields.length}
                  disableFields={disableFields}
                  removeLegalRepresentative={() => onRemove(index)}
                  legalRepresentative={legalRepresentative as any}
                />
              ))}

              <Button
                color='secondary'
                disabled={disableFields}
                startIcon={<Add />}
                style={{ alignSelf: 'start' }}
                onClick={addNewLegalRepresentative}
              >
                Adicionar representante
              </Button>
            </>
          )}

          <DocumentationFields currentCustomer={currentCustomer} disableFields={disableFields} personType={personTypeWatch} />

          <BankFields disableFields={disableFields || disableBankFields} />

          {contract && <ContractAnnotations contract={contract} />}

          {isCancelledOrDeactivated && contract?.canceledReason && (
            <>
              <Typography variant='h3' fontWeight={500} marginTop={2}>Motivo do cancelamento</Typography>

              <Input
                multiline
                minRows={5}
                name='cancellationReason'
                disabled
                label={contract?.canceledReason}
              />
            </>
          )}

          <ActionButtons onSubmit={methods.handleSubmit(onSubmit)} loading={isLoading} {...props} />
          <CreatingProposalDialog isVisible={false} />
        </Box>
      </Form>
    </FormProvider>
  )
}
