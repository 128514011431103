import { Box, Typography } from '@mui/material'
import { IconCheck, IconPercentage } from '@tabler/icons-react'
import { useCustomerContract } from '~/contexts'
import { ContractConfigContainer, IconContainer, IconTitleContainer, TitleDescriptionContainer } from '../../styles'
import { displayPercentage } from '~/utils'

export const CashbackInfo: React.FC = () => {
  const { contract } = useCustomerContract()

  return (
    <>
      <ContractConfigContainer>
        <IconTitleContainer>
          <IconContainer>
            <IconPercentage />
          </IconContainer>

          <TitleDescriptionContainer>
            <Typography>Percentual (desconto ou cashback)</Typography>
            <Typography color='grey.400'>Percentual de cashback a qual o cliente tem direito</Typography>
          </TitleDescriptionContainer>
        </IconTitleContainer>

        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <IconCheck size='20px' color='#29CC6A' />
          <Typography>{displayPercentage(contract?.reward?.percent || 0)}</Typography>
        </Box>
      </ContractConfigContainer>
    </>
  )
}
