import * as yup from 'yup'
import { toast } from 'react-toastify'
import { Info } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, CircularProgress, Divider, Typography, useMediaQuery } from '@mui/material'
import { Dialog } from '~/components/Dialog'
import { Form } from '~/components/Form/Form'
import { Input } from '~/components/Form/Input'
import { useProposalContext } from '~/contexts'
import { Button } from '~/components/Form/Button'
import { ELossReason, ProposalModel, useProposalCancelMutation } from '~/graphql/types'
import { theme } from '~/utils'
import { Select } from '~/components/Form/Select'
import { lossReasonOptions } from '~/utils/options'
import { useCallback } from 'react'

type ProposalCancelForm = {
  reason: string
  lossReason: ELossReason
}

const schema = yup.object().shape({
  lossReason: yup.string().required('Campo obrigatório'),
  reason: yup.string().required('Campo obrigatório')
})

export type ProposalCancelDialogProps = {
  isVisible: boolean
  setIsVisibleProposalCancelModal: React.Dispatch<React.SetStateAction<boolean>>
}
export const ProposalCancelDialog: React.FC<ProposalCancelDialogProps> = ({ isVisible, setIsVisibleProposalCancelModal }) => {
  const { proposal, setProposal } = useProposalContext()
  const [cancelProposal, { loading }] = useProposalCancelMutation()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const methods = useForm<ProposalCancelForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      lossReason: '' as ELossReason
    }
  })

  const onCloseModal = useCallback(() => {
    setIsVisibleProposalCancelModal(false)
    methods.reset()
  }, [])

  const onSubmit = async (formData: ProposalCancelForm) => {
    if (!proposal) {
      return toast.error('Houve um problema ao cancelar a proposta', {
        autoClose: 3000,
        position: 'top-right'
      })
    }

    try {
      const { data } = await cancelProposal({
        variables: {
          params: {
            proposalId: proposal._id,
            reason: formData.reason,
            lossReason: formData.lossReason
          }
        }
      })

      data?.cancelProposal && setProposal(data.cancelProposal as ProposalModel)
      onCloseModal()
      toast.success('Proposta cancelada com sucesso!', {
        autoClose: 3000,
        position: 'top-right'
      })
    } catch (err) {
      toast.error('Houve um problema ao cancelar a proposta', {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  return (
    <Dialog
      isVisible={isVisible}
      title='Cancelar proposta'
      onClose={onCloseModal}
      minWidth={700}
      titleIcon={<Info fontSize='large' color='error' />}
    >
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box sx={{ flexDirection: 'column', display: 'flex', gap: 3 }}>
            <Divider />
            
            <Typography>Informe o motivo da recusa da proposta:</Typography>
            <Select label='Motivo' name='lossReason' options={lossReasonOptions} />
            <Input minRows={10} multiline name='reason' placeholder='Escreva aqui suas observações' />

            <Typography>Ao cancelar a proposta, não será possível dar continuidade a mesma proposta</Typography>

            <Divider />

            <Box sx={{ flexDirection: 'row', gap: 3, display: 'flex', alignSelf: isLowerSm ? 'auto' : 'end', flexFlow: isLowerSm ? 'column' : 'row' }}>
              <Button
                color='secondary'
                disabled={loading}
                onClick={onCloseModal}
              >
                Voltar para proposta
              </Button>

              <Button
                type='submit'
                color='error'
                disabled={loading}
                endIcon={loading ? <CircularProgress size={24} color='inherit' /> : null}
              >
                Cancelar proposta
              </Button>
            </Box>
          </Box>
        </Form>
      </FormProvider>
    </Dialog>
  )
}
