import { Button, Typography } from '@mui/material'
import { IconUpload } from '@tabler/icons-react'
import { PictureLogoType } from '../..'
import { Container } from './styles'
import { theme } from '~/utils'

type NoSelectInputFileProps = React.InputHTMLAttributes<HTMLInputElement> & {
  // eslint-disable-next-line no-unused-vars
  onSelectFile:(e: React.ChangeEvent<HTMLInputElement>)=> void
  typeLogo: PictureLogoType
  regularLogo: string
  reducedLogo: string
}

// eslint-disable-next-line no-unused-vars
const typesLogo: { [x in PictureLogoType]: {
  buttonText: string
  id: string
  title: string
} } = {
  defaultLogo: {
    id:'default-logo-upload',
    buttonText: 'Inserir marca regular',
    title: 'Marca regular',
  },
  reducedLogo: {
    id:'reduced-logo-upload',
    buttonText: 'Inserir marca reduzida',
    title: 'Marca reduzida'
  },
}

export const NoSelectInputFile: React.FC<NoSelectInputFileProps> = ({ typeLogo, reducedLogo, regularLogo, onSelectFile, ...rest }) => {

  const selectedType = typesLogo[typeLogo]

  return (
    <>
      <Typography sx={{ fontWeight: 500, fontSize: '1rem', color: theme.palette.grey[800], paddingBottom: '.4rem' }}>{selectedType.title}</Typography>
      <Container>
        {/* url */}
        <img src={typeLogo === 'defaultLogo' ? regularLogo : reducedLogo} alt='logo' />
        <strong>Insira aqui a marca da sua empresa</strong>
        <span>(JPG, JPEG, PNG, Tamanho máximo 1MB)</span>
        <input id={selectedType.id} onChange={onSelectFile} type='file' {...rest} style={{ display: 'none' }} />
        <label htmlFor={selectedType.id}>
          <Button
            variant='contained'
            component='span'
            size='medium'
            color='primary'
          >
            {selectedType.buttonText} <IconUpload size={20} style={{ marginLeft: '.4rem' }} />
          </Button>
        </label>
      </Container>
    </>
  )
}
