/* eslint-disable no-unused-vars */
import { ArrowBack, ArrowForward, Save } from '@mui/icons-material'
import { Box, CircularProgress, Divider, useMediaQuery } from '@mui/material'
import { IconDeviceFloppy, IconPencil, IconX } from '@tabler/icons-react'
import { useMemo } from 'react'
import { ContractFlowActionDropdown, PropsWithWizard } from '~/components'
import { Button } from '~/components/Form/Button'
import { useContract } from '~/contexts'
import { EContractStatus } from '~/graphql/types'
import { usePermission } from '~/hooks'
import { theme } from '~/utils'

type ActionButtonsProps = {
  toggleModal: () => void
  loading: boolean
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}
export const ActionButtons: React.FC<PropsWithWizard<ActionButtonsProps>> = (props) => {
  const { onNext, onPrev, loading, onSubmit, toggleModal } = props
  const { contract, isDisabledContractUpdate, isUpdatingContract, setIsDisabledContractUpdate, setIsUpdatingContract } = useContract()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const isDeactivated = contract?.status === EContractStatus.deactivated
  const isDeactivating = contract?.status === EContractStatus.inDeactivation
  const isCancelling = contract?.status === EContractStatus.cancellationRequested
  const canCreateEditContracts = usePermission(['contracts.updated', 'contracts.create'])

  const shouldDisableButtons = useMemo(() => isDeactivated || isDeactivating || isCancelling || loading || !canCreateEditContracts, [isDeactivated, isDeactivating, isCancelling, loading, canCreateEditContracts])

  const handleSubmit = async (submitType: 'continue' | 'save') => {
    await onSubmit()

    if (submitType === 'continue') {
      onNext()
    }
  }

  return (
    <>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }} >
        {!isLowerMd && (
          <Button disabled={loading} startIcon={<ArrowBack />} color='secondary' onClick={onPrev}>
            Voltar
          </Button>
        )}

        <Box sx={{ display: 'flex', flexDirection: isLowerSm ? 'column' : 'row', gap: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: isLowerSm ? 'space-between' : 'flex-end' }}>
            <ContractFlowActionDropdown disabled={shouldDisableButtons} />

            {isDisabledContractUpdate ? (
              <>
                <Button
                  onClick={() => (setIsDisabledContractUpdate(false), setIsUpdatingContract(true))}
                  size='small'
                  color='secondary'
                  startIcon={<IconPencil />}>
                  Editar dados
                </Button>
              </>
            ) : (
              <>
                {isUpdatingContract ? (
                  <>
                    <Button
                      onClick={() => (setIsDisabledContractUpdate(true), setIsUpdatingContract(false))}
                      startIcon={<IconX />}
                      color='secondary'
                      size='small'
                    >
                      Cancelar edição
                    </Button>
                    <Button
                      startIcon={loading ? <CircularProgress size={24} color='inherit' /> : <IconDeviceFloppy />}
                      onClick={() => handleSubmit('save')}
                    >
                      Gravar dados
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => handleSubmit('save')}
                      disabled={shouldDisableButtons}
                      startIcon={<Save />}
                      color='secondary'
                      size='small'
                    >
                      Gravar dados
                    </Button>

                    <Button
                      disabled={shouldDisableButtons}
                      endIcon={<ArrowForward />}
                      startIcon={loading ? <CircularProgress size={24} color='inherit' /> : null}
                      onClick={() => handleSubmit('continue')}
                    >
                      Continuar
                    </Button>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
