import { Check, ErrorOutline } from '@mui/icons-material'
import { Box, Step, StepButton, StepIconProps, StepLabel, Stepper, Typography, useMediaQuery } from '@mui/material'
import styled from '@emotion/styled'
import { CustomIconContainer } from './style'
import { PropsWithWizard } from '~/components'
import { ECustomerPersonType, ProposalModel } from '~/graphql/types'
import { useMemo } from 'react'
import { hasStepPendency, isStepCompleted, shouldDisableStep } from '../../utils'
import { theme } from '~/utils'

const StyledStepper = styled(Stepper)`
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 1rem 0 2rem 0;
  margin-bottom: 20px;

  &.MuiStepLabel-label {
    font-size: 16px;
    font-weight: 500;
    color: #CDCDCD;
  };
  &.MuiStepLabel-label.Mui-active {
    font-size: 16px;
    font-weight: 600;
    color: #434343;
  };
  &.MuiStepLabel-label.Mui-completed:not(&.Mui-active) {
    font-size: 16px;
    color: #CDCDCD
  }

  @media(max-width: 1000px) {
    overflow-x: auto;
    max-width: calc(100vw - 180px);
    
    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px ${(props) => props.theme.palette.grey[300]};
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.palette.grey[500]};
      border-radius: 10px;
    }
  }

  @media(max-width: ${theme.breakpoints.values.md}px) {
    overflow-x: auto;
    max-width: calc(100vw - 130px);
  }

  @media(max-width: ${theme.breakpoints.values.sm}px) {
    padding: 0;
    &::-webkit-scrollbar {
      height: 6px;
    }
  }
`

const CustomStepIcon = (props: StepIconProps) => {
  return (
    <CustomIconContainer active={!!props.active} completed={!!props.completed} error={props.error}>
      {props.error && <ErrorOutline color='error' />}
      {!props.error && props.completed && <Check fontSize='small' sx={{ color: '#3BA1FF' }} />}
      {!props.error && !props.completed && <Typography color={props.active ? 'white.main' : '#CDCDCD'}>{props.icon}</Typography>}
    </CustomIconContainer>
  )
}

export type ProposalStepperProps = {
  proposal: ProposalModel | null
}
export const ProposalStepper: React.FC<PropsWithWizard<ProposalStepperProps>> = (props) => {
  const { currentPage, goToPage, proposal } = props
  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))

  const steps = useMemo(() => [
    'Dados básicos',
    'Endereço',
    proposal?.customerRef.personType === ECustomerPersonType.pj && 'Representante',
    'Documentos',
    'Formalização'
  ].filter(step => !!step) as string[], [proposal])

  return (
    <Box>
      <StyledStepper
        nonLinear
        activeStep={currentPage}
      >
        {steps.map((label, index) => {
          const pendency = hasStepPendency(label, proposal)
          return (
            <Step completed={isStepCompleted(label, proposal)} key={label}>
              <StepButton disabled={shouldDisableStep(label, proposal)} onClick={() => goToPage(index)}>
                <StepLabel
                  error={pendency}
                  StepIconComponent={CustomStepIcon}
                >
                  <Typography sx={{ whiteSpace: 'nowrap' }} fontSize={isLowerLg ? '.85rem' : '1rem'}>
                    {label}
                  </Typography>
                  {pendency && <Typography color='error' variant='body3'>Ação necessária</Typography>}
                </StepLabel>
              </StepButton>
            </Step>
          )
        })}
      </StyledStepper>
    </Box>
  )
}
