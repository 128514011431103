import * as yup from 'yup'
import { clearString } from '~/utils'
import { bankAccountSchema, bankAgencySchema, documentSchema, personTypeSchema, phoneSchema } from '~/utils/yupSchema'
import { CustomerModel, EBankAccountType, EContractPersonType, ECustomerPersonType, EMaritalStatus } from '~/graphql/types'
import { isPhoneValid } from '~/utils/phoneValidator'
import { isEmpty } from 'ramda'

export type AddressForm = {
  city: string
  state: string
  street: string
  number: string
  zipcode: string
  complement: string
  neighborhood: string
}
 
export type LegalRepresentativeType = {
  //strings
  name: string
  email: string
  phone: string
  document: string
  nationality: string
  address: AddressForm
  maritalStatus: string
  identificationNumber: string

  //files
  customerPjRepresentativeDocumentWithPhotoBack?: any
  customerPjRepresentativeDocumentWithPhotoFront?: any
}

export type BaseDataForm = {
  //common
  name: string
  email: string
  phone: string
  document: string
  customerId?: string
  address: AddressForm
  personType: ECustomerPersonType

  //pf
  nationality?: string
  maritalStatus?: EMaritalStatus
  customerPfDocumentWithPhotoBack?: any
  customerPfDocumentWithPhotoFront?: any

  //pj
  customerPjDocumentCard?: any
  customerPjSocialContract?: any
  legalRepresentatives?: LegalRepresentativeType[]

  bankInfoOwner?: string
  code?: string
  agency?: string
  account?: string
  type?: EBankAccountType
  legalRepresentativeIdForBank?: string
}

export const getInitialValues = (customer?: CustomerModel | null): BaseDataForm => {
  return {
    name: customer?.name || '',
    document: customer?.name || '',
    phone: customer?.phone || '',
    email: customer?.email || '',
    nationality: customer?.nationality || undefined,
    personType: customer?.personType as ECustomerPersonType || ECustomerPersonType.pf,
    maritalStatus: customer?.maritalStatus as EMaritalStatus || '',
    address: {
      city: customer?.address?.city || '',
      state: customer?.address?.state || '',
      street: customer?.address?.street || '',
      number: customer?.address?.number || '',
      zipcode: customer?.address?.zipcode || '',
      neighborhood: customer?.address?.neighborhood || '',
      complement: customer?.address?.complement || ''
    },
    customerPfDocumentWithPhotoBack: undefined,
    customerPfDocumentWithPhotoFront: undefined,
    customerPjDocumentCard: undefined,
    customerPjSocialContract: undefined
  }
}

export const getLegalRepresentativesInfo = (formData: BaseDataForm, customer: CustomerModel) => {
  const { legalRepresentatives } = formData
  const { legalRepresentatives: legalRepresentativesFromCustomer } = customer

  const registeredRepresentativeDocuments = legalRepresentativesFromCustomer?.map(representative => clearString(representative.document || ''))
  const legalRepresentativeDocumentsFromForm = legalRepresentatives?.map(representative => clearString(representative.document || ''))

  const legalRepresentativesToAdd = legalRepresentatives?.filter(formRepresentative => !registeredRepresentativeDocuments?.includes(clearString(formRepresentative.document || '')))
  const legalRepresentativesToUpdate = legalRepresentatives?.filter(formRepresentative => registeredRepresentativeDocuments?.includes(clearString(formRepresentative.document || '')))

  const legalRepresentativesToRemoveDocuments = registeredRepresentativeDocuments?.filter(document => !legalRepresentativeDocumentsFromForm?.includes(clearString(document)))
  const legalRepresentativesToRemove = legalRepresentativesFromCustomer?.filter(formRepresentative => legalRepresentativesToRemoveDocuments?.includes(clearString(formRepresentative.document || '')))

  return {
    legalRepresentativesToAdd,
    legalRepresentativesToUpdate,
    legalRepresentativesToRemove: legalRepresentativesToRemove || []
  }
}

export const schema = yup.object().shape({
  phone: phoneSchema,
  document: documentSchema,
  personType: personTypeSchema,
  email: yup.string().required('Email é obrigatório').email('Email inválido'),
  name: yup.string().required('Nome completo é obrigatório').min(3, 'Nome deve possuir no mínimo 3 dígitos'),
  maritalStatus: yup.string().optional(),
  nationality: yup.string().optional(),
  // bank: bankSchema,
  code: yup.string(),
  type: yup.string(),
  agency: bankAgencySchema,
  account: bankAccountSchema,
  address: yup.object().shape({
    complement: yup.string().nullable().optional(),
    state: yup.string().optional(),
    city: yup.string().optional(),
    number: yup.string().optional(),
    neighborhood: yup.string().optional(),
    street: yup.string().when({
      is: (exists: any) => !!exists && exists.length > 0,
      then: (validationSchema) => validationSchema.min(5, 'Rua deve ter pelo menos 5 caracteres'),
      otherwise: (validationSchema) => validationSchema.optional()
    }),
    zipcode: yup.string().when({
      is: (exists: any) => !!exists && exists.length > 0,
      then: (validationSchema) => validationSchema.test('Length', 'CEP válido necessita ter 8 digitos', (value: any) => clearString(value).length === 8 ? true : false),
      otherwise: (validationSchema) => validationSchema.optional()
    })
  }).nullable(),
  legalRepresentatives: yup.array().when('personType', {
    is: (value: EContractPersonType) => value === EContractPersonType.pj,
    then: (validation) => validation.of(yup.object().shape({
      phone: yup.string()
        .test('Phone length', 'Celular deve ter 11 dígitos', phone => phone ? clearString(phone).length === 11 || clearString(phone).length === 10 : false)
        .test(
          'isPhoneValid',
          'Telefone inválido',
          phone => {
            return phone ? isPhoneValid(clearString(phone)) : false
          }
        ),
      address: yup.object().shape({
        complement: yup.string().nullable().optional(),
        state: yup.string().required(),
        city: yup.string().required(),
        number: yup.string().required(),
        neighborhood: yup.string().required(),
        street: yup.string().required(),
        zipcode: yup.string().test('Length', 'CEP válido necessita ter 8 digitos', (value: any) => clearString(value).length === 8 ? true : false).required()
      }),
      maritalStatus: yup.string().required(),
      name: yup.string().required(),
      document: yup.string().required(),
      identificationNumber: yup.string().optional(),
      nationality: yup.string(),
      email: yup.string().email('Email inválido').required()
    })),
    otherwise: (validation) => validation.optional()
  })
})

export const LEGAL_REPRESENTATIVE_INITIAL_VALUES: LegalRepresentativeType = {
  name: '',
  email: '',
  phone: '',
  document: '',
  nationality: '',
  maritalStatus: '' as EMaritalStatus,
  identificationNumber: '',
  address: {
    city: '',
    state: '',
    number: '',
    street: '',
    zipcode: '',
    complement: '',
    neighborhood: ''
  },
  customerPjRepresentativeDocumentWithPhotoBack: undefined,
  customerPjRepresentativeDocumentWithPhotoFront: undefined
}

export const hasAllBankData = (bank?: { account?: string, agency?: string, code?: string, type?: string } | null) => {
  if (!bank) return false

  const { account, agency, code, type } = bank

  return Boolean(
    !isEmpty(type) &&
    !isEmpty(code) &&
    !isEmpty(agency) &&
    !isEmpty(account)
  )
}
