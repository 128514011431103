/* eslint-disable no-nested-ternary */
import { toast } from 'react-toastify'
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material'
import { ContentCopy, Share } from '@mui/icons-material'
import { formatDate, theme, trimString } from '~/utils'
import { copyToClipboard } from '~/utils/clipboard'
import { ManualTermOptions, TermOfManagementOptions } from './components'
import { ManagementTermStatus } from './components/ManagementTermStatus'
import { ContractTermOfManagementModel, EStorageService, ETermOfManagementType, ETermStatus, useContractUpdateManuallyTermManagementMutation } from '~/graphql/types'
import { IconFileText, IconUpload, IconWriting } from '@tabler/icons-react'
import { RoundedIcon } from '../RoundedIcon'
import { useCallback } from 'react'
import { uploadToS3 } from '~/services/uploadFile'
import { useContract } from '~/contexts'

const getCardBorder = (hasSigned: boolean, status?: ETermStatus) => {

  if (status === ETermStatus.refused) return theme.palette.error.main

  if (hasSigned) return '#00A86B'

  if (status === ETermStatus.expired) return '#CDCDCD'

  if (status === ETermStatus.uploaded) return theme.palette.info.dark

  if (status === ETermStatus.requestedSignature) return theme.palette.primary.main

  if (status) return '#8989890D'

  return theme.palette.primary.main
}

export type ContractManagementTermCardProps = {
  term?: ContractTermOfManagementModel | null
}
export const ContractManagementTermCard: React.FC<ContractManagementTermCardProps> = ({ term }) => {
  const { contract, setContract } = useContract()

  const isManualManagement = term?.type === ETermOfManagementType.manual

  const hasSigned = term?.status === ETermStatus.signed
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('lg'))

  const [updateManuallyTermManagement] = useContractUpdateManuallyTermManagementMutation({
    onCompleted(data) {
      if (contract) {
        toast.success('Termo de gestão manual adicionado!')
        setContract({ ...contract, termOfManagement: data.contractUpdateManuallyTermManagement?.termOfManagement })
      }
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const onCopyToClipboard = (link?: string | null) => {
    copyToClipboard(link)
    toast.success('Link copiado!', {
      position: 'top-right',
      autoClose: 3000
    })
  }

  const onSelectFile = useCallback(async (file: File) => {
    try {
      const response = await uploadToS3([file])

      updateManuallyTermManagement({
        variables: {
          params: {
            contractId: contract?._id || '',
            file: {
              fileName: response.fileName,
              fileUrl: response.fileUrl,
              key: response.key,
              storageService: EStorageService.awsS3
            }
          }
        }
      })

    } catch (err) {
      toast.error('Houve um problema com um dos arquivos enviados')
    }

  }, [])

  console.log(term)

  return (
    <Box>

      <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', gap: '1rem', justifyContent: 'space-between', paddingBottom: '1rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
        <Typography fontWeight={500} variant='h3'>Termo de gestão</Typography>
        <input disabled={hasSigned} onChange={e => e.target.files && onSelectFile(e.target.files[0])} accept='application/pdf' id='manualTerm' type='file' style={{ display: 'none' }} />
        <label htmlFor='manualTerm'>
          <Button
            variant={hasSigned ? 'outlined' : 'contained'}
            component='span'
            size='medium'
            color='secondary'
            disabled={hasSigned}
          >
            <IconUpload
              size={20}
              style={{ marginRight: '.4rem' }}
            />
            Upload manual de termo
          </Button>
        </label>
      </Box>

      <Box sx={{
        gap: 3,
        padding: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #E6E6E6',
        borderLeft: `6px solid ${getCardBorder(hasSigned, term?.status)}`
      }}>
        {!isLowerSm && (
          <RoundedIcon>
            <IconWriting color={theme.palette.grey[400]} />
          </RoundedIcon>
        )}

        <Box sx={{
          gap: 3,
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column'
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Box sx={{
              gap: isLowerSm ? 1 : 3,
              display: 'flex',
              alignItems: isLowerSm ? 'start' : 'center',
              flexDirection: isLowerSm ? 'column' : 'row'
            }}>
              {isManualManagement ?
                <Typography>Termo manual</Typography>
                :
                <Typography>Assinatura do cliente</Typography>
              }
              <ManagementTermStatus status={term?.status || ETermStatus.created} />
              {isManualManagement ? (
                <>
                  {term?.status === ETermStatus.uploaded &&
                    <Typography color='#999999'>Aguardando aprovação pelo jurídico</Typography>
                  }
                </>
              )
                : (
                  <Typography color='#999999'>
                    {term?.status === ETermStatus.expired
                      ? 'Gere um novo termo de gestão'
                      : hasSigned
                        ? 'Assinado pelo cliente'
                        : 'Aguardando cliente assinar'}
                  </Typography>
                )}
            </Box>

            {!isManualManagement && (term?.status === ETermStatus.signed || term?.status === ETermStatus.expired) && <TermOfManagementOptions term={term} />}
            {isManualManagement && <ManualTermOptions term={term} />}
          </Box>

          {isManualManagement && (
            <>
              <Box sx={{ display: 'flex', gap: '2rem', flexFlow: isLowerMd ? 'column' : 'row' }}>
                <Box display='flex' flexDirection={isLowerSm ? 'column' : 'row'} gap={isLowerSm ? 0 : 1}>
                  <Typography display='inline-block'>
                    • Upload feito por:
                  </Typography>
                  <Typography color='#999999'>
                    {term.signerEmail}
                  </Typography>
                </Box>
                <Box display='flex' flexDirection={isLowerSm ? 'column' : 'row'} gap={isLowerSm ? 0 : 1}>
                  <Typography display='inline-block'>
                    • Data de upload:
                  </Typography>
                  <Typography color='#999999'>
                    {formatDate(term.createdAt, { dateHour: true })}
                  </Typography>
                </Box>
              </Box>

              {term.status === ETermStatus.refused && (
                <Box sx={{ display: 'flex', gap: '2rem', flexFlow: isLowerMd ? 'column' : 'row' }}>
                  <Box display='flex' flexDirection={isLowerSm ? 'column' : 'row'} gap={isLowerSm ? 0 : 1}>
                    <Typography display='inline-block'>
                      • Recusado por:
                    </Typography>
                    <Typography color='#999999'>
                      {term.signerEmail}
                    </Typography>
                  </Box>
                  <Box display='flex' flexDirection={isLowerSm ? 'column' : 'row'} gap={isLowerSm ? 0 : 1}>
                    <Typography display='inline-block'>
                      • Data de recusa:
                    </Typography>
                    <Typography color='#999999'>
                      {term.signedAt && formatDate(term.signedAt, { dateHour: true })}
                    </Typography>
                  </Box>
                </Box>
              )}

              {term.status === ETermStatus.signed && (
                <Box sx={{ display: 'flex', gap: '2rem', flexFlow: isLowerMd ? 'column' : 'row' }}>
                  <Box display='flex' flexDirection={isLowerSm ? 'column' : 'row'} gap={isLowerSm ? 0 : 1}>
                    <Typography display='inline-block'>
                      • Aprovado por:
                    </Typography>
                    <Typography color='#999999'>
                      {term.signerEmail}
                    </Typography>
                  </Box>
                  <Box display='flex' flexDirection={isLowerSm ? 'column' : 'row'} gap={isLowerSm ? 0 : 1}>
                    <Typography display='inline-block'>
                      • Data de aprovação:
                    </Typography>
                    <Typography color='#999999'>
                      {term.signedAt && formatDate(term.signedAt)}
                    </Typography>
                  </Box>
                </Box>
              )}

              <Box sx={{
                background: theme.palette.grey[100],
                padding: '1rem',
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '.6rem',
                display: 'flex',
                gap: '1rem'
              }}>
                <IconFileText color={theme.palette.grey[600]} />
                <Typography color={theme.palette.grey[600]}>Arquivo anexado</Typography>
                <Divider orientation='vertical' />
                <Typography color={theme.palette.grey[600]}>{term.file?.fileName}</Typography>

              </Box>
            </>
          )}

          {!isManualManagement && (
            <>
              <Box display='flex' flexDirection={isLowerSm ? 'column' : 'row'} gap={isLowerSm ? 0 : 1}>
                <Typography display='inline-block'>
                  • Assinante:
                </Typography>
                <Typography color='#999999'>
                  {term?.signerEmail}
                </Typography>
              </Box>

              {term?.signatureLink && (
                <Box gap={2} display='flex' flexDirection='column'>
                  <Box flexDirection='row' display='flex' alignItems='center' gap={1}>
                    <Typography fontWeight={600}>Compartilhar</Typography>
                    <Share color='action' />
                  </Box>

                  <Typography variant='body2' color='#999999'>Link para termo de gestão (Copie e cole onde desejar):</Typography>
                  <Box
                    onClick={() => term?.status !== ETermStatus.expired && onCopyToClipboard(term?.signatureLink)}
                    sx={{
                      display: 'flex',
                      padding: 2,
                      borderRadius: '8px',
                      border: '1px solid #E6E6E6',
                      justifyContent: 'space-between',
                      cursor: term?.status === ETermStatus.expired ? 'not-allowed' : 'pointer',
                      userSelect: term?.status === ETermStatus.expired ? 'none' : 'auto',
                      alignItems: 'center'
                    }}>
                    <Typography variant='body2' color={term?.status === ETermStatus.expired ? '#CDCDCD' : '#000000DE'}>
                      {trimString(term.signatureLink)}
                    </Typography>

                    <ContentCopy color={term?.status === ETermStatus.expired ? 'disabled' : 'action'} fontSize='small' />
                  </Box>
                </Box>
              )}
            </>
          )}
          {isManualManagement && term.status === ETermStatus.refused && (
            <>
              <Divider />
              <Box>
                <Typography color={theme.palette.grey[800]}>• Observações:</Typography>
                <Typography sx={{ padding: '.4rem 0 0 .5rem', fontWeight: 500 }} color='#999999'>{term?.refusedReason}</Typography>
              </Box>
            </>
          )}

        </Box>
      </Box >
    </Box>
  )
}
