/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom'
import { Save } from '@mui/icons-material'
import { Box, CircularProgress, Divider, useMediaQuery } from '@mui/material'
import { useCustomerFlow } from '~/contexts'
import { Button } from '~/components/Form/Button'
import { theme } from '~/utils'

export type ActionButtonsProps = {
  loading: boolean
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ loading, onSubmit }) => {
  const navigate = useNavigate()
  const { customer } = useCustomerFlow()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const onPrevClick = () => {
    navigate('/app/customers')
  }

  return (
    <>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <Box sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
        flexFlow: isLowerSm ? 'column-reverse' : 'row',
        gap: isLowerSm ? '1rem' : '0'
      }}
      >
        <Button
          disabled={loading}
          color='secondary'
          onClick={onPrevClick}
        >
          Cancelar
        </Button>

        <Button
          onClick={onSubmit}
          endIcon={loading ? <CircularProgress size='24px' color='inherit' /> : <Save />}
          disabled={loading}
        >
          {customer ? 'Gravar dados' : 'Cadastrar cliente'}
        </Button>
      </Box>
    </>
  )
}
