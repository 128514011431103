import { Typography } from '@mui/material'
import { Card } from '~/components'

export const Settings: React.FC = () => {
  return (
    <Card>
      <Typography variant='h3' fontWeight={600}>Configurações</Typography>
    </Card>
  )
}
