import { Box, Typography, useMediaQuery } from '@mui/material'
import { IconWallet } from '@tabler/icons-react'
import { SingleAccountStatus } from '~/components'
import { EDigitalAccountStatus, theme } from '~/utils'
import { RoundedIcon } from '../RoundedIcon'
import { useContract } from '~/contexts'
import { useCustomerGetQuery } from '~/graphql/types'
import { useEffect } from 'react'

export const SingleAccountCard: React.FC = () => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const { contract } = useContract()
  const { data, refetch } = useCustomerGetQuery({
    variables: {
      id: contract?.customerId || ''
    }
  })

  useEffect(() => {
    if(contract?.customerId) {
      refetch()
    }
  }, [contract])

  const textByStatuys = {
    [EDigitalAccountStatus.inactive]: 'Conta inativada',
    [EDigitalAccountStatus.approved]: 'Pronta para uso',
    [EDigitalAccountStatus.blocked]: 'Conta Bloqueada pela Iugu',
    [EDigitalAccountStatus.canceled]: 'Não foi possível criar a conta',
    [EDigitalAccountStatus.pending]: 'Aguardando preencher a URL',
  }

  const cardColorStatus = {
    [EDigitalAccountStatus.inactive]: '#999999',
    [EDigitalAccountStatus.approved]: '#00A86B',
    [EDigitalAccountStatus.blocked]: '#BD3535',
    [EDigitalAccountStatus.canceled]: '#BD3535',
    [EDigitalAccountStatus.pending]: '#F9DC45',
  }

  const accountDigitalStatus = data?.customerGet?.digitalAccount?.status as EDigitalAccountStatus || EDigitalAccountStatus.pending

  return (
    <Box sx={{
      border: `1px solid ${theme.palette.grey[300]}`,
      borderLeft: `6px solid ${cardColorStatus[accountDigitalStatus]}`,
      padding: '1.4rem',
      borderRadius: '.6rem',
      display: 'flex',
      gap: '2rem'
    }}>
      {!isLowerSm && (
        <RoundedIcon>
          <IconWallet color={theme.palette.grey[400]} />
        </RoundedIcon>
      )}
     
      <Box sx={{ display: 'flex', gap: '1rem', flexFlow: 'column' }}>
        <Box sx={{ display: 'flex', gap: '1rem', flexFlow: isLowerMd ? 'column' : 'row' }}>
          <Typography color={theme.palette.grey['900']}>Dados fornecidos para a iugu</Typography>
          <SingleAccountStatus status={accountDigitalStatus} />
          <Typography color='#999999'>{textByStatuys[accountDigitalStatus]}</Typography>
        </Box>
        <Box>
          <ul style={{ margin: '0', padding: '0 0 0 1rem' }}>
            <li>Titular: {data?.customerGet?.name}</li>
          </ul>
        </Box>
      </Box>

    </Box>
  )
}
