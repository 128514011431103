import { Container } from './styles'
import { translateCustomerStatus } from '~/utils'

type CustomerStatusProps = {
  status: string
}
export const CustomerStatus: React.FC<CustomerStatusProps> = ({ status }) => {
  return (
    <Container status={status || 'pending'}>
      <span>{translateCustomerStatus(status || 'peding')}</span>
    </Container>
  )
}
