/* eslint-disable no-unused-vars */
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { ContractModel, EContractStatus } from '~/graphql/types'

type ContractContextValue = {
  contract: ContractModel | null
  resetContract: () => void
  setContract: (contract: ContractModel | null) => void
  isDisabledContractUpdate: boolean
  setIsDisabledContractUpdate: React.Dispatch<React.SetStateAction<boolean>>
  isUpdatingContract: boolean
  setIsUpdatingContract: React.Dispatch<React.SetStateAction<boolean>>
}

const ContractContext = createContext({} as ContractContextValue)

export const ContractProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [contract, setContract] = useState<ContractModel | null>(null)
  const [isUpdatingContract, setIsUpdatingContract] = useState(false)
  const [isDisabledContractUpdate, setIsDisabledContractUpdate] = useState(false)

  const resetContract = () => {
    setContract(null)
  }

  useEffect(() => {
    setIsUpdatingContract(false)
    if (contract?._id && (contract?.status === EContractStatus.active || contract?.status === EContractStatus.sent)) {
      setIsDisabledContractUpdate(true)
    } else {
      setIsDisabledContractUpdate(false)
    }
  }, [contract])

  return (
    <ContractContext.Provider value={{
      contract,
      setContract,
      resetContract,
      setIsDisabledContractUpdate,
      isDisabledContractUpdate,
      isUpdatingContract,
      setIsUpdatingContract
    }}>
      {children}
    </ContractContext.Provider>
  )
}

export const useContract = () => useContext(ContractContext)
