import { useCallback, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { useContract } from '~/contexts'
import { Option } from '~/utils/options'
import { ActionButtons, ChangePowerplantModal } from './components'
import { Form } from '~/components/Form/Form'
import { Select } from '~/components/Form/Select'
import { ContractAnnotations, ContractInfo, PropsWithWizard } from '~/components'
import { ContractModel, EContractPersonType, EContractStatus, useConsortiumListQuery, useContractChangePowerPlantMutation, useContractUpdateMutation, usePowerGeneratorListQuery, usePowerPlantListQuery } from '~/graphql/types'
import { useToggle } from '~/hooks'

export type DealershipAndPowerplantForm = {
  consortiumId: string
  powerPlantId: string
  powerGeneratorId: string
}

const schema = yup.object().shape({
  powerPlantId: yup.string().required('Campo obrigatório'),
  consortiumId: yup.string().required('Campo obrigatório'),
  powerGeneratorId: yup.string().required('Campo obrigatório')
})

const getInitialValues = (contract?: ContractModel | null): DealershipAndPowerplantForm => {
  return {
    consortiumId: contract?.consortiumId || '',
    powerPlantId: contract?.powerPlantId || '',
    powerGeneratorId: contract?.powerGeneratorId || ''
  }
}

export const DealershipAndPowerplant: React.FC<PropsWithWizard> = (props) => {
  const { isTrue, toggle } = useToggle()
  const { contract, setContract, isDisabledContractUpdate, setIsDisabledContractUpdate, setIsUpdatingContract } = useContract()
  const [updateContract, { loading: updateContractLoading }] = useContractUpdateMutation()
  const [updateContractPowerplant, { loading: updateContractPowerplantLoading }] = useContractChangePowerPlantMutation({
    onError(error) {
      toast.error(error.message)
    },
  })
  const { data: consortiums, loading: loadingConsortiums } = useConsortiumListQuery({ variables: { params: { pageSize: 99999 } } })
  const { data: powerPlants, loading: loadingPowerPlants } = usePowerPlantListQuery({ variables: { params: { pageSize: 99999, pageNumber: 0 } } })
  const { data: powerGenerators, loading: loadingPowerGenerators } = usePowerGeneratorListQuery({ variables: { params: { pageSize: 99999 } } })

  const isComplete = contract?.status === EContractStatus.sent || contract?.status === EContractStatus.active

  const isPowerplantCashback = useMemo(() =>
    powerPlants?.powerPlantList.data.find((powerPlant) => powerPlant._id === contract?.powerPlantId)?.cashbackInfo?.active, [powerPlants, contract]
  )

  const [powerPlantsOptions, setPowerPlantsOptions] = useState<Option[]>([])
  const [powerGeneratorsOptions, setPowerGeneratorsOptions] = useState<Option[]>([])

  const methods = useForm<DealershipAndPowerplantForm>({
    values: getInitialValues(contract),
    resolver: yupResolver(schema)
  })

  const currentSelectedConsortium = methods.watch('consortiumId')
  const selectedPowerGeneratorId = methods.watch('powerGeneratorId')

  const consortiumsOptions: Option[] = useMemo(() => consortiums ? consortiums?.consortiumList?.data?.map(consortium => {
    return { value: consortium._id, label: consortium.name }
  }) : [], [consortiums])

  const onUpdateContract = async (formData: DealershipAndPowerplantForm) => {
    if (contract) {
      try {
        const { data } = await updateContract({
          variables: {
            params: {
              _id: contract?._id,
              dealershipLogin: contract.dealershipLogin ? {
                login: contract.dealershipLogin.login,
                password: contract.dealershipLogin.password,
                extraPassword: contract.dealershipLogin.extraPassword
              } : null,
              specialDiscount: contract.specialDiscount ? contract.specialDiscount.map(item => {
                return {
                  month: item.month,
                  discountPercent: item.discountPercent
                }
              }) : [],
              consortiumId: formData.consortiumId,
              powerPlantId: formData.powerPlantId,
              powerGeneratorId: formData.powerGeneratorId
            }
          }
        })

        data && data.contractUpdate && setContract(data.contractUpdate as ContractModel)
        toast.success('Contrato atualizado com sucesso')
      } catch (err) {
        toast.error('Houve um problema ao atualizar o contrato')
      }
    }
  }

  const onUpdateSentContract = useCallback(async (formData: DealershipAndPowerplantForm) => {
    const isPowerplantFromFormCashback = powerPlants?.powerPlantList.data.find((powerPlant) => powerPlant._id === formData.powerPlantId)?.cashbackInfo?.active

    if (isPowerplantCashback && !isPowerplantFromFormCashback) {
      toggle()
      return
    }

    try {
      const { data } = await updateContractPowerplant({
        variables: {
          params: {
            contractId: contract?._id || '',
            ...formData
          }
        }
      })

      data?.contractChangePowerPlan && setContract(data?.contractChangePowerPlan as ContractModel)
      toast.success('Dados atualizados com sucesso.')
      setIsDisabledContractUpdate(true)
      setIsUpdatingContract(false)
    } catch (err) {
      console.log(err)
    }
  }, [isPowerplantCashback, updateContractPowerplant, setContract])

  const onSubmit = async (formData: DealershipAndPowerplantForm) => {
    if (isComplete) {
      await onUpdateSentContract(formData)
    } else {
      await onUpdateContract(formData)
    }
  }

  useEffect(() => {
    if (powerGenerators) {
      const despertaPowerGenerators = powerGenerators.powerGeneratorList.data.filter(powerGenerator => powerGenerator.isDesperta)
      setPowerGeneratorsOptions(despertaPowerGenerators.map(item => {
        return {
          label: item.name,
          value: item._id
        }
      }))
    }
  }, [powerGenerators])

  useEffect(() => {
    if (powerPlants && selectedPowerGeneratorId) {
      setPowerPlantsOptions(powerPlants.powerPlantList.data.filter(powerPlant => {
        if (powerPlant.generatorId === selectedPowerGeneratorId) {
          if (powerPlant.acceptsPF && contract?.customerRef?.personType === EContractPersonType.pf) return true
          if (powerPlant.acceptsPJ && contract?.customerRef?.personType === EContractPersonType.pj) return true
          return false
        }

        return false
      })
        .map(powerPlant => {
          return {
            label: powerPlant.name,
            value: powerPlant._id,
            isCashback: powerPlant.cashbackInfo?.active
          }
        }))
    }
  }, [powerPlants, selectedPowerGeneratorId])

  if (loadingConsortiums || loadingPowerPlants || loadingPowerGenerators) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' flex='1'>
        <CircularProgress />
      </Box>
    )
  }

  const disabledFields = isDisabledContractUpdate && (contract?.status === EContractStatus.active || contract?.status === EContractStatus.sent)

  return (
    <>
      <FormProvider {...methods}>
        <Form>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
            {contract?.proposalId && <ContractInfo contract={contract} />}

            <Typography variant='h3' fontWeight={500}>Consórcio</Typography>
            <Grid spacing={3} container>
              <Grid item md={4} xs={12}>
                <Select
                  label='Consórcio'
                  name='consortiumId'
                  options={consortiumsOptions}
                  disabled={loadingConsortiums || disabledFields}
                  placeholder='Selecione o consórcio'
                  startAdornment={loadingConsortiums ? <CircularProgress sx={{ marginLeft: 2 }} size={18} color='inherit' /> : null}
                />
              </Grid>
            </Grid>

            <Typography variant='h3' fontWeight={500}>Gerador</Typography>
            <Grid spacing={3} container>
              <Grid item md={4} xs={12}>
                <Select
                  label='Gerador'
                  name='powerGeneratorId'
                  options={powerGeneratorsOptions}
                  placeholder='Selecione o gerador'
                  disabled={!currentSelectedConsortium || loadingPowerGenerators || disabledFields}
                  startAdornment={loadingPowerGenerators ? <CircularProgress sx={{ marginLeft: 2 }} size={18} color='inherit' /> : null}
                />
              </Grid>
            </Grid>

            <Typography variant='h3' fontWeight={500}>Usina</Typography>
            <Grid spacing={3} container>
              <Grid item md={4} xs={12}>
                <Select
                  label='Usina'
                  name='powerPlantId'
                  options={powerPlantsOptions}
                  disabled={!currentSelectedConsortium || loadingPowerPlants || disabledFields}
                  placeholder='Selecione a usina'
                  startAdornment={loadingPowerGenerators ? <CircularProgress sx={{ marginLeft: 2 }} size={18} color='inherit' /> : null}
                />
              </Grid>
            </Grid>

            {contract && <ContractAnnotations contract={contract} />}

            <ActionButtons onSubmit={methods.handleSubmit(onSubmit)} loading={updateContractLoading || updateContractPowerplantLoading} toggleModal={toggle} {...props} />
          </Box>
        </Form>
      </FormProvider>

      <ChangePowerplantModal isVisible={isTrue} onClose={toggle} formData={methods.watch()} />
    </>
  )
}
