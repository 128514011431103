import { EInvoiceStatus } from '~/graphql/types'
import { Container } from './styles'
import { CONTRAC_INVOICE, translateContractInvoice } from '~/utils'

type InvoiceStatusProps = {
  status: EInvoiceStatus
}
export const InvoiceStatus: React.FC<InvoiceStatusProps> = ({ status }) => {
  return (
    <Container status={status || EInvoiceStatus.pending}>
      <span>{translateContractInvoice(status || EInvoiceStatus.pending, CONTRAC_INVOICE)}</span>
    </Container>
  )
}
