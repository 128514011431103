import * as yup from 'yup'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Box, Grid, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import { useToggle } from '~/hooks'
import { useContract } from '~/contexts'
import { Form } from '~/components/Form/Form'
import { Input } from '~/components/Form/Input'
import { ActionButtons, DataReviewModal, SpecialDiscount } from './components'
import { ContractAnnotations, ContractInfo, PropsWithWizard } from '~/components'
import {
  ContractModel,
  ContractUpdateCommercialCondition,
  ContractUpdateDtoGraphql,
  EContractStatus,
  ERewardType,
  SpecialDiscountModel,
  useContractUpdateCommercialConditionMutation,
  useContractUpdateMutation,
  useProposalGetQuery,
  useSendContractToSignMutation
} from '~/graphql/types'
import { IconInfoCircle, IconPercentage } from '@tabler/icons-react'
import { useState } from 'react'

export type ComercialConditionsForm = {
  fidelityMonths?: number,
  validityMonths?: number,
  participationKwh?: number,
  discountPercent?: number,
  gracePeriodMonths?: number,
  contractedSharePercent?: number,
  specialDiscount?: SpecialDiscountModel[] | null,
  fakeInputBenefitOffered?: number
}

const specialDiscountOptions = new Array(12).fill({}).map((_, index) => {
  return {
    value: index + 1,
    label: `${index + 1}º mês`
  }
})

const getInitialValues = (contract?: ContractModel | null): ComercialConditionsForm => {
  return {
    fidelityMonths: (contract?.fidelityMonths === 0 ? undefined : contract?.fidelityMonths) ?? undefined,
    validityMonths: (contract?.validityMonths === 0 ? undefined : contract?.validityMonths) ?? undefined,
    participationKwh: contract?.participationKwh || undefined,
    gracePeriodMonths: (contract?.gracePeriodMonths === 0 ? undefined : contract?.gracePeriodMonths) ?? undefined,
    discountPercent: contract?.reward?.percent,
    contractedSharePercent: contract?.contractedSharePercent || 0,
    specialDiscount: contract?.specialDiscount ? contract?.specialDiscount.map(discount => {
      return {
        month: discount.month,
        discountPercent: discount.discountPercent
      }
    }) : [],
  }
}

const schema = yup.object().shape({
  fidelityMonths: yup
    .number()
    .typeError('O campo de fidelidade precisa ser um número!')
    .nullable()
    .moreThan(0, 'O número precisa ser maior que 0!')
    .transform((_, val) => val !== '' ? Number(val) : null)
  ,
  validityMonths: yup
    .number()
    .typeError('O campo de vigência precisa ser um número!')
    .nullable()
    .moreThan(0, 'O número precisa ser maior que 0!')
    .transform((_, val) => val !== '' ? Number(val) : null),
  participationKwh: yup.number().required(),
  gracePeriodMonths: yup
    .number()
    .typeError('O campo de carência precisa ser um número!')
    .nullable()
    .moreThan(0, 'O número precisa ser maior que 0!')
    .transform((_, val) => val !== '' ? Number(val) : null),
  discountPercent: yup.number().required().max(100, 'Valor máximo deve ser de 100%'),
  contractedSharePercent: yup.number().required().max(100, 'Valor máximo deve ser de 100%'),
  specialDiscount: yup.array().of(yup.object().shape({
    month: yup.number().required().min(1, 'Mês inválido').max(12, 'Mês inválido'),
    discountPercent: yup.number().required().max(100, 'Valor máximo deve ser de 100%')
  }))
})

export const ComercialConditions: React.FC<PropsWithWizard> = (props) => {
  const { onNext } = props
  const { contract, setContract, isDisabledContractUpdate, setIsDisabledContractUpdate, setIsUpdatingContract } = useContract()
  const [contractUpdateCommercialCondition, { loading: contractUpdateCommercialConditionLoading }] = useContractUpdateCommercialConditionMutation()
  const [contractUpdate, { loading: contractUpdateLoading }] = useContractUpdateMutation()
  const [sendContractToSign, { loading: sendContractToSignLoading }] = useSendContractToSignMutation()
  const [isProposalLoaded, setIsProposalLoaded] = useState(false)
  const { isTrue: isVisible, toggle: toggleModal } = useToggle()

  const isComplete = contract?.status === EContractStatus.sent || contract?.status === EContractStatus.active

  const methods = useForm<ComercialConditionsForm>({
    values: getInitialValues(contract),
    resolver: yupResolver(schema)
  })

  const { append, fields, remove } = useFieldArray<ComercialConditionsForm>({
    name: 'specialDiscount',
    control: methods.control
  })

  const specilDiscountFields = methods.watch('specialDiscount')

  const addSpecialMonth = (index: number) => {
    if (specilDiscountFields?.some(item => item.month === index + 1)) {
      return
    }
    append({ discountPercent: 0, month: index + 1 })
  }

  const updateContract = async (formData: ComercialConditionsForm) => {
    if (contract) {
      const params: ContractUpdateDtoGraphql = {
        _id: contract._id,
        participationKwh: formData.participationKwh,
        fidelityMonths: Number(formData.fidelityMonths),
        validityMonths: Number(formData.validityMonths),
        gracePeriodMonths: Number(formData.gracePeriodMonths),
        discountPercent: formData.discountPercent,
        contractedSharePercent: formData.contractedSharePercent,
        dealershipLogin: contract.dealershipLogin ? {
          login: contract.dealershipLogin.login,
          password: contract.dealershipLogin.password,
          extraPassword: contract.dealershipLogin.extraPassword
        } : null,
        specialDiscount: formData.specialDiscount ? formData.specialDiscount.map(discount => {
          return {
            month: discount.month,
            discountPercent: discount.discountPercent
          }
        }) : []
      }

      const formattedParams: ContractUpdateDtoGraphql = formData.discountPercent || formData.discountPercent === 0 ? {
        ...params,
        reward: {
          history: contract.reward?.history,
          type: ERewardType.discount,
          percent: formData.discountPercent
        }
      } : params

      const { data } = await contractUpdate({ variables: { params: formattedParams } })
      data && data.contractUpdate && setContract(data.contractUpdate as ContractModel)
    }
  }

  const onSaveData = async (formData: ComercialConditionsForm) => {
    try {
      await updateContract(formData)
      toast.success('Dados atualizados com sucesso!')
    } catch (err) {
      toast.error('Houve um problema ao atualizar os dados')
    }
  }

  const onSaveSentContractData = async (formData: ComercialConditionsForm) => {
    try {
      if (contract) {
        const params: ContractUpdateCommercialCondition = {
          contractId: contract._id,
          participationKwh: formData.participationKwh,
          fidelityMonths: Number(formData.fidelityMonths),
          validityMonths: Number(formData.validityMonths),
          gracePeriodMonths: Number(formData.gracePeriodMonths),
          defaultDiscount: formData.discountPercent,
          contractedSharePercent: formData.contractedSharePercent,
          specialDiscount: formData.specialDiscount ? formData.specialDiscount.map(discount => {
            return {
              month: discount.month,
              discountPercent: discount.discountPercent
            }
          }) : []
        }

        const { data } = await contractUpdateCommercialCondition({ variables: { params } })
        data && data.contractUpdateCommercialCondition && setContract(data.contractUpdateCommercialCondition as ContractModel)

        setIsDisabledContractUpdate(true)
        setIsUpdatingContract(false)
        toast.success('Dados atualizados com sucesso!')
      }
    } catch (err) {
      toast.error('Houve um problema ao atualizar os dados')
    }
  }

  const handleSaveData = async (formData: ComercialConditionsForm) => {
    if (isComplete) {
      await onSaveSentContractData(formData)
    } else {
      await onSaveData(formData)
    }
  }

  const onSendContractToSign = async (formData: ComercialConditionsForm) => {
    try {
      await updateContract(formData)
      const { data } = await sendContractToSign({ variables: { params: { contractId: contract?._id || '' } } })

      if (data && data.sendContractToSign) {
        setContract(data.sendContractToSign as ContractModel)
        onNext()
      }
    } catch (err) {
      toast.error('Houve um problema ao salvar os dados')
    }
  }

  const disabledFields = isDisabledContractUpdate && (contract?.status === EContractStatus.active || contract?.status === EContractStatus.sent || contract?.status === EContractStatus.cancellationRequested || contract?.status === EContractStatus.inDeactivation)

  useProposalGetQuery({
    variables: {
      id: String(contract?.proposalId),
    },
    onCompleted: (data) => {
      const cashbackPercentage = data?.proposalGet?.cashbackInfo
      if (cashbackPercentage) {
        methods.setValue('fakeInputBenefitOffered', cashbackPercentage?.cashbackPercentagePj || cashbackPercentage?.cashbackPercentagePf || undefined)
        setIsProposalLoaded(true)
      } else {
        setIsProposalLoaded(false)
      }
    },
  })

  return (
    <FormProvider {...methods}>
      <Form>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
          {contract?.proposalId && <ContractInfo contract={contract} />}

          <Typography variant='h3' fontWeight={500}>Especificações do contrato</Typography>
          <Grid spacing={3} container>
            <Grid item md={4} xs={12}>
              <Input disabled={disabledFields} name='participationKwh' label='Participação' mask='kwh' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input disabled={disabledFields} name='contractedSharePercent' label='Rateio' mask='percentage' icons={{ end: { element: <IconPercentage size={24} color='#999999' /> } }} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input disabled={disabledFields} name='fidelityMonths' label='Fidelidade (em meses)' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input disabled={disabledFields} name='validityMonths' label='Vigência (em meses)' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input disabled={disabledFields} name='gracePeriodMonths' label='Carência (em meses)' />
            </Grid>
          </Grid>

          <Typography variant='h3' fontWeight={500}>Benefícios</Typography>
          <Grid spacing={3} container>
            <Grid item md={4} xs={12}>
              <Typography variant='body2' fontWeight={500} marginBottom={2}>Benefício padrão</Typography>
              <Input disabled={disabledFields} name='discountPercent' label='Benefício padrão' mask='percentage' />
            </Grid>
            {contract?.proposalId && isProposalLoaded && (
              <Grid item md={4} xs={12}>
                <Typography variant='body2' fontWeight={500} marginBottom={2}>Benefício oferecido</Typography>
                <Input disabled={true} name='fakeInputBenefitOffered' label='Benefício oferecido' mask='percentage' icons={{ end: { element: <Tooltip title='Esse percentual já consta na proposta de benefício enviada ao cliente'><IconInfoCircle size={24} color='#3BA1FF' /></Tooltip> } }} />
              </Grid>
            )}

            <Grid item md={4} xs={12}>
              <Typography variant='body2' fontWeight={500} marginBottom={2}>Benefício especial</Typography>
              <>
                <Select disabled={disabledFields} sx={{ width: '100%' }} name='specialDiscount'>
                  <MenuItem value=''>Selecione</MenuItem>
                  {specialDiscountOptions.map((option, index) => {
                    return (
                      <MenuItem onClick={() => addSpecialMonth(index)} key={`select-option-${option.value}-${index}`} value={option.value}>{option.label}</MenuItem>
                    )
                  })}
                </Select>
              </>
              {fields.map((item, index) => <SpecialDiscount disabled={disabledFields} key={item.id} index={index} specialDiscount={item} remove={remove} />).sort((a, b) => {
                return a.props.specialDiscount.month - b.props.specialDiscount.month
              })}
            </Grid>
          </Grid>

          {contract && <ContractAnnotations contract={contract} />}

          <ActionButtons toggleModal={toggleModal} saveData={methods.handleSubmit(handleSaveData)} loading={contractUpdateLoading || contractUpdateCommercialConditionLoading} {...props} />
          <DataReviewModal isVisible={isVisible} toggleModal={toggleModal} onSubmit={methods.handleSubmit(onSendContractToSign)} loading={sendContractToSignLoading || contractUpdateLoading} />
        </Box>
      </Form>
    </FormProvider>
  )
}
