import { SvgIcon, SvgIconProps } from '@mui/material'

export const ThreeDots: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <SvgIcon width={width || 24} height={height || 24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M4 12.0001C4 12.2653 4.10536 12.5197 4.29289 12.7072C4.48043 12.8948 4.73478 13.0001 5 13.0001C5.26522 13.0001 5.51957 12.8948 5.70711 12.7072C5.89464 12.5197 6 12.2653 6 12.0001C6 11.7349 5.89464 11.4806 5.70711 11.293C5.51957 11.1055 5.26522 11.0001 5 11.0001C4.73478 11.0001 4.48043 11.1055 4.29289 11.293C4.10536 11.4806 4 11.7349 4 12.0001Z' stroke='#999999' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 12.0001C11 12.2653 11.1054 12.5197 11.2929 12.7072C11.4804 12.8948 11.7348 13.0001 12 13.0001C12.2652 13.0001 12.5196 12.8948 12.7071 12.7072C12.8946 12.5197 13 12.2653 13 12.0001C13 11.7349 12.8946 11.4806 12.7071 11.293C12.5196 11.1055 12.2652 11.0001 12 11.0001C11.7348 11.0001 11.4804 11.1055 11.2929 11.293C11.1054 11.4806 11 11.7349 11 12.0001Z' stroke='#999999' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 12.0001C18 12.2653 18.1054 12.5197 18.2929 12.7072C18.4804 12.8948 18.7348 13.0001 19 13.0001C19.2652 13.0001 19.5196 12.8948 19.7071 12.7072C19.8946 12.5197 20 12.2653 20 12.0001C20 11.7349 19.8946 11.4806 19.7071 11.293C19.5196 11.1055 19.2652 11.0001 19 11.0001C18.7348 11.0001 18.4804 11.1055 18.2929 11.293C18.1054 11.4806 18 11.7349 18 12.0001Z' stroke='#999999' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  )
}
