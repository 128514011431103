import MenuIcon from '@mui/icons-material/Menu'
import { Box, IconButton, Toolbar, Typography } from '@mui/material'
import { theme } from '~/utils'
import { StyleAppBar } from './style'
import { UserCircle } from '~/assets/icons'
import { useAuth } from '~/contexts'

type NavbarProps = {
  isDrawerOpen: boolean
  toggleDrawer: () => void
}

export const Navbar: React.FC<NavbarProps> = ({ isDrawerOpen, toggleDrawer }) => {
  const { authUser } = useAuth()
  return (
    <StyleAppBar
      position='fixed'
      open={isDrawerOpen}
      sx={{
        background: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.grey['300']}`
      }}
    >
      <Toolbar>
        <IconButton
          edge='start'
          onClick={toggleDrawer}
          aria-label='open drawer'
          sx={{ marginRight: 5, color: 'grey.400' }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ gap: 1, display: 'flex', alignItems: 'center' }}>
          <IconButton
            edge='start'
            sx={{ color: 'grey.400' }}
          >
            <UserCircle fontSize='medium' />
          </IconButton>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant='body2' fontWeight={700}>{authUser?.user.name}</Typography>
            <Typography variant='body3' color='#999999' fontWeight={500}>{authUser?.accessControlRef.name}</Typography>
          </Box>
        </Box>
      </Toolbar>
    </StyleAppBar>
  )
}
