
/* eslint-disable no-unused-vars */

import { Box, IconButton, Link, Typography } from '@mui/material'
import { useToggle } from '~/hooks'
import { Dropdown } from '~/components'
import { ThreeDots } from '~/assets/icons'
import { GenerateTermOfManagementDialog } from './components'
import { IconDownload, IconRefresh } from '@tabler/icons-react'
import { TermOfAdhesionModel, EProposalStatus } from '~/graphql/types'
import { useTheme } from '@emotion/react'
import { useProposalContext } from '~/contexts'

export type TermOfManagementOptionsProps = {
  term?: TermOfAdhesionModel | null
}
export const TermOfManagementOptions: React.FC<TermOfManagementOptionsProps> = ({ term }) => {
  const theme = useTheme()
  const { proposal } = useProposalContext()
  const { isTrue: isVisible, toggle: toggleModal } = useToggle()
  const hasDownloadLink = Boolean(term?.url) && term?.url !== '-'

  const hasRegenerateTerm = proposal?.status === EProposalStatus.finalized

  return (
    <>
      <Dropdown
        $options={{ left: '-160px' }}
        elementClick={(
          <IconButton>
            <ThreeDots />
          </IconButton>
        )}
      >
        <Box sx={{
          display: 'flex',
          borderRadius: 3,
          flexDirection: 'column',
          minWidth: '220px',
          background: 'white',
          boxShadow: '0px 0px 2px rgba(64, 64, 64, 0.1), 0px 6px 15px -4px rgba(64, 64, 64, 0.14)'
        }}>
          {!hasRegenerateTerm && (
            <Box onClick={toggleModal} display='flex' flex={1} gap={2} alignItems='center' sx={{ cursor: 'pointer', padding: 2, '&:hover': { background: '#FAFAFA' } }}>
              <IconRefresh />
              <Typography variant='body2'>Gerar novo termo de gestão</Typography>
            </Box>
          )}

          {hasDownloadLink && (
            <Link sx={{ textDecoration: 'none', color: theme.palette.grey[800] }} target='_blank' href={term?.url || ''} rel='noreferrer'>
              <Box display='flex' flex={1} gap={2} alignItems='center' sx={{ cursor: 'pointer', padding: 2, '&:hover': { background: '#FAFAFA' } }}>
                <IconDownload />
                <Typography variant='body2'>Baixar termo de gestão</Typography>
              </Box>
            </Link>
          )}

        </Box>
      </Dropdown>

      <GenerateTermOfManagementDialog isVisible={isVisible} toggleModal={toggleModal} />
    </>
  )
}
