import { MonthsNumber } from './types'

type FormatDateOptions = {
  dateHour?: boolean
}

export function formatDate(isoDate: string, options?: FormatDateOptions) {
  if(!isoDate) return ''
  const date = new Date(!isoDate.includes('Z') ? Number(isoDate) : isoDate)
  if(options?.dateHour) {
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute:'2-digit',
      second:'2-digit'
    }).format(date)
  } else {
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    }).format(date)
  }
}

export function formatHour(isoDate: string) {
  if(!isoDate) return ''
  const date = new Date(!isoDate.includes('Z') ? Number(isoDate) : isoDate)
 
  return new Intl.DateTimeFormat('pt-BR', {
    timeZone: 'America/Sao_Paulo',
    hour: '2-digit',
    minute:'2-digit'
  }).format(date)

}

export function formatDateToInput(isoDate: string) {
  if(!isoDate) return ''
  const date = new Date(isoDate).toISOString().substring(0,10)
  return date
}

// eslint-disable-next-line no-unused-vars
export const MONTH_BY_NUMBER: { [x in MonthsNumber]: string } = {
  1: 'Jan',
  '01': 'Jan',
  2: 'Fev',
  '02': 'Fev',
  3: 'Mar',
  '03': 'Mar',
  4: 'Abr',
  '04': 'Abr',
  5: 'Mai',
  '05': 'Mai',
  6: 'Jun',
  '06': 'Jun',
  7: 'Jul',
  '07': 'Jul',
  8: 'Ago',
  '08': 'Ago',
  9: 'Set',
  '09': 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez',
}

export const monthNameByMonthNumber = (monthNumber: MonthsNumber) => {
  return MONTH_BY_NUMBER[monthNumber] ?? monthNumber
}
