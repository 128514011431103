import { ContractModel, EContractStatus } from '~/graphql/types'

export const isStepCompleted = (label: string, contract: ContractModel | null) => {
  if (!contract) {
    return false
  }

  if (label === 'Faturas' && contract.status === EContractStatus.sent) {
    return false
  }

  if (contract.status === EContractStatus.sent) {
    return true
  }

  if (label === 'Dados do cliente') {
    return Boolean(contract.customerId)
  }

  if (label === 'Unid. consumidora') {
    const hasEnergyBillDocument = Boolean(contract.energyBillInfo?.energyBillFile)
    const hasEnegyBillInfo = Boolean(
      contract.energyBillInfo?.address &&
      contract.energyBillInfo?.tension &&
      contract.energyBillInfo?.AmountCents &&
      // energyBillInfo?.tusdValue &&
      // energyBillInfo?.energyTaxes &&
      contract.energyBillInfo?.clientNumber &&
      contract.energyBillInfo?.distributionTaxes &&
      contract.energyBillInfo?.installationNumber &&
      contract.energyBillInfo?.tariffClassification
    )

    const hasLoginInfo = Boolean(contract.dealershipLogin?.login && contract.dealershipLogin?.password)

    return Boolean(hasEnergyBillDocument && hasEnegyBillInfo && hasLoginInfo)
  }

  if (label === 'Consórcio e usina') {
    return Boolean(contract.consortiumId && contract.powerPlantId && contract.powerGeneratorId)
  }

  if (label === 'Condições comerciais') {
    const hasGracePeriodMonths = Boolean(contract.gracePeriodMonths || contract.gracePeriodMonths === 0)
    return Boolean(contract.participationKwh && contract.contractedSharePercent && contract.fidelityMonths && contract.validityMonths && hasGracePeriodMonths)
  }

  if (label === 'Envio') {
    const termOfAdhesion = contract?.termOfAdhesion?.find(term => term.active)
    const termOfManagement = contract?.termOfManagement?.find(term => term.active)

    return Boolean(termOfAdhesion && termOfAdhesion.signedAt && termOfManagement && termOfManagement.signedAt)
  }

  if(label === 'Faturas') {

    return contract.status === EContractStatus.active
  }
}

export const shouldDisableStep = (label: string, contract: ContractModel | null) => {
  if (!contract) {
    return label !== 'Dados do cliente'
  }

  // if (contract.status === EContractStatus.inDeactivation || contract.status === EContractStatus.deactivated || contract.status === EContractStatus.cancellationRequested) {
  //   return true
  // }

  if (label === 'Envio') {
    return !(contract.status === EContractStatus.readyToSend || contract.status === EContractStatus.sent || contract.status === EContractStatus.signatures || contract.status === EContractStatus.active || contract.status === EContractStatus.cancellationRequested)
  }`
  `
  if (label === 'Faturas') {
    return !(contract.status === EContractStatus.active || 
             contract.status === EContractStatus.cancellationRequested || 
             contract.status === EContractStatus.inDeactivation || 
             contract.status === EContractStatus.deactivated)
  }
}
