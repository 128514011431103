/* eslint-disable no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import { Box, Divider, useMediaQuery } from '@mui/material'
import { useMemo } from 'react'
import { ContractFlowActionDropdown, PropsWithWizard } from '~/components'
import { Button } from '~/components/Form/Button'
import { useContract } from '~/contexts'
import { EContractStatus } from '~/graphql/types'
import { usePermission } from '~/hooks'
import { theme } from '~/utils'
import { getCanFormalizeProposal } from '~/utils/proposal'

export const ActionButtons: React.FC<PropsWithWizard> = (props) => {
  const { onPrev } = props
  const { contract } = useContract()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const isDeactivated = contract?.status === EContractStatus.deactivated
  const isDeactivating = contract?.status === EContractStatus.inDeactivation
  const isCancelling = contract?.status === EContractStatus.cancellationRequested
  const canCreateEditContracts = usePermission(['contracts.updated', 'contracts.create'])

  const shouldDisableButtons = useMemo(() => isDeactivated || isDeactivating || isCancelling || !canCreateEditContracts, [isDeactivated, isDeactivating, isCancelling, canCreateEditContracts])

  return (
    <>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }} >
        {!isLowerMd && (
          <Button startIcon={<ArrowBack />} color='secondary' onClick={onPrev}>
            Voltar
          </Button>
        )}

        <Box sx={{ display: 'flex', flexDirection: isLowerSm ? 'column' : 'row', gap: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: isLowerSm ? 'space-between' : 'flex-end' }}>
            <ContractFlowActionDropdown disabled={shouldDisableButtons} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
