import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, Typography, useMediaQuery } from '@mui/material'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { ContentTitle } from '~/components/ContentTitle'
import { Checkbox } from '~/components/Form/Checkbox'
import { Input } from '~/components/Form/Input'
import { PowerGeneratorCreateDto, usePowerGeneratorCreateMutation, usePowerGeneratorGetQuery, usePowerGeneratorUpdateMutation } from '~/graphql/types'
import { useForm, FormProvider, useFieldArray } from 'react-hook-form'
import { IconTrash, IconCheck } from '@tabler/icons-react'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { Add } from '@mui/icons-material'
import { phoneSchema } from '~/utils/yupSchema'
import { usePermission } from '~/hooks'
import { ApolloError } from '@apollo/client'
import { mapErrorToMessage } from '~/utils/errors'
import { PowerGeneratorData } from '~/contexts/powerGenerator/types'
import { FullPageLoader } from '~/components/FullPageLoader'
import { theme } from '~/utils'

const schema = yup
  .object({
    name: yup.string().required('Campo obrigatório'),
    isDesperta: yup.boolean().required('Campo obrigatório'),
    active: yup.boolean().required('Campo obrigatório'),
    representatives: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Campo obrigatório'),
        email: yup.string().email().required('Campo obrigatório'),
        phone: phoneSchema,
      })
    )
  })
  .required()

export const PowerGeneratorCreateEdit: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  // const { selectedPowerGenerator, setPowerGenerators, setSelectedPowerGenerator } = usePowerGenerator()
  const [selectedPowerGenerator, setSelectedPowerGenerator] = useState<PowerGeneratorData | null>(null)
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const canCreateAndEditPowerGenerators = usePermission(['powerGenerator.updated', 'powerGenerator.create'])

  const formMethods = useForm<PowerGeneratorCreateDto>({
    resolver: yupResolver(schema),
    defaultValues: { representatives: [{ email: '', name: '', phone: '' }] },
  })

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'representatives',
  })

  const { refetch: refetchDealershipGet, loading: powerGeneratorGetIsLoading } = usePowerGeneratorGetQuery({
    variables: {
      id: id || ''
    },
    skip: Boolean(!id),
    onCompleted(data) {
      setSelectedPowerGenerator(data.powerGeneratorGet as PowerGeneratorData)
    },
    onError: () => {
      toast.error('Não foi possível carregar este gerador.')
    }
  })

  useEffect(() => {
    if (id) {
      refetchDealershipGet()
    }
    setSelectedPowerGenerator(null)
  }, [id])

  const [createPowerGenerator, { loading: createIsLoading }] = usePowerGeneratorCreateMutation({
    onCompleted: () => {

      toast.success('Uma novo gerador foi cadastrado!')
      navigate('/app/generators')
    },
    onError: () => {
      toast.error('Ocorreu um erro ao cadastrar um gerador.')
    }
  })
  const [updatePowerGenerator, { loading: updateIsLoading }] = usePowerGeneratorUpdateMutation({
    onCompleted: (data) => {

      setSelectedPowerGenerator(null)
      toast.success(`O gerador ${data.powerGeneratorUpdate.name} foi alterado!`)
      navigate(-1)
    },
    onError: (err) => {
      const apolloError = err as ApolloError
      toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, 'Ocorreu um erro ao alterar o gerador.'))
    }
  })

  function onSubmit(data: PowerGeneratorCreateDto) {
    if (id && selectedPowerGenerator) {
      updatePowerGenerator({ variables: { params: { _id: id, ...data } } })
    } else {
      createPowerGenerator({ variables: { params: data } })
    }
  }

  useEffect(() => {
    if (id && selectedPowerGenerator) {
      formMethods.setValue('name', selectedPowerGenerator?.name)
      formMethods.setValue('active', selectedPowerGenerator?.active)
      formMethods.setValue('isDesperta', selectedPowerGenerator?.isDesperta)
      formMethods.setValue('representatives', selectedPowerGenerator?.representatives.map(item => {
        return {
          email: item.email,
          name: item.name,
          phone: item.phone
        }
      }))
    } else {
      formMethods.reset()
    }
  }, [id, selectedPowerGenerator])

  if (!canCreateAndEditPowerGenerators) {
    return <Navigate replace to='/app' />
  }

  if (powerGeneratorGetIsLoading) return <FullPageLoader />

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem 1rem 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title={`${id && selectedPowerGenerator ? `Alterar o gerador ${selectedPowerGenerator.name}` : 'Cadastrar gerador'}`}
          description={`Complete os dados para ${id && selectedPowerGenerator ? 'alterar' : 'cadastrar'} Gerador`}
          breadcrumbLinks={{ currentLink: `${id && selectedPowerGenerator ? 'Alterar' : 'Cadastrar'} Gerador`, links: [{ href: '/app/generators', label: 'Geradores' }] }}
        />

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
            <Box minHeight='100vh' maxWidth='920px' display='flex' flexDirection='column' gap='1rem' >

              <Input name='name' label='Nome do gerador' />

              {fields.map((field, index) => {
                return (
                  <Box display='flex' flexDirection='column' gap='1rem' key={field.id}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                      <Typography>Representante {index + 1}</Typography>
                      {fields.length > 1 && <Button startIcon={<IconTrash size={20} />} size='small' color='error' onClick={() => fields.length > 1 && remove(index)} >Excluir</Button>}
                    </Box>
                    <Input name={`representatives.${index}.name`} label='Nome do representante' />
                    <Input name={`representatives.${index}.email`} label='Email' />
                    <Input name={`representatives.${index}.phone`} mask='phone' label='Telefone' />
                  </Box>
                )
              })}

              <Button startIcon={<Add />} sx={{ maxWidth: '220px' }} color='secondary' onClick={() => append({ email: '', name: '', phone: '' })}>Adicionar Contato</Button>
              <Box display='flex' gap='1rem'>
                <Checkbox name='active' label='Ativo' />
                <Checkbox name='isDesperta' label='Desperta' />
              </Box>
              <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexFlow: isLowerSm ? 'column-reverse' : 'row',
                gap: isLowerSm ? '1rem' : '0'
              }}>

                <Link style={{ width: '100%' }} to='/app/users'>
                  <Button sx={{ maxWidth: isLowerSm ? '100%' : '160px' }} fullWidth disabled={createIsLoading || updateIsLoading} color='secondary'>
                    Cancelar
                  </Button>
                </Link>

                <Button
                  startIcon={<IconCheck />}
                  sx={{ maxWidth: isLowerSm ? '100%' : '220px' }}
                  fullWidth
                  disabled={createIsLoading || updateIsLoading}
                  type='submit'>
                  {id && selectedPowerGenerator ? 'Alterar' : 'Cadastrar'} Gerador
                </Button>
              </Box>

            </Box>
          </form>
        </FormProvider>
      </Card>
    </div>
  )
}
