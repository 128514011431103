import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Input } from '~/components/Form/Input'
import { Select } from '~/components/Form/Select'
import { brazilStates } from '~/constants'
import { Option, customerTensionOptions, getConsumerClassificationOptions, tariffClassificationOptions } from '~/utils/options'
import { DocumentationForm } from '../..'
import { useCep } from '~/hooks'
import { FullPageLoader } from '~/components/FullPageLoader'
import { IconInfoCircle, IconSearch } from '@tabler/icons-react'

export type EnergyBillInfoProps = {
  dealerships: Option[]
  loadingDealerships: boolean,
  disableFields?: boolean
}
export const EnergyBillFields: React.FC<EnergyBillInfoProps> = ({ dealerships, loadingDealerships, disableFields }) => {
  const { watch, setValue } = useFormContext<DocumentationForm>()

  const currentTariffClassification = watch('tariffClassification')
  const address = watch('address')
  const consumerClassificationOptions = useMemo(() => getConsumerClassificationOptions(currentTariffClassification), [currentTariffClassification])

  const { fetchCep, isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })

  const onSearchCep = useCallback(async () => {
    if (address?.zipcode) {
      const { data, isSuccess } = await fetchCep(address.zipcode)
      if (isSuccess) {
        setValue('address.state', data?.uf || '',)
        setValue('address.city', data?.localidade || '',)
        setValue('address.neighborhood', data?.bairro || '',)
        setValue('address.street', data?.logradouro || '',)
      }
    }
  }, [address])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography fontWeight={600}>Dados da fatura</Typography>

      <Grid spacing={3} container>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} mask='BRL' name='AmountCents' label='Valor da fatura' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Select disabled={disableFields} options={tariffClassificationOptions} name='tariffClassification' label='Classificação tarifárica' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Select disabled={disableFields} options={consumerClassificationOptions} name='consumerClassification' label='Classe de consumo' />
        </Grid>

        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Select disabled={disableFields} options={customerTensionOptions} name='tension' label='Tensão do consumidor' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} name='installationNumber' label='Instalação / UC' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} name='clientNumber' label='Número do cliente' />
        </Grid>

        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} mask='kwh' name='avarageConsumption' label='Média de consumo' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} type='number' name='invoiceDueDay' label='Dia de vencimento' icons={{ end: { element: <Tooltip title='Data máxima para pagamento mensal de fatura. Ex.: Todo dia 05'><IconInfoCircle size={24} color='#3BA1FF' /></Tooltip> } }} />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Select disabled={loadingDealerships || disableFields} options={dealerships} name='dealershipId' label='Concessionária' />
        </Grid>
        {/* <Grid sx={{ paddingTop: '0px !important', width: '100%' }} item lg={4} sm={0} md={0} xs={0} /> */}

        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} mask='BRL' name='distributionTaxes' label='Taxa de contribuição' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} mask='BRL' name='energyTaxes' label='Taxa de energia' decimalScale={6} />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} mask='BRL' name='tusdValue' label='Tusd' />
        </Grid>

        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input
            disabled={disableFields}
            icons={{ end: { element: fetchCepIsLoading ? <FullPageLoader /> : <IconButton disabled={disableFields} onClick={() => onSearchCep()}><IconSearch /></IconButton> } }}
            mask='cep'
            name='address.zipcode'
            label='CEP'
          />
        </Grid>
        <Grid sx={{ paddingTop: '0px !important', width: '100%' }} item xs={8} />

        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} name='address.street' label='Rua' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} name='address.number' label='Número' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} name='address.complement' label='Complemento' />
        </Grid>

        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} name='address.neighborhood' label='Bairro' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Input disabled={disableFields} name='address.city' label='Cidade' />
        </Grid>
        <Grid item lg={4} sm={6} md={6} xs={12}>
          <Select disabled={disableFields} options={brazilStates} name='address.state' label='UF' />
        </Grid>
      </Grid>
    </Box>
  )
}
