import { createContext, useContext, useState } from 'react'
import { DiscountTableContextData, DiscountTableData, DiscountTableListData, DiscountTableProviderProps } from './types'
import { useDiscountTableByListLazyQuery } from '~/graphql/types'

const DiscountTableContext = createContext({} as DiscountTableContextData)

export const DiscountTableProvider: React.FC<DiscountTableProviderProps> = ({ children }) => {
  const [discountTables, setDiscountTables] = useState<DiscountTableListData>([])
  const [selectedDiscountTable, setSelectedDiscountTable] = useState<DiscountTableData | null>(null)

  const [getDiscountTablesList, { loading: discountTablesIsLoading }] = useDiscountTableByListLazyQuery()

  return (
    <DiscountTableContext.Provider value={{
      discountTables,
      discountTablesIsLoading,
      selectedDiscountTable,
      setDiscountTables,
      setSelectedDiscountTable,
      getDiscountTablesList
    }}>
      {children}
    </DiscountTableContext.Provider>
  )
}

export const useDiscountTable = () => useContext(DiscountTableContext)
