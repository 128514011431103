import { toast } from 'react-toastify'
import { Info } from '@mui/icons-material'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { Dialog } from '~/components'
import { useProposalContext } from '~/contexts'
import { Button } from '~/components/Form/Button'
import { ProposalModel, useGenerateNewTermOfAdhesionMutation } from '~/graphql/types'
import { useCallback } from 'react'

export type GenerateTermOfManagementDialogProps = {
  isVisible: boolean
  toggleModal: () => void
}

export const GenerateTermOfManagementDialog: React.FC<GenerateTermOfManagementDialogProps> = ({ isVisible, toggleModal }) => {
  const { proposal, setProposal } = useProposalContext()
  const [requestTermOfManagement, { loading }] = useGenerateNewTermOfAdhesionMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if (data.generateNewTermOfAdhesion) {
        setProposal(data.generateNewTermOfAdhesion as ProposalModel)
      }
      toggleModal()
      toast.success('Novo termo de gestão gerado!', {
        autoClose: 3000,
        position: 'top-right'
      })
    },
  })

  const onClick = useCallback(async () => {
    if (proposal) {
      await requestTermOfManagement({ variables: { proposalId: proposal._id } })
    } else {
      toast.error('Nenhum contrato selecionado, tente recarregar a página ou entre em contato com o time de tecnologia.')
    }
  }, [proposal])

  return (
    <Dialog
      isVisible={isVisible}
      title='Gerar novo termo de gestão'
      onClose={loading ? undefined : toggleModal}
      titleIcon={<Info fontSize='large' color='primary' />}
    >
      <Box sx={{ flexDirection: 'column', display: 'flex', gap: 3 }}>
        <Divider />
        <Typography>Tem certeza que deseja gerar um novo termo de gestão?</Typography>
        <Divider />

        <Box sx={{ flexDirection: 'row', display: 'flex', gap: 3, justifyContent: 'end' }}>
          <Button
            color='secondary'
            disabled={loading}
            onClick={toggleModal}
          >
            Cancelar
          </Button>

          <Button
            color='primary'
            onClick={onClick}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={24} color='inherit' /> : null}
          >
            Sim, gerar novo termo
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
