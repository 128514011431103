import { EProposalFileType, EProposalLegalRepresentantPendencies, ProposalLegalRepresentativeModel } from '~/graphql/types'
import { clearString } from '~/utils'

type AddressForm = {
  city: string
  state: string
  street: string
  number: string
  zipcode: string
  complement: string
  neighborhood: string
}

export type LegalRepresentativeType = {
  //strings
  name: string
  email: string
  phone: string
  document: string
  nationality: string
  address: AddressForm
  maritalStatus: string
  identificationNumber: string

  //files
  customerPjRepresentativeDocumentWithPhotoBack?: any
  customerPjRepresentativeDocumentWithPhotoFront?: any
}

export type LegalRepresentativeForm = {
  legalRepresentative: LegalRepresentativeType[]
}

export const mapProposalBusinessProposalsToForm = (legalRepresentatives: ProposalLegalRepresentativeModel[]): LegalRepresentativeType[] => {
  return legalRepresentatives.map(legalRepresentative => {
    const customerPjRepresentativeDocumentWithPhotoBackFile = legalRepresentative.files?.find(file => file.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack)
    const customerPjRepresentativeDocumentWithPhotoFrontFile = legalRepresentative.files?.find(file => file.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoFront)

    return {
      name: legalRepresentative.name || '',
      phone: legalRepresentative.phone,
      email: legalRepresentative.email,
      address: {
        city: legalRepresentative.address?.city || '',
        state: legalRepresentative.address?.state || '',
        number: legalRepresentative.address?.number || '',
        street: legalRepresentative.address?.street || '',
        country: legalRepresentative.address?.country || '',
        zipcode: legalRepresentative.address?.zipcode || '',
        complement: legalRepresentative.address?.complement || '',
        neighborhood: legalRepresentative.address?.neighborhood || ''
      },
      document: legalRepresentative.document || '',
      nationality: legalRepresentative.nationality || '',
      maritalStatus: legalRepresentative.maritalStatus || '',
      identificationNumber: legalRepresentative.identificationNumber || '',
      customerPjRepresentativeDocumentWithPhotoBack: customerPjRepresentativeDocumentWithPhotoBackFile ? [customerPjRepresentativeDocumentWithPhotoBackFile] : undefined,
      customerPjRepresentativeDocumentWithPhotoFront: customerPjRepresentativeDocumentWithPhotoFrontFile ? [customerPjRepresentativeDocumentWithPhotoFrontFile] : undefined
    }
  })
}

export const getLegalRepresentativeSolvedPendencies = (legalRepresentativeFromForm: LegalRepresentativeType, legalRepresentative?: ProposalLegalRepresentativeModel): EProposalLegalRepresentantPendencies[] => {
  const solvedPendecies: EProposalLegalRepresentantPendencies[] = []

  if (legalRepresentative) {
    if (clearString(legalRepresentativeFromForm.phone) !== clearString(legalRepresentative.phone)) {
      solvedPendecies.push(EProposalLegalRepresentantPendencies.phone)
    }

    if (JSON.stringify(legalRepresentativeFromForm.address) !== JSON.stringify(legalRepresentative.address)) {
      solvedPendecies.push(EProposalLegalRepresentantPendencies.address)
    }

    if (legalRepresentativeFromForm.name !== legalRepresentative.name) {
      solvedPendecies.push(EProposalLegalRepresentantPendencies.name)
    }

    if (legalRepresentativeFromForm.email !== legalRepresentative.email) {
      solvedPendecies.push(EProposalLegalRepresentantPendencies.email)
    }

    if (legalRepresentativeFromForm.identificationNumber !== legalRepresentative.identificationNumber || '') {
      solvedPendecies.push(EProposalLegalRepresentantPendencies.identificationNumber)
    }

    if (legalRepresentativeFromForm.maritalStatus !== legalRepresentative.maritalStatus) {
      solvedPendecies.push(EProposalLegalRepresentantPendencies.maritalStatus)
    }

    if (legalRepresentativeFromForm.nationality !== legalRepresentative.nationality) {
      solvedPendecies.push(EProposalLegalRepresentantPendencies.nationality)
    }

    if (clearString(legalRepresentativeFromForm.document) !== clearString(legalRepresentative.document)) {
      solvedPendecies.push(EProposalLegalRepresentantPendencies.document)
    }
  }

  return solvedPendecies
}
