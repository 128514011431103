import { useEffect } from 'react'
import { Box, Button, CircularProgress, Typography, Checkbox as MuiCheckbox, FormControlLabel, useMediaQuery } from '@mui/material'
import { StepTitle } from '../StepTitle'
import { useForm, FormProvider, useWatch, Controller } from 'react-hook-form'
import { Radio } from '~/components/Form/Radio'
import { Input } from '~/components/Form/Input'
import { Checkbox } from '~/components/Form/Checkbox'
import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react'
import { ECustomerPersonType, EMaritalStatus, ProposalWithDealershipsQuery, useNotifyCustomerFinishedDocumentationMutation } from '~/graphql/types'
import { InputPendenciesStatus } from '../InputPendenciesStatus'
import { FieldsType } from '../types'
import { Dialog } from '~/components'
import { useToggle } from '~/hooks'
import { toast } from 'react-toastify'
import { theme, transformMoney, translateMaritalStatus } from '~/utils'
import { useAuth } from '~/contexts'

type BasicDataProps = {
  proposal: ProposalWithDealershipsQuery['proposalGet']
  onRefuse: Function
  onApprove: Function
  modalType: 'approve' | 'refuse'
  setFields: React.Dispatch<React.SetStateAction<FieldsType>>
}

type BasicDataForm = {
  personType: string,
  name: string,
  document: string,
  email: string,
  phone: string,
  uf: string,
  dealership: string,
  averageEnergyBillValue: string,
  maritalStatus: string | null,
  nationality: string,
  pendencies: {
    teste: boolean,
    personType: boolean,
    name: boolean,
    document: boolean,
    email: boolean,
    phone: boolean,
    uf: boolean,
    dealershipName: boolean,
    averageEnergyBillValue: boolean,
    maritalStatus: boolean,
    nationality: boolean,
  }
  controlPendencies: boolean
}

export const BasicData: React.FC<BasicDataProps> = ({ proposal, onRefuse, onApprove, setFields }) => {
  const formMethods = useForm<BasicDataForm>()
  const notificationModal = useToggle(false)
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const [notifyCustomer, { loading: notifyIsLoading }] = useNotifyCustomerFinishedDocumentationMutation({
    onCompleted: () => {
      toast.success(`Foi enviado um E-mail para ${proposal?.customerRef.email} notificando uma pendência.`)
      notificationModal.setIsTrue(false)
    },
    onError: () => {
      toast.error('Ocorreu um erro ao notificar o cliente.')
      notificationModal.setIsTrue(false)
    }
  })

  const { authUser } = useAuth()
  const isSeller = authUser?.user.type === 'seller'

  useEffect(() => {
    if (proposal?.customerRef) {
      formMethods.setValue('personType', proposal.customerRef.personType)
      formMethods.setValue('name', proposal.customerRef.name)
      formMethods.setValue('document', proposal.customerRef.document)
      formMethods.setValue('email', proposal.customerRef.email || '')
      formMethods.setValue('phone', proposal.customerRef.phone || '')
      formMethods.setValue('averageEnergyBillValue', String(transformMoney(proposal.customerRef.averageEnergyBillValue || 0, 'toReal')))
      formMethods.setValue('dealership', proposal.customerRef.dealershipName || '')
      formMethods.setValue('uf', proposal.customerRef.uf || '')
      formMethods.setValue('maritalStatus', translateMaritalStatus(proposal.customerRef.maritalStatus as EMaritalStatus))
      formMethods.setValue('nationality', proposal.customerRef.nationality || '')
    }
  }, [proposal])

  function handleSubmit(data: BasicDataForm) {
    const selectedFields = Object?.entries(data?.pendencies).filter(item => item[1])
    const objectFields = Object.fromEntries(selectedFields)
    setFields(objectFields)
  }

  const { pendencies } = useWatch({
    control: formMethods.control,
  })

  function onSelectAllFields(isValid: boolean) {
    formMethods.setValue('controlPendencies', !isValid)
    formMethods.setValue('pendencies.name', !isValid)
    formMethods.setValue('pendencies.document', !isValid)
    formMethods.setValue('pendencies.email', !isValid)
    formMethods.setValue('pendencies.phone', !isValid)
    formMethods.setValue('pendencies.uf', !isValid)
    formMethods.setValue('pendencies.dealershipName', !isValid)
    formMethods.setValue('pendencies.averageEnergyBillValue', !isValid)
    
    if(proposal?.customerRef.personType === ECustomerPersonType.pf) {
      formMethods.setValue('pendencies.maritalStatus', !isValid)
      formMethods.setValue('pendencies.nationality', !isValid)
    }
  }

  const enableSelectAll = [
    Boolean(!proposal?.customerRef?.name),
    Boolean(!proposal?.customerRef?.document),
    Boolean(!proposal?.customerRef?.email),
    Boolean(!proposal?.customerRef?.phone),
    Boolean(!proposal?.customerRef?.uf),
    Boolean(!proposal?.customerRef.dealershipName),
    Boolean(!proposal?.customerRef.averageEnergyBillValue)
  ].some(item => item)

  const enableSelectAllToPf = [
    Boolean(!proposal?.customerRef.nationality),
    Boolean(!proposal?.customerRef.maritalStatus)
  ].some(item => item)

  const buttonIsEnable = pendencies && Object?.values(pendencies)?.some(item => item)

  return (
    <>
      <StepTitle proposalId={proposal?._id} clientName={proposal?.customerRef.name} step={{ value: '1', title: 'Dados básicos' }} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => handleSubmit(data))}>

          <Typography sx={{ padding: '1rem 0 0 0' }} variant='h6'>Tipo de pessoa:</Typography>
          <Radio radioProps={{ disabled: true }} disabled row options={[{ value: 'pf', label: 'Pessoa física' }, { value: 'pj', label: 'Pessoa Jurídica' }]} name='personType' />

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0', justifyContent: 'space-between' }}>
            
            <Typography sx={{ padding: '1rem 0 0 0' }} variant='h6'>Dados pessoais</Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
              {!isLowerSm && 
                <Typography fontWeight={500} color={theme.palette.grey[600]} fontSize='.95rem' variant='h6'>Selecionar todos</Typography>
              }
              <Controller
                name='controlPendencies'
                control={formMethods.control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={{ margin: 0 }}
                    onChange={(e: any) => {
                      field.onChange(e)
                      onSelectAllFields(field.value)
                    }}
                    control={<MuiCheckbox disabled={proposal?.customerRef.personType === ECustomerPersonType.pf ? enableSelectAll || enableSelectAllToPf : enableSelectAll} />}
                    label={<Typography variant='body2' color='grey.400'></Typography>}
                  />
                )}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.name} /> } }}
              disabled
              name='name'
              label='Nome completo'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.name) }} name='pendencies.name' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.document} /> } }}
              mask='cpfCnpj'
              disabled
              name='document'
              label='CPF/CNPJ'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.document) }} name='pendencies.document' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.email} /> } }}
              disabled
              name='email'
              label='E-mail'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.email) }} name='pendencies.email' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.phone} /> } }}
              mask='phone'
              disabled
              name='phone'
              label='Telefone'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.phone) }} name='pendencies.phone' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.uf} /> } }}
              disabled
              name='uf'
              label='UF'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef?.uf) }} name='pendencies.uf' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.dealershipName} /> } }}
              disabled
              name='dealership'
              label='Fornecedora de energia'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef.dealershipName) }} name='pendencies.dealershipName' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
            <Input
              mask='BRL'
              icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.averageEnergyBillValue} /> } }}
              disabled
              name='averageEnergyBillValue'
              label='Valor médio de consumo'
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef.averageEnergyBillValue) }} name='pendencies.averageEnergyBillValue' />
          </Box>

          {proposal?.customerRef.personType === ECustomerPersonType.pf && (
            <>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                <Input
                  icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.nationality} /> } }}
                  disabled
                  name='nationality'
                  label='Nacionalidade'
                />
                <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef.nationality) }} name='pendencies.nationality' />
              </Box>

              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                <Input
                  icons={{ end: { element: <InputPendenciesStatus pendencie={proposal?.customerRef?.pendencies?.maritalStatus} /> } }}
                  disabled
                  name='maritalStatus'
                  label='Estado civil'
                />
                <Checkbox checkboxProps={{ disabled: Boolean(!proposal?.customerRef.maritalStatus) }} name='pendencies.maritalStatus' />
              </Box>
            </>
          )}

          <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', flexFlow: isLowerMd ? 'column' : 'row' }}>
            <Box sx={{ display: 'flex', gap: isLowerSm ? '0' : '1rem', justifyContent: 'space-between', width: '100%' }}>
              <Button size='small' disabled={isSeller} type='button' onClick={() => notificationModal.toggle()} startIcon={isLowerSm ? <></> : <IconInfoCircle />} color='secondary'>Notificar cliente</Button>
              <Button size='small' disabled={!buttonIsEnable || isSeller} type='submit' onClick={() => onRefuse()} startIcon={<IconX />} color='secondary'>
                Recusar {isLowerSm ? '' : 'selecionados'}
              </Button>
            </Box>
            <Box sx={{ minWidth: isLowerMd ? '' : '230px' }}>
              <Button fullWidth={isLowerMd} disabled={!buttonIsEnable || isSeller} type='submit' onClick={() => onApprove()} startIcon={<IconCheck />}>Aprovar selecionados</Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
      <Dialog onClose={() => notificationModal.toggle()} isVisible={notificationModal.isTrue}>
        <Box sx={{ width: '100%' }}>
          {notifyIsLoading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>
              <CircularProgress size={140} color='inherit' />
            </Box>

          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem', paddingBottom: '1.4rem' }}>
                <Typography variant='h6'>Notificar cliente</Typography>
              </Box>

              <Box sx={{ padding: '1rem 0', borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>

                <Typography>A notificação informará que os dados e documentos foram analisados, porém existem <b>dados inconsistentes ou rejeitados.</b></Typography>

                <Typography sx={{ paddingTop: '.8rem' }}>Deseja notificar o cliente?</Typography>

              </Box>

              <Box sx={{ 
                display: 'flex', 
                alignItems: isLowerSm ? 'auto' : 'center', 
                justifyContent: isLowerSm ? 'auto' : 'flex-end', 
                gap: '1rem', paddingTop: '1.4rem', 
                flexFlow: isLowerSm ? 'column' : 'row' 
              }}>
                <Button onClick={() => notificationModal.toggle()} color='secondary'>Cancelar</Button>
                <Button onClick={() => notifyCustomer({ variables: { params: { proposalId: proposal?._id || '' } } })}>Sim, notificar agora</Button>
              </Box>
            </>
          )}

        </Box>
      </Dialog>

    </>
  )
}
