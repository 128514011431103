import { SvgIcon, SvgIconProps } from '@mui/material'

export const Logout: React.FC<SvgIconProps> = ({ width, height, htmlColor, ...props }) => {
  return (
    <SvgIcon width={width || 24} height={height || 24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='transparent' d='M10 8V6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4H19C19.5304 4 20.0391 4.21071 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20H12C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18V16' stroke={htmlColor || '#434343'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path fill='transparent' d='M17 12H3M3 12L6 9M3 12L6 15' stroke={htmlColor || '#434343'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  )
}
