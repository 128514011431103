import { Box, Divider } from '@mui/material'
import { ContractConfig } from './components'

export const SelectedContract: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
      <Divider sx={{ marginBottom: 2 }} />
      <ContractConfig />
    </Box>
  )
}
