import styled from '@emotion/styled'
import { TabList } from '@mui/lab'

export const TabListWithPadding = styled(TabList)`
  /* position: relative;
  padding: .8rem 0;
  
  & > div > div > button {
    text-transform: unset;
    font-weight: 400;

    &[aria-selected="true"] {
      font-weight: 500;
    }
  }

  & > div {
    position: unset;
  } */
`
