/* eslint-disable no-unused-vars */
import { ArrowForward, Save } from '@mui/icons-material'
import { Box, CircularProgress, Divider, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Button } from '~/components/Form/Button'
import { ContractFlowActionDropdown, PropsWithWizard } from '~/components'
import { useContract } from '~/contexts'
import { EContractStatus } from '~/graphql/types'
import { useCallback, useMemo } from 'react'
import { usePermission } from '~/hooks'
import { useFormContext } from 'react-hook-form'
import { theme } from '~/utils'

export type ActionButtonsProps = {
  loading: boolean
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}
export const ActionButtons: React.FC<PropsWithWizard<ActionButtonsProps>> = ({ loading, onSubmit, onNext }) => {
  const navigate = useNavigate()
  const { formState } = useFormContext()
  const { contract, isDisabledContractUpdate } = useContract()
  const canCreateEditContracts = usePermission(['contracts.updated', 'contracts.create'])
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const isDeactivated = contract?.status === EContractStatus.deactivated
  const isDeactivating = contract?.status === EContractStatus.inDeactivation
  const isCancelling = contract?.status === EContractStatus.cancellationRequested

  const shouldDisableButtons = useMemo(() => isDeactivated || isDeactivating || isCancelling || loading || !canCreateEditContracts, [isDeactivated, isDeactivating, isCancelling, loading, canCreateEditContracts])

  const onPrevClick = () => {
    navigate('/app/contracts')
  }

  const handleSubmit = useCallback(async (submitType: 'continue' | 'save') => {
    await onSubmit()

    if (formState.isValid && submitType === 'continue') {
      onNext()
    }

  }, [formState])

  return (
    <>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }} >
        {!isLowerMd && (
          <Button disabled={loading} color='secondary' onClick={onPrevClick}>
            Cancelar
          </Button>
        )}

        {!isDisabledContractUpdate ? (
          <Box sx={{ display: 'flex', flexDirection: isLowerSm ? 'column' : 'row', gap: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: isLowerSm ? 'space-between' : 'flex-end' }}>
              <ContractFlowActionDropdown disabled={shouldDisableButtons || !contract} />

              <Button
                color='secondary'
                onClick={() => handleSubmit('save')}
                disabled={shouldDisableButtons}
                startIcon={loading ? <CircularProgress size={24} color='inherit' /> : <Save />}
                size='small'
              >
                Gravar dados
              </Button>
            </Box>

            <Button onClick={() => handleSubmit('continue')} endIcon={<ArrowForward />} disabled={shouldDisableButtons}>
              Continuar
            </Button>
          </Box>
        ) : <ContractFlowActionDropdown disabled={shouldDisableButtons || !contract} />}

      </Box>
    </>
  )
}
