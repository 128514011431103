import { Box, Typography } from '@mui/material'
import { useContract } from '~/contexts'
import { EContractStatus } from '~/graphql/types'
import { ContractDeactivationDialog, ContractUpdateStatusDialog } from '~/components'
import { useToggle } from '~/hooks'
import { useMemo } from 'react'
import { IconInfoCircleFilled } from '@tabler/icons-react'
import { theme } from '~/utils'

export const DeactivationWarning: React.FC = () => {
  const { contract } = useContract()
  const { isTrue: updateContractStatusModalIsOpen, toggle: toggleUpdateContractStatus } = useToggle()
  const { isTrue: contractDeactivationModalIsOpen, toggle: toggleContractDeactivation } = useToggle()

  const isDeactivated = contract?.status === EContractStatus.deactivated

  const warningText = useMemo(() => {
    if (!contract?.status) return null
    if (contract?.status === EContractStatus.cancellationRequested) {
      return <Typography><b>IMPORTANTE:</b> Para sincronizar o contrato, altere o status deste contrato para <b>Em desativação</b> (próxima etapa) <br /> conforme o status do contrato na Gera. Clique <a style={{ cursor: 'pointer', color: '#3BA1FF' }} onClick={toggleUpdateContractStatus}>aqui</a> para alterar</Typography>
    }
  
    if (contract?.status === EContractStatus.inDeactivation) {
      return <Typography><b>IMPORTANTE:</b> Continue a desativação do contrato conforme o status o contrato na Gera, altere o status deste contrato para <b>Desativado</b> (última etapa) <br /> para concluir. Clique <a style={{ cursor: 'pointer', color: '#3BA1FF' }} onClick={toggleContractDeactivation}>aqui</a> para alterar</Typography>
    }
  
    return <Typography>Este contrato está desativado</Typography>
  }, [])

  return (
    <>
      <Box sx={{
        gap: 3,
        padding: 3,
        display: 'flex',
        borderRadius: '12px',
        alignItems: 'center',
        flexDirection: 'row',
        border: `1px solid ${isDeactivated ? '#3BA1FF' : '#F3CC04'}`
      }}>
        <IconInfoCircleFilled style={{ color: isDeactivated ? theme.palette.info.dark : theme.palette.primary.main }} size={32} />
        {warningText}
      </Box>
      <ContractUpdateStatusDialog isVisible={updateContractStatusModalIsOpen} toggleModal={toggleUpdateContractStatus} />
      <ContractDeactivationDialog isVisible={contractDeactivationModalIsOpen} toggleModal={toggleContractDeactivation} />
    </>
  )
}
