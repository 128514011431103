import { Box, Typography } from '@mui/material'
import { Info } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useProposalContext } from '~/contexts'

export const HasPendenciesWarning: React.FC = () => {
  const { proposal } = useProposalContext()

  return (
    <Box sx={{
      gap: 3,
      padding: 3,
      display: 'flex',
      borderRadius: '12px',
      alignItems: 'center',
      flexDirection: 'row',
      border: '1px solid #F3CC04'
    }}>
      <Info fontSize='large' color='primary' />
      <Typography>
        É necessário aprovar as pendências antes de continuar, clique <Link style={{ color: '#3BA1FF', fontWeight: 500 }} to={`/app/proposals/${proposal?._id}/pendencies`}>AQUI</Link> para analisar
      </Typography>
    </Box>
  )
}
