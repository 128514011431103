/* eslint-disable no-unused-vars */
import { IconTrash } from '@tabler/icons-react'
import { Box, IconButton } from '@mui/material'
import { Input } from '~/components/Form/Input'
import { SpecialDiscountModel } from '~/graphql/types'

type SpecialDiscountProps = {
  index: number
  remove: (index: number) => void,
  specialDiscount: SpecialDiscountModel
  disabled?: boolean
}

const fieldName = 'specialDiscount'
export const SpecialDiscount: React.FC<SpecialDiscountProps> = ({ specialDiscount, index, remove, disabled }) => {
  return (
    <Box sx={{
      display: 'flex',
      borderRadius: '8px',
      border: '1px solid #E6E6E6',
      //justifyContent: 'center',
      marginTop: 2,
    }}>
      <Box sx={{
        display: 'flex',
        //flexGrow: 1,
        color: '#999999',
        alignItems: 'center',
        padding: '0px 16px',
        backgroundColor: '#FAFAFA',
        borderRadius: '8px 0px 0px 8px'
      }}>
        {specialDiscount && `${specialDiscount.month}º mês`}
      </Box>
      <Box sx={{
        display: 'flex',
        flex: 1,
        borderRadius: '0px 8px 8px 0px',
        gap: 1
      }}>
        <Input disabled={disabled} sx={{ '& fieldset': { border: 'none' } }} name={`${fieldName}.${index}.discountPercent`} placeholder='Desconto (em %)' mask='percentage' />
      </Box>
      {!disabled && <IconButton style={{ padding: '0px 16px' }} onClick={() => remove(index)}><IconTrash color='#EF4444' /></IconButton>}
    </Box>
  )
}
