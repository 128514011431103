import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { Card, ProposalFlowLayout, Wizard } from '~/components'
import { UseWizardResult, useWizard } from '~/hooks'
import { useProposalContext } from '~/contexts'
import { ECustomerPersonType, ProposalModel, useProposalGetLazyQuery } from '~/graphql/types'
import { Address, BaseData, Documentation, Formalization, LegalRepresentative } from './components'

export const ProposalCreate: React.FC = () => {
  const { setProposal, resetProposal, proposal } = useProposalContext()
  const [getProposal, { loading }] = useProposalGetLazyQuery()

  const { proposalId } = useParams()

  const fetchProposal = async () => {
    if (proposalId) {
      try {
        const { data } = await getProposal({ variables: { id: proposalId }, fetchPolicy: 'no-cache' })
        setProposal(data?.proposalGet as ProposalModel || null)
      } catch (err) {
        toast.error('Não foi possível carregar a proposta', {
          autoClose: 3000,
          position: 'top-right'
        })
      }

      return
    }

    resetProposal()
  }

  useEffect(() => {
    fetchProposal()
  }, [proposalId])

  useEffect(() => {
    return () => {
      resetProposal()
    }
  }, [])

  const screens = useMemo(() => [
    BaseData,
    Address,
    proposal?.customerRef.personType === ECustomerPersonType.pj && LegalRepresentative,
    Documentation,
    Formalization
  ].filter(screen => screen) as React.ComponentType<UseWizardResult>[], [proposal])

  const wizardHelpers = useWizard(screens.length)

  if (loading) {
    return (
      <Card sx={{ minHeight: '800px', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Card>
    )
  }

  return (
    <ProposalFlowLayout {...wizardHelpers}>
      <Wizard screens={screens} wizardHelpers={wizardHelpers} />
    </ProposalFlowLayout>
  )
}
