/* eslint-disable no-unused-vars */
import { Delete } from '@mui/icons-material'
import { Box, CircularProgress, Divider, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { clearString, theme } from '~/utils'
import { brazilStates } from '~/constants'
import { Input } from '~/components/Form/Input'
import { Select } from '~/components/Form/Select'
import { Button } from '~/components/Form/Button'
import { LegalRepresentativeType } from '../../types'
import { maritalStatusOptions } from '~/utils/options'
import { DragAndDrop } from '~/components/Form/DragAndDrop'
import { CustomerModel, ECustomerFileType } from '~/graphql/types'
import { IconSearch } from '@tabler/icons-react'
import { useCep } from '~/hooks'

export type LegalRepresentativeFieldsProps = {
  index: number
  loading: boolean
  fieldsLength: number
  legalRepresentative: LegalRepresentativeType
  removeLegalRepresentative: (index: number) => void
  customer: CustomerModel | null
  onSearchCep: (index: number) => void
}

const fieldName = 'legalRepresentatives'

export const LegalRepresentativeFields: React.FC<LegalRepresentativeFieldsProps> = ({ index, legalRepresentative, removeLegalRepresentative, fieldsLength, loading, customer, onSearchCep }) => {
  const { isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const currentLegalRepresentative = customer?.legalRepresentatives?.find(representative => clearString(representative?.document || '') === clearString(legalRepresentative.document || ''))
  const customerPjRepresentativeDocumentWithPhotoBackFile = currentLegalRepresentative?.files?.find(file => file.type === ECustomerFileType.customerPjRepresentativeDocumentWithPhotoBack)
  const customerPjRepresentativeDocumentWithPhotoFrontFile = currentLegalRepresentative?.files?.find(file => file.type === ECustomerFileType.customerPjRepresentativeDocumentWithPhotoFront)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant='h3' fontWeight={500}>Representante legal</Typography>
      <Grid spacing={3} container>
        <Grid item xs={12} md={8}>
          <Input name={`${fieldName}.${index}.name`} label='Nome completo' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input mask='cpf' name={`${fieldName}.${index}.document`} label='CPF' />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input name={`${fieldName}.${index}.identificationNumber`} label='RG' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input name={`${fieldName}.${index}.email`} label='E-mail' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input mask='phone' name={`${fieldName}.${index}.phone`} label='Telefone' />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input name={`${fieldName}.${index}.nationality`} label='Nacionalidade' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Select name={`${fieldName}.${index}.maritalStatus`} label='Estado civil' options={maritalStatusOptions} />
        </Grid>
      </Grid>

      <Typography fontWeight={500}>Endereço</Typography>
      <Grid spacing={3} container>
        <Grid item md={4} xs={12}>
          <Input
            icons={{ end: { element: fetchCepIsLoading ? <CircularProgress size={18} color='inherit' /> : <IconButton onClick={() => onSearchCep(index)}><IconSearch /></IconButton> } }}
            label='CEP'
            name={`${fieldName}.${index}.address.zipcode`}
            mask='cep'
          />
        </Grid>
        {!isMobile && <Grid item md={8} />}

        <Grid item md={4} xs={12}>
          <Input label='Rua' name={`${fieldName}.${index}.address.street`} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input label='Número' name={`${fieldName}.${index}.address.number`} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input label='Complemento (Opcional)' name={`${fieldName}.${index}.address.complement`} />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input label='Bairro' name={`${fieldName}.${index}.address.neighborhood`} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input label='Cidade' name={`${fieldName}.${index}.address.city`} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Select name={`${fieldName}.${index}.address.state`} label='UF' options={brazilStates} />
        </Grid>
      </Grid>

      <Typography fontWeight={500}>Documento</Typography>
      <Grid spacing={3} container>
        <Grid item md={6} xs={12}>
          <DragAndDrop
            canResend
            label='Anexar RG ou CNH (Frente)'
            fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
            $variant='secondary'
            sentAt={customerPjRepresentativeDocumentWithPhotoFrontFile?.sendAt}
            $completed={Boolean(customerPjRepresentativeDocumentWithPhotoFrontFile?.sendAt)}
            fileName={customerPjRepresentativeDocumentWithPhotoFrontFile?.file?.fileName}
            name={`${fieldName}.${index}.customerPjRepresentativeDocumentWithPhotoFront`}
            fileUrl={customerPjRepresentativeDocumentWithPhotoFrontFile?.file?.fileUrl}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DragAndDrop
            canResend
            $variant='secondary'
            label='Anexar RG ou CNH (Verso)'
            fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
            sentAt={customerPjRepresentativeDocumentWithPhotoBackFile?.sendAt}
            $completed={Boolean(customerPjRepresentativeDocumentWithPhotoBackFile?.sendAt)}
            fileName={customerPjRepresentativeDocumentWithPhotoBackFile?.file?.fileName}
            name={`${fieldName}.${index}.customerPjRepresentativeDocumentWithPhotoBack`}
            fileUrl={customerPjRepresentativeDocumentWithPhotoBackFile?.file?.fileUrl}
          />
        </Grid>
      </Grid>

      {fieldsLength !== 1 && (
        <Button
          color='error'
          disabled={loading}
          variant='outlined'
          startIcon={<Delete />}
          style={{ alignSelf: 'end' }}
          onClick={() => removeLegalRepresentative(index)}
        >
          Remover representante
        </Button>
      )}

      {!(fieldsLength === index + 1) && <Divider />}
    </Box>
  )
}
