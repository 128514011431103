import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { AuthContextData, AuthProviderProps } from './types'
import { JwtUserTokenGraphql, useGetAuthUserQuery, useLoginUserMutation } from '~/graphql/types'
import { getToken, removeToken, storeSessionToken, storeToken } from '~/utils/token'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApolloError } from '@apollo/client'
import { toast } from 'react-toastify'
import { LoginFormData } from '~/pages/Login/components/LoginForm'

const AuthContext = createContext({} as AuthContextData)

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authUser, setAuthUser] = useState<null | JwtUserTokenGraphql>(null)
  const [authUserIsLoading, setAuthUserIsLoading] = useState(true)
  const [loginUser, { loading: loginIsLoading }] = useLoginUserMutation({ fetchPolicy: 'no-cache' })
  const { refetch: refetchGetMe } = useGetAuthUserQuery({ fetchPolicy: 'no-cache' })
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const authLogin = async (formData: LoginFormData) => {
    try {
      const result = await loginUser({
        variables: {
          password: formData.password,
          username: formData.username
        }
      })

      if (result.data) {
        if(formData.rememberMe) {
          storeToken(result.data.loginUser.accessToken)
        } else {
          storeSessionToken(result.data.loginUser.accessToken)
        }

        await getMe()
      }
    } catch (err: any) {
      const apolloError = err as ApolloError
      const errMessage = apolloError.graphQLErrors[0]
      toast.error(errMessage?.extensions?.code === 'BAD_USER_INPUT' ? 'E-mail ou senha incorretos' : 'houve um problema ao realizar o login', { autoClose: 2000 })
    }
  }

  const getMe = useCallback(async () => {
    setAuthUserIsLoading(true)
    try {
      const response = await refetchGetMe()

      if (response.data) {
        setAuthUser(response.data.getAuthUser as JwtUserTokenGraphql)
        if(!pathname.includes('/app')) {
          navigate('/app/')
        }
      } else {
        removeToken()
      }

    } catch (err) {
      removeToken()
    } finally {
      setAuthUserIsLoading(false)
    }
  }, [navigate])

  const logout = useCallback(() => {
    removeToken()
    setAuthUser(null)
    navigate('/')
  }, [])

  useEffect(() => {
    const token = getToken()
    if (token) {
      getMe()
    }
    else {
      setAuthUserIsLoading(false)
      if (pathname.includes('/app')) {
        navigate('/')
      }
    }
  }, [])

  return (
    <AuthContext.Provider value={{
      setAuthUser,
      authUser,
      authUserIsLoading,
      authLogin,
      loginIsLoading,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
