import { useCallback } from 'react'
import { CircularProgress, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Input } from '~/components/Form/Input'
import { Select } from '~/components/Form/Select'
import { brazilStates } from '~/constants'
import { ECustomerPersonType } from '~/graphql/types'
import { BaseDataForm } from '../../utils'
import { useCep } from '~/hooks'
import { IconSearch } from '@tabler/icons-react'
import { theme } from '~/utils'

export type AddressFieldsProps = {
  disableFields?: boolean
}
export const AddressFields: React.FC<AddressFieldsProps> = ({ disableFields }) => {
  const { watch, setValue } = useFormContext<BaseDataForm>()
  const { fetchCep, isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const isPf = watch('personType') === ECustomerPersonType.pf
  const address = watch('address')

  const onSearchCep = useCallback(async () => {
    if (address?.zipcode) {
      const { data, isSuccess } = await fetchCep(address?.zipcode)
      if (isSuccess) {
        setValue('address.state', data?.uf || '')
        setValue('address.city', data?.localidade || '')
        setValue('address.neighborhood', data?.bairro || '')
        setValue('address.street', data?.logradouro || '')
      }
    }
  }, [address])

  return (
    <>
      <Typography variant='h3' fontWeight={500}>Endereço {isPf ? '' : 'da empresa'}</Typography>
      <Grid spacing={3} container>
        <Grid item md={4} xs={12}>
          <Input
            icons={{ end: { element: fetchCepIsLoading ? <CircularProgress size={18} color='inherit' /> : <IconButton disabled={disableFields} onClick={() => onSearchCep()}><IconSearch /></IconButton> } }}
            label='CEP'
            name='address.zipcode'
            mask='cep'
            disabled={disableFields}
          />
        </Grid>
        {!isLowerMd && <Grid item md={8} xs={12} />}

        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Rua' name='address.street' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Número' name='address.number' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Complemento (Opcional)' name='address.complement' />
        </Grid>

        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Bairro' name='address.neighborhood' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Input disabled={disableFields} label='Cidade' name='address.city' />
        </Grid>
        <Grid item md={4} xs={12}>
          <Select disabled={disableFields} name='address.state' label='UF' options={brazilStates} />
        </Grid>
      </Grid>
    </>
  )
}
