import { SvgIcon, SvgIconProps } from '@mui/material'

export const WritingPen: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <SvgIcon width={width || 24} height={height || 24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M15 6C15 4.32672 16.3267 3 18 3C19.6733 3 21 4.32672 21 6V18C21 18.2652 20.8946 18.5196 20.7071 18.7071L18.7071 20.7071C18.3166 21.0976 17.6834 21.0976 17.2929 20.7071L15.2929 18.7071C15.1054 18.5196 15 18.2652 15 18V6ZM18 5C17.4313 5 17 5.43128 17 6V17.5858L18 18.5858L19 17.5858V6C19 5.43128 18.5687 5 18 5Z' fill='#561885' />
      <path fillRule='evenodd' clipRule='evenodd' d='M15 8C15 7.44772 15.4477 7 16 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H16C15.4477 9 15 8.55228 15 8Z' fill='#561885' />
      <path fillRule='evenodd' clipRule='evenodd' d='M5 12C5 11.4477 5.44772 11 6 11H9C9.79565 11 10.5587 11.3161 11.1213 11.8787C11.6839 12.4413 12 13.2043 12 14C12 14.7957 11.6839 15.5587 11.1213 16.1213C10.5587 16.6839 9.79565 17 9 17H5C4.73478 17 4.48043 17.1054 4.29289 17.2929C4.10536 17.4804 4 17.7348 4 18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H18C18.5523 19 19 19.4477 19 20C19 20.5523 18.5523 21 18 21H5C4.20435 21 3.44129 20.6839 2.87868 20.1213C2.31607 19.5587 2 18.7956 2 18C2 17.2044 2.31607 16.4413 2.87868 15.8787C3.44129 15.3161 4.20435 15 5 15H9C9.26522 15 9.51957 14.8946 9.70711 14.7071C9.89464 14.5196 10 14.2652 10 14C10 13.7348 9.89464 13.4804 9.70711 13.2929C9.51957 13.1054 9.26522 13 9 13H6C5.44772 13 5 12.5523 5 12Z' fill='#561885' />
    </SvgIcon>
  )
}
