import { useCallback, useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'ramda'
import { useForm, FormProvider } from 'react-hook-form'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { IconX, IconFilter, IconArrowDownLeft, IconArrowUpRight, IconCurrencyDollar } from '@tabler/icons-react'
import { Box, Button, CircularProgress, Drawer, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useCustomerFlow } from '~/contexts'
import { Input } from '~/components/Form/Input'
import { Status, TableApi, TableHeaderApi } from '~/components'
import { useApiPagination, useDebounce, useToggle } from '~/hooks'
import { formatDate, formatMoney, theme, transformMoney } from '~/utils'
import { useGetDigitalAccountWalletExtractByCustomerIdQuery, useGetWalletBalanceByCustomerIdQuery } from '~/graphql/types'

type FilterTableData = {
  dateEnd: Date | null
  dateBegin: Date | null
}

const DEFAULT_FILTER_VALUES = {
  dateBegin: new Date('2023-01-01T00:00:00.000Z'),
  dateEnd: new Date('2031-12-31T00:00:00.000Z')
}

export const DigitalAccountStatement: React.FC = () => {
  const { isTrue, toggle } = useToggle(false)
  const { customer } = useCustomerFlow()
  const paginationData = useApiPagination({ pageSize: 20, currentPage: 0 })
  const isLowerlg = useMediaQuery(theme.breakpoints.down('xl'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [filterTable, setFilterTable] = useState<FilterTableData>(DEFAULT_FILTER_VALUES)

  const debouncedFilterTable = useDebounce(filterTable, 1000)

  useEffect(() => {
    paginationData.setFiltersTable(filterTable)
    paginationData.setMetadata((prev) => ({ ...prev, currentPage: 0 }))
  }, [debouncedFilterTable])

  const { data, loading } = useGetDigitalAccountWalletExtractByCustomerIdQuery({
    variables: {
      customerId: customer?._id || '',
      filters: {
        pageSize: paginationData.metadata.pageSize,
        pageNumber: paginationData.metadata.currentPage,
        dateBegin: paginationData.filtersTable?.dateBegin ? paginationData.filtersTable?.dateBegin : DEFAULT_FILTER_VALUES.dateBegin,
        dateEnd: paginationData.filtersTable?.dateEnd ? paginationData.filtersTable?.dateEnd : DEFAULT_FILTER_VALUES.dateEnd,
      },
    },
    onCompleted(dataResponse) {
      paginationData.setMetadata({ ...paginationData.metadata, documentCounts: dataResponse.getDigitalAccountWalletExtractByCustomerId?.meta.documentCounts })
    },
    fetchPolicy: 'no-cache'
  })

  const { data: walletData, loading: loadingWalletData } = useGetWalletBalanceByCustomerIdQuery({
    variables: {
      customerId: customer?._id || ''
    },
    fetchPolicy: 'no-cache'
  })

  const formMethods = useForm()

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'description',
        Cell: ({ value, row }: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem', padding: '.8rem 0' }}>
            <Box sx={{
              width: '44px',
              height: '44px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '.5rem',
              justifyContent: 'center',
              background: theme.palette.grey['100'],
              border: `1px solid ${theme.palette.grey['300']}`
            }}>
              {row.original.operationType === 'C'
                ? <IconArrowDownLeft size={24} color={theme.palette.success.main} />
                : <IconArrowUpRight size={24} color={theme.palette.error.main} />
              }
            </Box>
            <Box display='flex' flexDirection='column'>
              <Tooltip title={value}>
                <Typography sx={{ fontSize: '1rem', color: theme.palette.black.main, fontWeight: 500 }}>{value?.length > 60 ? `${value?.substr(0, 60)}...` : value}</Typography>
              </Tooltip>
              {row.original.metadata?.contractId && <Typography>contrato: {row.original.metadata?.contractId}</Typography>}
            </Box>
          </Box>
        )
      },
      {
        Header: 'Data',
        accessor: 'createdAt',
        Cell: ({ value }) => <>{formatDate(value)}</>
      },
      {
        Header: 'Valor',
        accessor: 'valueCents',
        Cell: ({ value }) => <>{formatMoney(transformMoney(value, 'toReal'))}</>
      },
      {
        Header: 'Tipo',
        accessor: 'operationType',
        Cell: ({ value }) => (
          <>
            {value === 'C'
              ? <Status background={theme.palette.success.light} color={theme.palette.success.main} text='Entrada' />
              : <Status background={theme.palette.error.light} color={theme.palette.error.main} text='Saída' />}
          </>
        )
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.getDigitalAccountWalletExtractByCustomerId?.data || [], [data])

  const defaultColumnHiddens = isLowerlg ? ['createdAt'].concat(isLowerMd ? [] : '').concat(isLowerSm ? ['customerRef.document'] : '') : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    defaultColumn: {
      Filter: <></>
    },
    manualPagination: true,
    initialState: {
      sortBy: [{ id: 'createdAt', desc: true }],
      hiddenColumns: [...defaultColumnHiddens, 'customerRef.personType']
    },
  },
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
    //disable checkboxColumn
    // (hooks) => renderTableCheckbox(hooks)
  )

  const onApplyFilters = useCallback((formData: any) => {
    setFilterTable({
      ...filterTable,
      dateBegin: formData.dateBegin.length > 1 ? formData.dateBegin : DEFAULT_FILTER_VALUES.dateBegin,
      dateEnd: formData.dateEnd.length > 1 ? formData.dateEnd : DEFAULT_FILTER_VALUES.dateEnd,
    })
    toggle()
  }, [toggle, filterTable])

  const onRemoveFilters = useCallback(() => {
    setFilterTable({ dateBegin: DEFAULT_FILTER_VALUES.dateBegin, dateEnd: DEFAULT_FILTER_VALUES.dateEnd })
    toggle()
  }, [toggle])

  return (
    <div>
      <TableHeaderApi
        styles={{ hasBorderTop: false }}
        endElement={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Button disabled={isEmpty(dataWithMemo)} color='secondary' startIcon={<IconFilter />} onClick={toggle}>Filtros</Button>
          </Box>
        }
        startElement={
          (
            <>
              <Typography fontSize={isLowerMd ? '1.3rem' : '1.7rem'} variant='h3'>Extrato de cashback</Typography>

              <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={isTrue} onClose={toggle}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
                  <IconButton type='button' onClick={toggle}>
                    <IconX />
                  </IconButton>
                </Box>

                <FormProvider {...formMethods}>
                  <form onSubmit={formMethods.handleSubmit((formData) => onApplyFilters(formData))}>
                    <Typography>Período</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '2rem 0' }}>
                      <Input name='dateBegin' type='date' />
                      <Typography>Até</Typography>
                      <Input name='dateEnd' type='date' />
                    </Box>
                    <Box sx={{ display: 'flex', padding: '2rem 0', gap: '1rem', justifyContent: 'flex-end' }}>
                      <Button onClick={onRemoveFilters} type='button' fullWidth color='secondary'>Limpar</Button>
                      <Button type='submit' fullWidth color='primary'>Aplicar</Button>
                    </Box>
                  </form>
                </FormProvider>
              </Drawer>
            </>
          )
        }
      />
      <Box sx={{ display: 'flex', gap: 1, alignSelf: 'start' }}>
        <Box sx={{
          display: 'flex',
          padding: '10px',
          background: 'rgba(255, 238, 148, 0.15)',
          border: '1px solid #F9DC45',
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <IconCurrencyDollar color='#F9DC45' size={24} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color='grey.400'>Valor disponível</Typography>
          {loadingWalletData ? <CircularProgress size={18} color='inherit' /> : <Typography fontWeight={500}>{formatMoney(transformMoney(walletData?.getWalletBalanceByCustomerId?.balanceCents || 0, 'toReal'))}</Typography>}
        </Box>
      </Box>
      <TableApi
        columns={columns}
        isLoading={loading}
        backgroundColor='#FAFAFA'
        tableMethods={tableMethods}
        paginationData={paginationData}
      />
    </div>
  )
}
