import { Box, Link, Typography, useMediaQuery } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { theme } from '~/utils'
import { ProposalModel } from '~/graphql/types'

export type FormalizationInfoProps = {
  proposal: ProposalModel | null
}

export const FormalizationInfo: React.FC<FormalizationInfoProps> = ({ proposal }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: isLowerSm ? 'column' : 'row',
      gap: 3,
      padding: 3,
      alignItems: isLowerSm ? 'flex-start' : 'center',
      border: '1px solid #00A86B',
      borderRadius: '12px',
      marginBottom: '1rem'
    }}>

      <CheckCircle fontSize='large' color='success' />
      <Typography>
        A proposta foi concluída e o {proposal?.contractId ? <Link color={theme.palette.info.dark} href={`/app/contracts/${proposal.contractId}`}>contrato</Link> : 'contrato'} foi gerado com sucesso.
      </Typography>
    </Box>
  )
}
