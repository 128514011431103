import { createContext, useContext, useEffect, useState } from 'react'
import { ConsortiumContextData, ConsortiumData, ConsortiumListData, ConsortiumProviderProps } from './types'
import { useConsortiumListLazyQuery } from '~/graphql/types'

const ConsortiumContext = createContext({} as ConsortiumContextData)

export const ConsortiumProvider: React.FC<ConsortiumProviderProps> = ({ children }) => {
  const [consortiums, setConsortiums] = useState<ConsortiumListData>([])
  const [selectedConsortium, setSelectedConsortium] = useState<ConsortiumData | null>(null)

  const [getConsortiums, { data, loading: consortiumListIsLoading }] = useConsortiumListLazyQuery()

  useEffect(() => {
    if(data) {
      setConsortiums(data.consortiumList.data as ConsortiumListData)
    }
  }, [data])

  return (
    <ConsortiumContext.Provider value={{
      consortiumListIsLoading,
      consortiums,
      selectedConsortium,
      setConsortiums,
      setSelectedConsortium,
      getConsortiums
    }}>
      {children}
    </ConsortiumContext.Provider>
  )
}

export const useConsortium = () => useContext(ConsortiumContext)
