/* eslint-disable no-unused-vars */
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckCircle, Download } from '@mui/icons-material'
import { Box, CircularProgress, Link, Typography, useMediaQuery } from '@mui/material'
import { Dialog } from '~/components'
import { DialogProps } from '~/components/Dialog'
import { Button } from '~/components/Form/Button'
import { ProposalCreateMutationResult } from '~/graphql/types'
import { theme } from '~/utils'

export type CreatingProposalDialogProps = {
  proposal?: ProposalCreateMutationResult['data']
} & DialogProps

export const CreatingProposalDialog: React.FC<CreatingProposalDialogProps> = ({ isVisible, onClose, loading, proposal }) => {
  const navigate = useNavigate()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const latestPreProposal = useMemo(() => proposal?.proposalCreate?.preProposals && proposal?.proposalCreate?.preProposals[proposal?.proposalCreate?.preProposals.length - 1], [proposal])

  const onButtonPress = useCallback(() => {
    proposal && navigate(`/app/proposals/${proposal?.proposalCreate?._id}`, { state: { newProposalCreated: true } })
    onClose && onClose()
  }, [proposal, onClose])

  return (
    <Dialog isVisible={isVisible}>
      {loading && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', textAlign: 'center' }}>
          <CircularProgress size={100} color='inherit' />
          <Typography sx={{ marginTop: 3 }} fontWeight={600} variant='h3'>Criando proposta...</Typography>
          <Typography color='grey.400'>Aguarde, estamos terminando de <br />gerar a proposta</Typography>
        </Box>
      )}
      {!loading && proposal && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', textAlign: 'center' }}>
          <CheckCircle sx={{ fontSize: 100 }} color='success' />
          <Typography fontWeight={600} variant='h3'>Sucesso!</Typography>
          <Typography color='grey.400'>Proposta criada com sucesso para cliente <b>{proposal?.proposalCreate?.cashbackInfo?.active ? 'cashback' : 'desconto'}.</b><br />
            Gostaria de baixar a simulação de <br />
            economia dessa proposta?
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: isLowerSm ? 'column' : 'row', flexGrow: 1, justifyContent: 'space-between', width: '100%' }}>
            <Button
              size='small'
              color='secondary'
              onClick={onButtonPress}
            >
              Mais tarde
            </Button>
            <Link
              href={latestPreProposal?.file?.fileUrl || undefined}
              color='secondary'
              component={Button}
              style={{ color: 'black' }}
              startIcon={<Download />}
              onClick={onButtonPress}
            >
              Baixar simulação
            </Link>
          </Box>
        </Box>
      )}
    </Dialog>
  )
}
