import styled from '@emotion/styled'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Dropdown } from '../Dropdown'
import { Button } from '../Form/Button'
import { EProposalStatus, ProposalModel } from '~/graphql/types'
import { useToggle } from '~/hooks'
import { ProposalCancelDialog } from './components'
import { theme } from '~/utils'

const CustomLink = styled.a`
  flex: 1;
  gap: 16px;
  padding: 16px;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  color: black;
  &:hover {
    background: #FAFAFA;
  }
`

export type ProposalFlowActionDropdownProps = {
  proposal: ProposalModel | null
  disabled?: boolean
}
export const ProposalFlowActionDropdown: React.FC<ProposalFlowActionDropdownProps> = ({ proposal, disabled = false }) => {
  const latestPreProposal = proposal?.preProposals && proposal?.preProposals[proposal?.preProposals.length - 1]
  const { isTrue: isVisibleProposalCancelModal, toggle: toggleProposalCancelModal, setIsTrue: setIsVisibleProposalCancelModal } = useToggle()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Dropdown
        $options={{ left: '-100px', top: `${proposal?.status === EProposalStatus.formalization || proposal?.status === EProposalStatus.documentation ? '-110px' : '-60px'}` }}
        elementClick={(
          <Button
            size={isLowerSm ? 'small' : 'medium'}
            // disabled={disabled}
            color='secondary'
            endIcon={<ExpandMore />}>
            Ações
          </Button>
        )}
      >
        <Box sx={{
          display: 'flex',
          borderRadius: 3,
          flexDirection: 'column',
          minWidth: '220px',
          background: 'white',
          boxShadow: '0px 0px 2px rgba(64, 64, 64, 0.1), 0px 6px 15px -4px rgba(64, 64, 64, 0.14)'
        }}>
          <CustomLink target='_blank' href={latestPreProposal?.file?.fileUrl || undefined}>
            <Typography variant='body2'>Baixar proposta comercial</Typography>
          </CustomLink>
          {
            (proposal?.status === EProposalStatus.formalization || proposal?.status === EProposalStatus.documentation) && (
              <Box onClick={toggleProposalCancelModal} sx={{ cursor: 'pointer', padding: 2, display: 'flex', flex: 1, gap: 2, alignItems: 'center', color: 'black', '&:hover': { background: '#FAFAFA' } }}>
                <Typography variant='body2' color='error'>Cancelar proposta</Typography>
              </Box>
            )
          }

        </Box>
      </Dropdown>
      <ProposalCancelDialog isVisible={isVisibleProposalCancelModal} setIsVisibleProposalCancelModal={setIsVisibleProposalCancelModal} />
    </>
  )
}
