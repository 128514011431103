import { Box, Button, Card, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { IconArrowRight, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { Status, TableApiV2, TableHeaderApi } from '~/components'
import { ContentTitle } from '~/components/ContentTitle'
import { useAccessControl } from '~/contexts'
import { useDebounce, usePaginationValues, usePermission } from '~/hooks'
import { formatDate, theme } from '~/utils'

export const AccessControlList: React.FC = () => {
  const navigate = useNavigate()
  const { accessControls, accessControlListIsLoading, getAccessControls } = useAccessControl()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const [searchParams, setSearchParams] = useSearchParams()
  const { page, size } = usePaginationValues()
  const name = searchParams.get('name')
  const [filterName, setFilterName] = useState(name || '')
  const debouncedFilterName = useDebounce(filterName, 1000)

  const handleFilters = (filters: { name: string }) => {
    setSearchParams(state => {
      if(filters.name) {
        state.set('name', filters.name)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }
  
  useEffect(() => {
    handleFilters({ name: debouncedFilterName })
  }, [debouncedFilterName])

  useEffect(() => {
    getAccessControls({
      variables: {
        params: {
          sort: {
            field: 'createdAt',
            order: -1
          },
          pageSize: size,
          pageNumber: page,
          ...name && name?.length > 0 ? { name: name.trim() } : {},
        }
      },
      onCompleted(result) {
        setSearchParams(state => {
          state.set('items', String(result.accessControlList.meta.documentCounts))
          return state
        })
      },
    })
  }, [searchParams])

  const canCreateAndEditAccessControl = usePermission(['accessControl.updated', 'accessControl.create'])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Status',
        accessor: 'active',
        disableSortBy: true,
        Cell: ({ value }) => <>{value ? <Status color={theme.palette.success.main} text='Ativo' /> : <Status color={theme.palette.error.main} text='Inativo' />}</>
      },
      {
        Header: 'Criado',
        accessor: 'createdAt',
        Cell: ({ value }) => <>{formatDate(value)}</>
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            {canCreateAndEditAccessControl ? (
              <Link to={`/app/access-control/update/${value}`}>
                <IconButton>
                  <IconArrowRight />
                </IconButton>
              </Link>
            ) : (
              <IconButton disabled={true}>
                <IconArrowRight />
              </IconButton>
            )}
          </>
        )
        ,
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => accessControls || [], [accessControls])

  const defaultColumnHiddens = isLowerMd ? ['createdAt'] : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    },
    manualPagination: true,
    defaultColumn: {
      Filter: <></>,
    },
  },
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
  //disable checkboxColumn
  // (hooks) => renderTableCheckbox(hooks)
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Perfis'
          description='Cadastro e gestão de perfis de usuário'
          breadcrumbLinks={{ currentLink: 'Perfis do sistema', links: [{ href: '/app/', label: 'Home' }, { href: '/app/', label: 'Configurações' }] }}
          rightContent={<Button disabled={!canCreateAndEditAccessControl} onClick={() => navigate('/app/access-control/create')} startIcon={<IconPlus />}>Novo perfil</Button>}
        />
        <TableHeaderApi
          startElement={(
            <>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder='Procurar controle de acesso por nome'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </>
          )}
          endElement={
            (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {tableMethods.selectedFlatRows.length !== 0 &&
                  (
                    <>
                      <Typography color='grey.600'>{tableMethods.selectedFlatRows.length} Selecionado(s)</Typography>
                      <Button sx={{ fontWeight: 600 }} size='small' startIcon={<IconTrash></IconTrash>} color='error'>Excluir</Button>
                    </>
                  )
                }
              </Box>
            )
          }
        />
        <TableApiV2
          isLoading={accessControlListIsLoading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Card>
    </div>
  )
}
