import { SvgIcon, SvgIconProps } from '@mui/material'

export const HeartHandshake: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <SvgIcon sx={{ color: 'transparent' }} width={width || 40} height={height || 40} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' strokeWidth='2' stroke='#434343'  strokeLinecap='round' strokeLinejoin='round' {...props}>
      <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
      <path d='M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572'></path>
      <path d='M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25'></path>
      <path d='M12.5 15.5l2 2'></path>
      <path d='M15 13l2 2'></path>
    </SvgIcon>
  )
}
