import { useMemo, useState } from 'react'
import * as yup from 'yup'
import { isEmpty } from 'ramda'
import { Box } from '@mui/material'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { clearString } from '~/utils'
import { Option } from '~/utils/options'
import { ProposalInfo, PropsWithWizard } from '~/components'
import { useUploadMultiFiles } from '~/hooks'
import { Form } from '~/components/Form/Form'
import { useProposalContext } from '~/contexts'
import { Button } from '~/components/Form/Button'
import { ActionButtons, LegalRepresentativeFields } from './components'
import { documentSchema, maritalStatusSchema, phoneSchema } from '~/utils/yupSchema'
import { LegalRepresentativeForm, LegalRepresentativeType, getLegalRepresentativeSolvedPendencies, mapProposalBusinessProposalsToForm } from './utils'
import { EMaritalStatus, EProposalFileType, EProposalStatus, ProposalAddLegalRepresentativeGraphqlDto, ProposalLegalRepresentativeModel, ProposalModel, ProposalUpdateLegalRepresentativeGraphqlDto, useProposalAddLegalRepresentativeMutation, useProposalRemoveLegalRepresentativeMutation, useProposalUpdateLegalRepresentativeMutation, useSolveProposalLegalRepresentantPendenciesMutation } from '~/graphql/types'

const BUSINESS_PROPOSAL_INITIAL_VALUES: LegalRepresentativeType = {
  name: '',
  email: '',
  phone: '',
  document: '',
  nationality: '',
  maritalStatus: '',
  identificationNumber: '',
  address: {
    city: '',
    state: '',
    number: '',
    street: '',
    zipcode: '',
    complement: '',
    neighborhood: ''
  },
  customerPjRepresentativeDocumentWithPhotoBack: undefined,
  customerPjRepresentativeDocumentWithPhotoFront: undefined
}

const schema = yup.object().shape({
  legalRepresentative: yup.array().of(
    yup.object().shape({
      phone: phoneSchema,
      address: yup.object().shape({
        complement: yup.string().nullable(),
        state: yup.string().required('Estado é um campo obrigatório'),
        city: yup.string().required('Cidade é um campo obrigatório'),
        number: yup.string().required('Número é um campo obrigatório'),
        neighborhood: yup.string().required('Bairro é um campo obrigatório'),
        street: yup.string().min(5, 'Rua deve ter pelo menos 5 caracteres').required('Nome da rua é um campo obrigatório'),
        zipcode: yup.string().test('Length', 'CEP válido necessita ter 8 digitos', (value: any) => clearString(value).length === 8 ? true : false).required('CEP é obrigatório')
      }),
      maritalStatus: maritalStatusSchema,
      name: yup.string().required('Nome é um campo obrigatório'),
      document: documentSchema.required('O campo CPF é obrigatório'),
      identificationNumber: yup.string().required('RG é um campo obrigatório'),
      nationality: yup.string().required('Nacionalidade é um campo obrigatório'),
      email: yup.string().email('Email inválido').required('Email é um campo obrigatório'),
      customerPjRepresentativeDocumentWithPhotoFront: yup.mixed().required('Você precisa enviar pelo menos um arquivo.'),
      customerPjRepresentativeDocumentWithPhotoBack: yup.mixed().required('Você precisa enviar pelo menos um arquivo.')
    }))
})

export const getInitialValues = (proposal: ProposalModel | null): LegalRepresentativeForm => {
  if (!proposal) {
    return {
      legalRepresentative: [BUSINESS_PROPOSAL_INITIAL_VALUES]
    }
  }

  const { customerRef } = proposal

  return {
    legalRepresentative: customerRef.legalRepresentative && !isEmpty(customerRef.legalRepresentative) ?
      mapProposalBusinessProposalsToForm(customerRef.legalRepresentative) :
      [BUSINESS_PROPOSAL_INITIAL_VALUES]
  }
}

export type PowerGeneratorOption = Option & {
  isDesperta: boolean
}

export const LegalRepresentative: React.FC<PropsWithWizard> = (props) => {
  const [isLoading, setLoading] = useState(false)
  const { proposal, setProposal } = useProposalContext()
  const [uploadMultiFiles, { loading: uploadFilesLoading }] = useUploadMultiFiles()
  const [addProposalLegalRepresentative, { loading: addLegalRepresentativeLoading }] = useProposalAddLegalRepresentativeMutation()
  const [removeProposalLegalRepresentative, { loading: removeRepresentativeLoading }] = useProposalRemoveLegalRepresentativeMutation()
  const [updateProposalLegalRepresentative, { loading: updateLegalRepresentativeLoading }] = useProposalUpdateLegalRepresentativeMutation()
  const [solveProposalLegalRepresentantPendencies, { loading: solveLegalRepresentativePendenciesLoading }] = useSolveProposalLegalRepresentantPendenciesMutation()

  const isCanceled = proposal?.status === EProposalStatus.canceled

  const methods = useForm<LegalRepresentativeForm>({
    resolver: yupResolver(schema),
    defaultValues: getInitialValues(proposal)
  })

  const loading = useMemo(() => isLoading || uploadFilesLoading || addLegalRepresentativeLoading || updateLegalRepresentativeLoading || solveLegalRepresentativePendenciesLoading || removeRepresentativeLoading, [
    isLoading,
    uploadFilesLoading,
    removeRepresentativeLoading,
    addLegalRepresentativeLoading,
    updateLegalRepresentativeLoading,
    solveLegalRepresentativePendenciesLoading
  ])

  const { fields, append, remove } = useFieldArray<LegalRepresentativeForm>({
    name: 'legalRepresentative',
    control: methods.control
  })

  const addLegalRepresentative = async (legalRepresentative: LegalRepresentativeType) => {
    const { customerPjRepresentativeDocumentWithPhotoBack, customerPjRepresentativeDocumentWithPhotoFront } = legalRepresentative

    if (proposal) {
      let updatedProposal: ProposalModel | null = null
      const filesToUpload: File[][] | null = []
      const fileTypesToUpload: EProposalFileType[] = []

      const params: ProposalAddLegalRepresentativeGraphqlDto = {
        proposalId: proposal?._id,
        name: legalRepresentative.name,
        email: legalRepresentative.email,
        nationality: legalRepresentative.nationality,
        phone: clearString(legalRepresentative.phone),
        document: clearString(legalRepresentative.document),
        maritalStatus: legalRepresentative.maritalStatus as EMaritalStatus,
        identificationNumber: legalRepresentative.identificationNumber,
        address: {
          ...legalRepresentative.address,
          country: 'Brasil'
        }
      }

      if (customerPjRepresentativeDocumentWithPhotoBack && !isEmpty(customerPjRepresentativeDocumentWithPhotoBack) && !customerPjRepresentativeDocumentWithPhotoBack.size) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoBack)
        fileTypesToUpload.push(EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack)
      }

      if (customerPjRepresentativeDocumentWithPhotoFront && !isEmpty(customerPjRepresentativeDocumentWithPhotoFront && !customerPjRepresentativeDocumentWithPhotoFront.size)) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoFront)
        fileTypesToUpload.push(EProposalFileType.customerPjRepresentativeDocumentWithPhotoFront)
      }

      try {
        const { data } = await addProposalLegalRepresentative({ variables: { params } })
        if (data && data.proposalAddLegalRepresentative) {
          updatedProposal = data.proposalAddLegalRepresentative as ProposalModel
        }

        if (!isEmpty(filesToUpload)) {
          updatedProposal = await uploadMultiFiles({
            files: filesToUpload,
            types: fileTypesToUpload,
            proposalId: proposal._id,
            legalRepresentativeDocument: clearString(legalRepresentative.document)
          })
        }

        updatedProposal && setProposal(updatedProposal)
      } catch (err) {
        toast.error('Houve um erro ao adicionar o(s) representante(s) legal(is)', {
          autoClose: 3000,
          position: 'top-right'
        })
      }
    }
  }

  const addMultipleLegalRepresentatives = async (legalRepresentatives: LegalRepresentativeType[]) => {
    for (let i = 0; i < legalRepresentatives.length; i++) {
      await addLegalRepresentative(legalRepresentatives[i])
    }
  }

  const updateLegalRepresentative = async (legalRepresentative: LegalRepresentativeType) => {
    const { customerPjRepresentativeDocumentWithPhotoBack, customerPjRepresentativeDocumentWithPhotoFront } = legalRepresentative

    if (proposal) {
      const currentLegalRepresentativeFromProposal = proposal.customerRef.legalRepresentative?.find(item => clearString(item.document) === clearString(legalRepresentative.document))

      let updatedProposal: ProposalModel | null = null
      const filesToUpload: File[][] | null = []
      const fileTypesToUpload: EProposalFileType[] = []

      const params: ProposalUpdateLegalRepresentativeGraphqlDto = {
        proposalId: proposal?._id,
        name: legalRepresentative.name,
        email: legalRepresentative.email,
        nationality: legalRepresentative.nationality,
        phone: clearString(legalRepresentative.phone),
        documentNumber: clearString(legalRepresentative.document),
        maritalStatus: legalRepresentative.maritalStatus as EMaritalStatus,
        identificationNumber: legalRepresentative.identificationNumber,
        address: {
          ...legalRepresentative.address,
          country: 'Brasil'
        }
      }

      if (customerPjRepresentativeDocumentWithPhotoBack && !isEmpty(customerPjRepresentativeDocumentWithPhotoBack) && customerPjRepresentativeDocumentWithPhotoBack[0].size) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoBack)
        fileTypesToUpload.push(EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack)
      }

      if (customerPjRepresentativeDocumentWithPhotoFront && !isEmpty(customerPjRepresentativeDocumentWithPhotoFront) && customerPjRepresentativeDocumentWithPhotoFront[0].size) {
        filesToUpload.push(customerPjRepresentativeDocumentWithPhotoFront)
        fileTypesToUpload.push(EProposalFileType.customerPjRepresentativeDocumentWithPhotoFront)
      }

      try {
        const { data } = await updateProposalLegalRepresentative({ variables: { params } })
        if (data) {
          updatedProposal = data.proposalUpdateLegalRepresentative as ProposalModel
        }

        const solvedPendencies = getLegalRepresentativeSolvedPendencies(legalRepresentative, currentLegalRepresentativeFromProposal)
        const { data: solvedPendenciesProposal } = await solveProposalLegalRepresentantPendencies({
          variables: {
            params: {
              document: clearString(legalRepresentative.document),
              proposalId: proposal._id,
              pendencies: solvedPendencies
            }
          }
        })

        if (solvedPendenciesProposal) {
          updatedProposal = solvedPendenciesProposal.solveProposalLegalRepresentantPendencies as ProposalModel
        }

        if (!isEmpty(filesToUpload)) {
          updatedProposal = await uploadMultiFiles({
            files: filesToUpload,
            types: fileTypesToUpload,
            proposalId: proposal._id,
            legalRepresentativeDocument: clearString(legalRepresentative.document)
          })
        }

        updatedProposal && setProposal(updatedProposal)
      } catch (err) {
        toast.error('Houve um erro ao atualizar o(s) representante(s) legal(is)', {
          autoClose: 3000,
          position: 'top-right'
        })
      }
    }
  }

  const updateMultipleLegalRepresentatives = async (legalRepresentatives: LegalRepresentativeType[]) => {
    for (let i = 0; i < legalRepresentatives.length; i++) {
      await updateLegalRepresentative(legalRepresentatives[i])
    }
  }

  const removeLegalRepresentative = async (legalRepresentative: ProposalLegalRepresentativeModel) => {
    try {
      const { data } = await removeProposalLegalRepresentative({
        variables: {
          params: {
            proposalId: proposal?._id || '',
            document: legalRepresentative.document
          }
        }
      })

      return data && data.proposalRemoveLegalRepresentative as ProposalModel
    } catch (err) {
      toast.error('Houve um erro ao remover o(s) representante(s) legal(is)', {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const removeMultipleLegalRepresentative = async (legalRepresentatives: ProposalLegalRepresentativeModel[]) => {
    let updatedProposal: ProposalModel | null = null

    for (let i = 0; i < legalRepresentatives.length; i++) {
      const data = await removeLegalRepresentative(legalRepresentatives[i])

      if (data) {
        updatedProposal = data
      }
    }

    updatedProposal && setProposal(updatedProposal)
  }

  const getLegalRepresentativesInfo = (formData: LegalRepresentativeForm, proposalArg: ProposalModel) => {
    const { customerRef } = proposalArg
    const { legalRepresentative: legalRepresentativeFromProposal } = customerRef

    const { legalRepresentative } = formData

    const registeredRepresentativeDocuments = legalRepresentativeFromProposal?.map(representative => clearString(representative.document))
    const legalRepresentativeDocumentsFromForm = legalRepresentative?.map(representative => clearString(representative.document))

    const legalRepresentativesToAdd = legalRepresentative.filter(formRepresentative => !registeredRepresentativeDocuments?.includes(clearString(formRepresentative.document)))
    const legalRepresentativesToUpdate = legalRepresentative.filter(formRepresentative => registeredRepresentativeDocuments?.includes(clearString(formRepresentative.document)))

    const legalRepresentativesToRemoveDocuments = registeredRepresentativeDocuments?.filter(document => !legalRepresentativeDocumentsFromForm?.includes(clearString(document)))
    const legalRepresentativesToRemove = legalRepresentativeFromProposal?.filter(formRepresentative => legalRepresentativesToRemoveDocuments?.includes(clearString(formRepresentative.document)))

    return {
      legalRepresentativesToAdd,
      legalRepresentativesToUpdate,
      legalRepresentativesToRemove: legalRepresentativesToRemove || []
    }
  }

  const onSubmit = async (formData: LegalRepresentativeForm) => {
    if (proposal) {
      setLoading(true)
      const { legalRepresentativesToAdd, legalRepresentativesToRemove, legalRepresentativesToUpdate } = getLegalRepresentativesInfo(formData, proposal)

      try {

        if (!isEmpty(legalRepresentativesToAdd)) {
          await addMultipleLegalRepresentatives(legalRepresentativesToAdd)
        }
        if (!isEmpty(legalRepresentativesToUpdate)) {
          await updateMultipleLegalRepresentatives(legalRepresentativesToUpdate)
        }
        if (!isEmpty(legalRepresentativesToRemove)) {
          await removeMultipleLegalRepresentative(legalRepresentativesToRemove)
        }

        toast.success('Dados atualizados com sucesso!', {
          autoClose: 3000,
          position: 'top-right'
        })
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    }
  }

  const addNewLegalRepresentative = () => {
    append(BUSINESS_PROPOSAL_INITIAL_VALUES)
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
            <ProposalInfo />

            {fields.map((legalRepresentative, index) => (
              <LegalRepresentativeFields
                index={index}
                loading={loading}
                fieldsLength={fields.length}
                removeLegalRepresentative={remove}
                legalRepresentative={legalRepresentative}
                key={`legal-representative-${legalRepresentative.id}`}
              />
            ))}

            <Button
              color='secondary'
              disabled={loading || isCanceled}
              startIcon={<Add />}
              style={{ alignSelf: 'start' }}
              onClick={addNewLegalRepresentative}
            >
              Adicionar representante
            </Button>

            <ActionButtons onSubmit={methods.handleSubmit(onSubmit)} loading={loading} {...props} />
          </Box>
        </Form>
      </FormProvider>
    </>
  )
}
