import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Box, Button, Card, IconButton, InputAdornment, TextField, useMediaQuery } from '@mui/material'
import { IconArrowRight, IconPlus, IconSearch } from '@tabler/icons-react'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'

import { FilterButton, TableApiV2, TableHeaderApi, ContentTitle } from '~/components'
import { FilterDiscountTable } from './components'

import { useDiscountTable } from '~/contexts'
import { SearchQueysData, useDebounce, useQueryString, useToggle } from '~/hooks'

import { displayPercentage, formatDate, theme } from '~/utils'
import { EDiscountTableType } from '~/graphql/types'

const queryKeys: SearchQueysData[] = [
  { key: 'type', defaultValue: [], parseValue: true }, 
  { key: 'name', defaultValue: '' }, 
  { key: 'begin', defaultValue: '' }, 
  { key: 'end', defaultValue: '' }, 
]

export const DiscountTableList: React.FC = () => {
  const navigate = useNavigate()
  const { searchQuerys, paginationQuerySize, searchParams, setSearchParams, page, size } = useQueryString()
  const queryValues = searchQuerys(queryKeys)
  const { discountTables, discountTablesIsLoading, getDiscountTablesList, setDiscountTables } = useDiscountTable()
  const { isTrue: drawerIsOpen, toggle: toggleDrawer, setIsTrue: setDrawerIsOpen } = useToggle()
  const [filterName, setFilterName] = useState(queryValues.name)
  const debouncedFilterName = useDebounce(filterName, 1000)
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    getDiscountTablesList({
      variables: {
        params: {
          pageSize: Number(size),
          pageNumber: Number(page),
          filter: {
            ...queryValues.name && queryValues.name?.length > 0 ? { code: queryValues.name.trim() } : {},
            ...queryValues.type && queryValues.type?.length > 0 ? { floatType: queryValues.type } : {},
            ...queryValues.begin ? { begin: new Date(queryValues.begin) } : {},
            ...queryValues.end ? { end: new Date(`${queryValues.end}Z23:59:59`) } : {},
          },
          sort: {
            field: 'createdAt',
            order: -1
          }
        }
      },
      onCompleted(result) {
        setDiscountTables(result.discountTableByList.data)
        setSearchParams(state => {
          state.set('items', String(result.discountTableByList.meta.documentCounts))
          return state
        })
      },
      fetchPolicy: 'no-cache'
    })
  }, [searchParams])

  useEffect(() => {
    setSearchParams(state => {
      if(debouncedFilterName) {
        state.set('name', debouncedFilterName)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }, [debouncedFilterName])

  const defaultColumnHiddens = isLowerMd ? [''].concat(isLowerSm ? ['', ''] : '') : []

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome da tabela',
        accessor: 'code',
      },
      {
        Header: 'Percentual de desconto',
        accessor: 'discountPercentage',
        Cell: ({ value }) => <>{displayPercentage(value)}</>
      },
      {
        Header: 'Flutua bandeira?',
        accessor: 'type',
        Cell: ({ value }) => (
          <>
            {value === EDiscountTableType.customerFloatFlag ? 'Sim' : 'Não'}
          </>
        )
      },
      {
        Header: 'Criado em',
        accessor: 'createdAt',
        Cell: ({ value }) => <>{formatDate(value)}</>
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        disableSortBy: true,
        // width: 200,
        Cell: ({ value }) => (
          <>
            <Link to={`/app/discount-table/update/${value}`}>
              <IconButton>
                <IconArrowRight />
              </IconButton>
            </Link>
          </>
        )
        ,
      }
    ]
  }, [])

  const dataWithMemo = useMemo(() => discountTables || [], [discountTables])

  const tableMethods = useTable(
    {
      columns,
      data: dataWithMemo,
      initialState: {
        hiddenColumns: defaultColumnHiddens,
      },
      manualPagination: true,
      defaultColumn: {
        Filter: <></>
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    //disable checkboxColumn
    // (hooks) => renderTableCheckbox(hooks)
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Lista de tabelas de desconto'
          description='Visualize e gerencie as tabelas cadastradas'
          breadcrumbLinks={{ currentLink: 'Lista de tabelas de desconto', links: [{ href: '/app/power-plants', label: 'Usinas' }] }}
          rightContent={<Button onClick={() => navigate('/app/discount-table/create')} startIcon={<IconPlus />}>Cadastrar tabela</Button>}
        />
        <TableHeaderApi
          startElement={(
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', width: '100%', flexFlow: isLowerSm ? 'column-reverse' : 'row' }}>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder='Buscar por nome'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <FilterButton filtersToClear={queryKeys} toggleDrawer={toggleDrawer} filterCounter={searchParams.size - paginationQuerySize} />
              </Box>

              <FilterDiscountTable queryKeys={queryKeys}  drawerIsOpen={drawerIsOpen} setDrawerIsOpen={setDrawerIsOpen} toggleDrawer={toggleDrawer} />
            </Box>
          )}
        />
        <TableApiV2
          isLoading={discountTablesIsLoading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Card>
    </div>
  )
}
