import { Box, Typography } from '@mui/material'
import { Info } from '@mui/icons-material'
import { useProposalContext } from '~/contexts'

export const NotSendAllDocumentosWarning: React.FC = () => {
  const { proposal } = useProposalContext()
  proposal?.customerRef.hasPendencies
  return (
    <Box sx={{
      gap: 3,
      padding: 3,
      display: 'flex',
      borderRadius: '12px',
      alignItems: 'center',
      flexDirection: 'row',
      border: '1px solid #F3CC04'
    }}>
      <Info fontSize='large' color='primary' />
      <Typography>
        A proposta não pode ser formalizada porque existem dados do cliente que ainda precisam ser informados
      </Typography>
    </Box>
  )
}
