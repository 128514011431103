/* eslint-disable no-unused-vars */
import { ArrowBack, ArrowForward, Save } from '@mui/icons-material'
import { ProposalFlowActionDropdown, PropsWithWizard } from '~/components'
import { Box, CircularProgress, Divider, useMediaQuery } from '@mui/material'
import { Button } from '~/components/Form/Button'
import { useProposalContext } from '~/contexts'
import { EProposalStatus } from '~/graphql/types'
import { usePermission } from '~/hooks'
import { theme } from '~/utils'

type ActionButtonsProps = {
  loading: boolean
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}
export const ActionButtons: React.FC<PropsWithWizard<ActionButtonsProps>> = (props) => {
  const { proposal } = useProposalContext()
  const { onNext, onPrev, loading, onSubmit } = props

  const disableFields = proposal?.status !== EProposalStatus.formalization
  const canEditProposal = usePermission(['proposals.updated'])
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSubmit = async (submitType: 'continue' | 'save') => {
    await onSubmit()
    if (submitType === 'continue') {
      onNext()
    }
  }

  return (
    <>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
        {!isLowerMd && (
          <Button disabled={loading} startIcon={<ArrowBack />} color='secondary' onClick={onPrev}>
            Voltar
          </Button>
        )}

        <Box sx={{ display: 'flex', flexDirection: isLowerSm ? 'column' : 'row', gap: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: isLowerSm ? 'space-between' : 'flex-end' }}>
            <ProposalFlowActionDropdown disabled={loading || disableFields} proposal={proposal} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
