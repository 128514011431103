import { EProposalFileType, ProposalLegalRepresentativeModel, ProposalWithDealershipsQuery } from '~/graphql/types'
import { StepTitle } from '../StepTitle'
import { FieldsType } from '../types'
import { useForm, FormProvider, useFieldArray, useWatch, Controller } from 'react-hook-form'
import { Box, Button, Typography, Checkbox as MuiCheckbox, FormControlLabel, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { Input } from '~/components/Form/Input'
import { InputPendenciesStatus } from '../InputPendenciesStatus'
import { Checkbox } from '~/components/Form/Checkbox'
import { IconCheck, IconX } from '@tabler/icons-react'
import { theme, translateMaritalStatus } from '~/utils'
import { FilePendencyStatus } from '../FilePendencyStatus'
import { useAuth } from '~/contexts'

type RepresentativesProps = {
  proposal: ProposalWithDealershipsQuery['proposalGet']
  onRefuse: Function
  onApprove: Function
  modalType: 'approve' | 'refuse'
  setFields: React.Dispatch<React.SetStateAction<FieldsType>>
  representativeIndex: number
  setRepresentativeIndex: React.Dispatch<React.SetStateAction<number>>
}

type LegalRepresentativeWithPending = ProposalLegalRepresentativeModel & {
  sendPending: {
    name: boolean,
    document: boolean,
    email: boolean,
    phone: boolean,
    maritalStatus: boolean,

    zipcode: boolean,
    state: boolean,
    city: boolean,
    neighborhood: boolean,
    number: boolean,
    complement: boolean,

    customerPjRepresentativeDocumentWithPhotoFront: boolean,
    customerPjRepresentativeDocumentWithPhotoBack: boolean,
  }
  controlPendencies: boolean
}

type FormData = {
  legalRepresentative: LegalRepresentativeWithPending[]
}

export const Representatives: React.FC<RepresentativesProps> = ({ onApprove, onRefuse, proposal, setFields, representativeIndex, setRepresentativeIndex }) => {
  const formMethods = useForm<FormData>()
  const { authUser } = useAuth()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isSeller = authUser?.user.type === 'seller'

  const { fields } = useFieldArray({
    control: formMethods.control,
    name: 'legalRepresentative',
  })

  useEffect(() => {
    if (proposal?.customerRef.legalRepresentative) {
      const translatedRepresentativies = proposal?.customerRef.legalRepresentative.map(item => {
        return {
          ...item,
          maritalStatus: item.maritalStatus ? translateMaritalStatus(item.maritalStatus) : null
        }
      })
      formMethods.setValue('legalRepresentative', translatedRepresentativies as LegalRepresentativeWithPending[])
    }
  }, [proposal])

  function handlePendencyFile(data: FormData) {
    const selectedFields = Object?.entries(data.legalRepresentative[representativeIndex].sendPending).filter(item => item[1])
    const objectFields = Object.fromEntries(selectedFields)
    setFields(objectFields)
  }

  const { legalRepresentative } = useWatch({
    control: formMethods.control,
  })

  function onSelectAllFields(index: number, isValid: boolean) {
    formMethods.setValue(`legalRepresentative.${index}.sendPending.name`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.document`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.email`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.phone`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.maritalStatus`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.zipcode`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.state`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.city`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.neighborhood`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.number`, !isValid)
    if(proposal?.customerRef.legalRepresentative && proposal?.customerRef.legalRepresentative[index].address?.complement) {
      formMethods.setValue(`legalRepresentative.${index}.sendPending.complement`, !isValid)
    }
    formMethods.setValue(`legalRepresentative.${index}.sendPending.customerPjRepresentativeDocumentWithPhotoFront`, !isValid)
    formMethods.setValue(`legalRepresentative.${index}.sendPending.customerPjRepresentativeDocumentWithPhotoBack`, !isValid)
  }

  return (
    <>
      <StepTitle proposalId={proposal?._id} clientName={proposal?.customerRef.name} step={{ value: '4', title: 'Representantes' }} />

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => handlePendencyFile(data as any))}>
          {fields.map((field, index) => {

            const enableSelectAll = [
              Boolean(!field.name),
              Boolean(!field.document),
              Boolean(!field.email),
              Boolean(!field.phone),
              Boolean(!field.maritalStatus),
              Boolean(!field.address?.zipcode),
              Boolean(!field.address?.state),
              Boolean(!field.address?.city),
              Boolean(!field.address?.neighborhood),
              Boolean(!field.address?.number),
            ].some(item => item)

            const enableSelectAllWithDocuments = Boolean(!field.files?.some(item => item.file?.fileUrl))

            const buttonIsEnable = legalRepresentative && Object?.values(legalRepresentative[index]?.sendPending ?? {})?.some(item => item)

            return (
              <Box display='flex' flexDirection='column' gap='1rem' key={field.id}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1rem 0' }}>
                  <Typography sx={{ fontSize: isLowerSm ? '.95rem' : '1.1rem' }} variant='h6'>{field?.name}</Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                    {!isLowerSm && 
                      <Typography fontWeight={500} color={theme.palette.grey[600]} fontSize='.95rem' variant='h6'>Selecionar todos</Typography>
                    }
                    <Controller
                      name={`legalRepresentative.${index}.controlPendencies`}
                      control={formMethods.control}
                      defaultValue={false}
                      render={({ field: fieldController }) => (
                        <FormControlLabel
                          {...fieldController}
                          sx={{ margin: 0 }}
                          onChange={(e: any) => {
                            fieldController.onChange(e)
                            onSelectAllFields(index, fieldController.value)
                          }}
                          control={<MuiCheckbox disabled={enableSelectAll || enableSelectAllWithDocuments} />}
                          label={<Typography variant='body2' color='grey.400'></Typography>}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.name} /> } }}
                    disabled
                    name={`legalRepresentative.${index}.name`}
                    label='Nome'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.name) }} name={`legalRepresentative.${index}.sendPending.name`} />
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.document} /> } }}
                    disabled
                    mask='cpf'
                    name={`legalRepresentative.${index}.document`}
                    label='CPF'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.document) }} name={`legalRepresentative.${index}.sendPending.document`} />
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.email} /> } }}
                    disabled
                    name={`legalRepresentative.${index}.email`}
                    label='E-mail'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.email) }} name={`legalRepresentative.${index}.sendPending.email`} />
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.phone} /> } }}
                    disabled
                    mask='phone'
                    name={`legalRepresentative.${index}.phone`}
                    label='Telefone'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.phone) }} name={`legalRepresentative.${index}.sendPending.phone`} />
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.maritalStatus} /> } }}
                    disabled
                    name={`legalRepresentative.${index}.maritalStatus`}
                    label='Estado Civil'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.maritalStatus) }} name={`legalRepresentative.${index}.sendPending.maritalStatus`} />
                </Box>

                <Typography sx={{ fontSize: isLowerSm ? '.95rem' : '1.1rem' }} variant='h6'>Endereço</Typography>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    mask='cep'
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.zipcode} /> } }}
                    disabled
                    name={`legalRepresentative.${index}.address.zipcode`}
                    label='CEP'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.address?.zipcode) }} name={`legalRepresentative.${index}.sendPending.zipcode`} />
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.state} /> } }}
                    disabled
                    name={`legalRepresentative.${index}.address.state`}
                    label='Estado'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.address?.state) }} name={`legalRepresentative.${index}.sendPending.state`} />
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.city} /> } }}
                    disabled
                    name={`legalRepresentative.${index}.address.city`}
                    label='Cidade'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.address?.city) }} name={`legalRepresentative.${index}.sendPending.city`} />
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.neighborhood} /> } }}
                    disabled
                    name={`legalRepresentative.${index}.address.neighborhood`}
                    label='Bairro'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.address?.neighborhood) }} name={`legalRepresentative.${index}.sendPending.neighborhood`} />
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.number} /> } }}
                    disabled
                    name={`legalRepresentative.${index}.address.number`}
                    label='Número'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.address?.number) }} name={`legalRepresentative.${index}.sendPending.number`} />
                </Box>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '0 0 1rem 0' }}>
                  <Input
                    icons={{ end: { element: <InputPendenciesStatus pendencie={field?.pendencies?.complement} /> } }}
                    disabled
                    name={`legalRepresentative.${index}.address.complement`}
                    label='Complemento'
                  />
                  <Checkbox checkboxProps={{ disabled: Boolean(!field.address?.complement) }} name={`legalRepresentative.${index}.sendPending.complement`} />
                </Box>

                <Typography sx={{ fontSize: isLowerSm ? '.95rem' : '1.1rem' }} variant='h6'>Documentos - {field?.name}</Typography>

                {field.files?.map((file) => {
                  return (
                    <Box key={file.type} sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0' }}>
                      <FilePendencyStatus
                        label={file.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack ? 'Anexar RG ou CNH (Verso)' : 'Anexar RG ou CNH (Frente)'}
                        fileName={file?.file?.fileName}
                        fileUrl={file?.file?.fileUrl}
                        status={file?.status}
                      />
                      <Checkbox checkboxProps={{ disabled: Boolean(!file?.file?.fileUrl) }} name={`legalRepresentative.${index}.sendPending.${file.type}`} />
                    </Box>
                  )
                })}

                <Box 
                  sx={{ 
                    display: 'flex', 
                    gap: '1rem', 
                    justifyContent: 'flex-end', 
                    borderBottom: index > 0 ? '1px solid #E6E6E6' : '', 
                    paddingBottom: index > 0 ? '2rem' : '',
                    flexFlow: isLowerSm ? 'column' : 'row'
                  }}
                >
                  <Button 
                    disabled={!buttonIsEnable || isSeller} 
                    onClick={() => (setRepresentativeIndex(index), onRefuse())} 
                    type='submit' 
                    startIcon={<IconX />} 
                    color='secondary'>
                    Recusar selecionados
                  </Button>
                  <Button 
                    disabled={!buttonIsEnable || isSeller} 
                    onClick={() => (setRepresentativeIndex(index), onApprove())} 
                    type='submit' 
                    startIcon={<IconCheck />}>
                    Aprovar selecionados
                  </Button>
                </Box>
              </Box>
            )
          })}
        </form>
      </FormProvider>
    </>
  )
}
