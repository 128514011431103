import { EProposalCustomerPendencies, ProposalModel } from '~/graphql/types'
import { clearString } from '~/utils'
import { isPendencyField } from '~/utils/proposal'

export type AddressForm = {
  city: string
  state: string
  street: string
  number: string
  zipcode: string
  complement: string
  neighborhood: string
}

export const ADDRESS_FORM_INITIAL_VALUES: AddressForm = {
  city: '',
  state: '',
  street: '',
  number: '',
  zipcode: '',
  complement: '',
  neighborhood: ''
}

export const getProposalAddressSolvedPendencies = (formData: AddressForm, proposal?: ProposalModel | null): EProposalCustomerPendencies[] => {
  const solvedPendecies: EProposalCustomerPendencies[] = []

  if (proposal) {
    const { customerRef } = proposal
    const { pendencies } = customerRef

    if (isPendencyField(EProposalCustomerPendencies.city, pendencies) && formData.city !== customerRef.address?.city) {
      solvedPendecies.push(EProposalCustomerPendencies.city)
    }

    if (isPendencyField(EProposalCustomerPendencies.state, pendencies) && formData.state !== customerRef.address?.state) {
      solvedPendecies.push(EProposalCustomerPendencies.state)
    }

    if (isPendencyField(EProposalCustomerPendencies.street, pendencies) && formData.street !== customerRef.address?.street) {
      solvedPendecies.push(EProposalCustomerPendencies.street)
    }

    if (isPendencyField(EProposalCustomerPendencies.number, pendencies) && formData.number !== customerRef.address?.number) {
      solvedPendecies.push(EProposalCustomerPendencies.number)
    }

    if (isPendencyField(EProposalCustomerPendencies.zipcode, pendencies) && clearString(formData.zipcode) !== clearString(customerRef.address?.zipcode || '')) {
      solvedPendecies.push(EProposalCustomerPendencies.zipcode)
    }

    if (isPendencyField(EProposalCustomerPendencies.complement, pendencies) && formData.complement !== customerRef.address?.complement) {
      solvedPendecies.push(EProposalCustomerPendencies.complement)
    }

    if (isPendencyField(EProposalCustomerPendencies.neighborhood, pendencies) && formData.neighborhood !== customerRef.address?.neighborhood) {
      solvedPendecies.push(EProposalCustomerPendencies.neighborhood)
    }
  }

  return solvedPendecies
}
