import { PropsWithChildren, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material'
import { Card } from '../Card'
import { PropsWithWizard } from '../Wizard'
import { ContentTitle } from '../ContentTitle'
import { useContract } from '~/contexts'
import { ContractStepper, ContractTabs } from './components'
import { ContractStatus } from '../ContractStatus'
import { formatDate, theme } from '~/utils'
import { IconChevronLeft } from '@tabler/icons-react'
import { EContractStatus, useUserGetQuery } from '~/graphql/types'

export type ContractFlowLayoutProps = {
  nextButtonLabel?: string
}
export const ContractFlowLayout: React.FC<PropsWithChildren<PropsWithWizard<ContractFlowLayoutProps>>> = (props) => {
  const { children, goToPage } = props
  const { contract, resetContract } = useContract()
  const { state } = useLocation()
  const { contractId } = useParams()

  const isLowerXs = useMediaQuery('(max-width:500px)')
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const { data: activateData } = useUserGetQuery({
    skip: Boolean(!contract),
    variables: {
      id: contract?.activatedBy || ''
    }
  })

  useEffect(() => {
    if (!contractId) {
      resetContract()
      goToPage(0)
    }
    state?.newProposalCreated && goToPage(1)
    window.history.replaceState({}, document.title)
  }, [contractId])

  return (
    <Card>
      {isLowerMd ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexFlow: isLowerXs ? 'column' : 'row', gap: isLowerXs ? '1rem' : '0' }}>
            <Link to='/app/contracts'>
              <Button size='small' sx={{ margin: '0' }} color='secondary'>
                <IconChevronLeft />
              </Button>
            </Link>
            {contract ? <ContractStatus status={contract.status} /> : undefined}
          </Box>
          <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.6rem', paddingTop: '1rem' }}>
            <Typography color={theme.palette.grey[400]} fontSize='.9rem'>Gerada em: {formatDate(contract?.createdAt)} | por: Desperta</Typography>
            {contract?.activatedAt &&
              <Typography color={theme.palette.grey[400]} fontSize='.95rem' fontWeight={500}>Ativada em: {formatDate(contract?.activatedAt)} | por: {activateData?.userGet.name}</Typography>
            }
          </Box>

          <Divider sx={{ marginTop: 4, marginBottom: 4 }} />

          <ContentTitle
            style={{ padding: '0px 0px 24px 0px' }}
            title={contract ? 'Acompanhar contrato' : 'Gerar contrato'}
            breadcrumbLinks={{ currentLink: 'Gerar contrato', links: [{ href: '/app/contracts/create', label: 'Contratos' }] }}
            description='Complete os dados para enviar o contrato à Gera'
          />
        </>
      ) : (
        <ContentTitle
          style={{ padding: '0px 0px 24px 0px' }}
          title={contract ? 'Acompanhar contrato' : 'Gerar contrato'}
          rightContent={contract ? (
            <>
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.6rem', paddingTop: '1rem', alignItems: 'flex-end' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                  <Typography color={theme.palette.grey[400]} fontSize='.9rem'>Status do contrato: </Typography>
                  <ContractStatus status={contract.status} />
                </Box>
                <Typography color={theme.palette.grey[400]} fontSize='.9rem'>Gerada em: {formatDate(contract?.createdAt)} | por: Desperta</Typography>
                {contract?.activatedAt &&
                  <Typography color={theme.palette.grey[400]} fontSize='.95rem' fontWeight={500}>Ativada em: {formatDate(contract?.activatedAt)} | por: {activateData?.userGet.name}</Typography>
                }
              </Box>
            </>
          ) : undefined
          }
          breadcrumbLinks={{ currentLink: 'Gerar contrato', links: [{ href: '/app/contracts/create', label: 'Contratos' }] }}
          description='Complete os dados para enviar o contrato à Gera'
        />
      )}

      <Divider sx={{ marginBottom: 5 }} />

      {/* {contract?.status === EContractStatus.sent ? 
        <ContractTabs contract={contract} {...props} />
        :  */}

      <ContractStepper contract={contract} {...props} />

      {/* } */}

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {children}
      </Box>
    </Card>
  )
}
