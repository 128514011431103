import { Box, Button, Typography, Checkbox as MuiCheckbox, FormControlLabel, useMediaQuery } from '@mui/material'
import { StepTitle } from '../StepTitle'
import { useForm, FormProvider, useWatch, Controller } from 'react-hook-form'
import { Input } from '~/components/Form/Input'
import { Checkbox } from '~/components/Form/Checkbox'
import { IconCheck, IconX } from '@tabler/icons-react'
import { GridColumn } from '~/styles'
import { Select } from '~/components/Form/Select'
import { brazilStates } from '~/constants'
import { ECustomerPersonType, EProposalFileType, ProposalModel } from '~/graphql/types'
import { FieldsType } from '../types'
import { useEffect } from 'react'
import { FilePendencyStatus } from '../FilePendencyStatus'
import { Annotations } from '~/components'
import { useAuth } from '~/contexts'
import { theme, transformMoney } from '~/utils'

type DocumentsProps = {
  proposal: ProposalModel | null
  onRefuse: Function
  onApprove: Function
  modalType: 'approve' | 'refuse'
  setFields: React.Dispatch<React.SetStateAction<FieldsType>>
  setProposal: React.Dispatch<React.SetStateAction<ProposalModel | null>>
}

type FormData = {
  pendencies: {
    customerPjSocialContract: boolean
    customerPjDocumentCard: boolean
    customerPfDocumentWithPhotoBack: boolean
    customerPfDocumentWithPhotoFront: boolean
    energyBill: boolean
  }
  controlPendencies: boolean
}

export const Documents: React.FC<DocumentsProps> = ({ proposal, onApprove, onRefuse, setFields, setProposal }) => {
  const formMethods = useForm<FormData>()
  const staticFormMethods = useForm()
  const { authUser } = useAuth()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isSeller = authUser?.user.type === 'seller'

  useEffect(() => {
    if (proposal?.energyBillInfo) {
      staticFormMethods.setValue('address.zipcode', proposal.energyBillInfo.address?.zipcode)
      staticFormMethods.setValue('address.city', proposal.energyBillInfo.address?.city)
      staticFormMethods.setValue('address.street', proposal.energyBillInfo.address?.street)
      staticFormMethods.setValue('address.number', proposal.energyBillInfo.address?.number)
      staticFormMethods.setValue('address.complement', proposal.energyBillInfo.address?.complement)
      staticFormMethods.setValue('address.neighborhood', proposal.energyBillInfo.address?.neighborhood)
      staticFormMethods.setValue('address.state', proposal.energyBillInfo.address?.state)

      staticFormMethods.setValue('AmountCents', proposal.energyBillInfo.AmountCents ? transformMoney(proposal.energyBillInfo.AmountCents, 'toReal') : 0)
      staticFormMethods.setValue('avarageConsumption', proposal.energyBillInfo.avarageConsumption)
      staticFormMethods.setValue('clientNumber', proposal.energyBillInfo.clientNumber)
      staticFormMethods.setValue('dealershipName', proposal.energyBillInfo.dealershipName)
      staticFormMethods.setValue('distributionTaxes', proposal.energyBillInfo.distributionTaxes ? transformMoney(proposal.energyBillInfo.distributionTaxes, 'toReal') : 0)
      staticFormMethods.setValue('energyTaxes', proposal.energyBillInfo.energyTaxes ? transformMoney(proposal.energyBillInfo.energyTaxes, 'toReal') : 0)
      staticFormMethods.setValue('installationNumber', proposal.energyBillInfo.installationNumber)
      staticFormMethods.setValue('tusdValue', proposal.energyBillInfo.tusdValue ? transformMoney(proposal.energyBillInfo.tusdValue, 'toReal') : 0)
      staticFormMethods.setValue('tariffClassification', proposal.energyBillInfo.tariffClassification)
      staticFormMethods.setValue('tension', proposal.energyBillInfo.tension)
      staticFormMethods.setValue('consumerClassification', proposal.energyBillInfo.consumerClassification)
    }
  }, [proposal])

  //pf
  const customerPfDocumentWithPhotoFrontFile = proposal?.files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoFront)
  const customerPfDocumentWithPhotoBackFile = proposal?.files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoBack)
  //pj
  const customerPjDocumentCardFile = proposal?.files?.find(item => item.type === EProposalFileType.customerPjDocumentCard)
  const customerPjSocialContractFile = proposal?.files?.find(item => item.type === EProposalFileType.customerPjSocialContract)
  //common
  const energyBillFile = proposal?.files?.find(item => item.type === EProposalFileType.energyBill)

  function handlePendencyFile(data: FormData) {
    const selectedFields = Object?.entries(data?.pendencies).filter(item => item[1])
    const objectFields = Object.fromEntries(selectedFields)
    setFields(objectFields)
  }

  const { pendencies } = useWatch({
    control: formMethods.control,
  })

  const buttonIsEnable = pendencies && Object?.values(pendencies)?.some(item => item)

  function onSelectAllFields(isValid: boolean) {
    formMethods.setValue('controlPendencies', !isValid)
    formMethods.setValue('pendencies.energyBill', !isValid)
    if(proposal?.customerRef.personType === ECustomerPersonType.pf) {
      formMethods.setValue('pendencies.customerPfDocumentWithPhotoFront', !isValid)
      formMethods.setValue('pendencies.customerPfDocumentWithPhotoBack', !isValid)
    } else {
      formMethods.setValue('pendencies.customerPjSocialContract', !isValid)
      formMethods.setValue('pendencies.customerPjDocumentCard', !isValid)
    }
  }

  const enableSelectAll = [
    Boolean(!energyBillFile?.file?.fileUrl),
  ].some(item => item)

  const enableSelectAllToPf = [
    Boolean(!customerPfDocumentWithPhotoFrontFile?.file?.fileUrl),
    Boolean(!customerPfDocumentWithPhotoBackFile?.file?.fileUrl),
  ].some(item => item)
  
  const enableSelectAllToPj = [
    Boolean(!customerPjDocumentCardFile?.file?.fileUrl),
    Boolean(!customerPjSocialContractFile?.file?.fileUrl),
  ].some(item => item)

  return (
    <>
      <StepTitle proposalId={proposal?._id} clientName={proposal?.customerRef.name} step={{ value: '3', title: 'Documentos' }} />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => handlePendencyFile(data))}>

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0', justifyContent: 'space-between' }}>

            <Typography sx={{ padding: '1rem 0 0 0' }} variant='h6'>Documentos</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
              {!isLowerSm && 
                <Typography fontWeight={500} color={theme.palette.grey[600]} fontSize='.95rem' variant='h6'>Selecionar todos</Typography>
              }
              <Controller
                name='controlPendencies'
                control={formMethods.control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={{ margin: 0 }}
                    onChange={(e: any) => {
                      field.onChange(e)
                      onSelectAllFields(field.value)
                    }}
                    control={<MuiCheckbox disabled={proposal?.customerRef.personType === ECustomerPersonType.pf ? enableSelectAll || enableSelectAllToPf : enableSelectAll || enableSelectAllToPj} />}
                    label={<Typography variant='body2' color='grey.400'></Typography>}
                  />
                )}
              />
            </Box>

          </Box>
          {proposal?.customerRef.personType === ECustomerPersonType.pf ? (
            <>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0' }}>
                <FilePendencyStatus
                  label='Anexar RG ou CNH (Frente)'
                  fileName={customerPfDocumentWithPhotoFrontFile?.file?.fileName}
                  fileUrl={customerPfDocumentWithPhotoFrontFile?.file?.fileUrl}
                  status={customerPfDocumentWithPhotoFrontFile?.status}
                />
                <Checkbox checkboxProps={{ disabled: Boolean(!customerPfDocumentWithPhotoFrontFile?.file?.fileUrl) }} name='pendencies.customerPfDocumentWithPhotoFront' />
              </Box>

              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0' }}>
                <FilePendencyStatus
                  label='Anexar RG ou CNH (Verso)'
                  fileName={customerPfDocumentWithPhotoBackFile?.file?.fileName}
                  fileUrl={customerPfDocumentWithPhotoBackFile?.file?.fileUrl}
                  status={customerPfDocumentWithPhotoBackFile?.status}
                />
                <Checkbox checkboxProps={{ disabled: Boolean(!customerPfDocumentWithPhotoBackFile?.file?.fileUrl) }} name='pendencies.customerPfDocumentWithPhotoBack' />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0' }}>
                <FilePendencyStatus
                  label='Contrato social'
                  fileName={customerPjSocialContractFile?.file?.fileName}
                  fileUrl={customerPjSocialContractFile?.file?.fileUrl}
                  status={customerPjSocialContractFile?.status}
                />
                <Checkbox checkboxProps={{ disabled: Boolean(!customerPjSocialContractFile?.file?.fileUrl) }} name='pendencies.customerPjSocialContract' />
              </Box>
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0' }}>
                <FilePendencyStatus
                  label='Cartão CNPJ'
                  fileName={customerPjDocumentCardFile?.file?.fileName}
                  fileUrl={customerPjDocumentCardFile?.file?.fileUrl}
                  status={customerPjDocumentCardFile?.status}
                />
                <Checkbox checkboxProps={{ disabled: Boolean(!customerPjDocumentCardFile?.file?.fileUrl) }} name='pendencies.customerPjDocumentCard' />
              </Box>

            </>
          )}

          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', padding: '1rem 0 1rem 0' }}>
            <FilePendencyStatus
              label='Ultima fatura de energia'
              fileName={energyBillFile?.file?.fileName}
              fileUrl={energyBillFile?.file?.fileUrl}
              status={energyBillFile?.status}
            />
            <Checkbox checkboxProps={{ disabled: Boolean(!energyBillFile?.file?.fileUrl) }} name='pendencies.energyBill' />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', flexFlow: isLowerSm ? 'column': 'row' }}>
            <Button disabled={!buttonIsEnable || isSeller} type='submit' onClick={() => onRefuse()} startIcon={<IconX />} color='secondary'>Recusar selecionados</Button>
            <Button disabled={!buttonIsEnable || isSeller} type='submit' onClick={() => onApprove()} startIcon={<IconCheck />}>Aprovar selecionados</Button>
          </Box>
        </form>
      </FormProvider>

      <Typography sx={{ padding: '1rem 0 1rem 0' }} variant='h6'>Dados da fatura de energia</Typography>

      <FormProvider {...staticFormMethods}>
        <form>
          <GridColumn columns={3} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 2 }, { breakWidth: theme.breakpoints.values.sm, columns: 1 }]}>
            <Input disabled mask='BRL' name='AmountCents' label='Valor da fatura' />
            <Input disabled name='tariffClassification' label='Classificação tarifária' />
            <Input disabled name='consumerClassification' label='Classe de consumo' />
          </GridColumn>

          <GridColumn padding='1rem 0' columns={3} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 2 }, { breakWidth: theme.breakpoints.values.sm, columns: 1 }]}>
            <Input disabled name='tension' label='Tensão do consumidor' />
            <Input disabled name='installationNumber' label='Instalação / UC' />
            <Input disabled name='clientNumber' label='Número do cliente' />
            <Input disabled mask='kwh' name='avarageConsumption' label='Média de consumo' />
            <Input disabled name='dealershipName' label='Concessionária' />
          </GridColumn>

          <GridColumn columns={3} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 2 }, { breakWidth: theme.breakpoints.values.sm, columns: 1 }]}>
            <Input disabled mask='BRL' name='distributionTaxes' label='Taxa de contribuição' />
            <Input disabled mask='BRL' name='energyTaxes' label='Taxa de energia' />
            <Input disabled mask='BRL' name='tusdValue' label='Tusd' />
            <Input mask='cep' disabled name='address.zipcode' label='CEP' />
          </GridColumn>

          <GridColumn padding='1rem 0' columns={3} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 2 }, { breakWidth: theme.breakpoints.values.sm, columns: 1 }]}>
            <Input disabled name='address.street' label='Rua' />
            <Input disabled name='address.number' label='Número' />
            <Input disabled name='address.complement' label='Complemento' />
            <Input disabled name='address.neighborhood' label='Bairro' />
            <Input disabled name='address.city' label='Cidade' />
            <Select options={brazilStates} disabled name='address.state' label='Estado' />
          </GridColumn>
        </form>

        {proposal && <Annotations proposal={proposal} customUpdateProposal={setProposal} />}

      </FormProvider>

    </>
  )
}
