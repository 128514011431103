import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, CircularProgress, Divider, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useNavigate, useParams, Navigate, Link } from 'react-router-dom'
import * as yup from 'yup'
import { ContentTitle } from '~/components/ContentTitle'
import { Checkbox } from '~/components/Form/Checkbox'
import { Input } from '~/components/Form/Input'
import { EPowerPlantModality, PowerPlantModel, usePowerPlantCreateMutation, usePowerPlantGetQuery, usePowerPlantUpdateMutation, useRenderPowerPlantQuery } from '~/graphql/types'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Select } from '~/components/Form/Select'
import { FullPageLoader } from '~/components/FullPageLoader'
import { clearString, formatDateToInput, theme } from '~/utils'
import { useCep, usePermission } from '~/hooks'
import { mapErrorToMessage } from '~/utils/errors'
import { ApolloError } from '@apollo/client'
import { IconCheck, IconSearch } from '@tabler/icons-react'
import { Switch } from '~/components/Form/Switch'
import { GridColumn } from '~/styles'
import { brazilStates } from '~/constants'
import { Status } from '~/components'
import { addressSchema } from '~/utils/yupSchema'
import { Radio } from '~/components/Form/Radio'
import { powerPlantModalityOptions } from '~/utils/options'

export type PowerPlantForm = {
  acceptsPF: boolean
  acceptsPJ: boolean
  accessKeyExtra?: string
  accessKeyLogin?: string
  accessKeyPassword?: string
  clientNumber: string
  dealershipId: string
  generatorId: string
  installation: string
  name: string
  observation: string
  productionKwh: number
  productionMwac: number
  isCashbackActive: boolean
  useDealershipDiscountPercentage: boolean
  pfDiscount: number | null;
  pjDiscount: number | null;
  document: string,
  email: string,
  corporateName: string,
  operationStartDate: string,
  modality: EPowerPlantModality,
  address: {
    zipcode: string,
    neighborhood: string,
    state: string,
    city: string,
    street: string,
    number: string,
    complement: string | null,
  }
}

const schema = yup
  .object().shape({
    name: yup.string().required(),
    dealershipId: yup.string(),
    generatorId: yup.string(),
    installation: yup.string().required(),
    observation: yup.string().required(),
    productionKwh: yup.number().required(),
    productionMwac: yup.number().required(),
    acceptsPF: yup.boolean().required(),
    acceptsPJ: yup.boolean().required(),
    isCashbackActive: yup.boolean().required(),
    document: yup.string().required(),
    modality: yup.mixed<EPowerPlantModality>().oneOf(Object.values(EPowerPlantModality)).required(),
    email: yup.string().email().required(),
    corporateName: yup.string().required(),
    useDealershipDiscountPercentage: yup.boolean().required(),
    pfDiscount: yup.number().when(['useDealershipDiscountPercentage'], {
      is: (useDealershipDiscountPercentage: boolean) => Boolean(useDealershipDiscountPercentage),
      then: (validationSchema) => validationSchema.max(100).required(),
      otherwise: (validationSchema) => validationSchema.nullable()
    }).default(null),
    pjDiscount: yup.number().max(100).when(['useDealershipDiscountPercentage'], {
      is: (useDealershipDiscountPercentage: boolean) => Boolean(useDealershipDiscountPercentage),
      then: (validationSchema) => validationSchema.max(100).required(),
      otherwise: (validationSchema) => validationSchema.nullable()
    }).default(null),
    address: addressSchema,
    operationStartDate: yup.string().nullable(),

  })
  .required()

export const PowerPlantCreateEdit: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [selectedPowerPlant, setSelectedPowerPlant] = useState<PowerPlantModel | null>(null)
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const canCreateAndEditPowerPlants = usePermission(['powerPlants.updated', 'powerPlants.create'])
  const { fetchCep, isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })

  const { data: powerPlantDatas, loading: allIsLoading } = useRenderPowerPlantQuery({
    variables: {

      dealershipParams: {
        pageSize: 999999,
        sort: {
          field: 'createdAt',
          order: -1
        }
      },
      powerGeneratorParams: {
        pageSize: 999999,
        sort: {
          field: 'createdAt',
          order: -1
        }
      }
    },
    fetchPolicy: 'no-cache'
  })

  const powerGenerators = powerPlantDatas?.powerGeneratorList.data || []
  const dealerships = powerPlantDatas?.dealershipList.data || []

  const POWER_GENERATORS_OPTIONS = powerGenerators.map(item => ({ label: item.name, value: item._id }))

  const DEALERSHIP_OPTIONS = useMemo(() => {
    return dealerships?.map(item => ({ label: item.name || '', value: item._id }))
  }, [dealerships])

  const formMethods = useForm<PowerPlantForm>({
    resolver: yupResolver(schema)
  })
  const { isCashbackActive, acceptsPF, acceptsPJ, address, useDealershipDiscountPercentage, dealershipId } = useWatch({ control: formMethods.control })

  useEffect(() => {
    const dealershipSelected = dealerships.find(item => item._id === dealershipId)
    if (useDealershipDiscountPercentage && dealershipSelected) {

      formMethods.setValue('pfDiscount', dealershipSelected?.maxDiscountPercentagePf || 0)
      formMethods.setValue('pjDiscount', dealershipSelected?.maxDiscountPercentagePj || 0)
    }
  }, [useDealershipDiscountPercentage, dealershipId, dealerships])

  const onSearchCep = useCallback(async () => {
    if (address?.zipcode) {
      const { data, isSuccess } = await fetchCep(address?.zipcode)
      if (isSuccess) {
        formMethods.setValue('address.state', data?.uf || '')
        formMethods.setValue('address.city', data?.localidade || '')
        formMethods.setValue('address.neighborhood', data?.bairro || '')
        formMethods.setValue('address.street', data?.logradouro || '')
      }
    }
  }, [address])

  const { refetch: refetchPowerPlantGet, loading } = usePowerPlantGetQuery({
    variables: {
      id: id || ''
    },
    skip: Boolean(!id),
    onCompleted(data) {
      setSelectedPowerPlant(data.powerPlantGet as PowerPlantModel)
    },
    onError: () => {
      toast.error('Não foi possível carregar esta usina.')
    }
  })

  useEffect(() => {
    if (id) {
      refetchPowerPlantGet()
    }
    setSelectedPowerPlant(null)
  }, [id])

  const [createPowerPlant, { loading: createIsLoading }] = usePowerPlantCreateMutation({
    onCompleted: () => {

      toast.success('Uma nova usina foi cadastrada!')
      navigate('/app/power-plants')
    },
    onError: (err) => {
      const apolloError = err as ApolloError
      toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, 'Ocorreu um erro ao cadastrar uma nova usina.'))
    }
  })
  const [updatePowerPlant, { loading: updateIsLoading }] = usePowerPlantUpdateMutation({
    onCompleted: (data) => {
      setSelectedPowerPlant(null)
      toast.success(`A usina ${data.powerPlantUpdate.name} foi alterada!`)
      navigate(-1)
    },
    onError: () => {
      toast.error('Ocorreu um erro ao alterar a usina.')
    }
  })

  function onSubmit(data: PowerPlantForm) {

    if (id && selectedPowerPlant) {
      formMethods.unregister('generatorId')
      formMethods.unregister('dealershipId')
      updatePowerPlant({
        variables: {
          params:
          {
            _id: id,
            acceptsPF: data.acceptsPF,
            acceptsPJ: data.acceptsPJ,
            name: data.name,
            installation: data.installation,
            observation: data.observation,
            productionKwh: data.productionKwh,
            productionMwac: data.productionMwac,
            accessKeyExtra: data.accessKeyExtra,
            accessKeyLogin: data.accessKeyLogin,
            accessKeyPassword: data.accessKeyPassword,
            clientNumber: data.clientNumber,
            corporateName: data.corporateName,
            document: clearString(data.document),
            email: data.email,
            operationStartDate: data.operationStartDate,
            modality: data.modality,
            address: {
              ...data.address,
              country: 'Brasil'
            },
            ...!data.useDealershipDiscountPercentage && {
              discountConfiguration: {
                useDealershipDiscountPercentage: data.useDealershipDiscountPercentage,
                discount: {
                  pf: data.pfDiscount || 0,
                  pj: data.pjDiscount || 0
                }
              },

            },

            cashbackInfo: {
              active: data.isCashbackActive,
            }

          }
        }
      })
    } else {
      createPowerPlant({
        variables: {
          params: {
            acceptsPF: data.acceptsPF,
            acceptsPJ: data.acceptsPJ,
            clientNumber: data.clientNumber,
            corporateName: data.corporateName,
            dealershipId: data.dealershipId,
            email: data.email,
            generatorId: data.generatorId,
            installation: data.installation,
            name: data.name,
            observation: data.observation,
            productionKwh: data.productionKwh,
            productionMwac: data.productionMwac,
            accessKeyExtra: data.accessKeyExtra,
            accessKeyLogin: data.accessKeyLogin,
            accessKeyPassword: data.accessKeyPassword,
            operationStartDate: new Date(data.operationStartDate).toISOString(),
            document: clearString(data.document),
            modality: data.modality,
            address: {
              ...data.address,
              country: 'Brasil'
            },
            discountConfiguration: {
              useDealershipDiscountPercentage: data.useDealershipDiscountPercentage,

              discount: {
                pf: data.pfDiscount || 0,
                pj: data.pjDiscount || 0
              }
            },
            cashbackInfo: {
              active: data.isCashbackActive,
            }
          }
        }
      })
    }
  }

  useEffect(() => {
    if (id && selectedPowerPlant) {
      formMethods.setValue('name', selectedPowerPlant?.name)
      formMethods.setValue('installation', selectedPowerPlant?.installation)
      formMethods.setValue('observation', selectedPowerPlant?.observation)
      formMethods.setValue('dealershipId', selectedPowerPlant?.dealershipId)
      formMethods.setValue('generatorId', selectedPowerPlant?.generatorId)
      formMethods.setValue('productionKwh', selectedPowerPlant?.productionKwh)
      formMethods.setValue('productionMwac', selectedPowerPlant?.productionMwac)
      formMethods.setValue('acceptsPF', selectedPowerPlant?.acceptsPF)
      formMethods.setValue('acceptsPJ', selectedPowerPlant?.acceptsPJ)
      if (selectedPowerPlant.address) {
        formMethods.setValue('address.zipcode', selectedPowerPlant?.address.zipcode)
        formMethods.setValue('address.state', selectedPowerPlant?.address.state)
        formMethods.setValue('address.city', selectedPowerPlant?.address.city)
        formMethods.setValue('address.neighborhood', selectedPowerPlant?.address.neighborhood)
        formMethods.setValue('address.street', selectedPowerPlant?.address.street)
        formMethods.setValue('address.number', selectedPowerPlant?.address.number)
        formMethods.setValue('address.complement', selectedPowerPlant?.address.complement || '')
      }
      if (selectedPowerPlant.modality) formMethods.setValue('modality', selectedPowerPlant?.modality)

      formMethods.setValue('clientNumber', selectedPowerPlant?.clientNumber || '')
      formMethods.setValue('accessKeyExtra', selectedPowerPlant?.accessKeyExtra || '')
      formMethods.setValue('accessKeyLogin', selectedPowerPlant?.accessKeyLogin || '')
      formMethods.setValue('accessKeyPassword', selectedPowerPlant?.accessKeyPassword || '')
      formMethods.setValue('isCashbackActive', selectedPowerPlant?.cashbackInfo?.active || false)

      formMethods.setValue('email', selectedPowerPlant?.email || '')
      formMethods.setValue('operationStartDate', formatDateToInput(selectedPowerPlant?.operationStartDate))
      formMethods.setValue('document', selectedPowerPlant?.document || '')
      formMethods.setValue('corporateName', selectedPowerPlant?.corporateName || '')
      formMethods.setValue('pfDiscount', selectedPowerPlant?.discountConfiguration?.discount?.pf || null)
      formMethods.setValue('pjDiscount', selectedPowerPlant?.discountConfiguration?.discount?.pj || null)
      formMethods.setValue('useDealershipDiscountPercentage', selectedPowerPlant?.discountConfiguration?.useDealershipDiscountPercentage || false)
    } else {
      formMethods.reset({
        dealershipId: '',
        generatorId: '',
        acceptsPF: false,
        acceptsPJ: false,
        accessKeyExtra: '',
        accessKeyLogin: '',
        accessKeyPassword: '',
        name: '',
        address: {
          city: '',
          complement: '',
          neighborhood: '',
          number: '',
          state: '',
          street: '',
          zipcode: ''
        },
        clientNumber: '',
        corporateName: '',
        document: '',
        email: '',
        installation: '',
        isCashbackActive: false,
        modality: EPowerPlantModality.gd1,
        observation: '',
        operationStartDate: '',
        pfDiscount: 0,
        pjDiscount: 0,
        productionKwh: 0,
        productionMwac: 0,
        useDealershipDiscountPercentage: true,
      })
    }
  }, [selectedPowerPlant])

  useEffect(() => {
    if (!acceptsPF && !acceptsPJ) {
      formMethods.setValue('isCashbackActive', false)
    }
  }, [acceptsPF, acceptsPJ])

  if (!canCreateAndEditPowerPlants) {
    return <Navigate replace to='/app' />
  }

  if (id && loading || allIsLoading) return <FullPageLoader />

  return (
    <div>
      <Card sx={{ background: 'white', padding: '1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title={`${id && selectedPowerPlant ? `Alterar a usina ${selectedPowerPlant.name}` : 'Cadastrar usina'}`}
          description={`Complete os dados para ${id && selectedPowerPlant ? 'alterar' : 'cadastrar uma'} Usina`}
          breadcrumbLinks={{ currentLink: `${id && selectedPowerPlant ? 'Alterar' : 'Cadastrar'} Usina`, links: [{ href: '/app/power-plants', label: 'Usinas' }] }}
        />

        <Divider sx={{ marginBottom: '2rem' }} />

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
            <Box minHeight='100vh' display='flex' flexDirection='column' gap='1rem' >

              <Typography fontWeight={500} variant='h5'>Dados básicos</Typography>

              <GridColumn gap='1.2rem 2rem' columns={2} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 1 }]}>
                <Input name='name' label='Nome da Usina' />
                <Input name='installation' label='Instalação' />
                <Input name='corporateName' label='Razão Social' />
                <Input name='document' mask='cnpj' label='CNPJ' />
                <Select disabled={Boolean(selectedPowerPlant?._id)} label='Gerador' name='generatorId' options={POWER_GENERATORS_OPTIONS || []} />
                <Select disabled={Boolean(selectedPowerPlant?._id)} label='Concessionária' name='dealershipId' options={DEALERSHIP_OPTIONS || []} />
                <Input name='email' label='Email da usina' />
                <Input InputLabelProps={{ shrink: true }} type='date' name='operationStartDate' label='Data de início de operação' />
              </GridColumn>

              <Typography paddingTop='1rem' fontWeight={500} variant='body1'>Modalidade de geração</Typography>

              <Radio row name='modality' options={powerPlantModalityOptions} />

              <Typography paddingTop='1rem' fontWeight={500} variant='h5'>Endereço da usina</Typography>

              <GridColumn
                gap='0 2rem'
                columns={3}
                responsive={[{ breakWidth: theme.breakpoints.values.lg, columns: 2 }, { breakWidth: theme.breakpoints.values.md, columns: 1 }]}
              >
                <Input
                  icons={{ end: { element: fetchCepIsLoading ? <CircularProgress size={18} color='inherit' /> : <IconButton onClick={() => onSearchCep()}><IconSearch /></IconButton> } }}
                  mask='cep'
                  name='address.zipcode'
                  label='CEP'
                />
                <></>
                <></>
              </GridColumn>

              <GridColumn gap='1.2rem 2rem' columns={3} responsive={[{ breakWidth: theme.breakpoints.values.lg, columns: 2 }, { breakWidth: theme.breakpoints.values.md, columns: 1 }]}>
                <Input name='address.street' label='Rua' />
                <Input name='address.number' label='Número' />
                <Input name='address.complement' label='Complemento(Opcional)' />
                <Input name='address.neighborhood' label='Bairro' />
                <Input name='address.city' label='Cidade' />
                <Select options={brazilStates} name='address.state' />
              </GridColumn>

              <Typography paddingTop='1rem' fontWeight={500} variant='h5'>Credenciais</Typography>

              <GridColumn gap='1.2rem 2rem' columns={2} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 1 }]}>
                <Input name='clientNumber' label='Número do cliente na concessionária' />
                <Input name='accessKeyExtra' label='Chave de acesso concessionária' />
                <Input autoComplete='new-password' name='accessKeyLogin' label='Login concessionária' />
                <Input autoComplete='new-password' name='accessKeyPassword' label='Senha concessionária' />
              </GridColumn>

              <Typography paddingTop='1rem' fontWeight={500} variant='h5'>Produção</Typography>

              <GridColumn gap='1.2rem 2rem' columns={2} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 1 }]}>
                <Input type='number' name='productionKwh' label='Produção Kwh' />
                <Input type='number' name='productionMwac' label='Produção Mwac' />
                <Input name='observation' label='Observação' />
              </GridColumn>

              <Box display='flex' gap='1.2rem'>
                <Checkbox name='acceptsPF' label='Aceita PF' />
                <Checkbox name='acceptsPJ' label='Aceita PJ' />
              </Box>

              <Box display='flex' flexDirection='column' gap='0'>
                <Typography paddingTop='1rem' fontWeight={500} variant='h5'>Parâmetros de desconto</Typography>
                <Typography paddingTop='1rem' fontWeight={500} variant='body1'>Usar porcentagem de desconto cadastrada na concessionária?</Typography>
              </Box>
              {/* <Checkbox name='useDealershipDiscountPercentage' label='Sim' /> */}
              <Radio name='useDealershipDiscountPercentage' row options={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]} />

              <GridColumn gap='1.2rem 2rem' columns={2} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 1 }]}>
                <Input disabled={useDealershipDiscountPercentage} mask='percentage' name='pfDiscount' label='Desconto PF' />
                <Input disabled={useDealershipDiscountPercentage} mask='percentage' name='pjDiscount' label='Desconto PJ' />
              </GridColumn>

              {/*<GridColumn columns={2} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 1 }]}>
                <Select
                  sx={{ marginBottom: '1rem' }}
                  options={DISCOUNT_TABLE_OPTIONS || []}
                  name='discountTable'
                  label='Tabela de desconto'
                />
              </GridColumn> */}

              <Box sx={{
                background: theme.palette.grey[100],
                borderRadius: '1rem',
                border: `1px solid ${theme.palette.grey[300]}`,
                width: '100%',
                padding: '2rem'
              }}>
                <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', justifyContent: 'space-between', flexFlow: isLowerSm ? 'column' : 'row', gap: '1rem' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}>
                    <Typography color={isCashbackActive ? theme.palette.grey[800] : theme.palette.grey[400]} fontWeight={500} variant='h5'>Cashback</Typography>
                    {selectedPowerPlant?.cashbackInfo?.active ?
                      <Status background={theme.palette.success.light} text='Ativo' color={theme.palette.success.main} />
                      :
                      <Status background={theme.palette.grey['300']} text='Inativo' color={theme.palette.grey[400]} />
                    }
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}>
                    <Typography color={isCashbackActive ? theme.palette.grey[800] : theme.palette.grey[400]}>Habilitar cashback</Typography>
                    <Switch name='isCashbackActive' />
                  </Box>
                </Box>

              </Box>

              <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexFlow: isLowerSm ? 'column-reverse' : 'row',
                gap: isLowerSm ? '1rem' : '0'
              }}>

                <Link style={{ width: isLowerSm ? '100%' : '160px' }} to='/app/power-plants'>
                  <Button sx={{ maxWidth: isLowerSm ? '100%' : '160px' }} fullWidth disabled={createIsLoading || updateIsLoading} color='secondary'>
                    Cancelar
                  </Button>
                </Link>

                <Button
                  startIcon={<IconCheck />}
                  sx={{ maxWidth: isLowerSm ? '100%' : '220px' }}
                  fullWidth
                  disabled={createIsLoading || updateIsLoading}
                  type='submit'>
                  {id && selectedPowerPlant ? 'Alterar' : 'Cadastrar'} Usina
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </div>
  )
}
