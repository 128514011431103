import { Box, Button } from '@mui/material'
import { UserBanner } from './components'
import { ContentTitle } from '~/components/ContentTitle'
import { InfoCards } from './components/InfoCards'

export const Home: React.FC = () => {
  return (
    <Box display='flex' flexDirection='column'>
      <ContentTitle
        description=''
        title='Bem vindo 🎉 👋'
        style={{ padding: '0px 0px 24px 0px' }}
        breadcrumbLinks={{ currentLink: 'Visão Geral', links: [{ href: '/app', label: 'Home' }] }}
      />
      <UserBanner />
      <InfoCards />
    </Box>
  )
}
