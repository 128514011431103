/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { ProposalModel } from '~/graphql/types'
import { UploadMultiFilesParams, uploadMultiFiles as restApiUploadMultiFiles } from '~/services/uploadFile'

type UploadFileState = {
  loading: boolean
  data: ProposalModel | null
  response: 'success' | 'error' | null
}

export type UseUploadMultiFilesOutput = [
  uploadMultiFiles: (params: UploadMultiFilesParams) => Promise<ProposalModel | null>,
  uploadMultiFilesState: UploadFileState
]
export const useUploadMultiFiles = (): UseUploadMultiFilesOutput => {
  const [uploadMultiFilesState, setUploadMultiFileState] = useState<UploadFileState>({ data: null, loading: false, response: null })

  const uploadMultiFiles = async (params: UploadMultiFilesParams) => {
    setUploadMultiFileState({ ...uploadMultiFilesState, loading: true })

    try {
      const updatedProposal = await restApiUploadMultiFiles(params)
      setUploadMultiFileState({
        loading: false,
        response: 'success',
        data: updatedProposal || null
      })

      return updatedProposal
    } catch (err) {
      setUploadMultiFileState({
        loading: false,
        response: 'error',
        data: null
      })

      return null
    }
  }

  return [uploadMultiFiles, uploadMultiFilesState]
}
